export const AddUpdateTask = (taskPayload) => {
    return {
        type: "ADD_UPDATE_TASK",
        payload: { taskPayload },
    };
};

export const AddUpdateTaskSuccess = (taskPayload) => {
    return {
        type: "ADD_UPDATE_TASK_SUCCESS",
        payload: taskPayload,
    };
};

export const setShowTaskGetList = (showTaskGetList) => {
    return {
        type: "SET_SHOW_TASK_LIST",
        payload: showTaskGetList,
    };
};

export const GetTasks = () => {
    return {
        type: "GET_ALL_TASKS",
    };
};

export const GetTasksSuccess = (taskPayload) => {
    return {
        type: "GET_ALL_TASKS_SUCCESS",
        payload: taskPayload,
    };
};

export const GetTaskById = (id) => {
    return {
        type: "GET_TASK",
        payload: { id },
    };
};

export const GetTaskSuccess = (taskPayload) => {
    return {
        type: "GET_TASK_SUCCESS",
        payload: taskPayload,
    };
};

export const GetProjectInfoById = (id) => {
    return {
        type: "GET_PROJECTINFO",
        payload: { id },
    };
};

export const GetProjectInfoByIdSuccess = (taskPayload) => {
    return {
        type: "GET_PROJECTINFO_SUCCESS",
        payload: taskPayload,
    };
};

export const TaskAPIError = (error) => {
    return {
        type: "Task_API_ERROR",
        payload: error,
    };
};

export const SetTaskDefaults = (projectId, parentTaskId) => {
    return {
        type: "SET_TASK_DEFAULTS",
        payload: { projectId, parentTaskId },
    };
};

export const ClearTaskDefaults = () => {
    return {
        type: "CLEAR_TASK_DEFAULTS",
    };
};

export const AddUpdateTaskTimeline = (taskPayload) => {
    return {
        type: "ADD_UPDATE_TASK_TIMELINE",
        payload: { taskPayload },
    };
};

export const AddUpdateTaskTimelineSuccess = (taskPayload) => {
    return {
        type: "ADD_UPDATE_TASK_TIMELINE_SUCCESS",
        payload: taskPayload,
    };
};

export const SetDefaultProject = (projectId) => {
    return {
        type: "SET_DEFAULT_PROJECT",
        payload: projectId,
    };
};

export const ClearDefaultProject = () => {
    return {
        type: "CLEAR_DEFAULT_PROJECT",
    };
};