import axios from "axios"
import { post, del, get, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// const API_URL = process.env.API_URL || "http://localhost:5000/api";
const API_URL = process.env.API_URL || "http://localhost:5100/api"
// Register Method
const postLogin = async data => {
  debugger
  try {
    const response = await axios.post(`${API_URL}/auth/login`, data)
    if (response.status >= 200 && response.status <= 299) {
      return response.data
    }
    throw response.data
  } catch (err) {
    let message
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found"
          break
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team"
          break
        case 401:
          message = "Invalid credentials"
          break
        default:
          message =
            err.response.data?.error ||
            err.response.statusText ||
            "An error occurred"
          break
      }
    }
    message =
      err.response.data?.error || err.response.statusText || "An error occurred"
    throw message
  }
}

// Login Method
// const postLogin = (data) => post(`${API_URL}/auth/login`, data);

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

export const addMessage = message => post(url.ADD_MESSAGE, message)

// delete message
export const deleteMessage = data =>
  del(url.DELETE_MESSAGE, { headers: { data } })

// get tasks
export const getTasks = () => get(url.GET_TASKS)

// add CardData Kanban
export const addCardData = cardData => post(url.ADD_CARD_DATA, cardData)

// update jobs
export const updateCardData = card => put(url.UPDATE_CARD_DATA, card)

// delete Kanban
export const deleteKanban = kanban =>
  del(url.DELETE_KANBAN, { headers: { kanban } })

// export const getUserProfile = () => get(url.GET_USER_PROFILE)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtForgetPwd,
  postJwtProfile,
}
