import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Row, Col, Label, FormFeedback, Form, Input, Button } from "reactstrap";
import Select from 'react-select';
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { AddUpdateProject, AddUpdateTask, GetDirectoryList, GetDropdown, GetMultipleDropdown, GetProjectInfoById, GetTasks, setShowProjectGetList } from "../../store/actions";
import { toast } from "react-toastify";
const apiUrl = process.env.REACT_APP_API_URL;

const AddProject = (props) => {
    const dispatch = useDispatch();
    const tasksFromStore = useSelector((state) => state.Task?.tasks?.data) || [];
    const projectInfoById = useSelector((state) => state.Task?.projectInfoById?.data) || [];
    
    const multipleDropdownList = useSelector((state) => state.CommonAPI?.multipleDropdownValues?.data) || [];
    const directoryList = useSelector((state) => state.CommonAPI?.directoryList) || [];
    
    const [projectList, setProjectList] = useState([]);
    const [projectGroupListing, setProjectGroupListing] = useState([]);
    const [projectStatus, setProjectStatus] = useState([]);
    const [projectCategory, setProjectCategory] = useState([]);
    const [organizationList, setOrganizationList] = useState([]);
    const [projectPlatformList, setProjectPlatformList] = useState([]);
    const [projectTechStackList, setProjectTechStackList] = useState([]);
    const [normalizedProjectStatus, setNormalizedProjectStatus] = useState([]);

    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedProjectGroupID, setSelectedProjectGroupID] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedProjectCategory, setSelectedProjectCategory] = useState(null);
    const [selectedClientID, setSelectedClientID] = useState(null);
    const [selectedProjectManager, setSelectedProjectManager] = useState(null);
    const [selectedDefaultAssignee, setSelectedDefaultAssignee] = useState(null);

    const [selectedAccess, setSelectedAccess] = useState(null);
    const [accessList, setAccessList] = useState([]);
    const [selectedTechStack, setSelectedTechStack] = useState(null);
    const [techStackList, setTechStackList] = useState([]);
    const [selectedPlatform, setSelectedPlatform] = useState(null);
    const [platformList, setPlatformList] = useState([]);

    const [inputProjectValue, setInputProjectValue] = useState('');
    const [inputAccessValue, setInputAccessValue] = useState('');
    const [inputTechStackValue, setInputTechStackValue] = useState('');
    const [inputPlatformValue, setInputPlatformValue] = useState('');
    const [inputProjectFocus, setInputProjectFocus] = useState(false);
    const [inputAccessFocus, setInputAccessFocus] = useState(false);
    const [inputTechStackFocus, setInputTechStackFocus] = useState(false);
    const [inputPlatformFocus, setInputPlatformFocus] = useState(false);

    const [selectedNormalizedStatus, setSelectedNormalizedStatus] = useState(null);
    const [projectStatusInput, setProjectStatusInput] = useState("");
    const [addedProjectStatuses, setAddedProjectStatuses] = useState([]);

    const handleAddProjectStatus = () => {
        if (selectedNormalizedStatus && projectStatusInput) {
          const newStatus = {
            ProjectID: '',
            NormalizedStatus: selectedNormalizedStatus.label,
            ProjectTaskStatus: projectStatusInput,
          };
          setAddedProjectStatuses((prevStatuses) => [...prevStatuses, newStatus]);
          setSelectedNormalizedStatus(null);
          setProjectStatusInput("");
        }
      };
    
      const handleRemoveProjectStatus = (index) => {
        setAddedProjectStatuses((prevStatuses) => prevStatuses.filter((_, i) => i !== index));
      };



    useEffect(() => {
        dispatch(GetMultipleDropdown({
            "Input": [
                { "StringMapType": "PROJECTLIST", "OrderBy": "StringMapName" },
                { "StringMapType": "PROJECTGROUPLIST", "OrderBy": "StringMapName" },
                { "StringMapType": "PROJECTSTATUS", "OrderBy": "StringMapName" },
                { "StringMapType": "PROJECTCATEGORY", "OrderBy": "StringMapName" },
                { "StringMapType": "OrganizationList", "OrderBy": "StringMapName" },
                { "StringMapType": "PROJECTPLATFORM", "OrderBy": "StringMapName" },
                { "StringMapType": "PROJECTTECHSTACK", "OrderBy": "StringMapName" },
                { "StringMapType": "NORMALIZEDSTATUS", "OrderBy": "StringMapName" }
            ]
        }));

        dispatch(GetDirectoryList());
    }, [dispatch])

    useEffect(() => {
        const projectListFiltered = multipleDropdownList
            .filter(item => item.StringMapType === "PROJECTLIST")
            .sort((a, b) => a.StringMapName.localeCompare(b.StringMapName));
        const projectGroupListFiltered = multipleDropdownList
            .filter(item => item.StringMapType === "PROJECTGROUPLIST")
            .sort((a, b) => a.StringMapName.localeCompare(b.StringMapName));
        const projectStatusFiltered = multipleDropdownList
            .filter(item => item.StringMapType === "PROJECTSTATUS")
            .sort((a, b) => a.StringMapName.localeCompare(b.StringMapName));
        const projectCategoryFiltered = multipleDropdownList
            .filter(item => item.StringMapType === "PROJECTCATEGORY")
            .sort((a, b) => a.StringMapName.localeCompare(b.StringMapName));
        const organizationListFiltered = multipleDropdownList
            .filter(item => item.StringMapType === "OrganizationList")
            .sort((a, b) => a.StringMapName.localeCompare(b.StringMapName));
        const projectPlatformListFiltered = multipleDropdownList
            .filter(item => item.StringMapType === "PROJECTPLATFORM")
            .sort((a, b) => a.StringMapName.localeCompare(b.StringMapName))
            .map(item => ({
                value: item.StringMapId,
                label: item.StringMapName
            }));
        const projectTechStackListFiltered = multipleDropdownList
            .filter(item => item.StringMapType === "PROJECTTECHSTACK")
            .sort((a, b) => a.StringMapName.localeCompare(b.StringMapName))
            .map(item => {
                const hasIcon = item.TechStackIcon;
                const iconURL = hasIcon
                    ? `${apiUrl}${item.TechStackIcon}`
                    : generatePlaceholderProfile(item.StringMapName, item.StringMapName);

                return {
                    label: item.StringMapName,
                    value: item.StringMapId,
                    iconURL,
                };
            });
        const normalizedStatusListFiltered = multipleDropdownList
            .filter(item => item.StringMapType === "NORMALIZEDSTATUS")
            .sort((a, b) => a.StringMapName.localeCompare(b.StringMapName))
            .map(item => ({
                value: item.StringMapId,
                label: item.StringMapName
            }));

        setProjectList(projectListFiltered);
        setProjectGroupListing(projectGroupListFiltered);
        setProjectStatus(projectStatusFiltered);
        setProjectCategory(projectCategoryFiltered);
        setOrganizationList(organizationListFiltered);
        setProjectPlatformList(projectPlatformListFiltered);
        setProjectTechStackList(projectTechStackListFiltered);
        setNormalizedProjectStatus(normalizedStatusListFiltered);

    }, [multipleDropdownList.length, dispatch]);

    const generatePlaceholderProfile = (firstName, lastName) => {
        const initials = `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
        const canvas = document.createElement('canvas');
        canvas.width = 100;
        canvas.height = 100;
        const context = canvas.getContext('2d');
    
        context.fillStyle = '#7A6FBE';
        context.fillRect(0, 0, canvas.width, canvas.height);
    
        context.font = '50px Arial';
        context.fillStyle = 'white';
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(initials, canvas.width / 2, canvas.height / 2);
    
        return canvas.toDataURL('image/png');
    };

    const createAssociationsList = (type) => {
        return directoryList
            .map(item => {
                if (type === 'Access') {
                    const hasProfilePhoto = item.PicturePath;
                    const profilePhoto = hasProfilePhoto
                        ? `${apiUrl}${item.PicturePath}`
                        : generatePlaceholderProfile(item.FirstName, item.LastName);

                    return {
                        label: item.FullName,
                        value: item.PersonId,
                        profilePhoto,
                        firstName: item.FirstName,
                        lastName: item.LastName,
                    };
                }
            });
    };

    const projectAccessList = useMemo(() => {
        const list = createAssociationsList('Access');
        return list.sort((a, b) => a.label.localeCompare(b.label));
    }, [directoryList]);  

    const mapToOptions = useCallback((data) => {
        return data.map(item => ({
            label: item.StringMapName,
            value: item.StringMapId
        }));
    }, []);
    
    const projectGroupList = useMemo(() => mapToOptions(projectGroupListing), [projectGroupListing, mapToOptions]);
    const projectStatusList = useMemo(() => mapToOptions(projectStatus), [projectStatus, mapToOptions]);
    const projectCategoryList = useMemo(() => mapToOptions(projectCategory), [projectCategory, mapToOptions]);
    const clientOrganizationList = useMemo(() => mapToOptions(organizationList), [organizationList, mapToOptions]);

    const handleAddItem = (list, setList, selectedItem, type) => {
        if (selectedItem && !list.some(item => item.AssociationValue === selectedItem.value)) {
            const newItem = {
                Status: "Active",
                SortOrder: list.length + 1,
                AssociationType: type,
                AssociationValue: selectedItem.value,
                AssociationName: selectedItem.label,
                IconURL: selectedItem.iconURL || selectedItem.profilePhoto
            };
            setList([...list, newItem]);
        }
        setSelectedTechStack(null);
        setSelectedPlatform(null);
        setSelectedAccess(null);
    };

    const handleRemoveItem = (list, setList, item) => {
        setList(prevList => prevList.filter(i => i.AssociationValue !== item.AssociationValue)
            .map((i, index) => ({ ...i, SortOrder: index + 1 })));
    };

    const handleAddTechStack = () => handleAddItem(techStackList, setTechStackList, selectedTechStack, 'TechStack');
    const handleRemoveTechStack = (item) => handleRemoveItem(techStackList, setTechStackList, item);

    const handleAddPlatform = () => handleAddItem(platformList, setPlatformList, selectedPlatform, 'Platform');
    const handleRemovePlatform = (item) => handleRemoveItem(platformList, setPlatformList, item);

    const handleAddAccess = () => handleAddItem(accessList, setAccessList, selectedAccess, 'Access');
    const handleRemoveAccess = (item) => handleRemoveItem(accessList, setAccessList, item);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            ProjectGroupID: '',
            ProjectName: '',
            Status: '',
            ProjectCode: '',
            ProjectIconURL: '',
            ProjectCategory: '',
            ClientOrganizationID: '',
            ProjectManager: '',
        },
        validationSchema: Yup.object({
            ProjectGroupID: Yup.string().required("Please Select a Project Group"),
            ProjectName: Yup.string().required("Please Enter a Project Name"),
            Status: Yup.string().required("Please Select a Status"),
            ProjectCode: Yup.string()
            .required("Please enter a Project Code")
            .length(3, "Project Code must be exactly 3 characters")
            .matches(
                /^[A-Za-z]{3}$/,
                "Project Code must only contain alphabets"
            ),        
            ProjectIconURL: Yup.string()
                .url("Please enter a valid URL"),
            ProjectCategory: Yup.string().required("Please Select a Category"),
            ClientOrganizationID: Yup.string().required("Please Select a Client"),
            ProjectManager: Yup.string().required("Please Select a Project Manager"),
        }),
        onSubmit: async (values) => {

            const requiredStatuses = ["Not Started", "In Progress", "Completed"];

            const existingStatuses = addedProjectStatuses.map(
                (status) => status.NormalizedStatus
            );
    
            const missingStatuses = requiredStatuses.filter(
                (status) => !existingStatuses.includes(status)
            );
    
            if (missingStatuses.length > 0) {
                missingStatuses.forEach((status) => {
                    toast.error(`Missing status: ${status}`);
                });
                return;
            }
            
            console.log("status values", addedProjectStatuses);

            const combinedAssociations = [
                ...accessList.map(access => ({ ...access })),
                ...platformList.map(platform => ({ ...platform })),
                ...techStackList.map(techStack => ({ ...techStack }))
            ];
        
            values.Associations = combinedAssociations;
            values.ProjectStatus = addedProjectStatuses;
            
            console.log("add project values", values);

            dispatch(AddUpdateProject(values));
        }
    });

    const handleSelectChange = (selectedOption, field) => {
        switch (selectedOption) {
            case 'ProjectID':
                setSelectedProject(field);
                validation.setFieldValue('ProjectID', field ? field.value : '');
                break;
            case 'ProjectGroupID':
                setSelectedProjectGroupID(field);
                validation.setFieldValue('ProjectGroupID', field ? field.value : '');
                break;
            case 'Status':
                setSelectedStatus(field);
                validation.setFieldValue('Status', field ? field.label : '');
                break;
            case 'ProjectCategory':
                setSelectedProjectCategory(field);
                validation.setFieldValue('ProjectCategory', field ? field.label : '');
                break;
            case 'ClientOrganizationID':
                setSelectedClientID(field);
                validation.setFieldValue('ClientOrganizationID', field ? field.value : '');
                break;
            case 'ProjectManager':
                setSelectedProjectManager(field);
                validation.setFieldValue('ProjectManager', field ? field.value : '');
                break;
            case 'DefaultAssignee':
                setSelectedDefaultAssignee(field);
                validation.setFieldValue('DefaultAssignee', field ? field.value : '');
                break;
            default:
                break;
        }
    };

    const selectStyles = (hasError) => ({
        control: (provided, state) => ({
            ...provided,
            borderColor: hasError ? 'red' : provided.borderColor,
            boxShadow: 'none',
            '&:hover': {
                borderColor: hasError ? 'red' : provided['&:hover'].borderColor,
            },
        }),
        outline: "none",
    });

    const selectStylesWithImage = (hasError) => ({
        control: (provided, state) => ({
            ...provided,
            borderColor: hasError ? 'red' : provided.borderColor,
            boxShadow: 'none',
            minHeight: '38px',
            height: '38px',
            '&:hover': {
                borderColor: hasError ? 'red' : provided['&:hover'].borderColor,
            },
        }),
        dropdownIndicator: () => ({
            display: 'none',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0 8px',
            display: 'flex',
            alignItems: 'center',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            margin: 0,
        }),
        outline: "none",
    });

    const selectStylesWithImage2 = (hasError) => ({
        control: (provided, state) => ({
            ...provided,
            borderColor: hasError ? 'red' : provided.borderColor,
            boxShadow: 'none',
            minHeight: '38px',
            height: '38px',
            '&:hover': {
                borderColor: hasError ? 'red' : provided['&:hover'].borderColor,
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0 8px',
            display: 'flex',
            alignItems: 'center',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            margin: 0,
        }),
        outline: "none",
    });

    const CustomOption = (props) => (
        <div {...props.innerProps} className="d-flex align-items-center p-2">
            <img src={props.data.ProjectIconURL} alt="" style={{ width: 20, height: 20, marginRight: 8 }} />
            <span>{props.data.label}</span>
        </div>
    );

    const CustomSingleValue = (props) => (
        <div className="d-flex align-items-center" style={{ height: '100%', margin: 0, padding: 0 }}>
            <img src={props.data.ProjectIconURL} alt="" style={{ width: 20, height: 20, marginRight: 8 }} />
            <span>{props.data.label}</span>
        </div>
    );  

    const CustomOption2 = (props) => (
        <div {...props.innerProps} className="d-flex align-items-center p-2">
            <img src={props.data.profilePhoto || props.data.iconURL} alt="" style={{ width: 24, height: 24, marginRight: 8, borderRadius: '50%', objectFit: 'cover' }} />
            <span>{props.data.label}</span>
        </div>
    );

    const CustomSingleValue2 = (props) => (
        <div className="d-flex align-items-center" style={{ height: '100%', margin: 0, padding: 0 }}>
            <img 
                src={props.data.profilePhoto || props.data.iconURL}
                alt={props.data.label || 'Profile Image'} 
                style={{ width: 24, height: 24, marginRight: 8, borderRadius: '50%', objectFit: 'cover' }} 
            />
            <span>{props.data.label}</span>
        </div>
    ); 

    return (
        <React.Fragment>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit()
                    return false;
                }}
            >

                {/* ProjectGroupID Code */}
                <Row>
                    <Col lg={3} className="mb-2">
                        <Label htmlFor="ProjectGroupID" className="col-form-label">
                            Project Group<span className="text-danger">*</span>
                        </Label>
                    </Col>
                    <Col lg={3} className="mb-2">
                        <Select
                            id="ProjectGroupID"
                            name="ProjectGroupID"
                            options={projectGroupList}
                            onChange={(selectedOption) => handleSelectChange('ProjectGroupID', selectedOption)}
                            onBlur={validation.handleBlur}
                            value={selectedProjectGroupID}
                            placeholder="Select Project Group"
                            isSearchable={true}
                            styles={selectStyles(validation.touched.ProjectGroupID && validation.errors.ProjectGroupID)}
                            className="mb-2"
                            classNamePrefix="react-select"
                        />

                        {validation.touched.ProjectGroupID && validation.errors.ProjectGroupID ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.ProjectGroupID}</FormFeedback>
                        ) : null}
                    </Col>
                </Row>

                {/* ProjectName Code */}
                <Row>
                    <Col lg={3} className="mb-3">
                        <Label htmlFor="ProjectName" className="col-form-label">
                            Project Name<span className="text-danger">*</span>
                        </Label>
                    </Col>
                    <Col lg={6} className="mb-3">
                        <Input
                            id="ProjectName"
                            name="ProjectName"
                            type="text"
                            className="form-control validate"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.ProjectName}
                            invalid={
                                validation.touched.ProjectName && validation.errors.ProjectName
                                    ? true
                                    : false
                            } />

                        {validation.touched.ProjectName && validation.errors.ProjectName ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.ProjectName}</FormFeedback>
                        ) : null}
                    </Col>
                </Row>

                {/* Access Code */}
                <Row>
                    <Col lg={3}>
                        <Label htmlFor="Access" className="col-form-label">
                            Access
                        </Label>
                    </Col>
                    <Col lg={3}>
                        <Select
                            id="Access"
                            name="Access"
                            options={projectAccessList}
                            value={selectedAccess}
                            onChange={setSelectedAccess}
                            placeholder={inputAccessFocus ? "" : "Select Access"}
                            isSearchable={true}
                            onFocus={() => setInputAccessFocus(true)}
                            onBlur={() => setInputAccessFocus(false)}
                            inputValue={inputAccessValue}
                            onInputChange={(value) => setInputAccessValue(value)}
                            menuIsOpen={inputAccessValue.length > 0}
                            className="mb-3"
                            classNamePrefix="react-select"
                            styles={selectStylesWithImage()}
                            components={{ Option: CustomOption2, SingleValue: CustomSingleValue2 }}
                        />
                    </Col>
                    <Col lg={3} className="mb-3">
                        <Button
                            color="primary"
                            className="ms-2"
                            onClick={handleAddAccess}
                        >
                            +
                        </Button>
                    </Col>
                </Row>

                {accessList.length > 0 && (
                    <Row>
                        <Col lg={3} className="mb-2"></Col>
                        <Col lg={9} className="mb-2">
                            <div className="d-flex flex-wrap">
                                {accessList.map((access, index) => (
                                    <div
                                        key={index}
                                        className="d-flex align-items-center me-3 mb-2 p-2 border rounded"
                                    >
                                        <img
                                            src={access.IconURL}
                                            alt={access.AssociationName || 'Profile Image'}
                                            style={{ width: 24, height: 24, marginRight: 8, borderRadius: '50%', objectFit: 'cover' }}
                                        />
                                        <span>{access.AssociationName}</span>
                                        <Button
                                            color="danger"
                                            size="sm"
                                            className="ms-2"
                                            style={{ padding: '0px 5px', borderRadius: "50%" }}
                                            type="button"
                                            onClick={() => handleRemoveAccess(access)}
                                        >
                                            &times;
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                )}

                {/* Status Code */}
                <Row>
                    <Col lg={3} className="mb-2">
                        <Label htmlFor="Status" className="col-form-label">
                            Status<span className="text-danger">*</span>
                        </Label>
                    </Col>
                    <Col lg={3} className="mb-2">
                        <Select
                            id="Status"
                            name="Status"
                            options={projectStatusList}
                            onChange={(selectedOption) => handleSelectChange('Status', selectedOption)}
                            onBlur={validation.handleBlur}
                            value={selectedStatus}
                            placeholder="Select Status"
                            isSearchable={true}
                            styles={selectStyles(validation.touched.Status && validation.errors.Status)}
                            className="mb-2"
                            classNamePrefix="react-select"
                        />

                        {validation.touched.Status && validation.errors.Status ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.Status}</FormFeedback>
                        ) : null}
                    </Col>
                </Row>

                {/* ProjectCode Code */}
                <Row>
                    <Col lg={3} className="mb-3">
                        <Label htmlFor="ProjectCode" className="col-form-label">
                            Project Code<span className="text-danger">*</span>
                        </Label>
                    </Col>
                    <Col lg={3} className="mb-3">
                        <Input
                            id="ProjectCode"
                            name="ProjectCode"
                            type="text"
                            className="form-control validate"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.ProjectCode}
                            maxLength={3}
                            invalid={
                                validation.touched.ProjectCode && validation.errors.ProjectCode
                                    ? true
                                    : false
                            } />

                        {validation.touched.ProjectCode && validation.errors.ProjectCode ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.ProjectCode}</FormFeedback>
                        ) : null}
                    </Col>
                </Row>

                {/* ProjectIcon Code */}
                <Row>
                    <Col lg={3} className="mb-3">
                        <Label htmlFor="ProjectIconURL" className="col-form-label">
                            Project Icon URL
                        </Label>
                    </Col>
                    <Col lg={3} className="mb-3">
                        <Input
                            id="ProjectIconURL"
                            name="ProjectIconURL"
                            type="text"
                            className="form-control validate"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.ProjectIconURL}
                            invalid={
                                validation.touched.ProjectIconURL && validation.errors.ProjectIconURL
                                    ? true
                                    : false
                            } />
                            {validation.touched.ProjectIconURL && validation.errors.ProjectIconURL ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.ProjectIconURL}</FormFeedback>
                        ) : null}
                    </Col>
                </Row>

                {/* ProjectCategory Code */}
                <Row>
                    <Col lg={3} className="mb-2">
                        <Label htmlFor="Status" className="col-form-label">
                        Project Category<span className="text-danger">*</span>
                        </Label>
                    </Col>
                    <Col lg={3} className="mb-2">
                        <Select
                            id="ProjectCategory"
                            name="ProjectCategory"
                            options={projectCategoryList}
                            onChange={(selectedOption) => handleSelectChange('ProjectCategory', selectedOption)}
                            onBlur={validation.handleBlur}
                            value={selectedProjectCategory}
                            placeholder="Select Project Category"
                            isSearchable={true}
                            styles={selectStyles(validation.touched.ProjectCategory && validation.errors.ProjectCategory)}
                            className="mb-2"
                            classNamePrefix="react-select"
                        />

                        {validation.touched.ProjectCategory && validation.errors.ProjectCategory ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.ProjectCategory}</FormFeedback>
                        ) : null}
                    </Col>
                </Row>

                {/* Client Code */}
                <Row>
                    <Col lg={3} className="mb-2">
                        <Label htmlFor="ClientOrganizationID" className="col-form-label">
                            Client<span className="text-danger">*</span>
                        </Label>
                    </Col>
                    <Col lg={3} className="mb-2">
                        <Select
                            id="ClientOrganizationID"
                            name="ClientOrganizationID"
                            options={clientOrganizationList}
                            onChange={(selectedOption) => handleSelectChange('ClientOrganizationID', selectedOption)}
                            onBlur={validation.handleBlur}
                            value={selectedClientID}
                            placeholder="Select Project Client"
                            isSearchable={true}
                            styles={selectStyles(validation.touched.ClientOrganizationID && validation.errors.ClientOrganizationID)}
                            className="mb-2"
                            classNamePrefix="react-select"
                        />

                        {validation.touched.ClientOrganizationID && validation.errors.ClientOrganizationID ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.ClientOrganizationID}</FormFeedback>
                        ) : null}
                    </Col>
                </Row>

                {/* Project Manager Code */}
                <Row>
                    <Col lg={3} className="mb-2">
                        <Label htmlFor="ProjectManager" className="col-form-label">
                            Project Manager<span className="text-danger">*</span>
                        </Label>
                    </Col>
                    <Col lg={3} className="mb-2">
                        <Select
                            id="ProjectManager"
                            name="ProjectManager"
                            options={projectAccessList}
                            onChange={(selectedOption) => handleSelectChange('ProjectManager', selectedOption)}
                            onBlur={validation.handleBlur}
                            value={selectedProjectManager}
                            placeholder={"Select Project Manager..."}
                            isSearchable={true}
                            styles={selectStylesWithImage2(validation.touched.ProjectManager && validation.errors.ProjectManager)}
                            className="mb-2"
                            classNamePrefix="react-select"
                            components={{ Option: CustomOption2, SingleValue: CustomSingleValue2 }}
                        />
                        {validation.touched.ProjectManager && validation.errors.ProjectManager ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.ProjectManager}</FormFeedback>
                        ) : null}
                    </Col>
                </Row>

                {/* Platform Code */}
                <Row>
                    <Col lg={3}>
                        <Label htmlFor="Platform" className="col-form-label">
                            Platform
                        </Label>
                    </Col>
                    <Col lg={3}>
                        <Select
                            id="Platform"
                            name="Platform"
                            options={projectPlatformList}
                            value={selectedPlatform}
                            onChange={setSelectedPlatform}
                            placeholder={inputPlatformFocus ? "" : "Select Platform..."}
                            isSearchable={true}
                            onFocus={() => setInputPlatformFocus(true)}
                            onBlur={() => setInputPlatformFocus(false)}
                            inputValue={inputPlatformValue}
                            onInputChange={(value) => setInputPlatformValue(value)}
                            menuIsOpen={inputPlatformValue.length > 0}
                            className="mb-3"
                            classNamePrefix="react-select"
                            styles={selectStylesWithImage()}
                            // components={{ Option: CustomOption2, SingleValue: CustomSingleValue2 }}
                        />
                    </Col>
                    <Col lg={3} className="mb-3">
                        <Button
                            color="primary"
                            className="ms-2"
                            onClick={handleAddPlatform}
                        >
                            +
                        </Button>
                    </Col>
                </Row>

                {platformList.length > 0 && (
                    <Row>
                        <Col lg={3} className="mb-2"></Col>
                        <Col lg={9} className="mb-2">
                            <div className="d-flex flex-wrap">
                                {platformList.map((platform, index) => (
                                    <div
                                        key={index}
                                        className="d-flex align-items-center me-3 mb-2 p-2 border rounded"
                                    >
                                        <span>{platform.AssociationName}</span>
                                        <Button
                                            color="danger"
                                            size="sm"
                                            className="ms-2"
                                            style={{ padding: '0px 5px', borderRadius: "50%" }}
                                            type="button"
                                            onClick={() => handleRemovePlatform(platform)}
                                        >
                                            &times;
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                )}

                {/* TechStack Code */}
                <Row>
                    <Col lg={3}>
                        <Label htmlFor="TechStack" className="col-form-label">
                            Tech Stack
                        </Label>
                    </Col>
                    <Col lg={3}>
                        <Select
                            id="TechStack"
                            name="TechStack"
                            options={projectTechStackList}
                            value={selectedTechStack}
                            onChange={setSelectedTechStack}
                            placeholder={inputTechStackFocus ? "" : "Select Tech Stack..."}
                            isSearchable={true}
                            onFocus={() => setInputTechStackFocus(true)}
                            onBlur={() => setInputTechStackFocus(false)}
                            inputValue={inputTechStackValue}
                            onInputChange={(value) => setInputTechStackValue(value)}
                            menuIsOpen={inputTechStackValue.length > 0}
                            className="mb-3"
                            classNamePrefix="react-select"
                            styles={selectStylesWithImage()}
                            components={{ Option: CustomOption2, SingleValue: CustomSingleValue2 }}
                        />
                    </Col>
                    <Col lg={3} className="mb-3">
                        <Button
                            color="primary"
                            className="ms-2"
                            onClick={handleAddTechStack}
                        >
                            +
                        </Button>
                    </Col>
                </Row>

                {techStackList.length > 0 && (
                    <Row>
                        <Col lg={3} className="mb-2"></Col>
                        <Col lg={9} className="mb-2">
                            <div className="d-flex flex-wrap">
                                {techStackList.map((tech, index) => (
                                    <div
                                        key={index}
                                        className="d-flex align-items-center me-3 mb-2 p-2 border rounded"
                                    >
                                        <img
                                            src={tech.IconURL}
                                            alt={tech.AssociationName || 'Profile Image'}
                                            style={{ width: 24, height: 24, marginRight: 8, borderRadius: '50%', objectFit: 'cover' }}
                                        />
                                        <span>{tech.AssociationName}</span>
                                        <Button
                                            color="danger"
                                            size="sm"
                                            className="ms-2"
                                            style={{ padding: '0px 5px', borderRadius: "50%" }}
                                            type="button"
                                            onClick={() => handleRemoveTechStack(tech)}
                                        >
                                            &times;
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                )}

                {/* Default Assignee Code */}
                <Row>
                    <Col lg={3} className="mb-2">
                        <Label htmlFor="DefaultAssignee" className="col-form-label">
                            Default Assignee<span className="text-danger">*</span>
                        </Label>
                    </Col>
                    <Col lg={3} className="mb-2">
                        <Select
                            id="DefaultAssignee"
                            name="DefaultAssignee"
                            options={projectAccessList}
                            onChange={(selectedOption) => handleSelectChange('DefaultAssignee', selectedOption)}
                            onBlur={validation.handleBlur}
                            value={selectedDefaultAssignee}
                            placeholder={"Select Default Assignee..."}
                            isSearchable={true}
                            styles={selectStylesWithImage2(validation.touched.DefaultAssignee && validation.errors.DefaultAssignee)}
                            className="mb-2"
                            classNamePrefix="react-select"
                            components={{ Option: CustomOption2, SingleValue: CustomSingleValue2 }}
                        />
                        {validation.touched.DefaultAssignee && validation.errors.DefaultAssignee ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.DefaultAssignee}</FormFeedback>
                        ) : null}
                    </Col>
                </Row>

                {/* Project Status Code */}
                <Row>
                    <Col lg={3} className="mb-2">
                        <Label htmlFor="ProjectStatus" className="col-form-label">
                            Project Status<span className="text-danger">*</span>
                        </Label>
                    </Col>
                    <Col lg={3} className="mb-2">
                        <Select
                            options={normalizedProjectStatus}
                            value={selectedNormalizedStatus}
                            onChange={(selected) => setSelectedNormalizedStatus(selected)}
                            placeholder="Select Normalized Status"
                        />
                    </Col>
                    <Col md={3} className="mb-2">
                        <Input
                            type="text"
                            id="projectStatus"
                            value={projectStatusInput}
                            onChange={(e) => setProjectStatusInput(e.target.value)}
                            placeholder="Enter Project Status"
                            style={{height: "38px"}}
                        />
                    </Col>
                    <Col md={3} className="mb-2">
                        <Button
                            color="primary"
                            className="ms-2"
                            onClick={handleAddProjectStatus}
                        >
                            +
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col lg={3} className="mb-2"></Col>
                    <Col lg={9} className="mb-2">
                        <div className="d-flex flex-wrap">
                            {addedProjectStatuses.map((status, index) => (
                                <div
                                    key={index}
                                    className="d-flex align-items-center me-3 mb-2 p-2 border rounded"
                                >
                                    <span>
                                        <strong>Project Status:</strong> {status.ProjectTaskStatus} |{" "}
                                        <strong>Normalized Status:</strong> {status.NormalizedStatus}
                                    </span>
                                    <Button
                                        color="danger"
                                        size="sm"
                                        className="ms-2"
                                        style={{ padding: '0px 5px', borderRadius: "50%" }}
                                        type="button"
                                        onClick={() => handleRemoveProjectStatus(index)}
                                    >
                                        &times;
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row>











                <Row className="mt-4 justify-content-end">
                    <Col lg={6} className="d-flex justify-content-end mb-3">
                        <button
                            type="button"
                            className="btn btn-danger large-button me-2"
                            onClick={() => {
                                props.setShowProjectGetList(true);
                            }}
                        >
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-success large-button">
                            Submit
                        </button>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        showProjectGetList: state.Project?.showProjectGetList,
    };
};

export default connect(mapStateToProps, { setShowProjectGetList })(AddProject);

