import { call, put, takeEvery, select } from "redux-saga/effects";
import { ReportAPIError, GetTimelineReportSuccess } from "./actions";
import axios from "axios";
import * as url from "../../helpers/url_helper";
import { toast } from "react-toastify";

function* GetTimesheetReport({ payload: { ResourceID, ProjectID, DateType, StartDate, EndDate } }) {
    debugger;
    try {
        const token = yield select((state) => state.Login.token);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };
        const requestBody = {
            ResourceID: ResourceID,
            ProjectID: ProjectID,
            DateType: DateType,
            StartDate: StartDate,
            EndDate: EndDate
        };
        const response = yield call(axios.post, url.GET_TIMESHEET, requestBody, config);
        if (response.status >= 200 && response.status < 300) {
            yield put(GetTimelineReportSuccess(response.data.tasks || response.data));
        } else {
            throw new Error("Failed to fetch items");
        }
    } catch (err) {
        console.error("Error Response:", err);

        const errorMessage = err.response?.data?.message || err.response?.statusText || err.message || "An error occurred";
        yield put(ReportAPIError(errorMessage));
        toast.error(errorMessage);
    }
}

function* ReportSaga() {
  yield takeEvery("GET_TIMESHEET_REPORT", GetTimesheetReport);
}

export default ReportSaga;
