import React, { useEffect, useMemo } from "react"
import { Row, Col, Button, CardBody, Card } from "reactstrap"
import { connect } from "react-redux"
import { setBreadcrumbItems, setShowInvoiceGetList } from "../../store/actions"

import AddInvoice from "./AddInvoice"
import InvoiceList from "./AllInvoice"

const InvoiceMaster = props => {
  document.title = "Invoice | Cognisun - Responsive Bootstrap 5 Admin Dashboard"

  const breadcrumbItems = useMemo(
    () => [
      { title: "Comfort Zone" },
      { title: "Invoice" },
      { title: "List" },
      // { title: props.showInvoiceGetList ? "List" : "Add" },
    ],
    [props.showInvoiceGetList],
  )

  useEffect(() => {
    props.setBreadcrumbItems("Invoice", breadcrumbItems)
  }, [props, breadcrumbItems])

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          {props.showInvoiceGetList ? <InvoiceList /> : <AddInvoice />}
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  console.log("Redux Stateqqq:", state)
  return {
    showInvoiceGetList: state.Invoice?.ShowInvoiceGetList,
  }
}

export default connect(mapStateToProps, {
  setBreadcrumbItems,
  setShowInvoiceGetList,
})(InvoiceMaster)
