import React, { useEffect, useState, useMemo } from "react";
import { Row, Col, Label, FormFeedback, Form, Input, Button, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import Select from 'react-select';
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { GetTasks, GetDropdown, GetProjectInfoById, AddUpdateTask, GetTaskById, UploadAttachment, UploadAttachmentClear, setShowTaskGetList, SetTaskDefaults, AddUpdateTaskTimeline } from "../../store/actions";
import { useParams, useNavigate } from "react-router-dom";
import { setBreadcrumbItems } from "../../store/actions"
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import moment from 'moment';
import axios from "axios";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as url from "../../helpers/url_helper";
import { Entity } from "draft-js";
const baseURL = url.API_URL.replace('/api', '');
const apiUrl = process.env.REACT_APP_API_URL;
// AKSHAY
const formatDate = (date) => {
    if (!date) return "0000-00-00";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const EditTask = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Id } = useParams();
    const tasksFromStore = useSelector((state) => state.Task?.tasks?.data) || [];
    const taskData = useSelector((state) => state.Task?.taskData?.data) || [];
    const projectInfoById = useSelector((state) => state.Task?.projectInfoById?.data) || [];
    const projectList = useSelector((state) => state.CommonAPI?.projectList?.data) || [];
    const projectStatusList = useSelector((state) => state.CommonAPI?.ProjectStatusList?.data) || [];
    const taskTypeList = useSelector((state) => state.CommonAPI?.taskTypeList?.data) || [];
    const isTaskDataLoading = useSelector((state) => state.Task?.loading);
    const uploadedAttachment = useSelector((state) => state.CommonAPI?.attachmentData?.filePath) || [];
    const token = useSelector(state => state.Login?.token);
    const userDetail = useSelector((state) => state.Login?.user) || [];
    const loading = useSelector((state) => state.Task?.loading);

    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedTaskStatus, setSelectedTaskStatus] = useState(null);
    const [selectedAssignedTo, setSelectedAssignedTo] = useState(null);
    const [selectedParentTask, setSelectedParentTask] = useState(null);
    const [sliderValue, setSliderValue] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [dueDate, setDueDate] = useState(null);
    const [dueTime, setDueTime] = useState('12:00 AM');

    const [dueDateOnly, setDueDateOnly] = useState();
    const [dueTimeOnly, setDueTimeOnly] = useState('12:00 AM');
    const [dueDateTime, setDueDateTime] = useState(null);

    const [taskNumber, setTaskNumber] = useState(null);
    const [actualStartDate, setActualStartDate] = useState(null);
    const [dateCompleted, setDateCompleted] = useState(null);
    const [hoursConsumed, setHoursConsumed] = useState(null);
    const [percentCompleted, setPercentCompleted] = useState(null);

    const [selectedHour, setSelectedHour] = useState("12");
    const [selectedMinute, setSelectedMinute] = useState("00");
    const [amPm, setAmPm] = useState("AM");

    const [selectedFollower, setSelectedFollower] = useState(null);
    const [followersList, setFollowersList] = useState([]);
    const [selectedTag, setSelectedTag] = useState(null);
    const [tagsList, setTagsList] = useState([]);

    const [inputProjectValue, setInputProjectValue] = useState('');
    const [inputAssignedToValue, setInputAssignedToValue] = useState('');
    const [inputFollowerValue, setInputFollowerValue] = useState('');
    const [inputTagValue, setInputTagValue] = useState('');
    const [inputParentTaskIDValue, setInputParentTaskIDValue] = useState('');

    const [inputProjectFocus, setInputProjectFocus] = useState(false);
    const [inputAssignedToFocus, setInputAssignedToFocus] = useState(false);
    const [inputFollowerFocus, setInputFollowerFocus] = useState(false);
    const [inputTagFocus, setInputTagFocus] = useState(false);
    const [inputParentTaskIDFocus, setInputParentTaskIDFocus] = useState(false);

    const [taskMainData, setTaskMainData] = useState('');
    const [specifyTimeToggle, setSpecifyTimeToggle] = useState(true);
    const [attachmentModal, setAttachmentModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [inputLink, setInputLink] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [subTasksList, setSubTasksList] = useState([]);
    const [taskTimeline, setTaskTimeline] = useState([]);
    const [filteredTaskTimeline, setFilteredTaskTimeline] = useState([]);
    const [timelineFilter, setTimelineFilter] = useState("All")
    const [newComment, setNewComment] = useState("")
    const [commentAdded, setCommentAdded] = useState(false);
    const [editingTaskTimelineID, setEditingTaskTimelineID] = useState(null);
    const [editedComment, setEditedComment] = useState("");
    const [attachmentFrom, setAttachmentFrom] = useState("");
    const [commentAttachments, setCommentAttachments] = useState([]);
    const [isCommentMode, setIsCommentMode] = useState(false);

    const toggleSpecifyTime = () => {
        setSpecifyTimeToggle(!specifyTimeToggle);
    };

    const today = new Date();
    const minDate = new Date();
    minDate.setDate(today.getDate() - 7);

    const breadcrumbItems = useMemo(() => [
        { title: "Cognisun" },
        { title: "Task" },
        { title: "Edit Task" },
    ], []);

    useEffect(() => {
        props.setBreadcrumbItems('Edit Task', breadcrumbItems);
        if (Id) {
            dispatch(GetTaskById(Id));
        }
    }, [Id, dispatch, breadcrumbItems, props, commentAdded]);

    const refreshTaskData = () => {
        dispatch(GetTaskById(Id));
    };    

    useEffect(() => {
        dispatch(GetDropdown({ "StringMapType": "PROJECTLIST", "OrderBy": "StringMapName" }));
        dispatch(GetDropdown({ "StringMapType": "TaskType", "OrderBy": "StringMapName" }));
        dispatch(GetDropdown({ "StringMapType": "ProjectStatusList", "OrderBy": "StringMapName" }));
    }, [dispatch])

    useEffect(() => {
        dispatch(GetTasks());
    }, [dispatch]);

    useEffect(() => {
        if (selectedProject?.value) {
            dispatch(GetProjectInfoById(selectedProject.value));
        }
    }, [selectedProject, dispatch]);

    const generatePlaceholderProfile = (firstName, lastName) => {
        const initials = `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
        const canvas = document.createElement('canvas');
        canvas.width = 100;
        canvas.height = 100;
        const context = canvas.getContext('2d');

        context.fillStyle = '#7A6FBE';
        context.fillRect(0, 0, canvas.width, canvas.height);

        context.font = '50px Arial';
        context.fillStyle = 'white';
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(initials, canvas.width / 2, canvas.height / 2);

        return canvas.toDataURL('image/png');
    };

    const createAssociationsList = (type) => {
        if (!selectedProject) return [];
        return projectInfoById
            .filter(item => item.AssociationType === type)
            .map(item => {
                if (type === 'Access') {
                    const hasProfilePhoto = item.PicturePath;
                    const profilePhoto = hasProfilePhoto
                        ? `${apiUrl}${item.PicturePath}`
                        : generatePlaceholderProfile(item.FirstName, item.LastName);

                    return {
                        label: item.AccessName,
                        value: item.AssociationValue,
                        profilePhoto,
                        firstName: item.FirstName,
                        lastName: item.LastName,
                    };
                }
                return {
                    label: item.AssociationValue,
                    value: item.ProjectAssociationID,
                };
            });
    };

    const taskAccessList = useMemo(() => {
        const list = createAssociationsList('Access');
        return list.sort((a, b) => a.label.localeCompare(b.label));
    }, [selectedProject, projectInfoById]); 

    const taskTagList = useMemo(() => {
        const list = createAssociationsList('Tag');
        return list.sort((a, b) => a.label.localeCompare(b.label));
    }, [selectedProject, projectInfoById]);

    const taskAccessListWithoutAssignee = useMemo(() => {
        if (!selectedAssignedTo) return taskAccessList;
        return taskAccessList
            .filter((assignee) => assignee.value !== selectedAssignedTo.value)
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [taskAccessList, selectedAssignedTo]);

    const taskStatusList = useMemo(() => {
        if (!selectedProject) return [];
        return projectStatusList
            .filter(item => item.ProjectID === selectedProject.value)
            .map(item => ({
                label: item.StringMapName,
                value: item.StringMapId
            }));
    }, [selectedProject, projectStatusList]);

    useEffect(() => {
        if (!selectedProject) {
            setSelectedTaskStatus(null);
            setSelectedAssignedTo(null);
            setSelectedParentTask(null);
        }
    }, [selectedProject]);

    useEffect(() => {
        if (isTaskDataLoading || !Id || taskData?.length === 0) {
            return;
        }
        const taskToEdit = taskData.find(task => task.TaskID === Id);
        if (!taskToEdit) return;
        if (taskToEdit) {
            setTaskNumber(taskToEdit.TaskNumber)
            setHoursConsumed(taskToEdit.HoursConsumed || 0)
            setPercentCompleted(taskToEdit.PercentCompleted || 0)

            setActualStartDate(moment(taskToEdit.ActualStartDate).format('DD-MM-YYYY'))
            setDateCompleted(moment(taskToEdit.DateCompleted).format('DD-MM-YYYY'))

            setTaskMainData(taskToEdit);
            const startDateValue = taskToEdit.EstimatedStartDate
                ? new Date(taskToEdit.EstimatedStartDate)
                : null;
            setStartDate(startDateValue);
            if (startDateValue) {
                validation.setFieldValue("EstimatedStartDate", formatDate(startDateValue));
            }

            const endDateValue = taskToEdit.DueDateTime
                ? new Date(taskToEdit.DueDateTime)
                : null;

            setDueDateOnly(endDateValue);

            if (!endDateValue) {
                setSpecifyTimeToggle(false);
            }
            if (endDateValue) {
                endDateValue.setHours(endDateValue.getHours());

                const hours = endDateValue.getHours();
                const minutes = endDateValue.getMinutes();
                const ampm = hours >= 12 ? 'PM' : 'AM';

                const hour12 = hours % 12 || 12;
                const formattedMinutes = String(minutes).padStart(2, '0');

                setSelectedHour(String(hour12).padStart(2, '0'));
                setSelectedMinute(formattedMinutes);
                setAmPm(ampm);

                const formattedTime = `${String(hour12).padStart(2, '0')}:${formattedMinutes} ${ampm}`;
                console.log("Formatted 12-hour time:", formattedTime);
                setDueTimeOnly(formattedTime);
            }

            const selectedProj = projectList.find(
                (project) => project.StringMapId === taskToEdit.ProjectID
            );

            const parentTask = tasksFromStore.find(
                task => task.TaskID === taskToEdit.ParentTaskID
            );

            if (parentTask) {
                setSelectedParentTask({
                    label: `${parentTask.TaskNumber} - ${parentTask.TaskTitle}`,
                    value: parentTask.TaskID
                });
                validation.setFieldValue("ParentTaskID", parentTask.TaskID);
            }

            if (selectedProj) {
                setSelectedProject({
                    label: selectedProj.StringMapName,
                    value: selectedProj.StringMapId,
                    ProjectIconURL: selectedProj.ProjectIconURL
                });
                validation.setFieldValue("ProjectID", selectedProj.ProjectID);
            }
        }

    }, [isTaskDataLoading, Id, taskData]);

    useEffect(() => {
        const taskToEdit = taskData.find(task => task.TaskID === Id);

        if (!taskToEdit) return;
        if (taskToEdit) {
            const selectedTask = taskStatusList.find(
                (task) => task.label === taskToEdit.TaskStatus
            );

            if (selectedTask) {
                setSelectedTaskStatus({
                    label: selectedTask.label,
                    value: selectedTask.value
                });
                validation.setFieldValue("TaskStatus", selectedTask.value);
            }
        }

    }, [taskData, Id, taskStatusList])

    useEffect(() => {
        const taskToEdit = taskData.find(task => task.TaskID === Id);

        if (!taskToEdit) return;
        if (taskToEdit) {
            const selectedAssignee = taskAccessList.find(
                (ass) => ass.value === taskToEdit.AssignedTo
            ) || {}

            const selectedProj = projectList.find(
                (project) => project.StringMapId === taskToEdit.ProjectID
            );

            if (selectedProj && selectedAssignee) {
                setSelectedAssignedTo({
                    label: selectedAssignee.label,
                    value: selectedAssignee.value,
                    firstName: selectedAssignee.firstName,
                    lastName: selectedAssignee.lastName,
                    profilePhoto: selectedAssignee.profilePhoto
                });
                validation.setFieldValue("AssignedTo", selectedAssignee.value);
            }
        }
    }, [taskData, Id, taskAccessList])

    useEffect(() => {
        if (taskData.length) {
            const taskFollowersToEdit = taskData.filter(task => task.AssociationType === 'Follower');
            const formattedFollowers = taskFollowersToEdit.map(follower => {

                const matchingAccess = taskAccessList.find(access => access.value === follower.AssociationValue);

                return {
                    TaskAssociationID: follower.TaskAssociationID ? follower.TaskAssociationID : '',
                    Status: follower.Status,
                    SortOrder: follower.SortOrder,
                    AssociationType: follower.AssociationType,
                    AssociationValue: follower.AssociationValue,
                    AssociationName: matchingAccess ? matchingAccess.label : '',
                    ProfilePhoto: matchingAccess ? matchingAccess.profilePhoto : generatePlaceholderProfile('N', 'A'),
                };
            });
            setFollowersList(formattedFollowers);
        }
    }, [taskData, taskAccessList]);

    useEffect(() => {
        if (taskData.length) {
            const taskTagsToEdit = taskData.filter(task => task.AssociationType === 'Tag');
            const formattedTags = taskTagsToEdit.map(tag => {

                const matchingTag = taskTagList.find(access => access.value === tag.AssociationValue);

                return {
                    TaskAssociationID: tag.TaskAssociationID ? tag.TaskAssociationID : '',
                    Status: tag.Status,
                    SortOrder: tag.SortOrder,
                    AssociationType: tag.AssociationType,
                    AssociationValue: tag.AssociationValue,
                    AssociationName: matchingTag ? matchingTag.label : '',
                    ProfilePhoto: matchingTag ? matchingTag.profilePhoto : generatePlaceholderProfile('N', 'A'),
                };
            });
            setTagsList(formattedTags);
        }
    }, [taskData, taskTagList]);

    useEffect(() => {
        if (taskData.length) {
            const taskAttachmentToEdit = taskData.filter(
                task => task.DocumentID && task.EntityType === 'Task'
            );
            setAttachments(taskAttachmentToEdit);
        }
    }, [taskData]);

    useEffect(() => {
        if (taskData.length) {
            const subTaskToEdit = taskData.filter(task => task.SubTaskID);
            setSubTasksList(subTaskToEdit);
        }
    }, [taskData]);

    useEffect(() => {
        if (isTaskDataLoading || !Id || taskData?.length === 0) {
            return;
        }
        const taskTimelineList = taskData.filter(task => task.TaskTimelineData);
        const attachments = taskData.filter(item => item.EntityType === "TaskTimeline");

        const attachmentsByTimelineID = attachments.reduce((acc, attachment) => {
            const { EntityId } = attachment;
            if (!acc[EntityId]) {
                acc[EntityId] = [];
            }
            acc[EntityId].push({
                Directory: attachment.Directory,
                DocumentID: attachment.DocumentID,
                EntityId: attachment.EntityId,
                EntityType: attachment.EntityType,
                FileName: attachment.FileName,
                Status: attachment.Status,
                Type: attachment.Type,
                URL: attachment.URL,
            });
            return acc;
        }, {});

        const formattedTimeline = taskTimelineList.map(timeline => {

            const formattedCreatedOn = new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            }).format(new Date(timeline.CreatedOn));

            return {
                TaskTimelineID: timeline.TaskTimelineID ? timeline.TaskTimelineID : '',
                TaskID: timeline.TaskID ? timeline.TaskID : '',
                NoteType: timeline.NoteType,
                NoteTitle: timeline.NoteTitle,
                NoteText: timeline.NoteText,
                Status: timeline.Status,
                CreatedBy: timeline.CreatedBy,
                CreatedOn: formattedCreatedOn,
                CreatedByFirstName: timeline.CreatedByFirstName,
                CreatedByLastName: timeline.CreatedByLastName,
                ProfilePhoto: timeline.CreatedByPicturePath ? `${baseURL}${timeline.CreatedByPicturePath}` : generatePlaceholderProfile(timeline.CreatedByFirstName, timeline.CreatedByLastName),
                Attachments: attachmentsByTimelineID[timeline.TaskTimelineID] || [],
            };
        });

        setTaskTimeline(formattedTimeline);

    }, [isTaskDataLoading, Id, taskData, commentAdded]);

    useEffect(() => {
        if (timelineFilter === "All") {
            setFilteredTaskTimeline(taskTimeline);
        } else if (timelineFilter === "Comment") {
            setFilteredTaskTimeline(taskTimeline.filter(
                timeline => timeline.NoteType === "Comment" || timeline.NoteType === "Task Comment"
            ));
        } else if (timelineFilter === "History") {
            setFilteredTaskTimeline(taskTimeline.filter(timeline => timeline.NoteType === "History"));
        }
    }, [timelineFilter, taskTimeline]);

    const handleSaveComment = () => {
        const comment = {
            TaskTimelineID: editingTaskTimelineID || "",
            TaskID: Id,
            NoteType: 'Task Comment',
            NoteTitle: '',
            NoteText: editingTaskTimelineID ? editedComment : newComment,
            Status: "Active",
            Attachments: commentAttachments
        };

        console.log("comments", comment);

        dispatch(AddUpdateTaskTimeline(comment));

        setEditedComment("");
        setEditingTaskTimelineID(null);
        setCommentAdded(prev => !prev);
        setNewComment("");
        setCommentAttachments([]);

        refreshTaskData();
        refreshTaskData();
    };

    const handleEditComment = (timeline) => {
        setEditingTaskTimelineID(timeline.TaskTimelineID);
        setEditedComment(timeline.NoteText);
        setCommentAttachments(timeline.Attachments);
    };

    const handleDeleteComment = (timeline) => {
        const comment = {
            TaskTimelineID: timeline.TaskTimelineID,
            TaskID: Id,
            NoteType: 'Task Comment',
            NoteTitle: '',
            NoteText: timeline.NoteText,
            Status: "Inactive",
        };

        dispatch(AddUpdateTaskTimeline(comment));

        setCommentAdded(prev => !prev);

        refreshTaskData();
        refreshTaskData();
    };

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            ProjectID: taskMainData.ProjectID || '',
            TaskType: taskMainData.TaskType || '',
            TaskStatus: taskMainData.TaskStatus || '',
            TaskTitle: taskMainData.TaskTitle || '',
            TaskDescription: taskMainData.TaskDescription || '',
            EstimatedHours: taskMainData.EstimatedHours || '',
            AssignedTo: taskMainData.AssignedTo || '',
            EstimatedStartDate: taskMainData.EstimatedStartDate
                ? formatDate(new Date(taskMainData.EstimatedStartDate))
                : formatDate(startDate),
            ParentTaskID: '',
        },
        validationSchema: Yup.object({
            // ProjectID: Yup.string().required("Please Select a Project"),
            TaskType: Yup.string().required("Please Select a Task Type"),
            TaskStatus: Yup.string().required("Please Select Task Status"),
            TaskTitle: Yup.string().required("Please Enter Task Title"),
            TaskDescription: Yup.string(),
            EstimatedHours: Yup.number()
                .typeError("Estimated Hours must be a number")
                .test(
                    "is-valid-float",
                    "Estimated Hours must be a multiple of 0.25",
                    (value) => !value || (value % 0.25 === 0)
                )
                .min(0, "Estimated Hours must be greater than or equal to 0")
                .max(2000, "Estimated Hours must be less than or equal to 2000"),
            AssignedTo: Yup.string(),
            EstimatedStartDate: Yup.date(),
            ParentTaskID: Yup.string()
        }),
        onSubmit: async (values) => {
            const convertTo24HourTime = (time12h) => {
                const [time, modifier] = time12h.split(' ');
                let [hours, minutes] = time.split(':');
                if (modifier === 'PM' && hours !== '12') {
                    hours = parseInt(hours, 10) + 12;
                }
                if (modifier === 'AM' && hours === '12') {
                    hours = '00';
                }
                return `${hours}:${minutes}`;
            };

            const formattedDate = dueDateOnly
                ? `${dueDateOnly.getFullYear()}-${String(dueDateOnly.getMonth() + 1).padStart(2, '0')}-${String(dueDateOnly.getDate()).padStart(2, '0')}`
                : "0000-00-00";

            const formattedTime = specifyTimeToggle ? convertTo24HourTime(dueTimeOnly) : "00:00";
            const combinedDateTime = `${formattedDate} ${formattedTime}:00`;

            values.DueDateTime = combinedDateTime;

            let currentProjectID = taskMainData.ProjectID || '';
            values.ProjectID = currentProjectID;

            const combinedAssociations = [
                ...followersList.map(follower => ({ ...follower, type: 'Follower' })),
                ...tagsList.map(tag => ({ ...tag, type: 'Tag' }))
            ];

            const cleanedAssociations = combinedAssociations.map(association => {
                const { ProfilePhoto, ...cleanedAssociation } = association;
                return cleanedAssociation;
            });

            values.Associations = cleanedAssociations;
            values.Attachments = attachments;

            if (values.EstimatedHours) {
                values.EstimatedHours = parseFloat(values.EstimatedHours);
            } else {
                values.EstimatedHours = 0;
            }

            if (Id) {
                values.TaskID = Id
            }

            const { DueDate, DueTime, ...filteredValues } = values;

            console.log("update task filteredValues", filteredValues);

            dispatch(AddUpdateTask(filteredValues));
            setTimeout(() => {
                navigate("/Task");
            }, 500);
        }
    });

    const toggleAttachmentModal = () => {
        dispatch(UploadAttachmentClear());
        setAttachmentModal(!attachmentModal);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && isValidFileType(file)) {
            setSelectedFile(file);
            if (isImageFile(file)) {
                setPreview(URL.createObjectURL(file));
            } else {
                setPreview(`${file.name}`);
            }
            setInputLink("");
        } else {
            toast.error("Please select a valid PNG, JPG, CSV, XLS, or XLSX file.");
        }
    };

    const handleLinkChange = (e) => {
        const link = e.target.value;
        setInputLink(link);
        if (link.trim() !== "") {
            setSelectedFile(null);
            setPreview(null);
        }
    };

    const getFormattedTimestamp = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        return `${year}${month}${day}_${hours}${minutes}${seconds}`;
    };

    const handleAttachmentSubmit = async (event) => {
        if (event) event.preventDefault();

        if (inputLink) {
            console.log("Submitted Link:", inputLink);

            const timestamp = new Date().getTime();

            const attachment = {
                DocumentID: "",
                FileName: `${selectedProject.label}_${timestamp}`,
                Directory: '',
                URL: inputLink,
                Status: "Active",
                Type: "link"
            };

            setAttachments(prev => [...prev, attachment]);
            setInputLink("");
            toggleAttachmentModal();

        } else if (selectedFile) {
            if (!selectedFile || !selectedProject?.label) {
                toast.error("Please select a project and a file to upload.");
                return;
            }

            const maxFileSize = 5 * 1024 * 1024;
            if (selectedFile.size > maxFileSize) {
                toast.error("File size exceeds the 5MB limit.");
                return;
            }

            const timestamp = getFormattedTimestamp();
            const newFileName = `${selectedProject.label}_${timestamp}${selectedFile.name.substring(selectedFile.name.lastIndexOf('.'))}`;

            const renamedFile = new File([selectedFile], newFileName, { type: selectedFile.type });

            const formData = new FormData();
            formData.append('file', renamedFile);
            formData.append('ProjectName', selectedProject.label);

            try {
                const response = await axios.post(
                    `${url.API_URL}/task/uploadAttachment`,
                    formData,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data',
                        }
                    }
                );

                if (response.status >= 200 && response.status < 300) {
                    const uploadedFile = response.data;

                    if (uploadedFile?.filePath) {
                        const attachment = {
                            DocumentID: "",
                            FileName: renamedFile.name,
                            Directory: `documents/attachment/${selectedProject.label}`,
                            URL: uploadedFile.filePath,
                            Status: "Active",
                            Type: "file"
                        };

                        setAttachments(prev => [...prev, attachment]);
                        setSelectedFile(null);
                        toggleAttachmentModal();
                        setPreview(null);

                        toast.success("File uploaded successfully.");
                    } else {
                        toast.error("Failed to retrieve file path from upload response.");
                    }
                } else {
                    toast.error("Failed to upload file.");
                }
            } catch (error) {
                console.error("Error during file upload:", error);
                toast.error("An error occurred while uploading the file.");
            }

        } else {
            console.log("No input provided.");
        }
    };

    const handleCommentAttachmentSubmit = async (event) => {
        event.preventDefault();
        setIsCommentMode(false);

        if (inputLink) {
            console.log("Submitted Link:", inputLink);

            const timestamp = new Date().getTime();

            const attachment = {
                DocumentID: "",
                FileName: `${selectedProject.label}_${timestamp}`,
                Directory: '',
                URL: inputLink,
                Status: "Active",
                Type: "link"
            };

            setCommentAttachments(prev => [...prev, attachment]);
            setInputLink("");
            toggleAttachmentModal();

        } else if (selectedFile) {
            if (!selectedFile || !selectedProject?.label) {
                toast.error("Please select a project and a file to upload.");
                return;
            }

            const maxFileSize = 5 * 1024 * 1024;
            if (selectedFile.size > maxFileSize) {
                toast.error("File size exceeds the 5MB limit.");
                return;
            }

            const timestamp = getFormattedTimestamp();
            const newFileName = `${selectedProject.label}_${timestamp}${selectedFile.name.substring(selectedFile.name.lastIndexOf('.'))}`;

            const renamedFile = new File([selectedFile], newFileName, { type: selectedFile.type });

            const formData = new FormData();
            formData.append('file', renamedFile);
            formData.append('ProjectName', selectedProject.label);

            try {
                const response = await axios.post(
                    `${url.API_URL}/task/uploadAttachment`,
                    formData,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data',
                        }
                    }
                );

                if (response.status >= 200 && response.status < 300) {
                    const uploadedFile = response.data;

                    if (uploadedFile?.filePath) {
                        const attachment = {
                            DocumentID: "",
                            FileName: renamedFile.name,
                            Directory: `documents/attachment/${selectedProject.label}`,
                            URL: uploadedFile.filePath,
                            Status: "Active",
                            Type: "file"
                        };

                        setCommentAttachments(prev => [...prev, attachment]);
                        setSelectedFile(null);
                        toggleAttachmentModal();
                        setPreview(null);

                        toast.success("File uploaded successfully.");
                    } else {
                        toast.error("Failed to retrieve file path from upload response.");
                    }
                } else {
                    toast.error("Failed to upload file.");
                }
            } catch (error) {
                console.error("Error during file upload:", error);
                toast.error("An error occurred while uploading the file.");
            }

        } else {
            console.log("No input provided.");
        }
    };

    const handleRemoveAttachment = (attachment) => {
        if (attachment.DocumentID) {
            setAttachments((prevAttachments) =>
                prevAttachments.map((att) =>
                    att.DocumentID === attachment.DocumentID
                        ? { ...att, Status: 'Inactive' }
                        : att
                )
            );
        } else {
            setAttachments((prevAttachments) =>
                prevAttachments.filter((att) => att.FileName !== attachment.FileName)
            );
        }
    };

    const handleRemoveCommentAttachment = (attachment) => {
        if (attachment.DocumentID) {
            setCommentAttachments((prevAttachments) =>
                prevAttachments.map((att) =>
                    att.DocumentID === attachment.DocumentID
                        ? { ...att, Status: 'Inactive' }
                        : att
                )
            );
        } else {
            setCommentAttachments((prevAttachments) =>
                prevAttachments.filter((att) => att.FileName !== attachment.FileName)
            );
        }
    };

    const handleFileClick = (filePath, type) => {
        if (type === 'file') {
            const fullURL = `${baseURL}/documents${filePath}`;

            window.open(fullURL, '_blank');
        } else if (type === 'link') {
            window.open(filePath, '_blank');
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file && isValidFileType(file)) {
            setSelectedFile(file);
            if (isImageFile(file)) {
                setPreview(URL.createObjectURL(file));
            } else {
                setPreview(`${file.name}`);
            }
        } else {
            toast.error("Please select a valid PNG, JPG, CSV, XLS, or XLSX file.");
        }
    };

    const isValidFileType = (file) => {
        const allowedTypes = [
            'image/png', 'image/jpeg', 'text/csv',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/pdf'
        ];
        return allowedTypes.includes(file.type);
    };

    const isImageFile = (file) => {
        if (file && file.type) {
            return file.type.startsWith('image/');
        }
        return false;
    };

    const handleAddItem = (list, setList, selectedItem, type) => {
        if (selectedItem) {
            const existingItem = list.find(item => item.AssociationValue === selectedItem.value);

            if (existingItem) {
                setList(prevList =>
                    prevList.map(i =>
                        i.AssociationValue === selectedItem.value
                            ? { ...i, Status: "Active", SortOrder: prevList.length + 1 }
                            : i
                    )
                );
            } else {
                const newItem = {
                    TaskAssociationID: '',
                    Status: "Active",
                    SortOrder: list.length + 1,
                    AssociationType: type,
                    AssociationValue: selectedItem.value,
                    AssociationName: selectedItem.label,
                    ProfilePhoto: selectedItem.profilePhoto
                };
                setList([...list, newItem]);
            }
        }
        setSelectedFollower(null);
        setSelectedTag(null);
    };

    const handleRemoveItem = (list, setList, item) => {
        setList(prevList =>
            prevList.map(i => {
                if (i.AssociationValue === item.AssociationValue) {
                    return i.TaskAssociationID
                        ? { ...i, Status: "Inactive" }
                        : null;
                }
                return i;
            }).filter(Boolean)
                .map((i, index) => ({ ...i, SortOrder: index + 1 }))
        );
    };

    const handleAddFollower = () => handleAddItem(followersList, setFollowersList, selectedFollower, 'Follower');
    const handleRemoveFollower = (follower) => handleRemoveItem(followersList, setFollowersList, follower);
    const handleAddTag = () => handleAddItem(tagsList, setTagsList, selectedTag, 'Tag');
    const handleRemoveTag = (tag) => handleRemoveItem(tagsList, setTagsList, tag);

    const handleStartDateChange = (date) => {
        const formattedDate = formatDate(date);
        setStartDate(date);
        validation.setFieldValue('EstimatedStartDate', formattedDate);
    };

    const handleDateChange = (date) => {
        setDueDateOnly(date);
    };

    const handleHourChange = (e) => {
        const newHour = e.target.value;
        setSelectedHour(newHour);
        updateDueTime(newHour, selectedMinute, amPm);
    };

    const handleMinuteChange = (e) => {
        const newMinute = e.target.value;
        setSelectedMinute(newMinute);
        updateDueTime(selectedHour, newMinute, amPm);
    };

    const handleAmPmChange = (e) => {
        const newAmPm = e.target.value;
        setAmPm(newAmPm);
        updateDueTime(selectedHour, selectedMinute, newAmPm);
    };

    const updateDueTime = (hour = selectedHour, minute = selectedMinute, period = amPm) => {
        const formattedTime = `${hour}:${minute} ${period}`;
        setDueTimeOnly(formattedTime);
    };

    const generateOptions = (start, end) => {
        return Array.from({ length: end - start + 1 }, (_, i) => {
            const value = String(i + start).padStart(2, "0");
            return <option key={value} value={value}>{value}</option>;
        });
    };

    const handleSelectChange = (selectedOption, field) => {
        switch (selectedOption) {
            case 'ProjectID':
                setSelectedProject(field);
                validation.setFieldValue('ProjectID', field ? field.value : '');
                if (!field) {
                    validation.setFieldValue('TaskStatus', '');
                    validation.setFieldValue('AssignedTo', '');
                    setSelectedTaskStatus(null);
                    setSelectedAssignedTo(null);
                } else {
                    setSelectedAssignedTo(null);
                    validation.setFieldValue('AssignedTo', '');
                }
                break;
            case 'TaskStatus':
                setSelectedTaskStatus(field);
                validation.setFieldValue('TaskStatus', field ? field.value : '');
                break;
            case 'AssignedTo':
                setSelectedAssignedTo(field);
                validation.setFieldValue('AssignedTo', field ? field.value : '');
                setFollowersList([]);
                break;
            case 'ParentTaskID':
                setSelectedParentTask(field);
                validation.setFieldValue('ParentTaskID', field ? field.value : '');
                break;
            default:
                break;
        }
    };

    const handleRemoveParentTask = () => {
        setSelectedParentTask(null);
        validation.setFieldValue('ParentTaskID', '');
    };

    const handleTaskClick = (subTaskID) => {
        navigate(`/Task/Edit/${subTaskID}`);
    };

    const handleAddSubtask = () => {
        dispatch(SetTaskDefaults(selectedProject.value, Id));
        dispatch(setShowTaskGetList(false));
        navigate('/Task');
    }

    const selectStyles = (hasError) => ({
        control: (provided, state) => ({
            ...provided,
            borderColor: hasError ? 'red' : provided.borderColor,
            boxShadow: 'none',
            '&:hover': {
                borderColor: hasError ? 'red' : provided['&:hover'].borderColor,
            },
        }),
        outline: "none",
    });

    const selectStyles2 = (hasError) => ({
        control: (provided, state) => ({
            ...provided,
            borderColor: hasError ? 'red' : provided.borderColor,
            boxShadow: 'none',
            '&:hover': {
                borderColor: hasError ? 'red' : provided['&:hover'].borderColor,
            },
        }),
        dropdownIndicator: () => ({
            display: 'none',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        outline: "none",
    });

    const selectStylesWithImage = (hasError) => ({
        control: (provided, state) => ({
            ...provided,
            borderColor: hasError ? 'red' : provided.borderColor,
            boxShadow: 'none',
            minHeight: '38px',
            height: '38px',
            '&:hover': {
                borderColor: hasError ? 'red' : provided['&:hover'].borderColor,
            },
        }),
        dropdownIndicator: () => ({
            display: 'none',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0 8px',
            display: 'flex',
            alignItems: 'center',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            margin: 0,
        }),
        outline: "none",
    });

    const selectStylesWithImage2 = (hasError) => ({
        control: (provided, state) => ({
            ...provided,
            borderColor: hasError ? 'red' : provided.borderColor,
            boxShadow: 'none',
            minHeight: '38px',
            height: '38px',
            '&:hover': {
                borderColor: hasError ? 'red' : provided['&:hover'].borderColor,
            },
        }),
        // dropdownIndicator: () => ({
        //     display: 'none',
        // }),
        // indicatorSeparator: () => ({
        //     display: 'none',
        // }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0 8px',
            display: 'flex',
            alignItems: 'center',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            margin: 0,
        }),
        outline: "none",
    });

    const CustomOption = (props) => (
        <div {...props.innerProps} className="d-flex align-items-center p-2">
            <img src={props.data.ProjectIconURL} alt="" style={{ width: 20, height: 20, marginRight: 8 }} />
            <span>{props.data.label}</span>
        </div>
    );

    const CustomSingleValue = (props) => (
        <div className="d-flex align-items-center" style={{ height: '100%', margin: 0, padding: 0 }}>
            <img src={props.data.ProjectIconURL} alt="" style={{ width: 20, height: 20, marginRight: 8 }} />
            <span>{props.data.label}</span>
        </div>
    );

    const CustomOption2 = (props) => (
        <div {...props.innerProps} className="d-flex align-items-center p-2">
            <img src={props.data.profilePhoto} alt="" style={{ width: 24, height: 24, marginRight: 8, borderRadius: '50%', objectFit: 'cover' }} />
            <span>{props.data.label}</span>
        </div>
    );

    const CustomSingleValue2 = (props) => (
        <div className="d-flex align-items-center" style={{ height: '100%', margin: 0, padding: 0 }}>
            <img
                src={props.data.profilePhoto}
                alt={props.data.label || 'Profile Image'}
                style={{ width: 24, height: 24, marginRight: 8, borderRadius: '50%', objectFit: 'cover' }}
            />
            <span>{props.data.label}</span>
        </div>
    );

    return (
        <React.Fragment>
            {!taskData.length ? (
                <div>Loading...</div>
            ) : (
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >

                    <Row className="mb-3">
                        <div className="col-form-label">
                            Fields marked with <span className="text-danger">*</span> are mandatory.
                        </div>
                    </Row>

                    {/* Project Code */}
                    <Row>
                        <Col lg={3} className="mb-2">
                            <Label htmlFor="ProjectID" className="col-form-label">
                                Select Project<span className="text-danger">*</span>
                            </Label>
                        </Col>
                        <Col lg={4} className="mb-2">
                            <Select
                                id="ProjectID"
                                name="ProjectID"
                                options={projectList.map((value) => ({
                                    label: value.StringMapName,
                                    value: value.StringMapId,
                                    ProjectIconURL: value.ProjectIconURL,
                                }))}
                                onChange={(selectedOption) => handleSelectChange('ProjectID', selectedOption)}
                                value={selectedProject}
                                // value={inputProjectFocus ? "" : selectedProject}
                                // placeholder={inputProjectFocus ? "" : "Select Project..."}
                                placeholder={"Select Project..."}
                                isSearchable={true}
                                // inputValue={inputProjectValue}
                                // onInputChange={(value) => setInputProjectValue(value)}
                                // menuIsOpen={inputProjectValue.length > 0}
                                // onFocus={() => setInputProjectFocus(true)}
                                // onBlur={() => setInputProjectFocus(false)}
                                styles={selectStylesWithImage2(validation.touched.ProjectID && validation.errors.ProjectID)}
                                className="mb-3"
                                classNamePrefix="react-select"
                                components={{ 
                                    // Option: CustomOption, 
                                    SingleValue: CustomSingleValue
                                 }}
                                 isDisabled
                            />

                            {validation.touched.ProjectID && validation.errors.ProjectID ? (
                                <FormFeedback type="invalid" className="d-block">{validation.errors.ProjectID}</FormFeedback>
                            ) : null}
                        </Col>
                    </Row>

                    {/* TaskType Code */}
                    <Row>
                        <Col lg={3} className="mb-2">
                            <Label htmlFor="TaskType" className="col-form-label">
                                Task Type<span className="text-danger">*</span>
                            </Label>
                        </Col>
                        <Col lg={6} className="mb-2">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                {taskTypeList.map((taskType) => {
                                    const isSelected = validation.values.TaskType === taskType.StringMapId;

                                    return (
                                        <div
                                            key={taskType.StringMapId}
                                            className={`task-tile ${isSelected ? "selected" : ""}`}
                                            onClick={() => validation.setFieldValue("TaskType", taskType.StringMapId)}
                                        >
                                            <div className="tile-image-container">
                                                <img src={`${apiUrl}${taskType.TaskTypeIcon}`} alt={taskType.StringMapName} className="tile-image" />
                                                {isSelected && <div className="tile-checkmark">✔</div>}
                                            </div>
                                            <div className="tile-label">{taskType.StringMapName}</div>
                                        </div>
                                    );
                                })}
                            </div>
                            {validation.touched.TaskType && validation.errors.TaskType ? (
                                <FormFeedback type="invalid" className="d-block">
                                    {validation.errors.TaskType}
                                </FormFeedback>
                            ) : null}
                        </Col>
                    </Row>

                    {/* TaskNumber Code */}
                    <Row>
                        <Col lg={3} className="mb-2">
                            <Label htmlFor="TaskNumber" className="col-form-label">
                                Task #
                            </Label>
                        </Col>
                        <Col lg={6} className="mb-2">
                            <div>{taskNumber}</div>
                        </Col>
                    </Row>

                    {/* TaskStatus Code */}
                    <Row>
                        <Col lg={3} className="mb-2">
                            <Label htmlFor="TaskStatus" className="col-form-label">
                                Status<span className="text-danger">*</span>
                            </Label>
                        </Col>
                        <Col lg={3} className="mb-2">
                            <Select
                                id="TaskStatus"
                                name="TaskStatus"
                                options={taskStatusList}
                                onChange={(selectedOption) => handleSelectChange('TaskStatus', selectedOption)}
                                onBlur={validation.handleBlur}
                                value={selectedTaskStatus}
                                placeholder="Select Task Status"
                                isSearchable={true}
                                styles={selectStyles(validation.touched.TaskStatus && validation.errors.TaskStatus)}
                                className="mb-3"
                                classNamePrefix="react-select"
                            />

                            {validation.touched.TaskStatus && validation.errors.TaskStatus ? (
                                <FormFeedback type="invalid" className="d-block">{validation.errors.TaskStatus}</FormFeedback>
                            ) : null}
                        </Col>
                    </Row>

                    {/* TaskTitle Code */}
                    <Row>
                        <Col lg={3} className="mb-3">
                            <Label htmlFor="TaskTitle" className="col-form-label">
                                Task Title<span className="text-danger">*</span>
                            </Label>
                        </Col>
                        <Col lg={9} className="mb-3">
                            <Input
                                id="TaskTitle"
                                name="TaskTitle"
                                type="text"
                                className="form-control validate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.TaskTitle}
                                invalid={
                                    validation.touched.TaskTitle && validation.errors.TaskTitle
                                        ? true
                                        : false
                                } />

                            {validation.touched.TaskTitle && validation.errors.TaskTitle ? (
                                <FormFeedback type="invalid" className="d-block">{validation.errors.TaskTitle}</FormFeedback>
                            ) : null}
                        </Col>
                    </Row>

                    {/* TaskDescription Code */}
                    <Row>
                        <Col lg={3} className="mb-2">
                            <Label htmlFor="TaskDescription" className="col-form-label">
                                Description
                            </Label>
                        </Col>
                        <Col lg={9} className="mb-2">
                            <Input
                                id="TaskDescription"
                                name="TaskDescription"
                                type="textarea"
                                className="form-control validate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.TaskDescription}
                                invalid={
                                    validation.touched.TaskDescription && validation.errors.TaskDescription
                                        ? true
                                        : false
                                } />
                        </Col>
                    </Row>

                    {/* Attachment Code */}
                    <Row>
                        <Col lg={3} className="mb-3">
                            <Label htmlFor="TaskDescription" className="col-form-label">
                                Attachment
                            </Label>
                        </Col>
                        <Col lg={9} className="mb-3">
                                <Row>
                                    <div
                                        onClick={selectedProject ? toggleAttachmentModal : null}
                                        style={{
                                            display: 'inline-flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '32px',
                                            height: '32px',
                                            cursor: selectedProject ? 'pointer' : 'not-allowed',
                                            color: selectedProject ? 'inherit' : '#ccc',
                                        }}
                                    >
                                        <i
                                            className="mdi mdi-paperclip"
                                            style={{
                                                fontSize: '24px',
                                                pointerEvents: selectedProject ? 'auto' : 'none',
                                            }}
                                        ></i>
                                    </div>
                            </Row>
                            <Row>
                                {attachments.length > 0 ? (
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }} className="d-flex">
                                        {attachments
                                            .filter((attachment) => {
                                                return attachment.Status === 'Active';
                                            })
                                            .map((attachment, index) => (
                                                <div
                                                    key={index}
                                                    className="d-flex flex-row align-items-center mt-2 me-3 mb-2 p-2 border rounded"
                                                >
                                                    <span
                                                        style={{ cursor: "pointer" }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleFileClick(attachment.URL, attachment.Type);
                                                        }}
                                                    >{attachment.FileName}</span>
                                                    <Button
                                                        color="danger"
                                                        size="sm"
                                                        className="ms-2"
                                                        style={{ padding: '0px 5px', borderRadius: "50%" }}
                                                        onClick={() => handleRemoveAttachment(attachment)}
                                                    >
                                                        &times;
                                                    </Button>
                                                </div>
                                            ))}
                                    </div>
                                ) : (
                                    <div>No attachments available.</div>
                                )}
                            </Row>
                        </Col>
                    </Row>

                    {/* EstimatedHours Code */}
                    <Row>
                        <Col lg={3} className="mb-3">
                            <Label htmlFor="EstimatedHours" className="col-form-label">
                                Estimated Hours
                            </Label>
                        </Col>
                        <Col lg={3} className="mb-3">
                            <Input
                                id="EstimatedHours"
                                name="EstimatedHours"
                                type="text"
                                className="form-control validate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.EstimatedHours}
                                invalid={
                                    validation.touched.EstimatedHours && validation.errors.EstimatedHours
                                        ? true
                                        : false
                                } />

                            {validation.touched.EstimatedHours && validation.errors.EstimatedHours ? (
                                <FormFeedback type="invalid" className="d-block">{validation.errors.EstimatedHours}</FormFeedback>
                            ) : null}
                        </Col>
                    </Row>

                    {/* AssignedTo Code */}
                    <Row>
                        <Col lg={3} className="mb-3">
                            <Label htmlFor="AssignedTo" className="col-form-label">
                                Assigned To
                            </Label>
                        </Col>
                        <Col lg={3} className="mb-3">
                            <Select
                                id="AssignedTo"
                                name="AssignedTo"
                                options={taskAccessList}
                                onChange={(selectedOption) => handleSelectChange('AssignedTo', selectedOption)}
                                value={selectedAssignedTo}
                                // placeholder="Select Assignee..."
                                placeholder={inputAssignedToFocus ? "" : "Select Assignee..."}
                                isSearchable={true}
                                // onFocus={() => setInputAssignedToFocus(true)}
                                // onBlur={() => setInputAssignedToFocus(false)}
                                // inputValue={inputAssignedToValue}
                                // onInputChange={(value) => setInputAssignedToValue(value)}
                                // menuIsOpen={inputAssignedToValue.length > 0}
                                // styles={selectStyles(validation.touched.AssignedTo && validation.errors.AssignedTo)}
                                styles={selectStylesWithImage2(validation.touched.AssignedTo && validation.errors.AssignedTo)}
                                className="mb-3"
                                classNamePrefix="react-select"
                                components={{
                                    // Option: CustomOption2, 
                                    SingleValue: CustomSingleValue2
                                }}
                            />
                            {validation.touched.AssignedTo && validation.errors.AssignedTo ? (
                                <FormFeedback type="invalid" className="d-block">{validation.errors.AssignedTo}</FormFeedback>
                            ) : null}
                        </Col>
                    </Row>

                    {/* EstimatedStartDate Code */}
                    <Row>
                        <Col lg={3} className="mb-3">
                            <Label htmlFor="EstimatedStartDate" className="col-form-label">
                                Start Date
                            </Label>
                        </Col>
                        <Col lg={3} className="mb-3">
                            <DatePicker
                                selected={startDate}
                                onChange={handleStartDateChange}
                                dateFormat="dd-MM-yyyy"
                                className="form-control"
                                id="EstimatedStartDate"
                                minDate={minDate}
                                placeholderText="Select Start Date"
                            />
                            {validation.touched.EstimatedStartDate && validation.errors.EstimatedStartDate ? (
                                <FormFeedback type="invalid" className="d-block">{validation.errors.EstimatedStartDate}</FormFeedback>
                            ) : null}
                        </Col>


                    </Row>

                    {/* DueDateTime Code */}
                    <Row>
                        <Col lg={3} className="mb-3">
                            <Label htmlFor="DueDate" className="col-form-label">
                                Due Date
                            </Label>
                        </Col>
                        <Col lg={3} className="mb-3">
                            <DatePicker
                                selected={dueDateOnly}
                                onChange={handleDateChange}
                                dateFormat="dd-MM-yyyy"
                                className="form-control"
                                id="DueDate"
                                placeholderText="Select Due Date"
                                minDate={startDate}
                            />
                            {validation.touched.DueDate && validation.errors.DueDate ? (
                                <FormFeedback type="invalid" className="d-block">{validation.errors.DueDate}</FormFeedback>
                            ) : null}
                        </Col>

                        {/* {!specifyTimeToggle && ( */}
                        <Col lg={1} className="mb-2" style={specifyTimeToggle ? { marginTop: "6px" } : {}}>
                            <div style={{ cursor: "pointer", textDecoration: "underline" }} onClick={toggleSpecifyTime}>
                                {specifyTimeToggle ? "Hide Time" : "Specify Time"}
                            </div>
                        </Col>
                        {/* )} */}

                        {specifyTimeToggle && (
                            <>
                                <Col lg={2} className="mb-2">
                                    <Label htmlFor="DueTime" className="col-form-label">
                                        Due Time
                                    </Label>
                                </Col>
                                <Col lg={3} className="mb-2 d-flex">
                                    <Input
                                        type="select"
                                        id="Hour"
                                        name="Hour"
                                        value={selectedHour}
                                        onChange={handleHourChange}
                                        className="me-2"
                                    >
                                        {generateOptions(1, 12)}
                                    </Input>
                                    <Input
                                        type="select"
                                        id="Minute"
                                        name="Minute"
                                        value={selectedMinute}
                                        onChange={handleMinuteChange}
                                        className="me-2"
                                    >
                                        {generateOptions(0, 59)}
                                    </Input>
                                    <Input
                                        type="select"
                                        id="AmPm"
                                        name="AmPm"
                                        value={amPm}
                                        onChange={handleAmPmChange}
                                    >
                                        <option value="AM">AM</option>
                                        <option value="PM">PM</option>
                                    </Input>
                                </Col>
                            </>
                        )}
                    </Row>

                    {/* ParentTaskID Code */}
                    <Row>
                        <Col lg={3} className="mb-3">
                            <Label htmlFor="ParentTaskID" className="col-form-label">
                                Parent Task
                            </Label>
                        </Col>
                        <Col lg={3} className="mb-3">
                            <Select
                                id="ParentTaskID"
                                name="ParentTaskID"
                                options={
                                    selectedProject
                                        ? tasksFromStore
                                            .filter((task) => task.ProjectID === selectedProject.value)
                                            .map((task) => ({
                                                label: `${task.TaskNumber} - ${task.TaskTitle}`,
                                                value: task.TaskID,
                                            }))
                                        : []
                                }
                                onChange={(selectedOption) => handleSelectChange('ParentTaskID', selectedOption)}
                                value={selectedParentTask}
                                placeholder={inputParentTaskIDFocus ? "" : "Select Parent Task..."}
                                isSearchable={true}
                                onFocus={() => setInputParentTaskIDFocus(true)}
                                onBlur={() => setInputParentTaskIDFocus(false)}
                                inputValue={inputParentTaskIDValue}
                                onInputChange={(value) => setInputParentTaskIDValue(value)}
                                menuIsOpen={inputParentTaskIDValue.length > 0}
                                styles={selectStyles2(validation.touched.ParentTaskID && validation.errors.ParentTaskID)}
                                className="mb-3"
                                classNamePrefix="react-select"
                            />

                            {validation.touched.ParentTaskID && validation.errors.ParentTaskID ? (
                                <FormFeedback type="invalid" className="d-block">{validation.errors.ParentTaskID}</FormFeedback>
                            ) : null}
                        </Col>
                        {selectedParentTask &&
                            <Col lg={3} className="mb-3">
                                <Button
                                    color="danger"
                                    className="ms-2"
                                    onClick={handleRemoveParentTask}
                                >
                                    x
                                </Button>
                            </Col>
                        }
                    </Row>

                    {/* ActualStartDate DateCompleted Code */}
                    <Row>
                        <Col lg={3} className="mb-3">
                            <Label htmlFor="ActualStartDate" className="col-form-label">
                                Actual Start Date
                            </Label>
                        </Col>
                        <Col lg={3} className="mb-3">
                            {actualStartDate !== 'Invalid date' ? (
                                <div>{actualStartDate}</div>
                            ) : (
                                <div>Not Started</div>
                            )}
                        </Col>
                        {dateCompleted !== 'Invalid date' &&
                            <>
                                <Col lg={3} className="mb-3">
                                    <Label htmlFor="DateCompleted" className="col-form-label">
                                        Date Completed
                                    </Label>
                                </Col>
                                <Col lg={3} className="mb-3">
                                    <div>{dateCompleted}</div>
                                </Col>
                            </>
                        }
                    </Row>

                    {/* HoursConsumed PercentCompleted Code */}
                    <Row>
                        <Col lg={3} className="mb-3">
                            <Label htmlFor="HoursConsumed" className="col-form-label">
                                Hours Consumed
                            </Label>
                        </Col>
                        <Col lg={3} className="mb-3">
                            <div>{hoursConsumed}</div>
                        </Col>
                        <Col lg={3} className="mb-3">
                            <Label htmlFor="PercentCompleted" className="col-form-label">
                                Percent Completed
                            </Label>
                        </Col>
                        <Col lg={3} className="mb-3">
                            <div>{percentCompleted}</div>
                        </Col>
                    </Row>

                    {/* Followers Code */}
                    <Row>
                        <Col lg={3} className="mb-3">
                            <Label htmlFor="Followers" className="col-form-label">
                                Followers
                            </Label>
                        </Col>
                        <Col lg={3} className="mb-3">
                            <Select
                                id="Followers"
                                name="Followers"
                                // options={taskAccessList}
                                options={taskAccessListWithoutAssignee}
                                value={selectedFollower}
                                onChange={setSelectedFollower}
                                placeholder={inputFollowerFocus ? "" : "Select Follower..."}
                                isSearchable={true}
                                // onFocus={() => setInputFollowerFocus(true)}
                                // onBlur={() => setInputFollowerFocus(false)}
                                // inputValue={inputFollowerValue}
                                // onInputChange={(value) => setInputFollowerValue(value)}
                                // menuIsOpen={inputFollowerValue.length > 0}
                                className="mb-3"
                                classNamePrefix="react-select"
                                styles={selectStylesWithImage2()}
                                components={{
                                    // Option: CustomOption2, 
                                    SingleValue: CustomSingleValue2
                                }}
                            />
                        </Col>
                        <Col lg={3} className="mb-3">
                            <Button
                                color="primary"
                                className="ms-2"
                                onClick={handleAddFollower}
                                disabled={!selectedFollower}
                            >
                                +
                            </Button>
                        </Col>
                    </Row>

                    {followersList.length > 0 && (
                        <Row>
                            <Col lg={3} className="mb-3"></Col>
                            <Col lg={9} className="mb-3">
                                <div className="d-flex flex-wrap">
                                    {followersList
                                        .filter(follower => follower.Status === "Active")
                                        .map((follower, index) => (
                                            <div
                                                key={index}
                                                className="d-flex align-items-center me-3 mb-2 p-2 border rounded"
                                            >
                                                <img
                                                    src={follower.ProfilePhoto}
                                                    alt={follower.AssociationName || 'Profile Image'}
                                                    style={{ width: 24, height: 24, marginRight: 8, borderRadius: '50%', objectFit: 'cover' }}
                                                />
                                                <span>{follower.AssociationName}</span>
                                                <Button
                                                    color="danger"
                                                    size="sm"
                                                    className="ms-2"
                                                    style={{ padding: '0px 5px', borderRadius: "50%" }}
                                                    onClick={() => handleRemoveFollower(follower)}
                                                >
                                                    &times;
                                                </Button>
                                            </div>
                                        ))}
                                </div>
                            </Col>
                        </Row>
                    )}

                    {/* Tags Code here */}
                    <Row>
                        <Col lg={3} className="mb-3">
                            <Label htmlFor="Tags" className="col-form-label">
                                Tags
                            </Label>
                        </Col>
                        <Col lg={3} className="mb-3">
                            <Select
                                id="Tags"
                                name="Tags"
                                options={taskTagList}
                                value={selectedTag}
                                onChange={setSelectedTag}
                                // placeholder="Select Tags"
                                placeholder={inputTagFocus ? "" : "Select a Tag..."}
                                isSearchable={true}
                                // onFocus={() => setInputTagFocus(true)}
                                // onBlur={() => setInputTagFocus(false)}
                                // inputValue={inputTagValue}
                                // onInputChange={(value) => setInputTagValue(value)}
                                // menuIsOpen={inputTagValue.length > 0}
                                className="mb-3"
                                classNamePrefix="react-select"
                                styles={selectStylesWithImage2()}
                            />
                        </Col>
                        <Col lg={3} className="mb-3">
                            <Button
                                color="primary"
                                className="ms-2"
                                onClick={handleAddTag}
                                disabled={!selectedTag}
                            >
                                +
                            </Button>
                        </Col>
                    </Row>

                    {tagsList.length > 0 && (
                        <Row>
                            <Col lg={3} className="mb-3"></Col>
                            <Col lg={9} className="mb-3">
                                <div className="d-flex flex-wrap">
                                    {tagsList
                                        .filter(tag => tag.Status === "Active")
                                        .map((tag, index) => (
                                            <div
                                                key={index}
                                                className="d-flex align-items-center me-3 mb-2 p-2 border rounded"
                                            >
                                                <span>{tag.AssociationName}</span>
                                                <Button
                                                    color="danger"
                                                    size="sm"
                                                    className="ms-2"
                                                    onClick={() => handleRemoveTag(tag)}
                                                    style={{ padding: '0px 5px', borderRadius: "50%" }}
                                                >
                                                    &times;
                                                </Button>
                                            </div>
                                        ))}
                                </div>
                            </Col>
                        </Row>
                    )}

                    {/* SubTask Code here */}
                    <Row>
                        <Col lg={3} className="mb-3">
                            <Label htmlFor="SubTasks" className="col-form-label">
                                Sub Tasks
                            </Label>
                        </Col>
                        <Col lg={3} className="mb-3">
                            <div className="subtasks-container">
                                {subTasksList.map((subtask, index) => (
                                    <div key={index} className="subtask-item">
                                        <span className="task-number">{subtask.TaskNumber}</span>
                                        <span
                                            className="task-title"
                                            onClick={() => handleTaskClick(subtask.SubTaskID)}
                                        >
                                            {subtask.TaskTitle}
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <span
                                    className="task-title"
                                    onClick={handleAddSubtask}
                                >
                                    Add Subtask
                                </span>
                            </div>
                        </Col>

                    </Row>

                    {/* Activity Code here */}
                    <Row>
                        <Col lg={3} className="mb-3">
                            <Label htmlFor="Activity" className="col-form-label">
                                Activity
                            </Label>
                        </Col>
                        <Col lg={6} className="mb-3">

                            <div className="mb-3">

                                <Button
                                    style={{ backgroundColor: timelineFilter === 'All' ? "#d8d8d8" : "#eceff4", border: "0", color: "black", cursor: "pointer" }}
                                    onClick={() => setTimelineFilter("All")}
                                    className="me-3"
                                >
                                    All
                                </Button>
                                <Button
                                    style={{ backgroundColor: timelineFilter === 'Comment' ? "#d8d8d8" : "#eceff4", border: "0", color: "black", cursor: "pointer" }}
                                    onClick={() => setTimelineFilter("Comment")}
                                    className="me-3"
                                >
                                    Comment
                                </Button>
                                <Button
                                    style={{ backgroundColor: timelineFilter === 'History' ? "#d8d8d8" : "#eceff4", border: "0", color: "black", cursor: "pointer" }}
                                    onClick={() => setTimelineFilter("History")}
                                    className="me-3"
                                >
                                    History
                                </Button>

                            </div>

                                <div
                                    style={{ height: "150px"}}
                                    className="mb-3"
                                >
                                    <ReactQuill
                                        value={editingTaskTimelineID ? editedComment : newComment}
                                        onChange={editingTaskTimelineID ? setEditedComment : setNewComment}
                                        placeholder="Comment here..."
                                        modules={{
                                            toolbar: [
                                                ['bold', 'italic', 'underline', 'strike'],
                                                [{ list: 'ordered' }, { list: 'bullet' }],
                                                [{ align: [] }],
                                            ],
                                        }}
                                        style={{ height: "120px" }}
                                    />
                                </div>

                                <div className="d-flex align-items-center">
                                    <Button
                                        onClick={handleSaveComment}
                                        color="primary"
                                        className="mb-3 mt-3"
                                    >
                                        Save
                                    </Button>
                                    <i
                                        onClick={() => {
                                            if (selectedProject) {
                                                setIsCommentMode(true);
                                                toggleAttachmentModal();
                                            }
                                        }}
                                        className="mdi mdi-paperclip"
                                        style={{
                                            fontSize: '24px',
                                            cursor: selectedProject ? 'pointer' : 'not-allowed',
                                            color: selectedProject ? 'inherit' : '#ccc'
                                        }}
                                    ></i>


                                    {commentAttachments.length > 0 ? (
                                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }} className="d-flex">
                                            {commentAttachments
                                                .filter((attachment) => {
                                                    return attachment.Status === 'Active';
                                                })
                                                .map((attachment, index) => (
                                                    <div
                                                        key={index}
                                                        className="d-flex flex-row align-items-center mt-2 me-3 mb-2 p-2 border rounded"
                                                    >
                                                        <span
                                                            style={{ cursor: "pointer" }}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleFileClick(attachment.URL, attachment.Type);
                                                            }}
                                                        >{attachment.FileName}</span>
                                                        <Button
                                                            color="danger"
                                                            size="sm"
                                                            className="ms-2"
                                                            style={{ padding: '0px 5px', borderRadius: "50%" }}
                                                            onClick={() => handleRemoveCommentAttachment(attachment)}
                                                        >
                                                            &times;
                                                        </Button>
                                                    </div>
                                                ))}
                                        </div>
                                    ) : (
                                        null
                                    )}


                                </div>

                            {filteredTaskTimeline?.map((timeline, index) => (
                                <div key={index} className="timeline-entry">
                                    <div>
                                        <img
                                            src={timeline.ProfilePhoto}
                                            alt='Profile Image'
                                            style={{ width: 24, height: 24, marginRight: 8, borderRadius: '50%', objectFit: 'cover' }}
                                        />
                                    </div>
                                    <div>
                                        <div className="timeline-header">
                                            <strong>
                                                {timeline.CreatedByFirstName} {timeline.CreatedByLastName}
                                            </strong>
                                            <span>
                                                {timeline.CreatedOn}
                                            </span>
                                        </div>
                                        <div className="timeline-comment">
                                            <p>{timeline.NoteTitle}</p>
                                        </div>
                                        <div className="timeline-comment mt-2">
                                            {timeline.NoteType === "Task Comment" ? (
                                                <div dangerouslySetInnerHTML={{ __html: timeline.NoteText }} />
                                            ) : (
                                                <p>{timeline.NoteText}</p>
                                            )}
                                        </div>

                                        {timeline.Attachments.length > 0 ? (
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }} className="d-flex">
                                                {timeline.Attachments
                                                    .filter((attachment) => {
                                                        return attachment.Status === 'Active';
                                                    })
                                                    .map((attachment, index) => (
                                                        <div
                                                            key={index}
                                                            className="d-flex flex-row align-items-center mt-2 me-3 mb-2 p-2 border rounded"
                                                        >
                                                            <span
                                                                style={{ cursor: "pointer" }}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handleFileClick(attachment.URL, attachment.Type);
                                                                }}
                                                            >{attachment.FileName}</span>
                                                        </div>
                                                    ))}
                                            </div>
                                        ) : (
                                            null
                                        )}

                                        {timeline.NoteType === "Task Comment" && timeline.CreatedBy === userDetail.CurrentUserId ? (
                                            <div>
                                                <span
                                                    className="task-title"
                                                    onClick={() => handleEditComment(timeline)}
                                                >
                                                    Edit
                                                </span>

                                                <span
                                                    className="task-title ms-3"
                                                    onClick={() => handleDeleteComment(timeline)}
                                                >
                                                    Delete
                                                </span>
                                            </div>
                                        ) : (
                                            null
                                        )}
                                    </div>
                                </div>
                            ))}
                        </Col>
                    </Row>









                    <Row className="mt-4 justify-content-end">
                            <Col lg={6} className="d-flex justify-content-end mb-3">
                                <button
                                    type="button"
                                    className="btn btn-danger large-button me-2"
                                    onClick={() => navigate("/Task")}
                                >
                                    Back to Dashboard
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-success large-button"
                                    disabled={loading}
                                >
                                    {loading ? "Updating..." : "Update"}
                                </button>
                            </Col>
                    </Row>
                </Form>

            )}


            <Modal isOpen={attachmentModal} toggle={toggleAttachmentModal}>
                <Form onSubmit={isCommentMode ? handleCommentAttachmentSubmit : handleAttachmentSubmit}>
                    <ModalHeader toggle={toggleAttachmentModal}>Add Attachment</ModalHeader>
                    <ModalBody>
                        <div>
                            <div
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                                style={{
                                    border: '2px dashed #ccc',
                                    padding: '20px',
                                    textAlign: 'center',
                                    borderRadius: '10px',
                                    cursor: 'pointer',
                                    backgroundColor: '#f9f9f9',
                                }}
                            >
                                <p>
                                    Drag and drop a file here, or{' '}
                                    <label
                                        style={{
                                            color: '#007bff',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        click to upload
                                        <Input
                                            type="file"
                                            accept=".png, .jpg, .jpeg, .csv, .xls, .xlsx, .pdf"
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                    </label>
                                </p>
                                {preview && (
                                    <div>
                                        <p>Preview:</p>
                                        {isImageFile(selectedFile) ? (
                                            <img
                                                src={preview}
                                                alt="Preview"
                                                style={{
                                                    maxWidth: '100%',
                                                    maxHeight: '200px',
                                                    marginTop: '10px',
                                                }}
                                            />
                                        ) : (
                                            <p>{preview}</p>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="mt-2">
                                <label>Or Enter a Link</label>
                                <Input
                                    type="url"
                                    placeholder="Enter a link"
                                    value={inputLink}
                                    onChange={handleLinkChange}
                                />
                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">Add</Button>
                        <Button color="secondary" onClick={toggleAttachmentModal}>Close</Button>
                    </ModalFooter>
                </Form>
            </Modal>

        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    console.log('Redux State:', state);
};

export default connect(mapStateToProps, { setBreadcrumbItems })(EditTask);
