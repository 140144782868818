import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Pages Calendar
import Calendar from "../pages/Calendar/index"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Kanban from "pages/Kanban"

//Master
import MasterItemCategory from "../pages/Master/ItemCategoryMaster/ItemCategoryMaster"
import ViewItemCategory from "../pages/Master/ItemCategoryMaster/ViewItemCategory"
import EditItemCategory from "../pages/Master/ItemCategoryMaster/EditItemCategory"
import MasterItem from "pages/Master/ItemMaster/ItemMaster"
import ViewItem from "pages/Master/ItemMaster/ViewItem"
import EditItem from "pages/Master/ItemMaster/EditItem"
import AddEditItemMetaData from "../pages/Master/ItemMetaDataMaster/AddEditItemMetaData"

// company
import MasterCompany from "pages/Company/CompanyMaster"
import ViewCompany from "pages/Company/ViewCompany"
import EditCompany from "pages/Company/EditCompany"

// organization
// import MasterCompany from "pages/Company/CompanyMaster"
import ViewOranization from "pages/Company/AddCompany"
import EditOranization from "pages/Company/AddCompany"

// Project
import ProjectMaster from "pages/Project/ProjectMaster"
import EditProject from "pages/Project/EditProject"

// Task
import TaskMaster from "pages/Task/TaskMaster"
import EditTask from "pages/Task/EditTask"

// EOD
import EODMaster from "pages/EOD/EODMaster"

// Reports
import Timesheet from "pages/Reports/Timesheet"

// Quotation
import QuotationMaster from "pages/Quotation/QuotationMaster"
import ViewQuotation from "pages/Quotation/ViewQuotation"
import EditQuotation from "pages/Quotation/EditQuotation"
import AllQuotation from "pages/Quotation/AllQuotation"
import AddQuotation from "pages/Quotation/AddQuotation"

// Invoice
import InvoiceMaster from "pages/Invoice/InvoiceMaster"
import AddInvoice from "pages/Invoice/AddInvoice"
import AllInvoice from "pages/Invoice/AllInvoice"

//Order
import OrderMaster from "pages/Order/OrderMaster"
import AddOrder from "pages/Order/AddOrder"
import AllOrder from "pages/Order/AllOrder"

const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  // //calendar
  { path: "/calendar", component: <Calendar /> },

  { path: "/kanbanboard", component: <Kanban /> },

  // // //profile
  { path: "/profile", component: <UserProfile /> },

  //StringMap
  { path: "/Master/ItemCategory", component: <MasterItemCategory /> },
  { path: "/Master/ItemCategory/View/:Id", component: <ViewItemCategory /> },
  { path: "/Master/ItemCategory/Edit/:Id", component: <EditItemCategory /> },
  { path: "/Master/Item", component: <MasterItem /> },
  { path: "/Master/Item/View/:Id", component: <ViewItem /> },
  { path: "/Master/Item/Edit/:Id", component: <EditItem /> },
  {
    path: "/Master/ItemMetaData/AddEdit/:Id",
    component: <AddEditItemMetaData />,
  },

  { path: "/Company", component: <MasterCompany /> },
  {
    path: "/Company/View/:Id",
    component: <ViewOranization isEditMode={true} />,
  },
  {
    path: "/Company/Edit/:Id",
    component: <EditOranization isEditMode={false} />,
  },
  { path: "/Company/View/:Id", component: <ViewCompany /> },
  { path: "/Company/Edit/:Id", component: <EditCompany /> },

  { path: "/Project", component: <ProjectMaster /> },
  { path: "/Project/Edit/:Id", component: <EditProject /> },

  { path: "/Task", component: <TaskMaster /> },
  { path: "/Task/Edit/:Id", component: <EditTask /> },

  { path: "/EndoftheDayStatus", component: <EODMaster /> },

  { path: "/Report/Timesheet", component: <Timesheet /> },

  { path: "/Invoice", component: <InvoiceMaster /> },
  { path: "/InvoiceList", component: <AllInvoice /> },
  { path: "/AddInvoice", component: <AddInvoice /> },
  { path: "/Invoice/:Type/:Id", component: <AddInvoice /> },

  { path: "/Order", component: <OrderMaster /> },
  { path: "/OrderList", component: <AllOrder /> },
  { path: "/AddOrder", component: <AddOrder /> },
  { path: "/Order/:Type/:Id", component: <AddOrder /> },

  { path: "/Quotation", component: <QuotationMaster /> },
  { path: "/QuotationList", component: <AllQuotation /> },
  { path: "/Quotation/:Type/:Id", component: <AddQuotation /> },
  // {path:"/Quotation/:Id",component:<AddQuotation/>},

  // { path: "/Quotation", component: <QuotationMaster /> },
  // {path:"/Quotation/View/:Id",component:<ViewQuotation/>},
  // {path:"/Quotation/Edit/:Id",component:<EditQuotation/>},
  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
]

export { userRoutes, authRoutes }
