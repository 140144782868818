import React, { useState, useEffect, useMemo } from "react"
import {
  AddUpdateInvoice,
  setShowOrderGetList,
  GetSelectedItemDetails,
  GetInvoiceNumber,
  // GetQuotationItemList,
  // ClearInvoice,
  ClearQuotationById,
  GetQuotationById,
} from "../../store/Order/actions"
import { connect, useDispatch, useSelector } from "react-redux"

const AddOrder = ({ props }) => {}
const mapStateToProps = state => ({
  showOrderGetList: state.Order?.showOrderGetList,
})

export default connect(mapStateToProps, { setShowOrderGetList })(AddOrder)
