// export const API_URL = process.env.API_URL
//   ? process.env.API_URL
//   : "http://localhost:5100/api"

// export const API_URL = "https://comfortapi.cognisun.net/api" //prod
export const API_URL = "https://comfortuatapi.cognisun.net/api" //uat
// LOGIN
export const POST_LOGIN = `${API_URL}/auth/weblogin`
export const POST_FAKE_REGISTER = "/post-fake-register"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

// Master
export const POST_ADD_UPDATE_ITEMS = `${API_URL}/master/AddUpdateItemCategoty`
export const GET_ITEMS_CATEGORIES = `${API_URL}/master/GetItemCategories`
export const GET_ITEM_CATEGORY = `${API_URL}/master/GetItemCategory/`
export const POST_ADD_UPDATE_ITEM = `${API_URL}/master/AddUpdateItem`
export const GET_ITEMS = `${API_URL}/master/GetItemList`
export const GET_ITEM = `${API_URL}/master/GetItemById`
export const GET_CATEGORY_DROPDOWN = `${API_URL}/master/GetDropdownData`
export const GET_MULTIPLE_DROPDOWN = `${API_URL}/master/GetMultipleDropdownData`
export const POST_ITEM_METADATA = `${API_URL}/master/AddUpdateItemMetaData`
export const GET_METADATA_ITEMS = `${API_URL}/master/GetItemMetadataList`
export const GET_ITEM_METADATA_ID = `${API_URL}/master/GetItemMetaDataById`
export const DELETE_ITEM_METADATA = `${API_URL}/master/ItemMetaData`
export const GET_EXISTING_FIELDS = `${API_URL}/master/GetExistingFields`

export const GET_DIRECTORY_LIST = `${API_URL}/auth/directoryList`

// Company
export const POST_ADD_UPDATE_COMPANY = `${API_URL}/master/AddUpdateOrganization`
// export const POST_ADD_UPDATE_COMPANY = `${API_URL}/company/AddUpdateCompany`
// export const GET_COMPANIES = `${API_URL}/company/GetCompanies`
export const GET_COMPANIES = `${API_URL}/master/GetOrgnaizationList`
// export const GET_COMPANY = `${API_URL}/company/GetCompany/`
export const GET_COMPANY = `${API_URL}/master/GetOrgnaizationById`
export const DELETE_COMPANY = `${API_URL}/master/DeleteOrgnaizationById`

// Project
export const POST_ADD_UPDATE_PROJECT = `${API_URL}/task/AddUpdateProjectDetail`
export const GET_PROJECTS = `${API_URL}/task/GetProjectList`
export const GET_PROJECT = `${API_URL}/task/GetProjectInfoById`

// Task
export const POST_ADD_UPDATE_TASK = `${API_URL}/task/AddUpdateTaskDetail`
export const GET_TASKS = `${API_URL}/task/GetTaskList`
export const GET_TASK = `${API_URL}/task/GetTaskInfoById`
export const GET_PROJECTINFO = `${API_URL}/task/GetProjectInfoById`
export const POST_ADD_UPDATE_TASK_TIMELINE = `${API_URL}/task/AddUpdateTaskTimeline`

// EOD
export const POST_ADD_UPDATE_WORK_REPORT = `${API_URL}/task/AddUpdateWorkReport`
export const GET_WORK_REPORT_BY_DATE = `${API_URL}/task/GetWorkReportByDate`

// REPORT
export const GET_TIMESHEET = `${API_URL}/report/GetTimesheet`

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"
export const DELETE_MESSAGE = "/delete/message"

//TASKS
// export const GET_TASKS = "/tasks";
export const DELETE_KANBAN = "/delete/tasks"
export const ADD_CARD_DATA = "/add/tasks"
export const UPDATE_CARD_DATA = "/update/tasks"

// Quotation
export const POST_ADD_UPDATE_QUOTATION = `${API_URL}/quotation/AddUpdateQuotation`
export const GET_QUOTATION_NUMBER = `${API_URL}/quotation/GetQuotationNumber`
export const GET_QUOTATION_LIST = `${API_URL}/quotation/GetQuotationList`
export const POST_SELECTED_ITEM_DETAILS = `${API_URL}/quotation/GetSelectedItemDetails`
export const GET_QUOTATION_ITEM_LIST = `${API_URL}/quotation/GetItemListForQuotation`
export const GET_QUOTATION_BY_ID = `${API_URL}/quotation/GetQuotationById`
export const GET_ITEM_DROPDOWN_QUOTATION = `${API_URL}/quotation/GetItemdropdownQuotation`

// Quotation
export const POST_ADD_UPDATE_INVOICE = `${API_URL}/order/AddUpdateInvoice`
export const GET_INVOICE_NUMBER = `${API_URL}/order/GetInvoiceNumber`
export const GET_INVOICE_LIST = `${API_URL}/order/GetInvoiceList`
// export const POST_SELECTED_ITEM_DETAILS = `${API_URL}/quotation/GetSelectedItemDetails`
// export const GET_QUOTATION_ITEM_LIST = `${API_URL}/quotation/GetItemListForQuotation`
export const GET_INVOICE_BY_ID = `${API_URL}/quotation/GetQuotationById`

//Order

export const GET_ORDER_LIST = `${API_URL}/order/GetUserOrderList`

//FILE UPLOAD
export const UPLOAD_FILE = `${API_URL}/upload`
export const REMOVE_UPLOAD = `${API_URL}/removeupload`
