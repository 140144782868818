import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  setPinStatus,
  setShowCompanyList,
  setShowItemGetCategoryList,
  setShowItemGetList,
  setShowProjectGetList,
  setShowQuotationGetList,
  setShowOrderGetList,
  setShowInvoiceGetList,
  setShowTaskGetList,
  setShowWorkReportGetList,
} from "store/actions"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const SidebarContent = props => {
  const { isSidebarActive = false } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const ref = useRef()

  const [isPinned, setIsPinned] = useState(false)

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const activeMenu = useCallback(() => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [props.router.location.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  const handleLinkClick = (e, actionType, navigateTo) => {
    e.preventDefault()
    switch (actionType) {
      case "category":
        dispatch(setShowItemGetCategoryList(true))
        break
      case "item":
        dispatch(setShowItemGetList(true))
        break
      case "company":
        dispatch(setShowCompanyList(true))
        break
      case "quotation":
        dispatch(setShowQuotationGetList(true))
        break
      case "order":
        dispatch(setShowOrderGetList(true))
        break
      case "invoice":
        dispatch(setShowInvoiceGetList(true))
        break
      case "project":
        dispatch(setShowProjectGetList(true))
        break
      case "task":
        dispatch(setShowTaskGetList(true))
        break
      case "EndoftheDayStatus":
        dispatch(setShowWorkReportGetList(true))
        break
      default:
        console.warn(`Unhandled action type: ${actionType}`)
    }
    if (navigateTo) {
      navigate(navigateTo)
    }
  }

  const handlePinClick = () => {
    const newPinStatus = !isPinned
    setIsPinned(newPinStatus)
    dispatch(setPinStatus(newPinStatus))
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <div className="d-flex justify-content-end me-3">
            {isSidebarActive && (
              <div style={{ cursor: "pointer" }} onClick={handlePinClick}>
                <i
                  className={isPinned ? "mdi mdi-pin" : "mdi mdi-pin-off"}
                  style={{ fontSize: "16px" }}
                ></i>
              </div>
            )}
          </div>
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Main")} </li>
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span className="badge rounded-pill bg-primary float-end">
                  2
                </span>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/calendar" className=" waves-effect">
                <i className="mdi mdi-calendar-check"></i>
                <span>{props.t("Calendar")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/calendar" className=" waves-effect">
                <i className="mdi mdi-calendar-check"></i>
                <span>{props.t("Calendar")}</span>
              </Link>
            </li> */}
            <li>
              <a
                href="/Company"
                onClick={e => handleLinkClick(e, "company", "/Company")}
              >
                <i className="mdi mdi-domain"></i>
                <span>Company</span>
              </a>
            </li>
            <li>
              <a
                href="/Quotation"
                onClick={e => handleLinkClick(e, "quotation", "/Quotation")}
              >
                <i className="mdi mdi-invoice-text-fast-outline"></i>
                <span>{props.t("Quotation")}</span>
              </a>
            </li>
            <li>
              <a
                href="/Order"
                onClick={e => handleLinkClick(e, "order", "/Order")}
              >
                <i className="mdi-truck-check"></i>
                <span>{props.t("Order")}</span>
              </a>
            </li>
            <li>
              <a
                href="/Invoice"
                onClick={e => handleLinkClick(e, "invoice", "/Invoice")}
              >
                <i className="mdi mdi-domain"></i>
                <span>{props.t("Invoice")}</span>
              </a>
            </li>

            <li>
              <a
                href="/Project"
                onClick={e => handleLinkClick(e, "project", "/Project")}
              >
                <i className="mdi mdi-briefcase-outline"></i>
                <span>Project</span>
              </a>
            </li>
            <li>
              <a
                href="/Task"
                onClick={e => handleLinkClick(e, "task", "/Task")}
              >
                <i className="mdi mdi-format-list-checks"></i>
                <span>Project Dashboard</span>
              </a>
            </li>
            <li>
              <a
                href="/EndoftheDayStatus"
                onClick={e =>
                  handleLinkClick(e, "EndoftheDayStatus", "/EndoftheDayStatus")
                }
              >
                <i className="mdi mdi-clock-check"></i>
                <span>EOD Report</span>
              </a>
            </li>
            <li>
              <Link to="/Reports" className="has-arrow waves-effect">
                <i className="mdi mdi-chart-bar"></i>
                <span>{props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu">
                <a
                  style={{ paddingLeft: "50px" }}
                  href="/Report/Timesheet"
                  onClick={e =>
                    handleLinkClick(e, "timesheet", "/Report/Timesheet")
                  }
                >
                  {props.t("Timesheet")}
                </a>
              </ul>
            </li>
            {/* <li>
              <Link to="/kanbanboard" className=" waves-effect">
                <i className="mdi mdi-billboard"></i>
                <span className="badge rounded-pill bg-success float-end">New</span>
                <span>Kanban Board</span>
              </Link>
            </li> */}
            <li>
              <Link to="/Master" className="has-arrow waves-effect">
                <i className="mdi mdi-buffer"></i>
                <span>{props.t("Master")}</span>
              </Link>
              <ul className="sub-menu">
                <a
                  style={{ paddingLeft: "50px" }}
                  href="/Master/ItemCategory"
                  onClick={e =>
                    handleLinkClick(e, "category", "/Master/ItemCategory")
                  }
                >
                  {props.t("Item Category")}
                </a>
                <a
                  style={{ paddingLeft: "50px" }}
                  href="/Master/Item"
                  onClick={e => handleLinkClick(e, "item", "/Master/Item")}
                >
                  {props.t("Item")}
                </a>

                {/* <li>
                  <Link to="/Master/Stringmap2">{props.t("Stringmap2")}</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t("Master")}
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/#">{props.t("Level 2.1")}</Link>
                    </li>
                    <li>
                      <Link to="/#">{props.t("Level 2.2")}</Link>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
