import React, { useEffect, useMemo } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems, setShowItemGetList, setShowTaskGetList } from "../../store/actions";
import AddTask from "./AddTask";
import AllTask from "./AllTask";

const TaskMaster = (props) => {
    document.title = "Task";

    const breadcrumbItems = useMemo(() => [
        { title: "Comfort Zone" },
        { title: "Task" },
        { title: props.showTaskGetList ? "List" : "Add" },
    ], [props.showTaskGetList]);

    useEffect(() => {
        props.setBreadcrumbItems("Tasks", breadcrumbItems);
    }, [props, breadcrumbItems]);

    return (
        <React.Fragment>
            <Row>
                <Col xl={12}>
                    {props.showTaskGetList ? <AllTask /> : <AddTask />}
                </Col>
            </Row>

        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    console.log('Redux State:', state);
    return {
        showTaskGetList: state.Task?.showTaskGetList,
    };
};

export default connect(mapStateToProps, { setBreadcrumbItems, setShowTaskGetList })(TaskMaster);