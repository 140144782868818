import { call, put, takeEvery, select } from "redux-saga/effects";
import { AddUpdateWorkReportSuccess, clearFilteredDataTodayRedux, GetWorkReportByDateSuccess, WorkReportAPIError } from "./actions";
import axios from "axios";
import * as url from "../../helpers/url_helper";
import { toast } from "react-toastify";

function* AddUpdateWorkReport({ payload: { workReportPayload } }) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(axios.post, url.POST_ADD_UPDATE_WORK_REPORT, workReportPayload, config);
    if (response.status >= 200 && response.status < 300) {
      yield put(AddUpdateWorkReportSuccess(response.data));
      yield put({ type: "SET_SHOW_WORK_REPORT_LIST", payload: true }); 
      yield put(clearFilteredDataTodayRedux());
      toast.success("Work Report " + (workReportPayload.TaskID ? "Updated" : "Added") + " Successfully");
    } else {
      throw new Error(response.data.errorMessage || "Failed to add/update task");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.error || err.response?.statusText || "An error occurred";
    yield put(WorkReportAPIError(errorMessage));
    toast.error(errorMessage);
  }
}

function* GetWorkReportByDate({ payload: { date, user } }) {
  debugger;
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    };
    const requestBody = {
      WorkReportDate: date,
      UserID: user
    };
    
    const response = yield call(axios.post, `${url.GET_WORK_REPORT_BY_DATE}`, requestBody, config);
    if (response.status >= 200 && response.status < 300) {
      yield put(GetWorkReportByDateSuccess(response.data));
    } else {
      throw new Error("Failed to fetch item");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.error || err.response?.statusText || err.message || "An error occurred";
    yield put(WorkReportAPIError(errorMessage));
    toast.error(errorMessage);
  }
}

function* WorkReportSaga() {
  yield takeEvery("ADD_UPDATE_WORK_REPORT", AddUpdateWorkReport);
  yield takeEvery("GET_WORK_REPORT_BY_DATE", GetWorkReportByDate);
}

export default WorkReportSaga;
