export const GetTimesheetReport = (values) => {
    debugger;
    console.log("valuesssss", values);
    
    return {
        type: "GET_TIMESHEET_REPORT",
        payload: values
    };
};

export const GetTimelineReportSuccess = (timesheetPayload) => {
    return {
        type: "GET_TIMESHEET_REPORT_SUCCESS",
        payload: timesheetPayload,
    };
};

export const ReportAPIError = (error) => {
    return {
        type: "REPORT_API_ERROR",
        payload: error,
    };
};
