import React, { useState, useEffect } from "react"
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
} from "reactstrap"
import classnames from "classnames"
import Tab1 from "./Tabs/BasicInfo" // Tab 1
import Tab2 from "./Tabs/Contacts" // Tab 2
import Tab3 from "./Tabs/Identifier.js" // Tab 3
import Tab4 from "./Tabs/ContactPerson" // Tab 4
import Tab5 from "./Tabs/Documents" // Tab 5
import Tab6 from "./Tabs/Notes" // Tab 6
import Tab7 from "./Tabs/TargetandPaymentTerms"

const TabsContainer = ({ isEditMode }) => {
  // const TabsContainer = ({}) => {
  // const isEditMode = true
  const [activeTab, setActiveTab] = useState("1")
  const [submittedTabs, setSubmittedTabs] = useState({})
  const [formData, setFormData] = useState({})

  const toggle = tab => {
    if (activeTab !== tab) {
      if (isEditMode || submittedTabs[tab]) {
        setFormData(prev => ({
          ...prev,
          [tab]: { ...formData[tab] },
        }))
        setActiveTab(tab)
      }
    }
  }

  const handleNext = (tabName, payload) => {
    console.log(`${tabName} data:`, payload)
    setSubmittedTabs(prev => ({ ...prev, [tabName]: true }))
    setFormData(prev => ({ ...prev, [tabName]: payload }))

    const nextTab = (parseInt(tabName) + 1).toString()
    setTimeout(() => {
      setActiveTab(nextTab)
    }, 500)
  }

  const previous = tabName => {
    console.log("Current formData before moving to previous tab:", formData)
    const prevTab = (parseInt(tabName) - 1).toString()
    setActiveTab(prevTab)
  }

  // Debugging: Check formData changes
  useEffect(() => {
    console.log("Updated formData:", formData)
  }, [formData])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Nav pills className="navtab-bg nav-justified">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => toggle("1")}
              >
                Basic Info
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => toggle("2")}
              >
                Contacts
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => toggle("3")}
              >
                Identifier
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "4" })}
                onClick={() => toggle("4")}
              >
                Contact Person
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "5" })}
                onClick={() => toggle("5")}
              >
                Documents
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "6" })}
                onClick={() => toggle("6")}
              >
                Target & Payment Terms
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "7" })}
                onClick={() => toggle("7")}
              >
                Notes
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} className="p-3 text-muted">
            <TabPane tabId="1">
              <Tab1
                next={payload => handleNext("1", payload)}
                formData={formData.Tab1 || { name: "John Doe" }}
                isEditMode={isEditMode}
              />
            </TabPane>
            <TabPane tabId="2">
              <Tab2
                activeTab={activeTab}
                previous={() => previous("2")}
                next={payload => handleNext("2", payload)}
                formData={formData.Tab2 || { email: "john.doe@example.com" }}
                isEditMode={isEditMode}
              />
            </TabPane>
            <TabPane tabId="3">
              <Tab3
                activeTab={activeTab}
                previous={() => previous("3")}
                next={payload => handleNext("3", payload)}
                formData={formData.Tab3 || { IdentiferType: "atat" }}
                isEditMode={isEditMode}
              />
            </TabPane>
            <TabPane tabId="4">
              <Tab4
                activeTab={activeTab}
                previous={() => previous("4")}
                next={payload => handleNext("4", payload)}
                formData={formData.Tab4}
                isEditMode={isEditMode}
              />
            </TabPane>
            <TabPane tabId="5">
              <Tab5
                activeTab={activeTab}
                previous={() => previous("5")}
                next={payload => handleNext("5", payload)}
                formData={formData.Tab5}
                isEditMode={isEditMode}
              />
            </TabPane>
            <TabPane tabId="6">
              <Tab7
                activeTab={activeTab}
                previous={() => previous("6")}
                next={payload => handleNext("6", payload)}
                formData={formData.Tab7}
                isEditMode={isEditMode}
              />
            </TabPane>
            <TabPane tabId="7">
              <Tab6
                activeTab={activeTab}
                previous={() => previous("7")}
                formData={formData.Tab6}
                isEditMode={isEditMode}
              />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TabsContainer
