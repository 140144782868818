import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

import Breadcrumb from "./Breadcrumb/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

// Item Reducer
import ItemCategoryMaster from "./Master/ItemCategory/reducer"
import ItemReducer from "./Master/Item/reducer"
import ItemMetaDataMaster from "./Master/ItemMetaData/reducer"
//Calendar
import calendar from "./calendar/reducer"

// Project
import Project from "./Project/reducer"

// Tasks
import tasks from "./tasks/reducer"
import Task from "./Task/reducer"

// EOD
import EOD from "./EOD/reducer"

import CommonAPI from "./Common/reducer"

import CompnayAPI from "./Company/reducer"
import Quotation from "./Quotation/reducer"
import Invoice from "./Invoice/reducer"

import Organization from "./Company/reducer"

// REPORTS
import Reports from "./Reports/reducer"

//order
import Order from "./Order/reducer"

const rootReducer = combineReducers({
  Layout,
  Breadcrumb,
  tasks,
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  ItemCategoryMaster,
  CommonAPI,
  CompnayAPI,
  ItemMaster: ItemReducer,
  ItemMetaDataMaster,
  Quotation,
  Invoice,
  Organization,
  Project,
  Task,
  EOD,
  Reports,
  Order,
})

export default rootReducer
