export const AddUpdateProject = (projectPayload) => {
    return {
        type: "ADD_UPDATE_PROJECT",
        payload: { projectPayload },
    };
};

export const AddUpdateProjectSuccess = (projectPayload) => {
    return {
        type: "ADD_UPDATE_PROJECT_SUCCESS",
        payload: projectPayload,
    };
};

export const setShowProjectGetList = (showProjectGetList) => {
    debugger;
    return {
        type: "SET_SHOW_PROJECT_LIST",
        payload: showProjectGetList,
    };
};

export const GetProjects = () => {
    return {
        type: "GET_ALL_PROJECTS",
    };
};

export const GetProjectsSuccess = (projectPayload) => {
    return {
        type: "GET_ALL_PROJECTS_SUCCESS",
        payload: projectPayload,
    };
};

export const GetProjectById = (id) => {
    return {
        type: "GET_PROJECT",
        payload: { id },
    };
};

export const GetProjectSuccess = (projectPayload) => {
    return {
        type: "GET_PROJECT_SUCCESS",
        payload: projectPayload,
    };
};

export const ProjectAPIError = (error) => {
    return {
        type: "PROJECT_API_ERROR",
        payload: error,
    };
};
