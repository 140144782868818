import React, { useState, useEffect, useMemo } from "react"
import {
  Row,
  Col,
  Label,
  Table,
  FormFeedback,
  Form,
  Input,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Button,
} from "reactstrap"
import Select from "react-select"
import Switch from "react-switch"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { AddUpdateOrganization, GetCompanyById } from "store/actions"
import { toast } from "react-toastify"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import * as url from "../../../helpers/url_helper"
import axios from "axios"
const apiAppUrl = process.env.REACT_APP_API_URL

const Contacts = ({ previous, next, formData, isEditMode, activeTab }) => {
  const dispatch = useDispatch()
  const organization = useSelector(state => state.CompnayAPI?.data) || []
  const multipleDropdownList =
    useSelector(state => state.CommonAPI?.multipleDropdownValues?.data) || []

  const [addressType, setAddressType] = useState([])
  const [phoneType, setPhoneType] = useState([])
  const [emailType, setEmailType] = useState([])

  const [addressEntries, setAddressEntries] = useState([])
  const [phoneEntries, setPhoneEntries] = useState([])
  const [emailEntries, setEmailEntries] = useState([])
  const [editIndex, setEditIndex] = useState({
    address: null,
    phone: null,
    email: null,
  })

  const [selectedAddressType, setSelectedAddressType] = useState(null)
  const [selectedPhoneType, setSelectedPhoneType] = useState(null)
  const [selectedEmailType, setSelectedEmailType] = useState(null)

  const location = useLocation()
  const isViewMode = location.pathname.includes("/View")

  const breadcrumbItems = useMemo(
    () => [
      { title: "Comfort" },
      { title: "Company" },
      { title: "View Company" },
    ],
    [],
  )

  useEffect(() => {
    if (multipleDropdownList.length > 0) {
      const mappedTypes = {
        ADDRESSTYPE: setAddressType,
        PHONETYPE: setPhoneType,
        EMAILTYPE: setEmailType,
      }

      Object.entries(mappedTypes).forEach(([type, setter]) => {
        const filteredList = multipleDropdownList.filter(
          item => item.StringMapType === type,
        )
        setter(filteredList)
      })
    }
  }, [multipleDropdownList])

  const { Id } = useParams()
  var company = []

  useEffect(() => {
    if (activeTab == 2) {
      const payloadTab = {
        TabName: "Contacts",
        OrganizationId: Id,
      }
      axios
        .post(`${url.API_URL}/master/GetOrgnaizationById`, payloadTab)
        .then(response => {
          if (response.status === 200) {
            console.log("Data Retrieved Successfully:", response.data)
            company = []
            company = response.data.data
            if (company.length > 0) {
              console.log("ContactsData", company)

              const { Address, Email, Phone } = company.reduce(
                (result, item) => {
                  if (item.AddressId) {
                    result.Address.push(item)
                  } else if (item.EmailId) {
                    result.Email.push(item)
                  } else if (item.PhoneId) {
                    result.Phone.push(item)
                  }
                  return result
                },
                { Address: [], Email: [], Phone: [] },
              )
              console.log("Address Array:", Address)
              console.log("Email Array:", Email)
              setAddressEntries(Address)
              setEmailEntries(Email)
              setPhoneEntries(Phone)
            }
          }
        })
        .catch(error => {
          console.error("Error Setting Up Request:", error)
        })
    }
  }, [Id, activeTab])

  const addressValidation = useFormik({
    initialValues: {
      AddressType: null,
      AddressLine1: "",
      AddressLine2: "",
      City: "",
      District: "",
      State: "",
      PostalCode: "",
      IsPreferred: false,
    },
    validationSchema: Yup.object({
      AddressType: Yup.string().required("Please select Address Type"),
      AddressLine1: Yup.string().required("Please enter Address Line 1"),
      AddressLine2: Yup.string().required("Please enter Address Line 2"),
      City: Yup.string().required("Please enter City"),
      District: Yup.string().required("Please enter District"),
      State: Yup.string().required("Please enter State"),
      PostalCode: Yup.string()
        .matches(/^\d+$/, "Postal Code must contain only digits")
        .max(6, "Postal Code cannot exceed 6 digits")
        .min(6, "Postal Code must be exactly 6 digits"),
    }),
    onSubmit: values => {
      if (
        values.IsPreferred &&
        addressEntries.some(
          (entry, idx) => entry.IsPreferred && idx !== editIndex.address,
        )
      ) {
        toast.error("Only one address can be marked as preferred.")
        return
      }

      const updatedEntries =
        editIndex.address !== null
          ? addressEntries.map((entry, index) =>
              index === editIndex.address ? values : entry,
            )
          : [...addressEntries, values]
      setAddressEntries(updatedEntries)
      addressValidation.resetForm()
      setEditIndex(prev => ({ ...prev, address: null }))
      setSelectedAddressType(null)
    },
  })

  const phoneValidation = useFormik({
    initialValues: {
      PhoneType: "",
      CountryCode: "",
      PhoneNumber: "",
      Ext: "",
      IsPreferred: false,
    },
    validationSchema: Yup.object({
      PhoneType: Yup.string().required("Please select Phone Type"),
      PhoneNumber: Yup.string()
        .required("Please enter Phone Number")
        .matches(/^\d+$/, "Phone number must contain only digits")
        .max(10, "Phone number cannot exceed 10 digits")
        .min(10, "Phone number must be exactly 10 digits"),
    }),
    onSubmit: values => {
      if (
        values.IsPreferred &&
        phoneEntries.some(
          (entry, idx) => entry.IsPreferred && idx !== editIndex.phone,
        )
      ) {
        toast.error("Only one phone can be marked as preferred.")
        return
      }

      const updatedEntries =
        editIndex.phone !== null
          ? phoneEntries.map((entry, index) =>
              index === editIndex.phone ? values : entry,
            )
          : [...phoneEntries, values]
      setPhoneEntries(updatedEntries)
      phoneValidation.resetForm()
      setEditIndex(prev => ({ ...prev, phone: null }))
      setSelectedPhoneType(null)
    },
  })

  const emailValidation = useFormik({
    initialValues: { EmailType: "", Email: "", IsPreferred: false },
    validationSchema: Yup.object({
      // EmailType: Yup.string().required("Please select Email Type"),
      // Email: Yup.string().email("Invalid email").required("Please enter Email"),
    }),
    onSubmit: values => {
      if (
        values.IsPreferred &&
        emailEntries.some(
          (entry, idx) => entry.IsPreferred && idx !== editIndex.email,
        )
      ) {
        toast.error("Only one phone can be marked as preferred.")
        return
      }

      const updatedEntries =
        editIndex.email !== null
          ? emailEntries.map((entry, index) =>
              index === editIndex.email ? values : entry,
            )
          : [...emailEntries, values]
      setEmailEntries(updatedEntries)
      emailValidation.resetForm()
      setEditIndex(prev => ({ ...prev, email: null }))
      setSelectedEmailType(null)
    },
  })

  const handleFinalSubmit = () => {
    // const OrganizationId = organization?.data[0]?.OrganizationId;
    const OrganizationId = Id ? Id : organization?.data[0]?.OrganizationId
    const payload = {
      Conatcts: {
        Address: addressEntries.map(entry => ({ ...entry, OrganizationId })),
        Phone: phoneEntries.map(entry => ({ ...entry, OrganizationId })),
        Email: emailEntries.map(entry => ({ ...entry, OrganizationId })),
      },
    }

    try {
      dispatch(AddUpdateOrganization(payload))
    } catch (error) {
      console.error("Dispatch failed: ", error)
    }
    next(formData)
  }

  useEffect(() => {
    if (addressValidation.values.AddressType) {
      const initialSelected = addressType.find(
        cat => cat.StringMapId === addressValidation.values.AddressType,
      )
      setSelectedAddressType(
        initialSelected
          ? {
              label: initialSelected.StringMapName,
              value: initialSelected.StringMapId,
            }
          : null,
      )
    }
  }, [addressType, addressValidation.values.AddressType])

  const handleAddressTypeChange = option => {
    setSelectedAddressType(option)
    addressValidation.setFieldValue("AddressType", option?.value)
  }

  useEffect(() => {
    if (phoneValidation.values.PhoneType) {
      const initialSelected = phoneType.find(
        cat => cat.StringMapId === phoneValidation.values.PhoneType,
      )
      setSelectedPhoneType(
        initialSelected
          ? {
              label: initialSelected.StringMapName,
              value: initialSelected.StringMapId,
            }
          : null,
      )
    }
  }, [phoneType, phoneValidation.values.PhoneType])

  const handlePhoneTypeChange = option => {
    setSelectedPhoneType(option)
    phoneValidation.setFieldValue("PhoneType", option?.value)
  }

  useEffect(() => {
    if (emailValidation.values.EmailType) {
      const initialSelected = emailType.find(
        cat => cat.StringMapId === emailValidation.values.EmailType,
      )
      setSelectedEmailType(
        initialSelected
          ? {
              label: initialSelected.StringMapName,
              value: initialSelected.StringMapId,
            }
          : null,
      )
    }
  }, [emailType, emailValidation.values.EmailType])

  const handleEmailTypeChange = option => {
    setSelectedEmailType(option)
    emailValidation.setFieldValue("EmailType", option?.value)
  }

  return (
    <React.Fragment>
      <Form onSubmit={e => e.preventDefault()}>
        {/* Address Section */}
        <Card>
          <CardHeader>Address</CardHeader>
          <CardBody>
            {!isViewMode && (
              <div>
                <Row>
                  <Col lg={4}>
                    <FormGroup>
                      <Label htmlFor="AddressType">
                        Address Type<span className="text-danger">*</span>
                      </Label>
                      <Select
                        id="AddressType"
                        options={addressType.map(cat => ({
                          label: cat.StringMapName,
                          value: cat.StringMapId,
                        }))}
                        onChange={handleAddressTypeChange}
                        onBlur={addressValidation.handleBlur}
                        value={selectedAddressType}
                        placeholder="Select Address Type"
                      />
                      {addressValidation.touched.AddressType &&
                        addressValidation.errors.AddressType && (
                          <FormFeedback type="invalid" className="d-block">
                            {addressValidation.errors.AddressType}
                          </FormFeedback>
                        )}
                    </FormGroup>
                  </Col>
                  <Col lg={4}>
                    <FormGroup>
                      <Label htmlFor="AddressLine1">
                        Address Line 1<span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="AddressLine1"
                        name="AddressLine1"
                        type="text"
                        onChange={addressValidation.handleChange}
                        onBlur={addressValidation.handleBlur}
                        value={addressValidation.values.AddressLine1}
                      />
                      {addressValidation.touched.AddressLine1 &&
                        addressValidation.errors.AddressLine1 && (
                          <FormFeedback type="invalid" className="d-block">
                            {addressValidation.errors.AddressLine1}
                          </FormFeedback>
                        )}
                    </FormGroup>
                  </Col>
                  <Col lg={4}>
                    <FormGroup>
                      <Label htmlFor="AddressLine2">
                        Address Line 2<span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="AddressLine2"
                        name="AddressLine2"
                        type="text"
                        onChange={addressValidation.handleChange}
                        onBlur={addressValidation.handleBlur}
                        value={addressValidation.values.AddressLine2}
                      />
                      {addressValidation.touched.AddressLine2 &&
                        addressValidation.errors.AddressLine2 && (
                          <FormFeedback type="invalid" className="d-block">
                            {addressValidation.errors.AddressLine2}
                          </FormFeedback>
                        )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <FormGroup>
                      <Label htmlFor="City">
                        City<span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="City"
                        name="City"
                        type="text"
                        onChange={addressValidation.handleChange}
                        onBlur={addressValidation.handleBlur}
                        value={addressValidation.values.City}
                      />
                      {addressValidation.touched.City &&
                        addressValidation.errors.City && (
                          <FormFeedback type="invalid" className="d-block">
                            {addressValidation.errors.City}
                          </FormFeedback>
                        )}
                    </FormGroup>
                  </Col>
                  <Col lg={4}>
                    <FormGroup>
                      <Label htmlFor="District">
                        District<span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="District"
                        name="District"
                        type="text"
                        onChange={addressValidation.handleChange}
                        onBlur={addressValidation.handleBlur}
                        value={addressValidation.values.District}
                      />
                      {addressValidation.touched.District &&
                        addressValidation.errors.District && (
                          <FormFeedback type="invalid" className="d-block">
                            {addressValidation.errors.District}
                          </FormFeedback>
                        )}
                    </FormGroup>
                  </Col>
                  <Col lg={4}>
                    <FormGroup>
                      <Label htmlFor="State">
                        State<span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="State"
                        name="State"
                        type="text"
                        onChange={addressValidation.handleChange}
                        onBlur={addressValidation.handleBlur}
                        value={addressValidation.values.State}
                      />
                      {addressValidation.touched.State &&
                        addressValidation.errors.State && (
                          <FormFeedback type="invalid" className="d-block">
                            {addressValidation.errors.State}
                          </FormFeedback>
                        )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between">
                  <Col lg={4}>
                    <Label htmlFor="PostalCode">Postal Code</Label>
                    <Input
                      id="PostalCode"
                      name="PostalCode"
                      type="text"
                      onChange={addressValidation.handleChange}
                      onBlur={addressValidation.handleBlur}
                      value={addressValidation.values.PostalCode}
                    />
                    {addressValidation.touched.PostalCode &&
                      addressValidation.errors.PostalCode && (
                        <FormFeedback type="invalid" className="d-block">
                          {addressValidation.errors.PostalCode}
                        </FormFeedback>
                      )}
                  </Col>
                  <Col className="d-flex align-items-center" lg={4}>
                    <Label className="mb-0 me-3">Is Preferred</Label>
                    <Switch
                      checked={addressValidation.values.IsPreferred}
                      onChange={checked =>
                        addressValidation.setFieldValue(
                          "IsPreferred",
                          checked ? 1 : 0,
                        )
                      }
                      onColor="#7A6FBE"
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                  </Col>
                  <Col
                    className="d-flex justify-content-end align-items-center"
                    lg={4}
                  >
                    <button
                      type="button"
                      onClick={addressValidation.handleSubmit}
                      className="btn btn-primary"
                    >
                      {editIndex.address !== null
                        ? "Update Address"
                        : "Add Address"}
                    </button>
                  </Col>
                </Row>
              </div>
            )}
            {/* Address Table */}
            {addressEntries.length > 0 && (
              <Table bordered className="mt-3">
                <thead>
                  <tr>
                    <th>Address Type</th>
                    <th>Address Line 1</th>
                    <th>City</th>
                    <th>District</th>
                    <th>State</th>
                    <th>Postal Code</th>
                    <th>Is Preferred</th>
                    {!isViewMode && <th>Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {addressEntries
                    .filter(entry => entry.Status !== "Inactive")
                    .map((entry, index) => (
                      <tr key={index}>
                        <td>
                          {
                            addressType.find(
                              cat => cat.StringMapId === entry.AddressType,
                            )?.StringMapName
                          }
                        </td>
                        <td>{entry.AddressLine1}</td>
                        <td>{entry.City}</td>
                        <td>{entry.District}</td>
                        <td>{entry.State}</td>
                        <td>{entry.PostalCode}</td>
                        <td>{entry.IsPreferred ? "Yes" : "No"}</td>
                        {!isViewMode && (
                          <td>
                            <Button
                              className="me-2"
                              size="sm"
                              onClick={() => {
                                setEditIndex(prev => ({
                                  ...prev,
                                  address: index,
                                }))
                                addressValidation.setValues(entry)
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              size="sm"
                              color="danger"
                              onClick={() => {
                                setAddressEntries(entries =>
                                  entries.some(
                                    (entry, i) => i === index && entry.Status,
                                  )
                                    ? entries.map((entry, i) =>
                                        i === index
                                          ? { ...entry, Status: "Inactive" }
                                          : entry,
                                      )
                                    : entries.filter((_, i) => i !== index),
                                )
                              }}
                              // onClick={() =>
                              //   setAddressEntries(entries =>
                              //     entries.filter((_, i) => i !== index),
                              //   )
                              // }
                            >
                              Delete
                            </Button>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>

        {/* Phone Section */}
        <Card>
          <CardHeader>Phone</CardHeader>
          <CardBody>
            {!isViewMode && (
              <div>
                <Row>
                  <Col lg={4}>
                    <FormGroup>
                      <Label htmlFor="PhoneType">
                        Phone Type<span className="text-danger">*</span>
                      </Label>
                      <Select
                        id="PhoneType"
                        options={phoneType.map(cat => ({
                          label: cat.StringMapName,
                          value: cat.StringMapId,
                        }))}
                        onChange={handlePhoneTypeChange}
                        onBlur={phoneValidation.handleBlur}
                        value={selectedPhoneType}
                        placeholder="Select Phone Type"
                      />
                      {phoneValidation.touched.PhoneType &&
                        phoneValidation.errors.PhoneType && (
                          <FormFeedback type="invalid" className="d-block">
                            {phoneValidation.errors.PhoneType}
                          </FormFeedback>
                        )}
                    </FormGroup>
                  </Col>
                  <Col lg={4}>
                    <FormGroup>
                      <Label htmlFor="CountryCode">Country Code</Label>
                      <Input
                        id="CountryCode"
                        name="CountryCode"
                        type="text"
                        onChange={phoneValidation.handleChange}
                        onBlur={phoneValidation.handleBlur}
                        value={phoneValidation.values.CountryCode}
                      />
                      {phoneValidation.touched.CountryCode &&
                        phoneValidation.errors.CountryCode && (
                          <FormFeedback type="invalid" className="d-block">
                            {phoneValidation.errors.CountryCode}
                          </FormFeedback>
                        )}
                    </FormGroup>
                  </Col>
                  <Col lg={4}>
                    <FormGroup>
                      <Label htmlFor="PhoneNumber">
                        Phone Number<span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="PhoneNumber"
                        name="PhoneNumber"
                        type="text"
                        onChange={phoneValidation.handleChange}
                        onBlur={phoneValidation.handleBlur}
                        value={phoneValidation.values.PhoneNumber}
                      />
                      {phoneValidation.touched.PhoneNumber &&
                        phoneValidation.errors.PhoneNumber && (
                          <FormFeedback type="invalid" className="d-block">
                            {phoneValidation.errors.PhoneNumber}
                          </FormFeedback>
                        )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <FormGroup>
                      <Label htmlFor="Ext">Extension</Label>
                      <Input
                        id="Ext"
                        name="Ext"
                        type="text"
                        onChange={phoneValidation.handleChange}
                        onBlur={phoneValidation.handleBlur}
                        value={phoneValidation.values.Ext}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="d-flex align-items-center" lg={4}>
                    <Label className="mb-0 me-3">Is Preferred</Label>
                    <Switch
                      checked={phoneValidation.values.IsPreferred}
                      onChange={checked =>
                        phoneValidation.setFieldValue(
                          "IsPreferred",
                          checked ? 1 : 0,
                        )
                      }
                      onColor="#7A6FBE"
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                  </Col>
                  <Col
                    className="d-flex justify-content-end align-items-center"
                    lg={4}
                  >
                    <button
                      type="button"
                      onClick={phoneValidation.handleSubmit}
                      className="btn btn-primary"
                    >
                      {editIndex.phone !== null ? "Update Phone" : "Add Phone"}
                    </button>
                  </Col>
                </Row>
              </div>
            )}
            {/* Phone Table */}
            {phoneEntries.length > 0 && (
              <Table bordered className="mt-3">
                <thead>
                  <tr>
                    <th>Phone Type</th>
                    <th>Country Code</th>
                    <th>Phone Number</th>
                    <th>Extension</th>
                    <th>Is Preferred</th>
                    {!isViewMode && <th>Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {phoneEntries
                    .filter(entry => entry.Status !== "Inactive")
                    .map((entry, index) => (
                      <tr key={index}>
                        <td>
                          {
                            phoneType.find(
                              cat => cat.StringMapId === entry.PhoneType,
                            )?.StringMapName
                          }
                        </td>
                        <td>{entry.CountryCode}</td>
                        <td>{entry.PhoneNumber}</td>
                        <td>{entry.Ext}</td>
                        <td>{entry.IsPreferred ? "Yes" : "No"}</td>
                        {!isViewMode && (
                          <td>
                            <Button
                              className="me-2"
                              size="sm"
                              onClick={() => {
                                setEditIndex(prev => ({
                                  ...prev,
                                  phone: index,
                                }))
                                phoneValidation.setValues(entry)
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              size="sm"
                              color="danger"
                              onClick={() => {
                                setPhoneEntries(entries =>
                                  entries.some(
                                    (entry, i) => i === index && entry.Status,
                                  )
                                    ? entries.map((entry, i) =>
                                        i === index
                                          ? { ...entry, Status: "Inactive" }
                                          : entry,
                                      )
                                    : entries.filter((_, i) => i !== index),
                                )
                              }}
                              // onClick={() =>
                              //   setPhoneEntries(entries =>
                              //     entries.filter((_, i) => i !== index),
                              //   )
                              // }
                            >
                              Delete
                            </Button>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>

        {/* Email Section */}
        <Card>
          <CardHeader>Email</CardHeader>
          <CardBody>
            {!isViewMode && (
              <div>
                <Row>
                  <Col lg={4}>
                    <FormGroup>
                      <Label htmlFor="EmailType">Email Type</Label>
                      <Select
                        id="EmailType"
                        options={emailType.map(cat => ({
                          label: cat.StringMapName,
                          value: cat.StringMapId,
                        }))}
                        onChange={handleEmailTypeChange}
                        onBlur={emailValidation.handleBlur}
                        value={selectedEmailType}
                        placeholder="Select Email Type"
                      />
                      {emailValidation.touched.EmailType &&
                        emailValidation.errors.EmailType && (
                          <FormFeedback type="invalid" className="d-block">
                            {emailValidation.errors.EmailType}
                          </FormFeedback>
                        )}
                    </FormGroup>
                  </Col>
                  <Col lg={4}>
                    <FormGroup>
                      <Label htmlFor="Email">Email</Label>
                      <Input
                        id="Email"
                        name="Email"
                        type="email"
                        onChange={emailValidation.handleChange}
                        onBlur={emailValidation.handleBlur}
                        value={emailValidation.values.Email}
                      />
                      {emailValidation.touched.Email &&
                        emailValidation.errors.Email && (
                          <FormFeedback type="invalid" className="d-block">
                            {emailValidation.errors.Email}
                          </FormFeedback>
                        )}
                    </FormGroup>
                  </Col>
                  <Col className="d-flex align-items-center" lg={4}>
                    <Label className="mb-0 me-3">Is Preferred</Label>
                    <Switch
                      checked={emailValidation.values.IsPreferred}
                      onChange={checked =>
                        emailValidation.setFieldValue(
                          "IsPreferred",
                          checked ? 1 : 0,
                        )
                      }
                      onColor="#7A6FBE"
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="d-flex justify-content-end align-items-center"
                    lg={12}
                  >
                    <button
                      type="button"
                      onClick={emailValidation.handleSubmit}
                      className="btn btn-primary"
                    >
                      {editIndex.email !== null ? "Update Email" : "Add Email"}
                    </button>
                  </Col>
                </Row>
              </div>
            )}
            {/* Email Table */}
            {emailEntries.length > 0 && (
              <Table bordered className="mt-3">
                <thead>
                  <tr>
                    <th>Email Type</th>
                    <th>Email</th>
                    <th>Is Preferred</th>
                    {!isViewMode && <th>Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {emailEntries
                    .filter(entry => entry.Status !== "Inactive")
                    .map((entry, index) => (
                      <tr key={index}>
                        <td>
                          {
                            emailType.find(
                              cat => cat.StringMapId === entry.EmailType,
                            )?.StringMapName
                          }
                        </td>
                        <td>{entry.Email}</td>
                        <td>{entry.IsPreferred ? "Yes" : "No"}</td>
                        {!isViewMode && (
                          <td>
                            <Button
                              className="me-2"
                              size="sm"
                              onClick={() => {
                                setEditIndex(prev => ({
                                  ...prev,
                                  email: index,
                                }))
                                emailValidation.setValues(entry)
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              size="sm"
                              color="danger"
                              onClick={() => {
                                setEmailEntries(entries =>
                                  entries.some(
                                    (entry, i) => i === index && entry.Status,
                                  )
                                    ? entries.map((entry, i) =>
                                        i === index
                                          ? { ...entry, Status: "Inactive" }
                                          : entry,
                                      )
                                    : entries.filter((_, i) => i !== index),
                                )
                              }}
                              // onClick={() =>
                              //   setEmailEntries(entries =>
                              //     entries.filter((_, i) => i !== index),
                              //   )
                              // }
                            >
                              Delete
                            </Button>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>

        {/* Submit Section */}
        {!isViewMode && (
          <Row
            className="justify-content-between"
            style={{ paddingRight: "20px", paddingLeft: "20px" }}
          >
            <Col className="d-flex justify-content-start">
              <button
                type="button"
                onClick={previous}
                className="btn btn-secondary large-button"
              >
                Previous
              </button>
            </Col>
            <Col className="d-flex justify-content-end">
              <button
                type="button"
                onClick={handleFinalSubmit}
                className="btn btn-primary large-button"
              >
                Save & Next
              </button>
            </Col>
          </Row>
        )}
      </Form>
    </React.Fragment>
  )
}

export default Contacts
