import React, { useEffect, useState, useRef } from "react"
import {
  Row,
  Col,
  Label,
  Table,
  FormFeedback,
  Form,
  Input,
  FormGroup,
  Button,
} from "reactstrap"
import Select from "react-select"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import {
  AddUpdateOrganization,
  UploadFile,
  ClearFilePath,
  GetCompanyById,
  RemoveUpload,
} from "store/actions"
import { toast } from "react-toastify"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import axios from "axios"
import * as url from "../../../helpers/url_helper"
const apiUrl = process.env.REACT_APP_URL
const apiAppUrl = process.env.REACT_APP_API_URL

const Identifier = ({ previous, next, formData, isEditMode, activeTab }) => {
  const initialValues =
    isEditMode && formData ? formData : { IdentiferType: "" }
  const dispatch = useDispatch()
  const multipleDropdownList =
    useSelector(state => state.CommonAPI?.multipleDropdownValues?.data) || []
  const fileData = useSelector(state => state.CommonAPI?.fileData) || ""
  const organization = useSelector(state => state.CompnayAPI?.data) || []
  const [selectedIdentiferType, setSelectedIdentiferType] = useState(null)

  const [identifierType, setIdentifierType] = useState([])
  const [entries, setEntries] = useState([])
  const [editIndex, setEditIndex] = useState(null)

  const [uploadedFile, setUploadedFile] = useState(null)
  const [fileName, setFileName] = useState("")

  const location = useLocation()
  const isViewMode = location.pathname.includes("/View")

  const fileInputRef = useRef(null)

  const { Id } = useParams()
  var company = []

  useEffect(() => {
    if (multipleDropdownList.length > 0) {
      const identifierList = multipleDropdownList.filter(
        item => item.StringMapType === "IDENTIFIERTYPE",
      )

      setIdentifierType(identifierList)
    }
  }, [multipleDropdownList])

  useEffect(() => {
    if (activeTab == 3) {
      const payloadTab = {
        TabName: "Identifier",
        OrganizationId: Id,
      }

      axios
        .post(`${url.API_URL}/master/GetOrgnaizationById`, payloadTab)
        .then(response => {
          if (response.status === 200) {
            console.log("Data Retrieved Successfully:", response.data)
            company = []
            company = response.data.data
            debugger
            if (company) {
              console.log("comppppIdentifier", company)
              setEntries(company)
            }
          }
        })
        .catch(error => {
          console.error("Error Setting Up Request:", error)
        })
    }
  }, [Id, activeTab])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      IdentiferType: "",
      IdentifierNumber: "",
      UploadedDocument: null,
      OrganizationIdentifierId: "",
    },
    validationSchema: Yup.object({
      IdentiferType: Yup.string().required("Please enter Identifier Type"),
      IdentifierNumber: Yup.string().required("Please enter Identifier Number"),
    }),
    onSubmit: values => {
      const updatedValues = { ...values, UploadedDocument: fileName || null }

      if (editIndex !== null) {
        const updatedEntries = [...entries]
        updatedEntries[editIndex] = updatedValues
        setEntries(updatedEntries)
        setEditIndex(null)
      } else {
        setEntries([...entries, updatedValues])
      }

      validation.resetForm()
      setSelectedIdentiferType(null)
      setFileName("")
    },
  })

  const handleIdentifierTypeChange = (selectedOption, name) => {
    setSelectedIdentiferType(selectedOption)
    validation.setFieldValue(name, selectedOption ? selectedOption.value : "")
  }

  const selectStyles = hasError => ({
    control: provided => ({
      ...provided,
      borderColor: hasError ? "red" : provided.borderColor,
      boxShadow: "none",
      "&:hover": {
        borderColor: hasError ? "red" : provided["&:hover"].borderColor,
      },
    }),
  })

  const handleEdit = index => {
    const entryToEdit = entries[index]
    setEditIndex(index)
    validation.setValues(entryToEdit)
    const selectedOption = identifierType.find(
      cat => cat.StringMapId === entryToEdit.IdentiferType,
    )
    setSelectedIdentiferType(
      selectedOption
        ? {
            label: selectedOption.StringMapName,
            value: selectedOption.StringMapId,
          }
        : null,
    )
  }

  const handleDelete = index => {
    debugger
    const entryToDelete = entries[index]

    if (entryToDelete?.UploadedDocument?.name) {
      try {
        dispatch(RemoveUpload(entryToDelete.UploadedDocument.name))
        setEntries(entries =>
          entries.some((entry, i) => i === index && entry.Status)
            ? entries.map((entry, i) =>
                i === index ? { ...entry, Status: "Inactive" } : entry,
              )
            : entries.filter((_, i) => i !== index),
        )
        // setEntries(entries.filter((_, i) => i !== index))
        toast.success("Entry and file deleted successfully!")
      } catch (error) {
        toast.error("Failed to delete the file. Please try again.")
      }
    } else {
      setEntries(entries =>
        entries.some((entry, i) => i === index && entry.Status)
          ? entries.map((entry, i) =>
              i === index ? { ...entry, Status: "Inactive" } : entry,
            )
          : entries.filter((_, i) => i !== index),
      )
      toast.success("Entry deleted successfully!")
      // setEntries(entries.filter((_, i) => i !== index))
    }
  }

  const handleFinalSubmit = () => {
    const OrganizationId = Id ? Id : organization?.data[0]?.OrganizationId
    const finalArray = entries.map(item => ({
      ...item,
      OrganizationId: OrganizationId,
    }))
    const payload = {
      Identifier: finalArray,
    }
    debugger
    const finalPayload = transformIdentifierData(payload)
    function transformIdentifierData(values) {
      const transformedIdentifier = values.Identifier.map(item => {
        return {
          ...item,
          OrganizationId: item.OrganizationId,
          IdentifierType: item.IdentiferType,
          IdentifierValue: item.IdentifierNumber,
          URL: item.UploadedDocument?.name
            ? item.UploadedDocument?.name
            : item.UploadedDocument || "",
          OrganizationIdentifierId: item.OrganizationIdentifierId || "",
        }
      })
      const payload = {
        IdentifierInfo: {
          Identifier: transformedIdentifier,
        },
      }

      return payload
    }
    dispatch(AddUpdateOrganization(finalPayload))
    next(formData)
  }

  const getUniqueFileName = originalName => {
    const timestamp = Date.now()
    const fileExtension = originalName.split(".").pop()
    const baseName = originalName.replace(/\.[^/.]+$/, "")
    return `identifier_${timestamp}.${fileExtension}`
  }

  const handleFileChange = event => {
    const file = event.currentTarget.files[0]
    if (file) {
      const uniqueFile = new File([file], getUniqueFileName(file.name), {
        type: file.type,
      })
      setUploadedFile(uniqueFile)
    }
  }

  const handleUpload = () => {
    if (uploadedFile) {
      const filePayload = new FormData()
      filePayload.append("file", uploadedFile)
      dispatch(UploadFile(filePayload))

      fileInputRef.current.value = ""
      setUploadedFile(null)
      setFileName(uploadedFile.name)
    } else {
      toast.error("Please select a file to upload")
    }
  }

  useEffect(() => {
    if (fileData.filename) {
      setFileName(fileData.filename)
      toast.success(`File Path URL: ${fileData.filename}`)
      dispatch(ClearFilePath())
    }
  }, [fileData.filename, dispatch])

  return (
    <React.Fragment>
      {!isViewMode && (
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row className="justify-content-center">
            <Col lg={6} className="mb-3">
              <FormGroup>
                <Label htmlFor="IdentiferType" className="col-form-label">
                  Identifier Type<span className="text-danger">*</span>
                </Label>
                <Select
                  id="IdentiferType"
                  name="IdentiferType"
                  options={identifierType.map(value => ({
                    label: value.StringMapName,
                    value: value.StringMapId,
                  }))}
                  onChange={option =>
                    handleIdentifierTypeChange(option, "IdentiferType")
                  }
                  onBlur={validation.handleBlur}
                  value={selectedIdentiferType}
                  placeholder="Select Identifier Type"
                  isSearchable={true}
                  styles={selectStyles(
                    validation.touched.IdentiferType &&
                      validation.errors.IdentiferType,
                  )}
                  classNamePrefix="react-select"
                />
                {validation.touched.IdentiferType &&
                validation.errors.IdentiferType ? (
                  <FormFeedback type="invalid" className="d-block">
                    {validation.errors.IdentiferType}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>

            <Col lg={6} className="mb-3">
              <div className="form-group">
                <Label htmlFor="IdentifierNumber" className="col-form-label">
                  Identifier Number<span className="text-danger">*</span>
                </Label>
                <Input
                  id="IdentifierNumber"
                  name="IdentifierNumber"
                  type="text"
                  className="form-control"
                  placeholder="Enter Identifier Number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.IdentifierNumber}
                  invalid={
                    validation.touched.IdentifierNumber &&
                    validation.errors.IdentifierNumber
                  }
                />
                {validation.touched.IdentifierNumber &&
                validation.errors.IdentifierNumber ? (
                  <FormFeedback type="invalid" className="d-block">
                    {validation.errors.IdentifierNumber}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col lg={6} className="mb-4">
              <FormGroup>
                <Label htmlFor="UploadedDocument">Upload Document</Label>
                <div className="d-flex align-items-center">
                  <Input
                    id="UploadedDocument"
                    name="UploadedDocument"
                    type="file"
                    innerRef={fileInputRef}
                    onChange={handleFileChange}
                  />
                  <Button
                    className="ms-2"
                    color="primary"
                    onClick={handleUpload}
                  >
                    Upload
                  </Button>
                </div>
                {(fileName || editIndex !== null) && (
                  <div className="mt-2">
                    <Label className="text-success">
                      Uploaded File:{" "}
                      <a
                        style={{ color: "green" }}
                        href={`${apiUrl}/images/${fileName}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {fileName}
                      </a>
                    </Label>
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col lg={6} className="mb-4">
              <Row className="mt-4 justify-content-end">
                <Col lg={6} className="d-flex justify-content-end">
                  <button type="submit" className="btn btn-primary">
                    {editIndex !== null
                      ? "Update Itentifier"
                      : "Add Itentifier"}
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
      {entries.length > 0 && (
        <Row className="justify-content-center">
          <Col>
            <Label htmlFor="IdentifierTable" className="col-form-label">
              Identifier Details
            </Label>
            <Table bordered className="mt-3">
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>Identifier Type</th>
                  <th style={{ width: "20%" }}>Identifier Number</th>
                  <th style={{ width: "20%" }}>Uploaded Document</th>
                  {!isViewMode && <th style={{ width: "20%" }}>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {entries
                  .filter(entry => entry.Status !== "Inactive")
                  .map((entry, index) => (
                    <tr key={index}>
                      <td>
                        {
                          identifierType.find(
                            cat => cat.StringMapId === entry.IdentiferType,
                          )?.StringMapName
                        }
                      </td>
                      <td>{entry.IdentifierNumber}</td>
                      {/* <td>{entry.UploadedDocument?.name ? entry.UploadedDocument?.name : entry.UploadedDocument}</td> */}
                      <td>
                        {entry.UploadedDocument ? (
                          <a
                            href={`${apiUrl}/images/${entry.UploadedDocument}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {entry.UploadedDocument}
                          </a>
                        ) : (
                          "No file uploaded"
                        )}
                      </td>

                      {!isViewMode && (
                        <td>
                          <Button
                            className="me-2"
                            size="sm"
                            onClick={() => handleEdit(index)}
                          >
                            Edit
                          </Button>
                          <Button
                            size="sm"
                            color="danger"
                            onClick={() => handleDelete(index)}
                          >
                            Delete
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
      {!isViewMode && (
        <Row className="justify-content-between">
          <Col className="d-flex justify-content-start">
            <button
              type="button"
              className="btn btn-secondary large-button"
              onClick={previous}
            >
              Previous
            </button>
          </Col>
          <Col className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary large-button"
              disabled={false}
              onClick={handleFinalSubmit}
            >
              Save & Next
            </button>
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

export default Identifier
