const initialState = {
  error: "",
  loading: false,
  Response: [],
  InvoiceById: [],
  InvoiceNumber: [],
  InvoiceList: [],
  InvoiceItemList: [],
  IitemList: [],
  data: null,
  ShowInvoiceGetList: false,
}

const Invoice = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_UPDATE_QUOTATION":
    case "GET_ALL_QUOTATION":
      return {
        ...state,
        loading: true,
      }

    case "ADD_UPDATE_QUOTATION_SUCCESS":
      return {
        ...state,
        loading: false,
        Response: action.payload, // Store the add/update response
        error: "",
      }

    case "GET_ALL_QUOTATION_SUCCESS":
      return {
        ...state,
        loading: false,
        // Response: action.payload, // Store the fetched
        error: "",
      }

    case "GET_SELECTED_ITEM_DETAILS":
      return {
        ...state,
        loading: false,
        // Response: action.payload, // Store the fetched
        error: "",
      }

    case "GET_SELECTED_ITEM_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        Response: action.payload, // Store the fetched
        error: "",
      }

    case "GET_INVOICE_NUMBER":
      return {
        ...state,
        loading: false,
        error: "",
      }

    case "GET_INVOICE_NUMBER_SUCCESS":
      return {
        ...state,
        loading: false,
        InvoiceNumber: action.payload, // Store the fetched
        error: "",
      }

    case "GET_INVOICE_LIST":
      return {
        ...state,
        loading: false,
        error: "",
      }

    case "GET_INVOICE_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        QuotationList: action.payload, // Store the fetched
        error: "",
      }
    case "GET_QUOTATION_ITEM_LIST":
      return {
        ...state,
        loading: false,
        error: "",
      }

    case "GET_QUOTATION_ITEM_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        QuotationItemList: action.payload, // Store the fetched
        error: "",
      }

    case "SET_SHOW_INVOICE_LIST":
      return {
        ...state,
        ShowInvoiceGetList: action.payload,
      }

    // case "GET_QUOTATION_BY_ID":
    //   return {
    //     ...state,
    //     loading: false,
    //     error: "",
    //   }

    // case "GET_QUOTATION_BY_ID_SUCCESS":
    //   return {
    //     ...state,
    //     loading: false,
    //     QuotationById: action.payload, // Store the fetched
    //     error: "",
    //   }

    // case "CLEAR_QUOTATION":
    //   return {
    //     ...state,
    //     loading: false,
    //     // Response: [],
    //     // QuotationById:[],
    //     InvoiceNumber: [],
    //     // QuotationList:[],
    //     // QuotationItemList:[],
    //     // IitemList:[],
    //     // data: null,
    //     // ShowInvoiceGetList:false,
    //     error: "",
    //   }
    // case "CLEAR_QUOTATION_BY_ID":
    //   return {
    //     ...state,
    //     loading: false,
    //     QuotationById: [],
    //     error: "",
    //   }
    default:
      return state
  }
}

export default Invoice
