import { call, put, takeEvery, select } from "redux-saga/effects";
import { AddUpdateItemMetadataSuccess, GetMetadataIdSuccess, ItemMetadataAPIError, } from "./actions";
import axios from "axios";
import * as url from "../../../helpers/url_helper";
import { toast } from "react-toastify";
import { ItemAPIError } from "store/actions";

// Saga for adding item meta data
function* AddUpdateItemMetadata({ payload }) {
  debugger
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    };
    console.log('target payload ', payload);
    const response = yield call(axios.post, url.POST_ITEM_METADATA, payload, config);
    console.log('data', response);
    if (response.status >= 200 && response.status < 300) {
      yield put(AddUpdateItemMetadataSuccess(response.data));
      const metaData = JSON.parse(payload)
      toast.success("Item Meta Data " + (metaData.items[0].ItemMetaDataId ? "Updated" : "Added") + " Successfully");
      yield put({ type: "SET_SHOW_ITEM_METADATA_LIST", payload: true }); 
    } else {
      throw new Error("Failed to fetch item categories");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.error || err.response?.statusText || "An error occurred";
    yield put(ItemMetadataAPIError(errorMessage));
    toast.error(errorMessage);
  }
}

function* GetMetaDataById({ payload: { id } }) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(axios.get, `${url.GET_ITEM_METADATA_ID}/${id}`, config);
    if (response.status >= 200 && response.status < 300) {
      yield put(GetMetadataIdSuccess(response.data));
    } else {
      throw new Error("Failed to fetch item");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.error || err.response?.statusText || "An error occurred";
    yield put(ItemAPIError(errorMessage));
    toast.error(errorMessage);
  }
}

// Watcher saga
function* ItemCategorySaga() {
  yield takeEvery("ADD_UPDATE_ITEM_METADATA", AddUpdateItemMetadata);
  yield takeEvery("GET_META_DATA_ID", GetMetaDataById);
}

export default ItemCategorySaga;