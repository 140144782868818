const initialState = {
  error: "",
  loading: false,
  Response: [],
  ItemdropdownList: [],
  QuotationById: [],
  QuotationNumber: [],
  QuotationList: [],
  QuotationItemList: [],
  IitemList: [],
  data: null,
  ShowQuotationGetList: false,
  ItemdropdownListloading: false,
}

const Quotation = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_UPDATE_QUOTATION":
    case "GET_ALL_QUOTATION":
      return {
        ...state,
        loading: true,
      }

    case "ADD_UPDATE_QUOTATION_SUCCESS":
      return {
        ...state,
        loading: false,
        Response: action.payload, // Store the add/update response
        error: "",
      }

    case "GET_ALL_QUOTATION_SUCCESS":
      return {
        ...state,
        loading: false,
        // Response: action.payload, // Store the fetched
        error: "",
      }

    case "GET_SELECTED_ITEM_DETAILS":
      return {
        ...state,
        loading: false,
        // Response: action.payload, // Store the fetched
        error: "",
      }

    case "GET_SELECTED_ITEM_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        Response: action.payload, // Store the fetched
        error: "",
      }

    case "GET_ITEM_DROPDOWN_QUOTATION":
      return {
        ...state,
        ItemdropdownListloading: true,
        // Response: action.payload, // Store the fetched
        error: "",
      }

    case "GET_ITEM_DROPDOWN_QUOTATION_SUCCESS":
      return {
        ...state,
        ItemdropdownListloading: false,
        ItemdropdownList: action.payload, // Store the fetched
        error: "",
      }

    case "GET_QUOTATION_NUMBER":
      return {
        ...state,
        loading: false,
        error: "",
      }

    case "GET_QUOTATION_NUMBER_SUCCESS":
      return {
        ...state,
        loading: false,
        QuotationNumber: action.payload, // Store the fetched
        error: "",
      }

    case "GET_QUOTATION_LIST":
      return {
        ...state,
        loading: false,
        error: "",
      }

    case "GET_QUOTATION_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        QuotationList: action.payload, // Store the fetched
        error: "",
      }
    case "GET_QUOTATION_ITEM_LIST":
      return {
        ...state,
        loading: false,
        error: "",
      }

    case "GET_QUOTATION_ITEM_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        QuotationItemList: action.payload, // Store the fetched
        error: "",
      }

    case "SET_SHOW_QUOTATION_LIST":
      return {
        ...state,
        ShowQuotationGetList: action.payload,
      }

    case "GET_QUOTATION_BY_ID":
      return {
        ...state,
        loading: false,
        error: "",
      }

    case "GET_QUOTATION_BY_ID_SUCCESS":
      return {
        ...state,
        loading: false,
        QuotationById: action.payload, // Store the fetched
        error: "",
      }

    case "CLEAR_QUOTATION":
      return {
        ...state,
        loading: false,
        // Response: [],
        // QuotationById:[],
        QuotationNumber: [],
        // QuotationList:[],
        // QuotationItemList:[],
        // IitemList:[],
        // data: null,
        // ShowQuotationGetList:false,
        error: "",
      }
    case "CLEAR_QUOTATION_BY_ID":
      return {
        ...state,
        loading: false,
        QuotationById: [],
        error: "",
      }
    default:
      return state
  }
}

export default Quotation
