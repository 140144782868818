import React, { useEffect, useMemo } from "react"
import { Row, Col, Button, CardBody, Card } from "reactstrap"
import { connect } from "react-redux"
import { setBreadcrumbItems, setShowOrderGetList } from "../../store/actions"

import AddOrder from "./AddOrder"
import AllOrder from "./AllOrder"

const OrderMaster = props => {
  document.title = "Order | Cognisun - Responsive Bootstrap 5 Admin Dashboard"

  const breadcrumbItems = useMemo(
    () => [{ title: "Comfort Zone" }, { title: "Order" }, { title: "List" }],
    [props.showOrderGetList],
  )

  useEffect(() => {
    props.setBreadcrumbItems("Order", breadcrumbItems)
  }, [props, breadcrumbItems])

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          {props.showOrderGetList ? <AllOrder /> : <AddOrder />}
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  console.log("Redux Stateqqq:", state)
  return {
    showOrderGetList: state.Order?.ShowOrderGetList,
  }
}

export default connect(mapStateToProps, {
  setBreadcrumbItems,
  setShowOrderGetList,
})(OrderMaster)
