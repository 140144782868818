import React, { useEffect, useMemo } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems, setShowWorkReportGetList } from "../../store/actions";
import AllEOD from "./AllEOD";

const WorkReportMaster = (props) => {
    document.title = "EOD Report";

    const breadcrumbItems = useMemo(() => [
        { title: "Comfort Zone" },
        { title: "EOD Report" },
        { title: "List"},
    ], [props.showWorkReportGetList]);

    useEffect(() => {
        props.setBreadcrumbItems("EOD Report", breadcrumbItems);
    }, [props, breadcrumbItems]);

    return (
        <React.Fragment>
            <Row>
                <Col xl={12}>
                    <AllEOD />
                </Col>
            </Row>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    console.log('Redux State:', state);
    return {
        showWorkReportGetList: state.EOD?.showWorkReportGetList,
    };
};

export default connect(mapStateToProps, { setBreadcrumbItems, setShowWorkReportGetList })(WorkReportMaster);