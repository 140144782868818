export const setShowInvoiceGetList = ShowInvoiceGetList => ({
  type: "SET_SHOW_INVOICE_LIST",
  payload: ShowInvoiceGetList,
})

// Get Invoice Number Action
export const GetInvoiceNumber = () => {
  return {
    type: "GET_INVOICE_NUMBER",
  }
}

// Success for Get Invoice Number
export const GetInvoiceNumberSuccess = InvoiceNumber => {
  debugger
  return {
    type: "GET_INVOICE_NUMBER_SUCCESS",
    payload: InvoiceNumber,
  }
}

// Get Invoice List Action
export const GetInvoiceList = inputdata => ({
  type: "GET_INVOICE_LIST",
  payload: inputdata,
})

// Success for Get Invoice List
export const GetInvoiceListSuccess = InvoiceList => {
  return {
    type: "GET_INVOICE_LIST_SUCCESS",
    payload: InvoiceList,
  }
}

// Add/Update Invoice
export const AddUpdateInvoice = input => {
  return {
    type: "ADD_UPDATE_INVOICE",
    payload: input,
  }
}

// Success for Add/Update Invoice
export const AddUpdateInvoiceSuccess = input => {
  return {
    type: "ADD_UPDATE_INVOICE_SUCCESS",
    payload: input,
  }
}

// Get Invoice Action
// export const GetQuotation = () => {
//   return {
//     type: "GET_ALL_INVOICE",
//   }
// }

// Success for Get Invoice
// export const GetQuotationSuccess = payload => {
//   return {
//     type: "GET_ALL_INVOICE_SUCCESS",
//     payload: payload,
//   }
// }

// export const GetSelectedItemDetails = inputpayload => {
//   return {
//     type: "GET_SELECTED_ITEM_DETAILS",
//     payload: inputpayload,
//   }
// }

// Success for Get Invoice
// export const GetSelectedItemDetailsSuccess = inputpayload => {
//   return {
//     type: "GET_SELECTED_ITEM_DETAILS_SUCCESS",
//     payload: inputpayload,
//   }
// }

// export const GetInvoiceList = inputpayload => {
//   return {
//     type: "GET_INVOICE_BY_ID",
//     payload: inputpayload,
//   }
// }

// Success for Get Invoice
export const GetInvoiceByIdSuccess = inputpayload => {
  return {
    type: "GET_INVOICE_BY_ID_SUCCESS",
    payload: inputpayload,
  }
}

// Get Quotation Number Action
// export const GetQuotationItemList = () => {
//   return {
//     type: "GET_QUOTATION_ITEM_LIST",
//   }
// }

// // Success for Get Quotation Number
// export const GetQuotationItemListSuccess = QuotationItemList => {
//   return {
//     type: "GET_QUOTATION_ITEM_LIST_SUCCESS",
//     payload: QuotationItemList,
//   }
// }
// Action for API error
export const InvoiceAPIError = error => {
  return {
    type: "INVOICE_API_ERROR",
    payload: error,
  }
}
