import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Label, Form } from "reactstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { useFormik } from "formik";
import * as Yup from "yup";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { GetDirectoryList, GetMultipleDropdown, GetTimesheetReport, setBreadcrumbItems, setShowItemGetList, setShowTaskGetList } from "../../store/actions";
import DataTable from "../../components/Common/CustomDataTable";
const apiUrl = process.env.REACT_APP_API_URL;

const formatDate = (date) => {
    if (!date) return "0000-00-00";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const Timesheet = (props) => {
    const dispatch = useDispatch();
    const multipleDropdownList = useSelector((state) => state.CommonAPI?.multipleDropdownValues?.data) || [];
    const filteredData = useSelector((state) => state.Reports?.timesheetData?.data) || [];

    const [resourceList, setResourceList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [dateTypeList, setDateTypeList] = useState([]);

    const [selectedResource, setSelectedResource] = useState({ label: "All", value: "All" });
    const [selectedProject, setSelectedProject] = useState({ label: "All", value: "All" });
    const [selectedDateType, setSelectedDateType] = useState({ label: "Week Till Date", value: "Week Till Date" });
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    document.title = "Reports";

    const breadcrumbItems = useMemo(() => [
        { title: "Cognisun" },
        { title: "Reports" },
        { title: "Timesheet" },
    ]);

    useEffect(() => {
        props.setBreadcrumbItems("Timesheet", breadcrumbItems);
    }, [props, breadcrumbItems]);

    useEffect(() => {
        dispatch(GetMultipleDropdown({
            "Input": [
                { "StringMapType": "PROJECTLIST", "OrderBy": "StringMapName" },
                { "StringMapType": "ResourceList", "OrderBy": "StringMapName" },
                { "StringMapType": "TimesheetDateType", "OrderBy": "StringMapName" }
            ]
        }));
        dispatch(GetDirectoryList());
    }, [dispatch])

    useEffect(() => {
        const projectListFiltered = multipleDropdownList
            .filter(item => item.StringMapType === "PROJECTLIST")
            .sort((a, b) => a.StringMapName.localeCompare(b.StringMapName));
        const resourceListFiltered = multipleDropdownList
            .filter(item => item.StringMapType === "ResourceList")
            .sort((a, b) => a.StringMapName.localeCompare(b.StringMapName));
        const dateTypeListFiltered = multipleDropdownList
            .filter(item => item.StringMapType === "TimesheetDateType")
            .sort((a, b) => a.StringMapName.localeCompare(b.StringMapName));

        setProjectList(projectListFiltered);
        setResourceList(resourceListFiltered);
        setDateTypeList(dateTypeListFiltered);

        if (resourceListFiltered.length === 1) {
            setSelectedResource({
                label: resourceListFiltered[0].StringMapName,
                value: resourceListFiltered[0].StringMapId,
                IconURL: resourceListFiltered[0].PersonIcon,
                FirstName: resourceListFiltered[0].FirstName,
                LastName: resourceListFiltered[0].LastName,
            });
            validation.setFieldValue('ResourceID', resourceListFiltered[0].StringMapId);
        } else {
            setSelectedResource({ label: "All", value: "All" });
            validation.setFieldValue('ResourceID', 'All');
        }
    }, [multipleDropdownList.length, dispatch]);

    const generatePlaceholderProfile = (firstName, lastName) => {
        const initials = `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
        const canvas = document.createElement('canvas');
        canvas.width = 100;
        canvas.height = 100;
        const context = canvas.getContext('2d');

        context.fillStyle = '#7A6FBE';
        context.fillRect(0, 0, canvas.width, canvas.height);

        context.font = '50px Arial';
        context.fillStyle = 'white';
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(initials, canvas.width / 2, canvas.height / 2);

        return canvas.toDataURL('image/png');
    };

    const handleSelectChange = (selectedOption, field) => {
        switch (selectedOption) {
            case 'ProjectID':
                setSelectedProject(field);
                validation.setFieldValue('ProjectID', field ? field.value : '');
                break;
            case 'ResourceID':
                setSelectedResource(field);
                validation.setFieldValue('ResourceID', field ? field.value : '');
                break;
            case 'DateType':
                setSelectedDateType(field);
                validation.setFieldValue('DateType', field ? field.value : '');
                break;
            default:
                break;
        }
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
        validation.setFieldValue('StartDate', formatDate(date));
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        validation.setFieldValue('EndDate', formatDate(date));
    };

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            ResourceID: 'All',
            ProjectID: 'All',
            DateType: 'Week Till Date',
            StartDate: formatDate(new Date()),
            EndDate: formatDate(new Date()),
        },
        validationSchema: Yup.object({
            ResourceID: Yup.string().required("Please Select a Resource"),
            ProjectID: Yup.string().required("Please Select a Project"),
            DateType: Yup.string().required("Please Select a Date Type"),
            StartDate: Yup.date(),
            EndDate: Yup.date(),
        }),
        onSubmit: async (values) => {
            console.log("add task values", values);
            dispatch(GetTimesheetReport(values))
        }
    });

    useEffect(() => {
        const { ResourceID, ProjectID, DateType, StartDate, EndDate } = validation.values;

        if (ResourceID && ProjectID && DateType && StartDate && EndDate) {
            dispatch(GetTimesheetReport(validation.values));
        }
    }, [
        validation.values.ResourceID,
        validation.values.ProjectID,
        validation.values.DateType,
        validation.values.StartDate,
        validation.values.EndDate,
        dispatch
    ]);

    const taskColumns = [
        { name: "Date", field: "WorkReportDate", sortable: true, width: "150px", body: (rowData) => moment(rowData.WorkReportDate, "YYYY-MM-DD").format("DD-MM-YYYY"), },
        { name: "DOW", field: "DOW", sortable: true, width: "100px" },
        { name: "Project", field: "ProjectName", sortable: true, width: "200px" },
        { name: "Task", field: "TaskTitle", sortable: true, width: "250px" },
        { name: "Hours", field: "HoursConsumed", sortable: true, width: "150px" },
        { name: "Total Day Hours", field: "TotalDayHours", sortable: true, width: "200px" },
    ];

    const transformDataForCSV = (data) => {
        return data.map((task) => ({
            WorkReportDate: moment(task.WorkReportDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
            DOW: task.DOW,
            ProjectName: task.ProjectName,
            TaskTitle: task.TaskTitle,
            HoursConsumed: task.HoursConsumed,
            TotalDayHours: task.TotalDayHours,
        }));
    };

    const memoizedCsvReport = useMemo(() => {
        const plainData = transformDataForCSV(filteredData);
        return {
            filename: `${selectedResource.label}_Timeline_Report`,
            headers: taskColumns.map((col) => ({ label: col.name, key: col.field })),
            data: plainData,
        };
    }, [filteredData]);

    const downloadPDF = () => {
        const doc = new jsPDF();
        const tableColumnHeaders = taskColumns.map((col) => col.name);

        // const tableRows = filteredData.map((task) => taskColumns.map((col) => task[col.field]));

        const tableRows = filteredData.map((task) => 
            taskColumns.map((col) => {
                if (col.field === "WorkReportDate") {
                    return moment(task.WorkReportDate, "YYYY-MM-DD").format("DD-MM-YYYY");
                }
                return task[col.field];
            })
        );
    
        doc.text(`${selectedResource.label} Timesheet Report`, 14, 10);

        const tableColumnWidths = [
            15, // Date
            10, // DOW
            20, // Project
            30, // Task
            10, // Hours
            15, // Total Day Hours
        ];

        const pageWidth = doc.internal.pageSize.getWidth() - 30; // Subtract margins
        const columnWidths = tableColumnWidths.map((percent) => (pageWidth * percent) / 100);
    
        doc.autoTable({
            head: [tableColumnHeaders],
            body: tableRows,
            startY: 20,
            columnStyles: columnWidths.reduce((styles, width, index) => {
                styles[index] = { cellWidth: width };
                return styles;
            }, {}),
        });
    
        doc.save(`${selectedResource.label}_Timesheet_Report.pdf`);
    };

    const selectStylesWithImage = (hasError) => ({
        control: (provided, state) => ({
            ...provided,
            borderColor: hasError ? 'red' : provided.borderColor,
            boxShadow: 'none',
            minHeight: '38px',
            height: '38px',
            '&:hover': {
                borderColor: hasError ? 'red' : provided['&:hover'].borderColor,
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0 8px',
            display: 'flex',
            alignItems: 'center',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            margin: 0,
        }),
        outline: "none",
    });

    const CustomSingleValue = (props) => {
        const iconUrl = props.data.IconURL ? `${apiUrl}${props.data.IconURL}` : generatePlaceholderProfile(props.data.FirstName, props.data.LastName)
        return (
            <div className="d-flex align-items-center" style={{ height: '100%', margin: 0, padding: 0 }}>
                {props.data.label !== 'All' && (
                    <img src={iconUrl} alt="" style={{ width: 30, height: 30, marginRight: 8, borderRadius: "50%" }} />
                )}
                <span>{props.data.label}</span>
            </div>
        );
    };

    const CustomSingleValue2 = (props) => (
        <div className="d-flex align-items-center" style={{ height: '100%', margin: 0, padding: 0 }}>
            {props.data.label !== 'All' && (
                <img src={props.data.IconURL} alt="" style={{ width: 25, height: 25, marginRight: 8 }} />
            )}
            <span>{props.data.label}</span>
        </div>
    );

    const CustomSingleValue3 = (props) => (
        <div className="d-flex align-items-center" style={{ height: '100%', margin: 0, padding: 0 }}>
            <span>{props.data.label}</span>
        </div>
    );

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return (
        <React.Fragment>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit()
                    return false;
                }}
                className="mb-3"
            >
                <Row className="mb-1">

                    {/* Resource List Code */}
                    <Col md={3}>
                        <Row>
                            <Label htmlFor="ResourceID" className="pb-0 col-form-label">
                                <i style={{ fontSize: "30px" }} className="mdi mdi-account-multiple-outline"></i>
                            </Label>
                        </Row>
                        <Row>
                            <Select
                                id="ResourceID"
                                name="ResourceID"
                                options={[
                                    { label: "All", value: "All" },
                                    ...resourceList.map((value) => ({
                                        label: value.StringMapName,
                                        value: value.StringMapId,
                                        IconURL: value.PersonIcon,
                                        FirstName: value.FirstName,
                                        LastName: value.LastName,
                                    }))
                                ]}
                                value={selectedResource}
                                onChange={(selectedOption) => handleSelectChange('ResourceID', selectedOption)}
                                placeholder="Select Resource"
                                isSearchable={true}
                                classNamePrefix="react-select"
                                styles={selectStylesWithImage()}
                                components={{
                                    // Option: CustomOption, 
                                    SingleValue: CustomSingleValue
                                }}
                            />
                        </Row>
                    </Col>

                    {/* Project List Code */}
                    <Col md={4}>
                        <Row>
                            <Label htmlFor="ProjectID" className="pb-0 col-form-label">
                                <i style={{ fontSize: "30px" }} className="mdi mdi-clipboard-list-outline"></i>
                            </Label>
                        </Row>
                        <Row>
                            <Select
                                id="ProjectID"
                                name="ProjectID"
                                options={[
                                    { label: "All", value: "All" },
                                    ...projectList.map((value) => ({
                                        label: value.StringMapName,
                                        value: value.StringMapId,
                                        IconURL: value.ProjectIconURL,
                                    }))
                                ]}
                                value={selectedProject}
                                onChange={(selectedOption) => handleSelectChange('ProjectID', selectedOption)}
                                placeholder="Select Project"
                                isSearchable={true}
                                classNamePrefix="react-select"
                                styles={selectStylesWithImage()}
                                components={{
                                    // Option: CustomOption, 
                                    SingleValue: CustomSingleValue2
                                }}
                            />
                        </Row>
                    </Col>

                    {/* DateType List Code */}
                    <Col md={3}>
                        <Row>
                            <Label htmlFor="DateType" className="pb-0 col-form-label">
                                <i style={{ fontSize: "30px" }} className="mdi mdi-calendar-clock"></i>
                            </Label>
                        </Row>
                        <Row>
                            <Select
                                id="DateType"
                                name="DateType"
                                options={[
                                    { label: "Custom", value: "Custom" },
                                    ...dateTypeList.map((value) => ({
                                        label: value.StringMapName,
                                        value: value.StringMapName,
                                    }))
                                ]}
                                value={selectedDateType}
                                onChange={(selectedOption) => handleSelectChange('DateType', selectedOption)}
                                placeholder="Select DateType"
                                isSearchable={true}
                                classNamePrefix="react-select"
                                styles={selectStylesWithImage()}
                                components={{
                                    // Option: CustomOption, 
                                    SingleValue: CustomSingleValue3
                                }}
                            />
                        </Row>
                    </Col>

                    {/* Download Button Code */}
                    <Col md={2} className="d-flex flex-column">
                        
                            <div className="mt-auto">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={downloadPDF}
                                    style={{marginBottom: "3px"}}
                                >
                                    <i className="mdi mdi-file-pdf-outline"></i>
                                </button>
                                {/* CSV Download Button Here */}
                                {/* <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={downloadPDF}
                                    style={{marginBottom: "3px", marginLeft: "20px"}}
                                >
                                    <i className="mdi mdi-file-pdf-outline"></i>
                                </button> */}
                            </div>
                        
                    </Col>

                </Row>


                {selectedDateType.label === 'Custom' && (
                    <Row className="mb-3">

                        {/* StartDate Code */}
                        <Col md={3} style={{ position: "relative" }}>
                            <Row>
                                <Label htmlFor="StartDate" className="pb-0 col-form-label">
                                    <i style={{ fontSize: "30px" }} className="mdi mdi-calendar-start"></i>
                                </Label>
                            </Row>
                            <Row>
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleStartDateChange}
                                    dateFormat="dd-MM-yyyy"
                                    className="form-control"
                                    id="StartDate"
                                    placeholderText="Select Start Date"
                                    maxDate={today}
                                />
                            </Row>
                        </Col>

                        {/* EndDate Code */}
                        <Col md={3} style={{ position: "relative" }}>
                            <Row>
                                <Label htmlFor="EndDate" className="pb-0 col-form-label">
                                    <i style={{ fontSize: "30px" }} className="mdi mdi-calendar-end"></i>
                                </Label>
                            </Row>
                            <Row>
                                <DatePicker
                                    selected={endDate}
                                    onChange={handleEndDateChange}
                                    dateFormat="dd-MM-yyyy"
                                    className="form-control"
                                    id="EndDate"
                                    placeholderText="Select End Date"
                                    maxDate={today}
                                />
                            </Row>
                        </Col>

                    </Row>
                )}

                {/* Testing Button */}
                {/* <button type="submit" className="btn btn-success large-button">
                    Submit
                </button> */}

            </Form>

            <DataTable
                filteredData={filteredData}
                Columns={taskColumns}
                csvReport={memoizedCsvReport}
                List={GetTimesheetReport}
                globalSearch={""}
                fontSize={"13px"}
            />

        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    console.log('Redux State:', state);
    return {
        showTaskGetList: state.Task?.showTaskGetList,
    };
};

export default connect(mapStateToProps, { setBreadcrumbItems, setShowTaskGetList })(Timesheet);