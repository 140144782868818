const initialState = {
    error: "",
    loading: false,
    projects: [],
    data: null,
    projectData: null,
    showProjectGetList: false,
};

const AddUpdateProject = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_UPDATE_PROJECT":
        case "GET_ALL_PROJECT":
            return {
                ...state,
                loading: true,
            };

        case "ADD_UPDATE_PROJECT_SUCCESS":
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
            };

        case "GET_ALL_PROJECTS_SUCCESS":
            return {
                ...state,
                loading: false,
                projects: action.payload,
                error: "",
            };

        case "GET_PROJECT_SUCCESS":
            return {
                ...state,
                loading: false,
                projectData: action.payload,
                error: "",
            };

        case "PROJECT_API_ERROR":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "SET_SHOW_PROJECT_LIST":
            console.log("Setting showProjectGetList to:", action.payload);
            return {
                ...state,
                showProjectGetList: action.payload,
            };

        default:
            return state;
    }
};

export default AddUpdateProject;
