const initialState = {
  error: "",
  loading: false,
  user: null,
  token: null,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_USER":
      return {
        ...state,
        loading: true,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        token: action.payload.token,
        isUserLogout: false,
      };
    case "LOGOUT_USER":
      return { ...state }; // This can be simplified further
    case "LOGOUT_USER_SUCCESS":
      return { ...state, isUserLogout: true };
    case "API_ERROR":
      return {
        ...state,
        error: action.payload,
        loading: false,
        isUserLogout: false,
      };
    default:
      return state; 
  }
};

export default login;
