import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import React, { useState, useEffect, useMemo, useRef } from "react"
import {
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Table,
  Col,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap"
import { useFormik } from "formik"
import { connect, useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import Switch from "react-switch"
import {
  AddUpdateOrganization,
  UploadFile,
  GetCompanyById,
  ClearCompany,
  GetMultipleDropdown,
} from "store/actions"
import { setBreadcrumbItems } from "../../../store/actions"
import { toast } from "react-toastify"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import axios from "axios"
import * as url from "../../../helpers/url_helper"
// const apiAppUrl = url
const apiUrl = process.env.REACT_APP_URL
// const apiAppUrl = process.env.REACT_APP_API_URL;

const validationSchema = Yup.object({
  Name: Yup.string().required("Please enter a name"),
  OrganizationType: Yup.string().required("Please select Organization Type"),
  BusinessType: Yup.string().required("Please select Business Type"),
})

const BasicInfo = ({ next, formData, isEditMode }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const isViewMode = location.pathname.includes("/View")
  const [isOkToContact, setIsOkToContact] = useState(
    formData?.isOkToContact || false,
  )
  const [uploadedFile, setUploadedFile] = useState(null)
  const [fileName, setFileName] = useState("")
  const fileInputRef = useRef(null)
  const { Id } = useParams()
  const [socialMediaEntries, setsocialMediaEntries] = useState([])
  const [editIndex, setEditIndex] = useState({
    SocialMedia: null,
  })
  const [selectedSocialMediaType, setSelectedSocialMediaType] = useState(null)

  const [socialMediaList, setSocialMediaList] = useState([])
  const [organizationType, setOrganizationType] = useState([])
  const [companyCategory, setCompanyCategory] = useState([])
  const [businessType, setBusinessType] = useState([])

  const breadcrumbItems = useMemo(
    () => [
      { title: "Comfort" },
      { title: "Company" },
      { title: "View Company" },
    ],
    [],
  )

  const multipleDropdownList =
    useSelector(state => state.CommonAPI?.multipleDropdownValues?.data) || []

  useEffect(() => {
    if (multipleDropdownList.length > 0) {
      const socialMedia = multipleDropdownList.filter(
        item => item.StringMapType === "SOCIALMEDIATYPE",
      )

      setSocialMediaList(socialMedia)

      const mappedData = {
        ORGANIZATIONTYPE: setOrganizationType,
        COMPANYCATEGORY: setCompanyCategory,
        BUSINESSTYPE: setBusinessType,
      }

      Object.keys(mappedData).forEach(key => {
        const filteredList = multipleDropdownList
          .filter(item => item.StringMapType === key)
          .map(data => ({
            label: data.StringMapName,
            value: data.StringMapId,
          }))

        mappedData[key](filteredList)
      })
    }
  }, [multipleDropdownList])

  const [fetchedData, setFetchedData] = useState({
    Name: "",
    Code: "",
    Logo: "",
    OrganizationType: "",
    BusinessType: "",
    Category: "",
    WebsiteName: "",
    isOkToContact: false,
    OrganizationId: "",
  })
  const [selectOrganizationType, setSelectOrganizationType] = useState(
    isEditMode ? formData?.OrganizationType : "",
  )
  const [selectBusinessType, setSelectBusinessType] = useState(
    isEditMode ? formData?.BusinessType : "",
  )
  const [selectCategoryType, setSelectCategoryType] = useState(
    isEditMode ? formData?.Category : "",
  )
  const handleSMTypeChange = option => {
    document.getElementById("SocialMediaError").textContent = ""
    setSelectedSocialMediaType(option)
    socialMediaValidation.setFieldValue("SocialMediaType", option?.value)
  }
  const socialMediaValidation = useFormik({
    initialValues: {
      OrganizationSocialMediaId: "",
      SocialMediaType: "",
      SocialMediaValue: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      if (values.SocialMediaType === "" && values.SocialMediaValue === "") {
        document.getElementById("SocialMediaError").textContent =
          "Please Select Social Media Type & Name"
        return
      } else {
        document.getElementById("SocialMediaError").textContent = ""
      }
      const updatedEntries =
        editIndex.SocialMedia !== null
          ? socialMediaEntries.map((entry, index) =>
              index === editIndex.SocialMedia ? values : entry,
            )
          : [...socialMediaEntries, values]
      setsocialMediaEntries(updatedEntries)
      socialMediaValidation.resetForm()
      setEditIndex(prev => ({ ...prev, SocialMedia: null }))
      setSelectedSocialMediaType(null)
    },
  })

  const initialValues = {
    Name: "",
    Code: "",
    Logo: "",
    OrganizationType: "",
    BusinessType: "",
    Category: "",
    isOkToContact: false,
    OrganizationId: "",
    PaymentTerms: 0,
    MaxLimit: 0.0,
    DiscountLimit: 0.0,
  }

  var company = []

  useEffect(() => {
    const payloadTab = {
      TabName: "BasicInfo",
      OrganizationId: Id,
    }
    debugger
    axios
      .post(`${url.API_URL}/master/GetOrgnaizationById`, payloadTab)
      .then(response => {
        if (response.status === 200) {
          console.log("Data Retrieved Successfully:", response.data)
          company = []
          company = response.data.data
          if (company.length > 0) {
            console.log("compppp111", company)
            formData = company
            setFetchedData({
              Name: company[0].OrganizationName || "",
              Code: company[0].OrganizationCode || "",
              Logo: company[0].Logo || "",
              OrganizationType: company[0].OrganizationType || "",
              BusinessType: company[0].BusinessType || "",
              Category: company[0].Category || "",
              WebsiteName: company[0].Website || "",
              isOkToContact: company[0].IsOkToContact,
              OrganizationId: company[0].OrganizationId || "",
              PaymentTerms: company[0].PaymentTerms || 0,
              MaxLimit: company[0].MaxLimit || 0.0,
              DiscountLimit: company[0].DiscountLimit || 0.0,
            })
            setIsOkToContact(company[0].IsOkToContact === 1 ? true : false)
            setSelectOrganizationType(company[0].OrganizationType || "")
            setSelectBusinessType(company[0].BusinessType || "")
            setSelectCategoryType(company[0].Category || "")
            setFileName(company[0].Logo)
            const { SocialMedia } = company.reduce(
              (result, item) => {
                if (item.OrganizationSocialMediaId) {
                  result.SocialMedia.push(item)
                }
                return result
              },
              { SocialMedia: [] },
            )
            setsocialMediaEntries(SocialMedia)
          }
        }
      })
      .catch(error => {
        console.error("Error Setting Up Request:", error)
      })
  }, [Id])

  useEffect(() => {
    dispatch(
      GetMultipleDropdown({
        Input: [
          { StringMapType: "BUSINESSTYPE", OrderBy: "StringMapName" },
          { StringMapType: "ORGANIZATIONTYPE", OrderBy: "StringMapName" },
          { StringMapType: "ADDRESSTYPE", OrderBy: "StringMapName" },
          { StringMapType: "PHONETYPE", OrderBy: "StringMapName" },
          { StringMapType: "EMAILTYPE", OrderBy: "StringMapName" },
          { StringMapType: "SOCIALMEDIATYPE", OrderBy: "StringMapName" },
          { StringMapType: "COMPANYCATEGORY", OrderBy: "StringMapName" },
          { StringMapType: "IDENTIFIERTYPE", OrderBy: "StringMapName" },
          { StringMapType: "Title", OrderBy: "StringMapName" },
          { StringMapType: "PERSONTYPE", OrderBy: "StringMapName" },
          { StringMapType: "DESIGNATION", OrderBy: "StringMapName" },
          { StringMapType: "DEPARTMENT", OrderBy: "StringMapName" },
          { StringMapType: "OrganizationList", OrderBy: "StringMapName" },
        ],
      }),
    )
  }, [dispatch])

  useEffect(() => {
    if (socialMediaValidation.values.SocialMediaType) {
      const initialSelected = socialMediaList.find(
        cat => cat.StringMapId === socialMediaValidation.values.SocialMediaType,
      )
      setSelectedSocialMediaType(
        initialSelected
          ? {
              label: initialSelected.StringMapName,
              value: initialSelected.StringMapId,
            }
          : null,
      )
    }
  }, [socialMediaList, socialMediaValidation.values.SocialMediaType])

  const handleFileChange = event => {
    const file = event.currentTarget.files[0]
    if (file) {
      const uniqueFile = new File([file], getUniqueFileName(file.name), {
        type: file.type,
      })
      setUploadedFile(uniqueFile)
      setFileName(file.name)
    }
  }

  const handleUpload = () => {
    if (uploadedFile) {
      const filePayload = new FormData()
      filePayload.append("file", uploadedFile)
      dispatch(UploadFile(filePayload))
      // setFileName("");
      fileInputRef.current.value = ""
      formData.Logo = uploadedFile.name
      setFileName(uploadedFile.name)
    } else {
      toast.error("Please select a file to upload")
    }
  }

  const getUniqueFileName = originalName => {
    const timestamp = Date.now()
    const fileExtension = originalName.split(".").pop()
    const baseName = originalName.replace(/\.[^/.]+$/, "")
    return `orgLogo_${timestamp}.${fileExtension}`
  }

  const handleSelectChange = (option, setFieldValue, fieldName) => {
    setFieldValue(fieldName, option.value)
    if (fieldName === "OrganizationType") {
      setSelectOrganizationType(option.value)
    } else if (fieldName === "BusinessType") {
      setSelectBusinessType(option.value)
    } else if (fieldName === "Category") {
      setSelectCategoryType(option.value)
    }
  }

  const selectStyles = hasError => ({
    control: provided => ({
      ...provided,
      borderColor: hasError ? "red" : provided.borderColor,
      boxShadow: "none",
    }),
  })

  return (
    <Formik
      initialValues={fetchedData}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values, { setSubmitting }) => {
        console.log("values", values)

        const payload = {
          organization: {
            OrganizationId: values.OrganizationId || "",
            OrganizationName: values.Name,
            OrganizationCode: parseInt(values.Code, 10),
            Logo: fileName,
            OrganizationType: values.OrganizationType,
            BusinessType: values.BusinessType,
            Category: values.Category,
            IsOkToContact: values.isOkToContact ? 1 : 0,
            Website: values.WebsiteName,
            PaymentTerms: values.PaymentTerms,
            MaxLimit: values.MaxLimit,
            DiscountLimit: values.DiscountLimit,
            SocialMediaInfo: socialMediaEntries,
          },
        }
        dispatch(AddUpdateOrganization(JSON.stringify(payload)))
        next(values)
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, errors, touched, setFieldValue }) => (
        <Form>
          <Row>
            <Col lg={6}>
              <FormGroup>
                <Label for="name">
                  Name<span className="text-danger">*</span>
                </Label>
                <Field
                  name="Name"
                  as={Input}
                  disabled={isViewMode}
                  invalid={touched.Name && !!errors.Name}
                />
                <ErrorMessage name="Name" component={FormFeedback} />
              </FormGroup>
            </Col>
            <Col lg={6}>
              <FormGroup>
                <Label for="Code">Code</Label>
                <Field
                  name="Code"
                  as={Input}
                  disabled={isViewMode}
                  invalid={touched.Code && !!errors.Code}
                />
                <ErrorMessage name="Code" component={FormFeedback} />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <FormGroup>
                <Label for="Logo">
                  Logo<span className="text-danger"></span>
                </Label>
                <div className="d-flex align-items-center">
                  <Input
                    name="Logo"
                    disabled={isViewMode}
                    type="file"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    invalid={touched.Logo && !!errors.Logo}
                  />
                  {!isViewMode && (
                    <Button
                      color="primary"
                      disabled={isViewMode}
                      onClick={handleUpload}
                      className="ms-2"
                    >
                      Upload
                    </Button>
                  )}
                </div>
                <ErrorMessage name="Logo" component={FormFeedback} />
                {fileName && (
                  <div className="mt-2">
                    <Label className="text-success">
                      Uploaded File:{" "}
                      <a
                        style={{ color: "green" }}
                        href={`${apiUrl}/images/${fileName}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {fileName}
                      </a>
                    </Label>
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col lg={6}>
              <FormGroup>
                <Label
                  htmlFor="OrganizationType"
                  className="col-form-label pt-0"
                >
                  Organization Type<span className="text-danger">*</span>
                </Label>
                <Select
                  id="OrganizationType"
                  name="OrganizationType"
                  options={organizationType}
                  isDisabled={isViewMode}
                  value={organizationType.find(
                    option => option.value === selectOrganizationType,
                  )}
                  onChange={option =>
                    handleSelectChange(
                      option,
                      setFieldValue,
                      "OrganizationType",
                    )
                  }
                  placeholder="Select Organization Type"
                  classNamePrefix="react-select"
                  styles={selectStyles(
                    touched.OrganizationType && errors.OrganizationType,
                  )}
                  className={
                    touched.OrganizationType && errors.OrganizationType
                      ? "is-invalid"
                      : ""
                  }
                />
                {touched.OrganizationType && errors.OrganizationType && (
                  <FormFeedback className="d-block">
                    {errors.OrganizationType}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <FormGroup>
                <Label htmlFor="BusinessType" className="col-form-label pt-0">
                  Business Type<span className="text-danger">*</span>
                </Label>
                <Select
                  id="BusinessType"
                  name="BusinessType"
                  isDisabled={isViewMode}
                  options={businessType}
                  value={businessType.find(
                    option => option.value === selectBusinessType,
                  )}
                  onChange={option =>
                    handleSelectChange(option, setFieldValue, "BusinessType")
                  }
                  placeholder="Select Business Type"
                  classNamePrefix="react-select"
                  styles={selectStyles(
                    touched.BusinessType && errors.BusinessType,
                  )}
                  className={
                    touched.BusinessType && errors.BusinessType
                      ? "is-invalid"
                      : ""
                  }
                />
                {touched.BusinessType && errors.BusinessType && (
                  <FormFeedback className="d-block">
                    {errors.BusinessType}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>

            {/* <Col lg={6}>
              <FormGroup>
                <Label for="WebsiteName">Website Name</Label>
                <Field
                  name="WebsiteName"
                  as={Input}
                  disabled={isViewMode}
                  invalid={touched.WebsiteName && !!errors.WebsiteName}
                />
                <ErrorMessage name="WebsiteName" component={FormFeedback} />
              </FormGroup>
            </Col> */}
            {/* </Row>

          <Row> */}
            <Col lg={6}>
              <FormGroup className="d-flex align-items-center gap-3">
                <div
                  style={{
                    display: "flex",
                    alignItems: "right",
                    gap: "8px",
                  }}
                ></div>
                <Label className="col-form-label mr-3">Is Ok To Contact?</Label>
                <Switch
                  checked={isOkToContact}
                  onChange={checked => {
                    setIsOkToContact(checked)
                    setFieldValue("isOkToContact", checked)
                  }}
                  disabled={isViewMode}
                  onColor="#4CAF50"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <FormGroup>
                <Label htmlFor="Category" className="col-form-label pt-0">
                  Category<span className="text-danger">*</span>
                </Label>
                <Select
                  id="Category"
                  name="Category"
                  isDisabled={isViewMode}
                  options={companyCategory}
                  value={companyCategory.find(
                    option => option.value === selectCategoryType,
                  )}
                  onChange={option =>
                    handleSelectChange(option, setFieldValue, "Category")
                  }
                  placeholder="Select Category"
                  classNamePrefix="react-select"
                  styles={selectStyles(touched.Category && errors.Category)}
                  className={
                    touched.Category && errors.Category ? "is-invalid" : ""
                  }
                />
                {touched.Category && errors.Category && (
                  <FormFeedback className="d-block">
                    {errors.Category}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="PaymentTerms">Payment Terms</Label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Field
                    type="number"
                    name="PaymentTerms"
                    as={Input}
                    disabled={isViewMode}
                    invalid={touched.PaymentTerms && !!errors.PaymentTerms}
                  />
                  <Label for="MaxLimit">Days</Label>
                </div>
                <ErrorMessage name="PaymentTerms" component={FormFeedback} />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="MaxLimit">Max Limit</Label>

                <Field
                  name="MaxLimit"
                  type="number"
                  step="0.01"
                  as={Input}
                  onBlur={e => {
                    const value = parseFloat(e.target.value)
                    setFieldValue(
                      "MaxLimit",
                      isNaN(value) ? "" : value.toFixed(2),
                    ) // Format to 2 decimal places
                  }}
                  disabled={isViewMode}
                  invalid={touched.MaxLimit && !!errors.MaxLimit}
                />

                <ErrorMessage name="MaxLimit" component={FormFeedback} />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="DiscountLimit">Discount Limit</Label>
                <Field
                  name="DiscountLimit"
                  as={Input}
                  type="number"
                  step="0.01"
                  onBlur={e => {
                    const value = parseFloat(e.target.value)
                    setFieldValue(
                      "DiscountLimit",
                      isNaN(value) ? "" : value.toFixed(1),
                    ) // Ensure 1 decimal place
                  }}
                  disabled={isViewMode}
                  invalid={touched.DiscountLimit && !!errors.DiscountLimit}
                />
                <ErrorMessage name="DiscountLimit" component={FormFeedback} />
              </FormGroup>
            </Col>
          </Row>

          <Card>
            {/* <CardHeader>SocialMedia</CardHeader> */}
            <CardBody>
              {!isViewMode && (
                <div>
                  <Row>
                    <Col lg={4}>
                      <FormGroup>
                        <Label htmlFor="SocialMediaType">
                          Social Media Type
                        </Label>
                        <Select
                          id="SocialMediaType"
                          options={socialMediaList.map(cat => ({
                            label: cat.StringMapName,
                            value: cat.StringMapId,
                          }))}
                          onChange={handleSMTypeChange}
                          onBlur={socialMediaValidation.handleBlur}
                          value={selectedSocialMediaType}
                          placeholder="Select SocialMedia Type"
                        />
                        {socialMediaValidation.touched.SocialMediaType &&
                          socialMediaValidation.errors.SocialMediaType && (
                            <FormFeedback type="invalid" className="d-block">
                              {socialMediaValidation.errors.SocialMediaType}
                            </FormFeedback>
                          )}
                      </FormGroup>
                    </Col>
                    <Col lg={4}>
                      <FormGroup>
                        <Label htmlFor="SocialMediaValue">
                          Social Media Name
                        </Label>
                        <Input
                          id="SocialMediaValue"
                          name="SocialMediaValue"
                          type="text"
                          onChange={socialMediaValidation.handleChange}
                          onBlur={socialMediaValidation.handleBlur}
                          value={socialMediaValidation.values.SocialMediaValue}
                        />
                        {socialMediaValidation.touched.SocialMediaValue &&
                          socialMediaValidation.errors.SocialMediaValue && (
                            <FormFeedback type="invalid" className="d-block">
                              {socialMediaValidation.errors.SocialMediaValue}
                            </FormFeedback>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className="d-flex justify-content-end align-items-center"
                      lg={12}
                    >
                      <button
                        type="button"
                        onClick={socialMediaValidation.handleSubmit}
                        className="btn btn-primary"
                      >
                        {editIndex.SocialMedia !== null ? "Update" : "Add"}
                      </button>
                    </Col>
                    <span id="SocialMediaError" class="error"></span>
                  </Row>
                </div>
              )}
              {/* socialMedia Table */}
              {socialMediaEntries.length > 0 && (
                <Table bordered className="mt-3">
                  <thead>
                    <tr>
                      <th>socialMedia Type</th>
                      <th>socialMedia</th>

                      {!isViewMode && <th width="180px">Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {socialMediaEntries
                      .filter(entry => entry.Status !== "Inactive")
                      .map((entry, index) => (
                        <tr key={index}>
                          <td>
                            {
                              socialMediaList.find(
                                cat =>
                                  cat.StringMapId === entry.SocialMediaType,
                              )?.StringMapName
                            }
                          </td>
                          <td>{entry.SocialMediaValue}</td>

                          {!isViewMode && (
                            <td>
                              <Button
                                className="me-2"
                                size="sm"
                                onClick={() => {
                                  setEditIndex(prev => ({
                                    ...prev,
                                    SocialMedia: index,
                                  }))
                                  socialMediaValidation.setValues(entry)
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                size="sm"
                                color="danger"
                                onClick={() => {
                                  setsocialMediaEntries(entries =>
                                    entries.some(
                                      (entry, i) => i === index && entry.Status,
                                    )
                                      ? entries.map((entry, i) =>
                                          i === index
                                            ? { ...entry, Status: "Inactive" }
                                            : entry,
                                        )
                                      : entries.filter((_, i) => i !== index),
                                  )
                                }}
                              >
                                Delete
                              </Button>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>
          {!isViewMode && (
            <Row className="justify-content-end">
              <Col lg={3} className="text-end">
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  className="large-button"
                >
                  Save & Next
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default connect(null, { setBreadcrumbItems })(BasicInfo)
