const initialState = {
    error: "",
    loading: false,
    showTaskGetList: true,
    timesheetData: [],
};

const Reports = (state = initialState, action) => {
    switch (action.type) {
        case "GET_TIMESHEET_REPORT":
            return {
                ...state,
                loading: true,
            };

        case "GET_TIMESHEET_REPORT_SUCCESS":
            return {
                ...state,
                loading: false,
                timesheetData: action.payload,
                error: "",
            };

        case "REPORT_API_ERROR":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default Reports;
