const initialState = {
    error: "",
    loading: false,
    tasks: [],
    data: null,
    taskData: null,
    projectInfoById: null,
    showTaskGetList: true,
    taskDefaults: {
        projectId: null,
        parentTaskId: null,
    },
    defaultProject: null,
    timelineData: null,
};

const AddUpdateTask = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_UPDATE_TASK":
        case "GET_ALL_TASKS":
            return {
                ...state,
                loading: true,
            };

        case "ADD_UPDATE_TASK_SUCCESS":
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
            };

        case "GET_ALL_TASKS_SUCCESS":
            return {
                ...state,
                loading: false,
                tasks: action.payload,
                error: "",
            };

        case "GET_TASK_SUCCESS":
            return {
                ...state,
                loading: false,
                taskData: action.payload,
                error: "",
            };

        case "GET_PROJECTINFO_SUCCESS":
            return {
                ...state,
                loading: false,
                projectInfoById: action.payload,
                error: "",
            };

        case "TASK_API_ERROR":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "SET_SHOW_TASK_LIST":
            console.log("Setting showTaskGetList to:", action.payload);
            return {
                ...state,
                showTaskGetList: action.payload,
            };

        case "SET_TASK_DEFAULTS":
            return {
                ...state,
                taskDefaults: {
                    projectId: action.payload.projectId,
                    parentTaskId: action.payload.parentTaskId,
                },
            };

        case "CLEAR_TASK_DEFAULTS":
            return {
                ...state,
                taskDefaults: {
                    projectId: null,
                    parentTaskId: null,
                },
            }; 

        case "ADD_UPDATE_TASK_TIMELINE":
            return {
                ...state,
                loading: true,
            };

        case "ADD_UPDATE_TASK_TIMELINE_SUCCESS":
            return {
                ...state,
                loading: false,
                timelineData: action.payload,
                error: "",
            };

        case "SET_DEFAULT_PROJECT":
            return {
                ...state,
                defaultProject: action.payload,
            };

        case "CLEAR_TASK_DEFAULTS":
            return {
                ...state,
                defaultProject: null,
            }; 

        default:
            return state;
    }
};

export default AddUpdateTask;
