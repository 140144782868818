import React, { forwardRef, useRef } from "react"
import logoSvg from "../../assets/images/logo.svg";
import Stamp from "../../assets/images/stamp.svg";

const QuotationPrint = forwardRef((props, ref) => {

  const { phoneEntries, selectedOptions, FinalCalculate, downloadNm } = props.QuotationData

  const tableHeaderStyle = {
    border: "1px solid #ddd",
    textAlign: "left",
    padding: "8px",
    backgroundColor: "#f4f4f4",
    fontWeight: "bold",
  }

  const tableCellStyle = {
    border: "1px solid #ddd",
    textAlign: "left",
    padding: "8px",
  }

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        margin: "0",
        padding: "0",
        backgroundColor: "#f8f8f8",
      }}
      ref={ref}
    >
      {/* <p>{props.QuotationData.FinalCalculate.Discount}</p> */}
      <div
        style={{
          width: "210mm",
          minHeight: "297mm",
          margin: "auto",
          padding: "10mm",
          backgroundColor: "#fff",
          border: "1px solid #ddd",
        }}
      >
        <div style={{display: "flex", width: "100%"}}>
          <div style={{marginLeft: "auto"}}>({downloadNm})</div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <img
            // src="https://comfort.cognisun.net/static/media/logo.dca9d5269df1334f6e53517bbf6506a8.svg"
            src={logoSvg}
            alt="Logo"
            style={{ height: "80px" }}
          />
          <div
            style={{ textAlign: "right", fontSize: "22px", fontWeight: "bold" }}
          >
            Quotation
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "start",
            justifyContent: "space-between",
          }}
        >

          <div
            style={{
              marginBottom: "15px",
              fontSize: "14px",
              lineHeight: "1.6",
            }}
          >
            <strong>Quotation from company:</strong>
            <br />
            {selectedOptions?.QuotationFromCompany?.label}
          </div>

          <div
            style={{
              marginBottom: "15px",
              fontSize: "14px",
              lineHeight: "1.6",
            }}
          >
            <strong>Party To:</strong>
            <br />
            {selectedOptions?.Party?.label}
            <br />
            {selectedOptions?.addressLine1}
            <br />
            {selectedOptions?.addressLine2}
            <br />
            {selectedOptions?.city},{selectedOptions?.state},
            {selectedOptions?.PostalCode}
            <br />
            Contact
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "14px",
            marginBottom: "15px",
          }}
        >
          <div>
            <strong>Quotation Number:</strong>{" "}
            {selectedOptions?.QuotationNumber}
            <br />
            <strong>Invoice Date:</strong> 20/01/2025
          </div>
          <div>
            <strong>Due Date:</strong> 01/02/2025
            <br />
            <strong>Customer GSTN:</strong> GSTNO
          </div>
        </div>

        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "15px",
            fontSize: "14px",
            minHeight: "420px"
          }}
        >
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Sr No</th>
              <th style={tableHeaderStyle}>Product</th>
              <th style={tableHeaderStyle}>HSN Code</th>
              <th style={tableHeaderStyle}>Qty</th>
              <th style={tableHeaderStyle}>Rate</th>
              <th style={tableHeaderStyle}>Disc(%)</th>
              <th style={tableHeaderStyle}>Amount</th>
            </tr>
          </thead>
          <tbody>

            {phoneEntries?.map((entry, index) => (
              <tr key={index}>
                <td style={{ ...tableCellStyle, verticalAlign: "Top" }}>{index + 1}</td>
                <td style={{ ...tableCellStyle, verticalAlign: "Top" }}>{entry?.itemlabel}</td>
                <td style={{ ...tableCellStyle, verticalAlign: "Top" }}>{entry?.hsn}</td>
                <td style={{ ...tableCellStyle, verticalAlign: "Top" }}>{entry?.qty}</td>
                <td style={{ ...tableCellStyle, verticalAlign: "Top" }}>{entry?.rate}</td>
                <td style={{ ...tableCellStyle, verticalAlign: "Top" }}>{entry?.disc}</td>
                <td style={{ ...tableCellStyle, verticalAlign: "Top" }}>{entry?.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div style={{display: "flex", alignItems: "start"}}>

          <div className="mt-3" style={{width: "60%", fontSize: "11px"}}>
            
            <div className="mb-2"><strong>Terms & Conditions:</strong></div>
            <div>We do not hold responsible for any breakage/damage/shortage/leakage in transit.</div>
            <div>Our responsibility ceases when the goods are delivered to the carrier.</div>
            <div>Goods once sold will not be accepted back.</div>

          </div>

          <div style={{width: "40%"}}>

            <table
              style={{
                marginLeft: "auto",
                marginTop: "15px",
                fontSize: "14px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ border: "1px solid #ddd", padding: "8px", fontWeight: "bold" }}>Sub Total:</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "right" }}>{FinalCalculate?.SubTotal}</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #ddd", padding: "8px", fontWeight: "bold" }}>RoundOff:</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "right" }}>{FinalCalculate?.RoundOff}</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #ddd", padding: "8px", fontWeight: "bold" }}>Discount:</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "right" }}>{FinalCalculate?.Discount}</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #ddd", padding: "8px", fontWeight: "bold" }}>Transportation Charges:</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "right" }}>{FinalCalculate?.TransportationCharges}</td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                      fontWeight: "bold",
                      backgroundColor: "#f4f4f4",
                    }}
                  >
                    Total:
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                      fontWeight: "bold",
                      textAlign: "right",
                      backgroundColor: "#f4f4f4",
                    }}
                  >
                    {FinalCalculate?.FinalAmount}
                  </td>
                </tr>
              </tbody>
            </table>

          </div>

        </div>

        {/* <div style={{ display: "flex" }}>
          <div style={{ width: "60%" }}></div>
          <div
            style={{
              width: "40%",
              display: "flex",
              flexDirection: "column",
              marginTop: "15px",
              fontSize: "14px",
            }}
          >
            <p style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                <strong>Sub Total:</strong>
              </span>{" "}
              <span>{FinalCalculate?.SubTotal}</span>
            </p>
            <p style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                <strong>RoundOff:</strong>
              </span>{" "}
              <span>{FinalCalculate?.RoundOff}</span>
            </p>
            <p style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                <strong>Discount:</strong>
              </span>{" "}
              <span>{FinalCalculate?.Discount}</span>
            </p>
            <p style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                <strong>Transportation Charges:</strong>
              </span>{" "}
              <span>{FinalCalculate?.TransportationCharges}</span>
            </p>
            <p
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "bold",
              }}
            >
              <span>Total:</span> <span>{FinalCalculate?.FinalAmount}</span>
            </p>
          </div>
        </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
            alignItems: "center",
          }}
        >
          <div style={{ height: "150px", width: "150px" }}>
            <img
              src={Stamp}
              alt="Stamp"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>

        <div
          style={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          THANK YOU FOR YOUR BUSINESS!
        </div>
      </div>
    </div>
  )
})

export default QuotationPrint
