import React, { useEffect, useMemo } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems, setShowProjectGetList } from "../../store/actions";
import AddProject from "./AddProject";
import AllProject from "./AllProject"


const ProjectMaster = (props) => {
    document.title = "Project";

    const breadcrumbItems = useMemo(() => [
        { title: "Comfort Zone" },
        { title: "Project" },
        { title: props.showTaskGetList ? "List" : "Add" },
    ], [props.showTaskGetList]);

    useEffect(() => {
        props.setBreadcrumbItems("Projects", breadcrumbItems);
    }, [props, breadcrumbItems]);

    return (
        <React.Fragment>
            <Row>
                <Col xl={12}>
                    {props.showProjectGetList ? <AllProject /> : <AddProject />}
                </Col>
            </Row>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    console.log('Redux State:', state);
    return {
        showProjectGetList: state.Project?.showProjectGetList,
    };
};

export default connect(mapStateToProps, { setBreadcrumbItems, setShowProjectGetList })(ProjectMaster);