import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Input, Collapse, UncontrolledTooltip } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { GetItems, GetProjects, setShowProjectGetList } from "../../store/actions";
import DataTable from "../../components/Common/CustomDataTable";
import FilterSection from "../../components/Common/FilterSection";
const apiUrl = process.env.REACT_APP_API_URL;

const AllProject = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const projectsDataFromStore = useSelector((state) => state.Project?.projects?.data) || [];

  const [projectMainData, setProjectMainData] = useState([]);
  const [projectAccessData, setProjectAccessData] = useState([]);
  const [globalSearch, setGlobalSearch] = useState("");
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [filters, setFilters] = useState({
    Category: "",
    SubCategory: "",
    SystemItemName: "",
    ItemName: "",
    ItemCode: "",
    HSNCode: "",
    Rate:"",
    Status: "",
  });

  useEffect(() => {
    dispatch(GetProjects());
  }, [dispatch]);

  useEffect(() => {
    const filteredProjectMainData = projectsDataFromStore.filter((project) => project.DataType === "AllMainProjectList");
    const filteredProjectAccessData = projectsDataFromStore.filter((project) => project.DataType === "AllAccessList");

    const updatedProjectMainData = filteredProjectMainData.map((mainProject) => ({
      ...mainProject,
      team: filteredProjectAccessData.filter(
        (accessProject) => accessProject.ProjectID === mainProject.ProjectID
      ),
    }));

    setProjectMainData(updatedProjectMainData);
    setProjectAccessData(filteredProjectAccessData);
}, [projectsDataFromStore]);

  const actionBodyTemplate = (rowData) => (
    <div className="actions-column">
      <Button color="info" id={`view-${rowData.ItemId}`} className="me-2" onClick={() => navigate(`View/${rowData.ItemId}`)}>
        <i className="mdi mdi-eye"></i>
      </Button>
      <UncontrolledTooltip target={`view-${rowData.ItemId}`} placement="bottom">
        View Item
      </UncontrolledTooltip>

      <Button color="primary" id={`edit-${rowData.ItemId}`} onClick={() => navigate(`Edit/${rowData.ItemId}`)}>
        <i className="mdi mdi-pencil"></i>
      </Button>
      <UncontrolledTooltip target={`edit-${rowData.ItemId}`} placement="bottom">
        Edit Item
      </UncontrolledTooltip>
    </div>
  );

  const taskHealthIcons = {
    "Not Started": `${apiUrl}/images/Icons/notstarted.svg`,
    "In Progress": `${apiUrl}/images/Icons/inprogress.svg`,
    "Overdue In Progress": `${apiUrl}/images/Icons/o-inprogress.svg`,
    "Overdue Not Started": `${apiUrl}/images/Icons/o-notstarted.svg`,
    "Delayed Completion": `${apiUrl}/images/Icons/d-completed.svg`,
    "Completed Within Time": `${apiUrl}/images/Icons/completed.svg`,
    "Unknown Status": `${apiUrl}/images/Icons/unknown.svg`,
  };

  const projectColumns = [
    { name: "Project", field: "ProjectName", sortable: true, width: "240px" },
    { name: "Category", field: "ProjectCategory", sortable: true, width: "150px" },
    { name: "Project Manager", field: "ManagerFullName", sortable: true, width: "240px",
      body: (rowData) => (
        <div style={{ display: "flex", alignItems: "center" }}>
                {rowData.ManagerPicturePath ? (
                    <img
                        src={`${apiUrl}${rowData.ManagerPicturePath}`}
                        alt="Assignee Image"
                        style={{
                            width: "35px",
                            height: "35px",
                            marginRight: "10px",
                            borderRadius: "50%",
                        }}
                    />
                ) : (
                    <div
                        style={{
                            width: "35px",
                            height: "35px",
                            marginRight: "10px",
                            borderRadius: "50%",
                            backgroundColor: "#7A6FBE",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#fff",
                            fontWeight: "bold",
                            fontSize: "14px",
                        }}
                    >
                        {`${rowData.ManagerFirstName?.[0] || ""}${rowData.ManagerLastName?.[0] || ""}`.toUpperCase()}
                    </div>
                )}
                <span>{rowData.ManagerFirstName} {rowData.ManagerLastName}</span>
            </div>
      ),
    },
    {
      name: "Team",
      field: "Team",
      sortable: true,
      width: "300px",
      body: (rowData) => (
        <div style={{ display: "flex", gap: "7px" }}>
          {rowData.team?.map((member, index) => (
            <div key={index} title={member.FullName}>
              {member.PicturePath ? (
              <img
                src={`${apiUrl}${member.PicturePath}`}
                alt={member.FullName}
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
              />
            ) : (
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    backgroundColor: "#7A6FBE",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "10px",
                    letterSpacing: "1px",
                    cursor: "pointer",
                  }}
                >
                  {`${member.FirstName?.[0] || ""}${member.LastName?.[0] || ""}`.toUpperCase()}
                </div>
              )}
            </div>
          ))}
        </div>
      ),
    },
    {
      name: "Task Summary", field: "TaskSummary", sortable: true, width: "300px",
      body: (rowData) => (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          {Object.entries(taskHealthIcons).map(([key, icon]) => {
            const taskCount = rowData[`${key.replace(/\s+/g, "")}Count`] || 0;
            return (
              <div
                key={key}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "relative",
                  width: "40px",
                }}
                title={key}
              >
                <img
                  src={icon}
                  alt={key}
                  style={{
                    width: "30px",
                    height: "30px",
                    objectFit: "contain",
                    cursor: "pointer",
                  }}
                />
                <span
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "-5px",
                    backgroundColor: "#7A6FBE",
                    color: "#FFF",
                    borderRadius: "50%",
                    width: "18px",
                    height: "18px",
                    fontSize: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  {taskCount}
                </span>
              </div>
            );
          })}
        </div>
      ),
    },
    { name: "Status", field: "Status", sortable: true, width: "100px" },
    // { name: "Action", field: "", body: (rowData) => actionBodyTemplate(rowData), ignoreRowClick: true, allowOverflow: true, button: true, width: "150px" },
  ];

  const filteredData = projectMainData.filter((data) =>
    Object.keys(filters).every(
      (key) => !filters[key] || (data[key] && data[key].toString().toLowerCase().includes(filters[key].toLowerCase()))
    )
  );

  const csvReport = {
    filename: "Project_List.csv",
    headers: projectColumns.map((col) => ({ label: col.name, key: col.field })),
    data: filteredData,
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleGlobalSearchChange = (e) => {
    setGlobalSearch(e.target.value);
  };

  return (
    <>
      <Row className="mb-3">
        <Col md={12} className="d-flex justify-content-end align-items-center">
          <Button color="primary" id="filter" onClick={() => setIsOpenFilter(!isOpenFilter)} className="me-2">
            <i className="mdi mdi-filter"></i>
          </Button>
          <UncontrolledTooltip target="filter" placement="bottom">
            Filter
          </UncontrolledTooltip>
          <Button color="success" id="add-new" onClick={() => props.setShowProjectGetList(false)} className="me-2">
            <i className="mdi mdi-plus-thick"></i> ADD
          </Button>
          <UncontrolledTooltip target="add-new" placement="bottom">
            Add New Item
          </UncontrolledTooltip>
        </Col>
      </Row>
      <Collapse isOpen={isOpenFilter}>
    <FilterSection
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          filters={filters}
          Columns={projectColumns}
          handleFilterChange={handleFilterChange}
        />
      </Collapse>

      <DataTable
        filteredData={filteredData}
        Columns={projectColumns}
        csvReport={csvReport}
        List={GetItems}
        globalSearch={globalSearch}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  showProjectGetList: state.Project?.showProjectGetList,
});

export default connect(mapStateToProps, { setShowProjectGetList })(AllProject);
