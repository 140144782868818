import { call, put, takeEvery, select } from "redux-saga/effects"
import {
  AddUpdateCompanySuccess,
  GetCompaniesSuccess,
  CompanyAPIError,
  GetCompanySuccess,
} from "./actions"
import axios from "axios"
import * as url from "../../helpers/url_helper"
import { toast } from "react-toastify"

function* AddUpdateOrganization({ payload: { companyPayload } }) {
  debugger
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select(state => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(
      axios.post,
      url.POST_ADD_UPDATE_COMPANY,
      companyPayload,
      config,
    )
    if (response.status >= 200 && response.status < 300) {
      debugger
      yield put(AddUpdateCompanySuccess(response.data))
      // yield put({ type: "SET_SHOW_COMPANY_LIST", payload: true });
      // toast.success("Company " + (companyPayload.CompanyId ? "Updated" : "Added") + " Successfully");
    } else {
      throw new Error(
        response.data.errorMessage || "Failed to add/update Company",
      )
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.message ||
      err.response?.statusText ||
      "An error occurred"
    yield put(CompanyAPIError(errorMessage))
    toast.error(errorMessage)
  }
}

// Saga for fetching item categories
function* GetCompanies() {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select(state => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(axios.get, url.GET_COMPANIES, config)
    if (response.status >= 200 && response.status < 300) {
      yield put(GetCompaniesSuccess(response.data))
    } else {
      throw new Error("Failed to fetch item categories")
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.error ||
      err.response?.statusText ||
      "An error occurred"
    yield put(CompanyAPIError(errorMessage))
    toast.error(errorMessage)
  }
}

function* GetCompanyById({ payload }) {
  // Adjust destructuring if necessary
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select(state => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    }
    // Check if payload is in JSON format
    console.log("Payload:", payload)

    const response = yield call(axios.post, url.GET_COMPANY, payload, config)

    if (response.status >= 200 && response.status < 300) {
      yield put(
        GetCompanySuccess({
          type: payload.TabName,
          data: response.data.data,
        }),
      )
    } else {
      throw new Error("Failed to fetch item category")
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.error ||
      err.response?.statusText ||
      "An error occurred"
    yield put(CompanyAPIError(errorMessage))
    toast.error(errorMessage)
  }
}

function* DeleteCompanyById({ payload }) {
  // Adjust destructuring if necessary
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select(state => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    }
    console.log("Payload:", payload)
    const response = yield call(axios.post, url.DELETE_COMPANY, payload, config)
    if (response.status >= 200 && response.status < 300) {
      yield put(GetCompaniesSuccess(response.data))
    } else {
      throw new Error("Failed to Delete Company")
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.error ||
      err.response?.statusText ||
      "An error occurred"
    yield put(CompanyAPIError(errorMessage))
    toast.error(errorMessage)
  }
}

// function* GetCompanyById({ payload: { payload } }) {
//   debugger;
//   try {
//     const config = {
//       headers: {
//         Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
//         "Content-Type": "application/json",
//       },
//     };
//     // Use the GET_ITEM_CATEGORY function to get the URL with the actual ID
//     const response = yield call(axios.post, url.GET_COMPANY,payload, config);

//     if (response.status >= 200 && response.status < 300) {
//       console.log("response.data", response.data);
//       yield put(GetCompanySuccess(response.data.data)); // Dispatch success action
//     } else {
//       throw new Error("Failed to fetch item category");
//     }
//   } catch (err) {
//     const errorMessage = err.response?.data?.error || err.response?.statusText || "An error occurred";
//     yield put(CompanyAPIError(errorMessage));
//     toast.error(errorMessage);
//   }
// }

// Watcher saga
function* CompanySaga() {
  yield takeEvery("ADD_UPDATE_COMPANY", AddUpdateOrganization)
  yield takeEvery("GET_ALL_COMPANY", GetCompanies)
  yield takeEvery("GET_COMPANY", GetCompanyById)
}

export default CompanySaga
