import { call, put, takeEvery, select, delay } from "redux-saga/effects"
import {
  AddUpdateQuotationSuccess,
  setShowQuotationGetList,
  GetSelectedItemDetailsSuccess,
  GetQuotationNumberSuccess,
  GetQuotationListSuccess,
  GetQuotationItemListSuccess,
  GetQuotationByIdSuccess,
  QuotationAPIError,
  GetItemdropdownQuotationSuccess,
} from "./actions"
import axios from "axios"
import * as url from "../../../src/helpers/url_helper"
import { toast } from "react-toastify"

function* AddUpdateQuotation(input) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select(state => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(
      axios.post,
      url.POST_ADD_UPDATE_QUOTATION,
      input.payload,
      config,
    )
    if (response.status >= 200 && response.status < 300) {
      yield put(AddUpdateQuotationSuccess(response.data))
      yield put({ type: "SET_SHOW_QUOTATION_LIST", payload: true })
      //  yield put (setShowQuotationGetList({type: "SET_SHOW_QUOTATION_LIST",payload: true }))
      toast.success(
        "Quotation " +
          (response.data.QuotationId ? "Updated" : "Added") +
          " Successfully",
      )
      // yield delay(2000);
      //   yield put({ type: "SET_SHOW_ITEM_CATERGORY_LIST", payload: true });
    } else {
      throw new Error(
        response.data.errorMessage || "Failed to add/update quotation",
      )
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.error ||
      err.response?.statusText ||
      "An error occurred"
    yield put(QuotationAPIError(errorMessage))
    toast.error(errorMessage)
  }
}

function* GetSelectedItemDetails(inputpayload) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select(state => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    }

    const response = yield call(
      axios.post,
      url.POST_SELECTED_ITEM_DETAILS,
      inputpayload.payload,
      config,
    )
    if (response.status >= 200 && response.status < 300) {
      yield put(GetSelectedItemDetailsSuccess(response.data)) // Dispatch success action
    } else {
      throw new Error("Failed to fetch item details")
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.error ||
      err.response?.statusText ||
      "An error occurred"
    yield put(QuotationAPIError(errorMessage))
    toast.error(errorMessage)
  }
}

function* GetQuotationNumber() {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select(state => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(
      axios.post,
      url.GET_QUOTATION_NUMBER,
      {},
      config,
    )
    if (response.status >= 200 && response.status < 300) {
      yield put(GetQuotationNumberSuccess(response.data)) // Dispatch success action
    } else {
      throw new Error("Failed to fetch item details")
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.error ||
      err.response?.statusText ||
      "An error occurred"
    yield put(QuotationAPIError(errorMessage))
    toast.error(errorMessage)
  }
}

function* GetQuotationList() {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select(state => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    }

    const response = yield call(axios.post, url.GET_QUOTATION_LIST, {}, config)
    if (response.status >= 200 && response.status < 300) {
      yield put(GetQuotationListSuccess(response.data)) // Dispatch success action
    } else {
      throw new Error("Failed to fetch item details")
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.error ||
      err.response?.statusText ||
      "An error occurred"
    yield put(QuotationAPIError(errorMessage))
    toast.error(errorMessage)
  }
}

function* GetQuotationItemList() {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select(state => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(
      axios.post,
      url.GET_QUOTATION_ITEM_LIST,
      {},
      config,
    )
    if (response.status >= 200 && response.status < 300) {
      yield put(GetQuotationItemListSuccess(response.data)) // Dispatch success action
    } else {
      throw new Error("Failed to fetch item details")
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.error ||
      err.response?.statusText ||
      "An error occurred"
    yield put(QuotationAPIError(errorMessage))
    toast.error(errorMessage)
  }
}

function* GetQuotationById(inputpayload) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select(state => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    }
    debugger
    const response = yield call(
      axios.post,
      url.GET_QUOTATION_BY_ID,
      inputpayload.payload,
      config,
    )
    if (response.status >= 200 && response.status < 300) {
      yield put(GetQuotationByIdSuccess(response.data)) // Dispatch success action
    } else {
      throw new Error("Failed to fetch Quotation details")
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.error ||
      err.response?.statusText ||
      "An error occurred"
    yield put(QuotationAPIError(errorMessage))
    toast.error(errorMessage)
  }
}

function* GetItemdropdownQuotation(inputpayload) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select(state => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    }
    debugger
    const response = yield call(
      axios.post,
      url.GET_ITEM_DROPDOWN_QUOTATION,
      inputpayload.payload,
      config,
    )
    console.log("chehchch,..", response)
    if (response.status >= 200 && response.status < 300) {
      yield put(GetItemdropdownQuotationSuccess(response.data)) // Dispatch success action
    } else {
      throw new Error("Failed to fetch Quotation details")
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.error ||
      err.response?.statusText ||
      "An error occurred"
    yield put(QuotationAPIError(errorMessage))
    toast.error(errorMessage)
  }
}
// Watcher saga
function* QuotationSaga() {
  yield takeEvery("ADD_UPDATE_QUOTATION", AddUpdateQuotation)
  yield takeEvery("GET_SELECTED_ITEM_DETAILS", GetSelectedItemDetails)
  yield takeEvery("GET_QUOTATION_NUMBER", GetQuotationNumber)
  yield takeEvery("GET_QUOTATION_LIST", GetQuotationList)
  yield takeEvery("GET_QUOTATION_ITEM_LIST", GetQuotationItemList)
  yield takeEvery("GET_QUOTATION_BY_ID", GetQuotationById)
  yield takeEvery("GET_ITEM_DROPDOWN_QUOTATION", GetItemdropdownQuotation)
}

export default QuotationSaga
