import { call, put, takeEvery, select } from "redux-saga/effects";
import { AddUpdateProjectSuccess, ProjectAPIError, GetProjectsSuccess, GetProjectSuccess } from "./actions";
import axios from "axios";
import * as url from "../../helpers/url_helper";
import { toast } from "react-toastify";

function* AddUpdateProject({ payload: { projectPayload } }) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(axios.post, url.POST_ADD_UPDATE_PROJECT, projectPayload, config);
    if (response.status >= 200 && response.status < 300) {
      yield put(AddUpdateProjectSuccess(response.data));
      yield put({ type: "SET_SHOW_PROJECT_LIST", payload: true }); 
      toast.success("Project " + (projectPayload.ProjectID ? "Updated" : "Added") + " Successfully");
    } else {
      throw new Error(response.data.errorMessage || "Failed to add/update project");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.error || err.response?.statusText || "An error occurred";
    yield put(ProjectAPIError(errorMessage));
    toast.error(errorMessage);
  }
}

function* GetProjects() {
    try {
        const token = yield select((state) => state.Login.token);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };

        const response = yield call(axios.post, url.GET_PROJECTS, {}, config);
        if (response.status >= 200 && response.status < 300) {
            yield put(GetProjectsSuccess(response.data.tasks || response.data));
        } else {
            throw new Error("Failed to fetch items");
        }
    } catch (err) {
        console.error("Error Response:", err);

        const errorMessage = err.response?.data?.message || err.response?.statusText || err.message || "An error occurred";
        yield put(ProjectAPIError(errorMessage));
        toast.error(errorMessage);
    }
}

function* GetProjectById({ payload: { id } }) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    };
    const requestBody = {
      ProjectID: id,
    };
    
    const response = yield call(axios.post, `${url.GET_PROJECT}`, requestBody, config);
    if (response.status >= 200 && response.status < 300) {
      yield put(GetProjectSuccess(response.data));
    } else {
      throw new Error("Failed to fetch item");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.error || err.response?.statusText || "An error occurred";
    yield put(ProjectAPIError(errorMessage));
    toast.error(errorMessage);
  }
}

function* ProjectSaga() {
  yield takeEvery("ADD_UPDATE_PROJECT", AddUpdateProject);
  yield takeEvery("GET_ALL_PROJECTS", GetProjects);
  yield takeEvery("GET_PROJECT", GetProjectById);
}

export default ProjectSaga;
