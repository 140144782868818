import { call, put, takeEvery, select } from "redux-saga/effects";
import { AddUpdateTaskSuccess, GetProjectInfoByIdSuccess, GetTasksSuccess, GetTaskSuccess, TaskAPIError, AddUpdateTaskTimelineSuccess } from "./actions";
import axios from "axios";
import * as url from "../../helpers/url_helper";
import { toast } from "react-toastify";

function* AddUpdateTask({ payload: { taskPayload } }) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(axios.post, url.POST_ADD_UPDATE_TASK, taskPayload, config);
    if (response.status >= 200 && response.status < 300) {
      yield put(AddUpdateTaskSuccess(response.data));
      yield put({ type: "SET_SHOW_TASK_LIST", payload: true }); 
      toast.success("Task " + (taskPayload.TaskID ? "Updated" : "Added") + " Successfully");
    } else {
      throw new Error(response.data.errorMessage || "Failed to add/update task");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.error || err.response?.statusText || "An error occurred";
    yield put(TaskAPIError(errorMessage));
    toast.error(errorMessage);
  }
}

function* GetTasks() {
    try {
        const token = yield select((state) => state.Login.token);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };

        const response = yield call(axios.post, url.GET_TASKS, {}, config);
        if (response.status >= 200 && response.status < 300) {
            yield put(GetTasksSuccess(response.data.tasks || response.data));
        } else {
            throw new Error("Failed to fetch items");
        }
    } catch (err) {
        console.error("Error Response:", err);

        const errorMessage = err.response?.data?.message || err.response?.statusText || err.message || "An error occurred";
        yield put(TaskAPIError(errorMessage));
        toast.error(errorMessage);
    }
}

function* GetTaskById({ payload: { id } }) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    };
    const requestBody = {
      TaskID: id,
    };
    
    const response = yield call(axios.post, `${url.GET_TASK}`, requestBody, config);
    if (response.status >= 200 && response.status < 300) {
      yield put(GetTaskSuccess(response.data));
    } else {
      throw new Error("Failed to fetch item");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.error || err.response?.statusText || "An error occurred";
    yield put(TaskAPIError(errorMessage));
    toast.error(errorMessage);
  }
}

function* GetProjectInfoById({ payload: { id } }) {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
                "Content-Type": "application/json",
            },
        };

        const requestBody = {
            ProjectID: id,
        };

        const response = yield call(axios.post, `${url.GET_PROJECTINFO}`, requestBody, config);
        if (response.status >= 200 && response.status < 300) {
            yield put(GetProjectInfoByIdSuccess(response.data));
        } else {
            throw new Error("Failed to fetch item");
        }
    } catch (err) {
        const errorMessage = err.response?.data?.error || err.response?.statusText || "An error occurred";
        yield put(TaskAPIError(errorMessage));
        toast.error(errorMessage);
    }
}

function* AddUpdateTaskTimeline({ payload: { taskPayload } }) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(axios.post, url.POST_ADD_UPDATE_TASK_TIMELINE, taskPayload, config);
    if (response.status >= 200 && response.status < 300) {
      yield put(AddUpdateTaskTimelineSuccess(response.data));
      if (taskPayload.Status === "Inactive") {
        toast.error("Comment Deleted")
      } else {
        toast.success("Comment " + (taskPayload.TaskTimelineID ? "Updated" : "Added") + " Successfully");
      }
    } else {
      throw new Error(response.data.errorMessage || "Failed to add/update task");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.error || err.response?.statusText || "An error occurred";
    yield put(TaskAPIError(errorMessage));
    toast.error(errorMessage);
  }
}

function* TaskSaga() {
  yield takeEvery("ADD_UPDATE_TASK", AddUpdateTask);
  yield takeEvery("GET_ALL_TASKS", GetTasks);
  yield takeEvery("GET_TASK", GetTaskById);
  yield takeEvery("GET_PROJECTINFO", GetProjectInfoById);
  yield takeEvery("ADD_UPDATE_TASK_TIMELINE", AddUpdateTaskTimeline);
}

export default TaskSaga;
