import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Label, Form, Input, Button, Badge, Card, CardHeader, CardBody, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { AddUpdateItemMetadata, deleteMetadata, GetDropdown, GetMetaDataById, setBreadcrumbItems } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EditItemMetaData = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Id } = useParams();
  const itemCategories = useSelector((state) => state.CommonAPI?.itemCategories?.data) || [];
  const metaDataById = useSelector((state) => state.ItemMetaDataMaster?.metaDataById?.data);
  const [categoryName, setcategoryName] = useState()

  const [inputValues, setInputValues] = useState({
    ItemSubCategory: "",
    ItemType1: "",
    ItemType2: "",
    ItemType3: "",
    UnitofMesurement: ""
  });

  const [badges, setBadges] = useState({
    ItemSubCategory: [],
    ItemType1: [],
    ItemType2: [],
    ItemType3: [],
    UnitofMesurement: []
  });

  const labelMap = {
    ItemSubCategory: "Sub Category",
    ItemType1: "Type 1",
    ItemType2: "Type 2",
    ItemType3: "Type 3",
    UnitofMesurement: "Unit of Measurement"
  };

  const [editingIndex, setEditingIndex] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [badgeToRemove, setBadgeToRemove] = useState(null);
  const [badgeKeyToRemove, setBadgeKeyToRemove] = useState(null);
  const [badgeValueToRemove, setBadgeValueToRemove] = useState("");
  const [showItemType2, setShowItemType2] = useState(false);
  const [showItemType3, setShowItemType3] = useState(false);

  const breadcrumbItems = useMemo(() => [
    { title: "Comfort Zone" },
    { title: "Item Meta Data" },
    { title: "Add/Edit Item Meta Data" },
  ], []);

  useEffect(() => {
    if (Id) {
      dispatch(GetMetaDataById(Id));
      dispatch(GetDropdown({ "StringMapType": "ItemCategory", "OrderBy": "StringMapName" }));
      const filteredSub = itemCategories.filter(
        (sub) => sub.StringMapId === Id
      );
      if (filteredSub[0]) {
        setcategoryName(filteredSub[0].StringMapName);
      } else {
        setcategoryName("");
      }
    }
    props.setBreadcrumbItems('Add/Edit Item Meta Data', breadcrumbItems);
  }, [Id, breadcrumbItems, props]);

  useEffect(() => {
    console.log("item categories", itemCategories);
    console.log("metaDataById data", metaDataById);
  }, [itemCategories, metaDataById]);

  useEffect(() => {
    if (metaDataById && Array.isArray(metaDataById)) {
      const updatedBadges = {};
      let hasItemType2 = false;
      let hasItemType3 = false;

      ["ItemSubCategory", "ItemType1", "ItemType2", "ItemType3", "UnitofMesurement"].forEach((key) => {
        updatedBadges[key] = metaDataById
          .filter((item) => item.ItemKey === key && item.Status === "Active")
          .map((item) => ({ value: item.ItemValue, id: item.ItemMetaDataId, IsUsed: item.IsUsed }));

        if (key === "ItemType2" && updatedBadges[key].length > 0) {
          hasItemType2 = true;
        }
        if (key === "ItemType3" && updatedBadges[key].length > 0) {
          hasItemType3 = true;
        }
      });
      setBadges(updatedBadges);

      setShowItemType2(hasItemType2);
      setShowItemType3(hasItemType3);
    }
  }, [metaDataById]);


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ItemSubCategory: "",
      ItemType1: "",
      ItemType2: "",
      ItemType3: "",
      UnitofMesurement: "",
    },
    validationSchema: Yup.object().shape({
      ItemSubCategory: Yup.string().test(
        'badges-check',
        'Please Add At least one Sub Category',
        function (value) {
          const { createError } = this;
          const hasActiveItems = badges.ItemSubCategory.some(item => item.IsDeleted !== 1);
          return hasActiveItems || createError('Please Add At least one Sub Category');
        }
      ),
      ItemType1: Yup.string().test(
        'badges-check',
        'Please Add At least one Type 1',
        function (value) {
          const { createError } = this;
          const hasActiveItems = badges.ItemType1.some(item => item.IsDeleted !== 1);
          return hasActiveItems || createError('Please Add At least one Type 1');
        }
      ),
      ...(showItemType2 && {
        ItemType2: Yup.string().test(
          'badges-check',
          'Please Add At least one Type 2',
          function (value) {
            const { createError } = this;
            const hasActiveItems = badges.ItemType2.some(item => item.IsDeleted !== 1);
            return hasActiveItems || createError('Please Add At least one Type 2');
          }
        ),
      }),
      ...(showItemType3 && {
        ItemType3: Yup.string().test(
          'badges-check',
          'Please Add At least one Type 3',
          function (value) {
            const { createError } = this;
            const hasActiveItems = badges.ItemType3.some(item => item.IsDeleted !== 1);
            return hasActiveItems || createError('Please Add At least one Type 3');
          }
        ),
      }),
      UnitofMesurement: Yup.string().test(
        'badges-check',
        'Please Add At least one Unit of Measurement',
        function (value) {
          const { createError } = this;
          const hasActiveItems = badges.UnitofMesurement.some(item => item.IsDeleted !== 1);
          return hasActiveItems || createError('Please Add At least one Unit of Measurement');
        }
      ),
    }),
    onSubmit: async (values) => {
      const mergedArray = Object.keys(badges).flatMap(key => {
        return badges[key].map(badge => {
          const originalBadge = metaDataById.find(item => item.ItemMetaDataId === badge.id);
          const isEdited = originalBadge && originalBadge.ItemValue !== badge.value ? 1 : 0;
          return {
            ItemMetaDataId: badge.id || '',
            ItemCategoryId: Id,
            ItemKey: key,
            ItemValue: badge.value,
            IsDeleted: badge.IsDeleted || 0,
            IsEdited: isEdited,
          };
        });
      });

      console.log("mergedArray", mergedArray);

      dispatch(AddUpdateItemMetadata(JSON.stringify({ items: mergedArray })));

      setTimeout(() => {
        navigate('/Master/ItemCategory');
      }, 2000);
    },
  });

  const handleInputChange = (key, value) => {
    setInputValues((prev) => ({ ...prev, [key]: value }));
    validation.setFieldValue(key, value);
    if (value.trim() === "") {
      validation.setFieldTouched(key, true);
    }
  };

  const handleAddBadge = (key) => {
    const inputValue = inputValues[key].trim();
    if (inputValue) {

      const isDuplicate = badges[key].some(badge =>
        badge.value.toLowerCase() === inputValue.toLowerCase() && badge.IsDeleted !== 1
      );
      if (isDuplicate) {
        toast.error(`"${inputValue}" already exists in ${labelMap[key]}.`);
        return;
      }

      if (editingIndex[key] !== undefined) {
        const updatedBadges = [...badges[key]];
        updatedBadges[editingIndex[key]].value = inputValue;
        setBadges((prev) => ({ ...prev, [key]: updatedBadges }));
        setEditingIndex((prev) => ({ ...prev, [key]: undefined }));
      } else {
        setBadges((prev) => ({ ...prev, [key]: [...prev[key], { value: inputValue, id: null }] }));
      }
      handleInputChange(key, "");
      validation.setFieldTouched(key, false);
      validation.setFieldValue(key, "");
      validation.setFieldError(key, undefined);
      if (badges[key].length === 0) {
        validation.setFieldError(key, undefined);
      }
    }
  };

  const handleEditBadge = (key, index) => {
    setInputValues((prev) => ({ ...prev, [key]: badges[key][index].value }));
    setEditingIndex((prev) => ({ ...prev, [key]: index }));
  };

  const confirmRemoveBadge = () => {
    if (badgeToRemove !== null && badgeKeyToRemove !== null) {
      const key = badgeKeyToRemove;
      let updatedBadges = [...badges[key]];
      const badge = updatedBadges[badgeToRemove];

      if (badge.IsUsed === 1) {
        toast.error("This badge is already in use and cannot be removed.");
        setShowModal(false);
        return;
      }

      if (badge.id !== null) {
        updatedBadges[badgeToRemove].IsDeleted = 1;
      } else {
        updatedBadges = updatedBadges.filter((_, i) => i !== badgeToRemove);
      }

      setBadges((prev) => ({ ...prev, [key]: updatedBadges }));
      setEditingIndex((prev) => ({ ...prev, [key]: undefined }));

      const activeBadges = updatedBadges.filter(badge => badge.IsDeleted !== 1);

      if (activeBadges.length === 0) {
        validation.setFieldTouched(key, true);
        validation.setFieldError(key, `Please Add At least one ${labelMap[key]}`);
        validation.setFieldValue(key, '');
      } else {
        validation.setFieldError(key, undefined);
      }
      validation.validateForm();
    }
    setShowModal(false);
  };

  const handleRemoveBadge = (key, index) => {
    setBadgeKeyToRemove(key);
    setBadgeToRemove(index);
    setBadgeValueToRemove(badges[key][index].value);
    setShowModal(true);
  };

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col lg={8}>
          <Card>
            <CardHeader className="mt-3">
              <h3 className="text-center">Item Category Name: {categoryName}</h3>
            </CardHeader>
            <CardBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="mb-4" lg={12}>
                    {["ItemSubCategory"].map((key) => (
                      <Row className="mt-3" key={key}>
                        <div className="form-group">
                          <Label htmlFor={key}>{labelMap[key]}</Label>
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <Input
                                type="text"
                                id={key}
                                value={inputValues[key]}
                                onChange={(e) => handleInputChange(key, e.target.value)}
                                placeholder={`Enter ${labelMap[key]} value`}
                                invalid={!!(validation.touched[key] && validation.errors[key])}
                              />
                              <FormFeedback className="d-block">
                                {validation.touched[key] && validation.errors[key]}
                              </FormFeedback>
                            </div>
                            <Button color="primary" className="ms-2" onClick={() => handleAddBadge(key)}>
                              {editingIndex[key] !== undefined ? 'Update' : 'Add'}
                            </Button>
                          </div>
                        </div>
                        {badges[key]?.length > 0 && (
                          <Row className="mt-2">
                            <div className="form-group">
                              {badges[key].map((badge, index) => (
                                !badge.IsDeleted && (
                                  <Badge pill id="custom-badge" className="me-2" key={index} onClick={() => handleEditBadge(key, index)}>
                                    {badge.value}
                                    <span className="ms-2">
                                      <i className="fas fa-times" onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveBadge(key, index);
                                      }} />
                                    </span>
                                  </Badge>
                                )
                              ))}
                            </div>
                          </Row>
                        )}
                      </Row>
                    ))}

                    {["ItemType1"].map((key) => (
                      <Row className="mt-3" key={key}>
                        <div className="form-group">
                          <Label htmlFor={key}>{labelMap[key]}</Label>
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <Input
                                type="text"
                                id={key}
                                value={inputValues[key]}
                                onChange={(e) => handleInputChange(key, e.target.value)}
                                placeholder={`Enter ${labelMap[key]} value`}
                                invalid={!!(validation.touched[key] && validation.errors[key])}
                              />
                              <FormFeedback className="d-block">
                                {validation.touched[key] && validation.errors[key]}
                              </FormFeedback>
                            </div>
                            <Button color="primary" className="ms-2" onClick={() => handleAddBadge(key)}>
                              {editingIndex[key] !== undefined ? 'Update' : 'Add'}
                            </Button>
                          </div>
                          <div className="d-flex justify-content-end">
                            {!showItemType2 && (
                              <a
                                href="#"
                                className="underline"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowItemType2(true);
                                }}
                              >
                                Add More Type
                              </a>
                            )}
                            {!showItemType3 && showItemType2 && (
                              <a
                                href="#"
                                className="underline"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowItemType3(true);
                                }}
                              >
                                Add More Type
                              </a>
                            )}
                          </div>
                        </div>
                        {badges[key]?.length > 0 && (
                          <Row className="mt-2">
                            <div className="form-group">
                              {badges[key].map((badge, index) => (
                                !badge.IsDeleted && (
                                  <Badge pill id="custom-badge" className="me-2" key={index} onClick={() => handleEditBadge(key, index)}>
                                    {badge.value}
                                    <span className="ms-2">
                                      <i className="fas fa-times" onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveBadge(key, index);
                                      }} />
                                    </span>
                                  </Badge>
                                )
                              ))}
                            </div>
                          </Row>
                        )}
                      </Row>
                    ))}

                    {showItemType2 && (
                      ["ItemType2"].map((key) => (
                        <Row className="mt-3" key={key}>
                          <div className="form-group">
                            <Label htmlFor={key}>
                              {labelMap[key]}
                              {showItemType2 && !showItemType3 && (
                                <i className="fas fa-times ms-2" style={{ cursor: 'pointer' }} onClick={() => setShowItemType2(false)} />
                              )}
                            </Label>
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1">
                                <Input
                                  type="text"
                                  id={key}
                                  value={inputValues[key]}
                                  onChange={(e) => handleInputChange(key, e.target.value)}
                                  placeholder={`Enter ${labelMap[key]} value`}
                                  invalid={!!(validation.touched[key] && validation.errors[key])}
                                />
                                <FormFeedback className="d-block">
                                  {validation.touched[key] && validation.errors[key]}
                                </FormFeedback>
                              </div>
                              <Button color="primary" className="ms-2" onClick={() => handleAddBadge(key)}>
                                {editingIndex[key] !== undefined ? 'Update' : 'Add'}
                              </Button>


                            </div>
                          </div>
                          {badges[key]?.length > 0 && (
                            <Row className="mt-2">
                              <div className="form-group">
                                {badges[key].map((badge, index) => (
                                  !badge.IsDeleted && (
                                    <Badge pill id="custom-badge" className="me-2" key={index} onClick={() => handleEditBadge(key, index)}>
                                      {badge.value}
                                      <span className="ms-2">
                                        <i className="fas fa-times" onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveBadge(key, index);
                                        }} />
                                      </span>
                                    </Badge>
                                  )
                                ))}
                              </div>
                            </Row>
                          )}
                        </Row>
                      ))
                    )}

                    {showItemType3 && (
                      ["ItemType3"].map((key) => (
                        <Row className="mt-3" key={key}>
                          <div className="form-group">
                            <Label htmlFor={key}>
                              {labelMap[key]}
                              {showItemType3 && (
                                <i className="fas fa-times ms-2" style={{ cursor: 'pointer' }} onClick={() => setShowItemType3(false)} />
                              )}
                            </Label>
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1">
                                <Input
                                  type="text"
                                  id={key}
                                  value={inputValues[key]}
                                  onChange={(e) => handleInputChange(key, e.target.value)}
                                  placeholder={`Enter ${labelMap[key]} value`}
                                  invalid={!!(validation.touched[key] && validation.errors[key])}
                                />
                                <FormFeedback className="d-block">
                                  {validation.touched[key] && validation.errors[key]}
                                </FormFeedback>
                              </div>
                              <Button color="primary" className="ms-2" onClick={() => handleAddBadge(key)}>
                                {editingIndex[key] !== undefined ? 'Update' : 'Add'}
                              </Button>
                            </div>
                          </div>
                          {badges[key]?.length > 0 && (
                            <Row className="mt-2">
                              <div className="form-group">
                                {badges[key].map((badge, index) => (
                                  !badge.IsDeleted && (
                                    <Badge pill id="custom-badge" className="me-2" key={index} onClick={() => handleEditBadge(key, index)}>
                                      {badge.value}
                                      <span className="ms-2">
                                        <i className="fas fa-times" onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveBadge(key, index);
                                        }} />
                                      </span>
                                    </Badge>
                                  )
                                ))}
                              </div>
                            </Row>
                          )}
                        </Row>
                      ))
                    )}

                    {["UnitofMesurement"].map((key) => (
                      <Row className="mt-3" key={key}>
                        <div className="form-group">
                          <Label htmlFor={key}>{labelMap[key]}</Label>
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <Input
                                type="text"
                                id={key}
                                value={inputValues[key]}
                                onChange={(e) => handleInputChange(key, e.target.value)}
                                placeholder={`Enter ${labelMap[key]} value`}
                                invalid={!!(validation.touched[key] && validation.errors[key])}
                              />
                              <FormFeedback className="d-block">
                                {validation.touched[key] && validation.errors[key]}
                              </FormFeedback>
                            </div>
                            <Button color="primary" className="ms-2" onClick={() => handleAddBadge(key)}>
                              {editingIndex[key] !== undefined ? 'Update' : 'Add'}
                            </Button>
                          </div>
                        </div>
                        {badges[key]?.length > 0 && (
                          <Row className="mt-2">
                            <div className="form-group">
                              {badges[key].map((badge, index) => (
                                !badge.IsDeleted && (
                                  <Badge pill id="custom-badge" className="me-2" key={index} onClick={() => handleEditBadge(key, index)}>
                                    {badge.value}
                                    <span className="ms-2">
                                      <i className="fas fa-times" onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveBadge(key, index);
                                      }} />
                                    </span>
                                  </Badge>
                                )
                              ))}
                            </div>
                          </Row>
                        )}
                      </Row>
                    ))}
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col lg={12} className="d-flex justify-content-between mb-3">
                    <button
                      type="button"
                      className="btn btn-secondary large-button"
                      id="Back"
                      onClick={() => navigate("/Master/ItemCategory")}
                    >
                      Back to List
                    </button>

                    <button
                      type="submit"
                      className="btn btn-success large-button"
                      id="updatetaskdetail"
                    >
                      Submit
                    </button>

                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal style={{ maxWidth: "350px" }} isOpen={showModal} toggle={() => setShowModal(!showModal)}>
        <ModalHeader toggle={() => setShowModal(!showModal)}>Alert</ModalHeader>
        <ModalBody>
          Are you sure you want to remove {badgeValueToRemove}?
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button color="secondary" onClick={() => setShowModal(false)}>No</Button>
          <Button color="success" onClick={confirmRemoveBadge}>Yes</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default connect(null, { setBreadcrumbItems })(EditItemMetaData);