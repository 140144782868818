import React, { useEffect, useMemo } from "react"
import { Row, Col, Button, CardBody, Card } from "reactstrap"
import { connect } from "react-redux"
import {
  setBreadcrumbItems,
  setShowItemGetList,
  setShowQuotationGetList,
} from "../../store/actions"
import AddQuotation from "./AddQuotation"
import QuotationList from "./AllQuotation"

const QuotationMaster = props => {
  document.title =
    "Quotation | Cognisun - Responsive Bootstrap 5 Admin Dashboard"

  const breadcrumbItems = useMemo(
    () => [
      { title: "Comfort Zone" },
      { title: "Quotation" },
      { title: props.showQuotationGetList ? "List" : "Add" },
    ],
    [props.showQuotationGetList],
  )

  useEffect(() => {
    props.setBreadcrumbItems("Quotation", breadcrumbItems)
  }, [props, breadcrumbItems])

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          {props.showQuotationGetList ? <QuotationList /> : <AddQuotation />}
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  console.log("Redux Stateqqq:", state)
  return {
    showQuotationGetList: state.Quotation?.ShowQuotationGetList,
  }
}

export default connect(mapStateToProps, {
  setBreadcrumbItems,
  setShowQuotationGetList,
})(QuotationMaster)
