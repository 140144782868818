import { call, put, takeEvery, select, delay } from "redux-saga/effects"
import {
  AddUpdateInvoiceSuccess,
  setShowInvoiceGetList,
  // GetSelectedItemDetailsSuccess,
  GetInvoiceNumberSuccess,
  GetInvoiceListSuccess,
  // GetQuotationItemListSuccess,
  GetInvoiceByIdSuccess,
  InvoiceAPIError,
} from "./actions"
import axios from "axios"
import * as url from "../../../src/helpers/url_helper"
import { toast } from "react-toastify"

function* AddUpdateInvoice(input) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select(state => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(
      axios.post,
      url.POST_ADD_UPDATE_INVOICE,
      input.payload,
      config,
    )
    if (response.status >= 200 && response.status < 300) {
      yield put(AddUpdateInvoiceSuccess(response.data))
      yield put({ type: "SET_SHOW_INVOICE_LIST", payload: true })

      toast.success(
        "Invoice " +
          (response.data.QuotationId ? "Updated" : "Added") +
          " Successfully",
      )
      // yield delay(2000);
      //   yield put({ type: "SET_SHOW_ITEM_CATERGORY_LIST", payload: true });
    } else {
      throw new Error(
        response.data.errorMessage || "Failed to add/update quotation",
      )
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.error ||
      err.response?.statusText ||
      "An error occurred"
    yield put(InvoiceAPIError(errorMessage))
    toast.error(errorMessage)
  }
}

function* GetSelectedItemDetails(inputpayload) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select(state => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    }

    const response = yield call(
      axios.post,
      url.POST_SELECTED_ITEM_DETAILS,
      inputpayload.payload,
      config,
    )
    if (response.status >= 200 && response.status < 300) {
      // yield put(GetSelectedItemDetailsSuccess(response.data)) // Dispatch success action
    } else {
      throw new Error("Failed to fetch item details")
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.error ||
      err.response?.statusText ||
      "An error occurred"
    yield put(InvoiceAPIError(errorMessage))
    toast.error(errorMessage)
  }
}

function* GetInvoiceNumber() {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select(state => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(axios.post, url.GET_INVOICE_NUMBER, {}, config)
    if (response.status >= 200 && response.status < 300) {
      yield put(GetInvoiceNumberSuccess(response.data)) // Dispatch success action
    } else {
      throw new Error("Failed to fetch item details")
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.error ||
      err.response?.statusText ||
      "An error occurred"
    yield put(InvoiceAPIError(errorMessage))
    toast.error(errorMessage)
  }
}

function* GetInvoiceList(input) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select(state => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(
      axios.post,
      url.GET_INVOICE_LIST,
      input.payload,
      config,
    )
    if (response.status >= 200 && response.status < 300) {
      yield put(GetInvoiceListSuccess(response.data)) // Dispatch success action
    } else {
      throw new Error("Failed to fetch item details")
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.error ||
      err.response?.statusText ||
      "An error occurred"
    yield put(InvoiceAPIError(errorMessage))
    toast.error(errorMessage)
  }
}

function* GetQuotationItemList() {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select(state => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(
      axios.post,
      url.GET_QUOTATION_ITEM_LIST,
      {},
      config,
    )
    if (response.status >= 200 && response.status < 300) {
      // yield put(GetQuotationItemListSuccess(response.data)) // Dispatch success action
    } else {
      throw new Error("Failed to fetch item details")
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.error ||
      err.response?.statusText ||
      "An error occurred"
    yield put(InvoiceAPIError(errorMessage))
    toast.error(errorMessage)
  }
}

function* GetInvoiceById(inputpayload) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select(state => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    }

    const response = yield call(
      axios.post,
      url.GET_QUOTATION_BY_ID,
      inputpayload.payload,
      config,
    )
    if (response.status >= 200 && response.status < 300) {
      yield put(GetInvoiceByIdSuccess(response.data)) // Dispatch success action
    } else {
      throw new Error("Failed to fetch Invoice details")
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.error ||
      err.response?.statusText ||
      "An error occurred"
    yield put(InvoiceAPIError(errorMessage))
    toast.error(errorMessage)
  }
}

// Watcher saga
function* InvoiceSaga() {
  yield takeEvery("ADD_UPDATE_INVOICE", AddUpdateInvoice)
  yield takeEvery("GET_SELECTED_ITEM_DETAILS", GetSelectedItemDetails)
  yield takeEvery("GET_INVOICE_NUMBER", GetInvoiceNumber)
  yield takeEvery("GET_INVOICE_LIST", GetInvoiceList)
  yield takeEvery("GET_QUOTATION_ITEM_LIST", GetQuotationItemList)
  yield takeEvery("GET_INVOICE_BY_ID", GetInvoiceById)
}

export default InvoiceSaga
