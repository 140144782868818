import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect, useSelector } from "react-redux"
import withRouter from "components/Common/withRouter"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

const Sidebar = props => {
  const isSidebarPinned = useSelector(state => state.Layout.isSidebarPinned);

  const [isSidebarActive, setIsSidebarActive] = useState(false);

  const handleMouseEnter = () => {
    if (!isSidebarPinned) {
      document.body.classList.remove("vertical-collpsed");
      document.body.classList.add("sidebar-enable");
      setIsSidebarActive(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isSidebarPinned) {
      document.body.classList.add("vertical-collpsed");
      document.body.classList.remove("sidebar-enable");
      setIsSidebarActive(false);
    }
  };

  return (
    <React.Fragment>
      <div 
        className="vertical-menu"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent isSidebarActive={isSidebarActive} /> : <SidebarContent isSidebarActive={isSidebarActive} />}
        </div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
