import React, { useState, useEffect, useMemo } from "react"
import { Row, Col, Label, Input, Button, Collapse } from "reactstrap"
import Select from "react-select"
import { useNavigate } from "react-router-dom"
import { connect, useDispatch, useSelector } from "react-redux"
import {
  GetQuotationList,
  setShowQuotationGetList,
  GetQuotationById,
} from "../../store/Quotation/actions"
import { setBreadcrumbItems, GetDropdown } from "../../store/actions"
import DataTable from "../../components/Common/CustomDataTable"
import FilterSection from "../../components/Common/FilterSection"
import Quotation from "store/Quotation/reducer"
import { filter } from "lodash"
import moment from "moment"

const AllQuotation = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const itemsFromStore =
    useSelector(state => state.Quotation?.QuotationList?.data) || []
  const Parties = useSelector(state => state.CommonAPI?.PartyList?.data) || []
  const [globalSearch, setGlobalSearch] = useState("")
  const [selectedId, setSelectedId] = useState("")
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const [selectedParty, setSelectedParty] = useState(null)
  const [filters, setFilters] = useState({
    Party: "", // Party filter
    QuotationNumber: "",
  })
  const [dateFilters, setDateFilters] = useState({
    startDate: "",
    endDate: "",
  })

  const breadcrumbItems = useMemo(
    () => [
      { title: "Comfort Zone" },
      { title: "Quotation" },
      { title: "List" },
    ],
    [],
  )

  useEffect(() => {
    dispatch(setBreadcrumbItems("Quotation", breadcrumbItems))
  }, [dispatch, breadcrumbItems])

  useEffect(() => {
    dispatch(GetQuotationList())
    dispatch(
      GetDropdown({ StringMapType: "PartyList", OrderBy: "StringMapName" }),
    )
  }, [dispatch])

  useEffect(() => {
    console.log("Items from Redux:", itemsFromStore)
    if (!Array.isArray(itemsFromStore)) {
      console.error("Items is not an array:", itemsFromStore)
    }
  }, [itemsFromStore])

  const handleCheckboxChange = input => {
    debugger
    document.getElementById("ItemError").textContent = ""
    setSelectedId(input.QuotationId)
    // setRows(prevRows =>
    //   prevRows.map(row =>
    //     row.QuotationId === QuotationId
    //       ? { ...row, isSelected: !row.isSelected }
    //       : row,
    //   ),
    // )
  }

  const checkboxAddList = rowData => (
    <input
      type="checkbox"
      checked={rowData.isSelected}
      onChange={() => handleCheckboxChange(rowData)}
    />
  )

  const actionBodyTemplate = rowData => (
    <div className="actions-column">
      <Button
        color="info"
        className="me-2"
        onClick={async () => {
          try {
            // Clear Quotation asynchronously
            // await dispatch(GetQuotationById(rowData.QuotationId));
            setTimeout(() => {
              navigate(`/Quotation/${"View"}/${rowData.QuotationId}`)
            }, 1000)
          } catch (error) {
            console.error(
              "Error while clearing quotation or navigating:",
              error,
            )
          }
        }}
      >
        <i className="mdi mdi-eye"></i>
      </Button>
      <Button
        color="primary"
        onClick={async () => {
          try {
            // Clear Quotation asynchronously
            // await dispatch(GetQuotationById(rowData.QuotationId));
            setTimeout(() => {
              navigate(`/Quotation/${"Edit"}/${rowData.QuotationId}`)
            }, 1000)
          } catch (error) {
            console.error(
              "Error while clearing quotation or navigating:",
              error,
            )
          }
        }}
      >
        <i className="mdi mdi-pencil"></i>
      </Button>
    </div>
  )

  const itemColumns = [
    {
      name: "Select",
      field: "",
      body: rowData => checkboxAddList(rowData),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      // width: "150px",
    },
    {
      name: "Quotation Date",
      field: "CreatedOn",
      sortable: true,
      width: "170px",
    },
    {
      name: "Quotation#",
      field: "QuotationNumber",
      sortable: true,
      width: "150px",
    },
    { name: "Party", field: "Party", sortable: true, width: "250px" },
    {
      name: "Ship To City/State",
      field: "City",
      sortable: true,
      width: "200px",
    },
    { name: "Amount", field: "FinalAmount", sortable: true, width: "100px" },
    {
      name: "Action",
      field: "",
      body: rowData => actionBodyTemplate(rowData),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "150px",
    },
  ]

  const filteredData = itemsFromStore.filter(data => {
    const matchesFilters = Object.keys(filters).every(key => {
      if (key === "LastRunTimestamp" && filters.LastRunTimestamp) {
        let jobDate = moment(data.CreatedOn, "DD/MM/YYYY", true) // Parse with explicit format

        if (!jobDate.isValid()) return false

        switch (filters.LastRunTimestamp) {
          case "24hours":
            return jobDate.isAfter(moment().subtract(24, "hours"))
          case "week":
            return jobDate.isAfter(moment().subtract(7, "days"))
          case "month":
            return jobDate.isAfter(moment().subtract(1, "month"))
          case "custom":
            const startDate = dateFilters.startDate
              ? moment(dateFilters.startDate, "YYYY-MM-DD").startOf("day")
              : null
            const endDate = dateFilters.endDate
              ? moment(dateFilters.endDate, "YYYY-MM-DD").endOf("day")
              : null

            if (startDate && !startDate.isValid()) {
              console.error("Invalid start date format:", dateFilters.startDate)
              return false
            }

            if (endDate && !endDate.isValid()) {
              console.error("Invalid end date format:", dateFilters.endDate)
              return false
            }

            if (startDate && endDate) {
              return jobDate.isBetween(startDate, endDate, null, "[]")
            } else if (startDate) {
              return jobDate.isSameOrAfter(startDate)
            } else if (endDate) {
              return jobDate.isSameOrBefore(endDate)
            }
            return true
          default:
            return true
        }
      }

      if (!filters[key]) return true
      return (
        data[key] &&
        data[key].toString().toLowerCase().includes(filters[key].toLowerCase())
      )
    })
    return matchesFilters
  })

  // const filteredData = itemsFromStore.filter(data => {
  //   const matchesFilters = Object.keys(filters).every(key => {
  //     if (key === "LastRunTimestamp" && filters.LastRunTimestamp) {
  //       let jobDate = moment(data.CreatedOn, moment.ISO_8601, true)
  //       console.log("checkckkc", jobDate)
  //       if (!jobDate.isValid()) return false

  //       switch (filters.LastRunTimestamp) {
  //         case "24hours":
  //           return jobDate.isAfter(moment().subtract(24, "hours"))
  //         case "week":
  //           return jobDate.isAfter(moment().subtract(7, "days"))
  //         case "month":
  //           return jobDate.isAfter(moment().subtract(1, "month"))
  //         case "custom":
  //           // const startDate = dateFilters.startDate ? moment(dateFilters.startDate, "YYYY-MM-DD") : null;
  //           // const endDate = dateFilters.endDate ? moment(dateFilters.endDate, "YYYY-MM-DD") : null;

  //           const startDate = dateFilters.startDate
  //             ? moment(dateFilters.startDate).startOf("day")
  //             : null
  //           const endDate = dateFilters.endDate
  //             ? moment(dateFilters.endDate).endOf("day")
  //             : null

  //           if (startDate && !startDate.isValid()) {
  //             console.error("Invalid start date format:", dateFilters.startDate)
  //             return false
  //           }

  //           if (endDate && !endDate.isValid()) {
  //             console.error("Invalid end date format:", dateFilters.endDate)
  //             return false
  //           }

  //           if (startDate && endDate) {
  //             return jobDate.isBetween(startDate, endDate, null, "[]")
  //           } else if (startDate) {
  //             return jobDate.isSameOrAfter(startDate)
  //           } else if (endDate) {
  //             return jobDate.isSameOrBefore(endDate)
  //           }
  //           return true
  //         default:
  //           return true
  //       }
  //     }

  //     if (!filters[key]) return true
  //     return (
  //       data[key] &&
  //       data[key].toString().toLowerCase().includes(filters[key].toLowerCase())
  //     )
  //   })
  //   return matchesFilters
  // })

  // const filteredData = itemsFromStore.filter(data =>
  //   Object.keys(filters).every(key => {
  //     if (!filters[key]) return true // Skip empty filters
  //     if (key === "Party") {
  //       return data[key] === filters[key] // Match exact ID for Party
  //     }
  //     return (
  //       data[key] &&
  //       data[key].toString().toLowerCase().includes(filters[key].toLowerCase())
  //     )
  //   }),
  // )

  const csvReport = {
    filename: "Item_Report.csv",
    headers: itemColumns.map(col => ({ label: col.name, key: col.field })),
    data: filteredData,
  }
  const handleCustomDateChange = (e, field) => {
    const { value } = e.target
    setDateFilters(prevFilters => {
      const updatedFilters = { ...prevFilters, [field]: value }

      if (updatedFilters.startDate && updatedFilters.endDate) {
        const startDate = moment(updatedFilters.startDate).startOf("day")
        const endDate = moment(updatedFilters.endDate).endOf("day")

        if (startDate.isAfter(endDate)) {
          alert("Start date should not be after the end date.")
          return prevFilters
        }
      }
      return updatedFilters
    })
  }

  const handleFilterChange = e => {
    const { name, value } = e.target
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value,
    }))
  }
  const handleRefresh = () => {
    setFilters({
      Party: "", // Reset to initial filter state
    })
    setSelectedParty(null) // Clear the selected party dropdown
  }
  const handleGlobalSearchChange = e => {
    setGlobalSearch(e.target.value)
  }

  const handleOnChange = (selectedOption, type) => {
    if (type === "Quotation") {
      setFilters(prevFilters => ({
        ...prevFilters,
        QuotationNumber: selectedOption.target.value || "", // Update Party filter
      }))
    }
  }
  const handleDropdownChange = selectedOption => {
    setFilters(prevFilters => ({
      ...prevFilters,
      Party: selectedOption?.label || "", // Update Party filter
    }))
    setSelectedParty(selectedOption)
  }
  // Today, Yesterday, This Week, This Month, Last Month, YTD, Custom

  const executionOptions = [
    { value: "", label: "All" },
    { value: "24hours", label: "Last 24 hours" },
    { value: "week", label: "Last Week" },
    { value: "month", label: "Last Month" },
    { value: "custom", label: "Custom" },
  ]

  const customMenuStyles = {
    menuPortal: base => ({
      ...base,
      zIndex: 9999,
    }),
    menu: base => ({
      ...base,
      zIndex: 9999,
      position: "absolute",
    }),
  }
  const handleExecutedFilterChange = selectedOption => {
    debugger
    const { value } = selectedOption
    setFilters(prevFilters => ({
      ...prevFilters,
      LastRunTimestamp: value,
    }))
  }

  return (
    <>
      <Row className="mb-3">
        <Col md={12} className="d-flex justify-content-end align-items-center">
          <span className="text-danger me-3" id="ItemError"></span>
          <Button
            color="primary"
            onClick={() => {
              if (selectedId === "") {
                document.getElementById("ItemError").textContent =
                  "Please select any one Quotation"
                return
              } else {
                debugger
                navigate(`/Invoice/${"Add"}/${selectedId}`)
                // navigate("/Invoice/AddInvoice")
              }
            }}
            className="me-2"
          >
            ADD INVOICE
          </Button>
          <div className="input-container me-2">
            <i className="mdi mdi-magnify search-icon"></i>
            <Input
              type="text"
              value={globalSearch}
              onChange={handleGlobalSearchChange}
              className="search-input"
            />
          </div>
          <Button
            color="primary"
            onClick={() => setIsOpenFilter(!isOpenFilter)}
            className="me-2"
          >
            <i className="mdi mdi-filter"></i>
          </Button>
          <Button
            color="success"
            onClick={() => props.setShowQuotationGetList(false)}
            className="me-2"
          >
            <i className="mdi mdi-plus-thick"></i> ADD
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={isOpenFilter}>
        <Row className="mb-3 d-flex align-items-center">
          <Col lg={4} className="mb-0 d-flex align-items-center">
            <div className="form-group flex-grow-1">
              <Label htmlFor="LastRunTimestamp" className="col-form-label">
                {" "}
                Date Range{" "}
              </Label>
              <Select
                id="LastRunTimestamp"
                name="LastRunTimestamp"
                options={executionOptions}
                value={{
                  value: filters.LastRunTimestamp || "",
                  label: filters.LastRunTimestamp || "All",
                }}
                onChange={selectedOption =>
                  handleExecutedFilterChange(selectedOption)
                }
                placeholder="Select Execution Time"
                styles={customMenuStyles}
              />
            </div>
          </Col>

          {filters.LastRunTimestamp === "custom" && (
            <>
              <Col lg={4} className="mt-2">
                <Label htmlFor="startDate" className="col-form-label">
                  {" "}
                  Start Date{" "}
                </Label>
                <Input
                  type="date"
                  name="startDate"
                  id="startDate"
                  className="form-control mb-0"
                  onChange={e => handleCustomDateChange(e, "startDate")}
                  value={dateFilters.startDate || ""}
                  max={
                    dateFilters.endDate
                      ? moment(dateFilters.endDate).format("YYYY-MM-DD")
                      : moment().format("YYYY-MM-DD")
                  }
                />
              </Col>
              <Col lg={4} className="mt-2">
                <Label htmlFor="endDate" className="col-form-label">
                  {" "}
                  End Date{" "}
                </Label>
                <Input
                  type="date"
                  name="endDate"
                  id="endDate"
                  className="form-control"
                  onChange={e => handleCustomDateChange(e, "endDate")}
                  value={dateFilters.endDate || ""}
                  max={moment().format("YYYY-MM-DD")}
                  min={
                    dateFilters.startDate
                      ? moment(dateFilters.startDate).format("YYYY-MM-DD")
                      : ""
                  }
                />
              </Col>
            </>
          )}
        </Row>

        <Row className="mb-3">
          <Col lg={6} className="mb-2 d-flex align-items-center">
            <div className="form-group flex-grow-1">
              <Label htmlFor="Party" className="col-form-label">
                Party
              </Label>
              <Select
                id="Party"
                name="Party"
                options={Parties.map(value => ({
                  label: value.StringMapName, // Display name
                  value: value.StringMapId, // Unique ID for filter
                }))}
                onChange={handleDropdownChange}
                value={selectedParty}
                placeholder="Select Party"
                isSearchable={true}
                classNamePrefix="react-select"
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
              />
            </div>
          </Col>
          <Col lg={6} className="mb-2 d-flex align-items-center">
            <div className="form-group flex-grow-1">
              <Label htmlFor="QuotationNumber" className="col-form-label">
                Quotation#
              </Label>
              <Input
                id="QuotationNumber"
                name="QuotationNumber"
                type="text"
                className="form-control validate"
                maxLength={50}
                placeholder=""
                onChange={e => handleOnChange(e, "Quotation")}
                value={filters.QuotationNumber}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="d-flex align-items-center">
            <Button
              color="primary"
              onClick={handleRefresh} // Reset filters on Refresh
              className="me-2"
            >
              Refresh
            </Button>
          </Col>
        </Row>
      </Collapse>

      <DataTable
        filteredData={filteredData}
        Columns={itemColumns}
        csvReport={csvReport}
        List={GetQuotationList}
        globalSearch={globalSearch}
      />
    </>
  )
}

const mapStateToProps = state => ({
  showQuotationGetList: state.Quotation?.showQuotationGetList,
})

export default connect(mapStateToProps, { setShowQuotationGetList })(
  AllQuotation,
)
