// Get Quotation Number Action
export const GetQuotationItemList = () => {
  return {
    type: "GET_QUOTATION_ITEM_LIST",
  }
}

// Success for Get Quotation Number
export const GetQuotationItemListSuccess = QuotationItemList => {
  return {
    type: "GET_QUOTATION_ITEM_LIST_SUCCESS",
    payload: QuotationItemList,
  }
}

export const setShowQuotationGetList = ShowQuotationGetList => ({
  type: "SET_SHOW_QUOTATION_LIST",
  payload: ShowQuotationGetList,
})

// Get Quotation Number Action
export const GetQuotationNumber = () => {
  return {
    type: "GET_QUOTATION_NUMBER",
  }
}

// Success for Get Quotation Number
export const GetQuotationNumberSuccess = QuotationNumber => {
  return {
    type: "GET_QUOTATION_NUMBER_SUCCESS",
    payload: QuotationNumber,
  }
}

// Get Quotation List Action
export const GetQuotationList = () => {
  return {
    type: "GET_QUOTATION_LIST",
  }
}

// Success for Get Quotation List
export const GetQuotationListSuccess = QuotationList => {
  return {
    type: "GET_QUOTATION_LIST_SUCCESS",
    payload: QuotationList,
  }
}

// Add/Update Quotation
export const AddUpdateQuotation = input => {
  return {
    type: "ADD_UPDATE_QUOTATION",
    payload: input,
  }
}

// Success for Add/Update Quotation
export const AddUpdateQuotationSuccess = input => {
  return {
    type: "ADD_UPDATE_QUOTATION_SUCCESS",
    payload: input,
  }
}

// Get Quotation Action
export const GetQuotation = () => {
  return {
    type: "GET_ALL_QUOTATION",
  }
}

// Success for Get Quotation
export const GetQuotationSuccess = payload => {
  return {
    type: "GET_ALL_QUOTATION_SUCCESS",
    payload: payload,
  }
}

export const GetSelectedItemDetails = inputpayload => {
  return {
    type: "GET_SELECTED_ITEM_DETAILS",
    payload: inputpayload,
  }
}

// Success for Get Quotation
export const GetSelectedItemDetailsSuccess = inputpayload => {
  return {
    type: "GET_SELECTED_ITEM_DETAILS_SUCCESS",
    payload: inputpayload,
  }
}

export const GetQuotationById = inputpayload => {
  return {
    type: "GET_QUOTATION_BY_ID",
    payload: inputpayload,
  }
}

// Success for Get Quotation
export const GetQuotationByIdSuccess = inputpayload => {
  return {
    type: "GET_QUOTATION_BY_ID_SUCCESS",
    payload: inputpayload,
  }
}

export const GetItemdropdownQuotation = inputpayload => {
  return {
    type: "GET_ITEM_DROPDOWN_QUOTATION",
    payload: inputpayload,
  }
}

// Success for Get Quotation
export const GetItemdropdownQuotationSuccess = inputpayload => {
  return {
    type: "GET_ITEM_DROPDOWN_QUOTATION_SUCCESS",
    payload: inputpayload,
  }
}

export const ClearQuotation = input => {
  return {
    type: "CLEAR_QUOTATION",
    tab: input,
  }
}
export const ClearQuotationById = input => {
  return {
    type: "CLEAR_QUOTATION_BY_ID",
    tab: input,
  }
}
// Action for API error
export const QuotationAPIError = error => {
  return {
    type: "QUOTATION_API_ERROR",
    payload: error,
  }
}
