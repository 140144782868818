import React from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";

const Authmiddleware = (props) => {
  const authUser = localStorage.getItem("authUser");
  const tokenFromStore = useSelector((state) => state.Login?.token);

  if (!authUser) {
    return <Navigate to="/login" />;
  }

  try {
    const token = JSON.parse(authUser).token;
    const decoded = jwtDecode(tokenFromStore);
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
      localStorage.removeItem("authUser");
      return <Navigate to="/login" />;
    }
  } catch (error) {
    console.error("Error decoding token:", error);
    localStorage.removeItem("authUser");
    return <Navigate to="/login" />;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default Authmiddleware;
