import React, { useState, useEffect, useMemo } from "react";
import { Button, Row, Col, Input, Collapse, UncontrolledTooltip, Form, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { GetItems } from "../../store/Master/Item/actions";
import { GetTasks, setShowTaskGetList, GetDropdown, GetProjectInfoById, GetDirectoryList, GetWorkReportByDate, AddUpdateWorkReport, setFilteredDataTodayRedux, clearFilteredDataTodayRedux } from "store/actions";
import DataTable from "../../components/Common/CustomDataTable";
import Select from 'react-select';
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { format } from "date-fns";
const apiUrl = process.env.REACT_APP_API_URL;

const AllEOD = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const projectList = useSelector((state) => state.CommonAPI?.projectList?.data) || [];
    const projectManagerList = useSelector((state) => state.CommonAPI?.ProjectManagerList?.data) || [];
    const tasksFromStore = useSelector((state) => state.Task?.tasks?.data) || [];
    const projectInfoById = useSelector((state) => state.Task?.projectInfoById?.data) || [];
    const directoryList = useSelector((state) => state.CommonAPI?.directoryList) || [];
    const userDetail = useSelector((state) => state.Login?.user) || [];
    const workReportData = useSelector((state) => state.EOD?.workReportData?.data) || [];
    const filteredDataTodayRedux = useSelector((state) => state.EOD?.filteredDataTodayRedux) || [];

    const [allTasks, setAllTasks] = useState([]);
    const [selectedProject, setSelectedProject] = useState({label: "All", value: "All"});
    const [selectedAssignedTo, setSelectedAssignedTo] = useState(null);
    const [selectedProjectManager, setSelectedProjectManager] = useState(null);
    const [globalSearch, setGlobalSearch] = useState("");
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState("All");

    const [inputProjectValue, setInputProjectValue] = useState('');
    const [inputProjectFocus, setInputProjectFocus] = useState(false);

    const [modal, setModal] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [filteredDataToday, setFilteredDataToday] = useState([]);
    const [filteredDataArray, setFilteredDataArray] = useState([]);
    const [inputTaskObstacles, setInputTaskObstacles] = useState(null);

    const [mainModal, setMainModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const [showResourceSelection, setShowResourceSelection] = useState(false);
    const [showPRSelection, setShowPRSelection] = useState(false);
    const [Is100Percent, setIs100Percent] = useState(false);

    useEffect(() => {
        dispatch(GetTasks());
        dispatch(GetDirectoryList());
        dispatch(GetDropdown({ "StringMapType": "PROJECTLIST", "OrderBy": "StringMapName" }));
        dispatch(GetDropdown({ "StringMapType": "ProjectManagerList", "OrderBy": "StringMapName" }));
    }, [dispatch]);

    useEffect(() => {
        if (selectedDate) {
            const formattedDate = formatToYYYYMMDD(selectedDate);
            dispatch(GetWorkReportByDate(formattedDate, userDetail.CurrentUserId));
        }
    }, [selectedDate]);

    useEffect(() => {
        const checkTimeAndClearState = () => {
            const now = new Date();
            const hours = now.getHours();
            const minutes = now.getMinutes();

            if (hours === 23 && minutes >= 49 && minutes <= 59) {
                console.log("Clearing filteredDataTodayRedux...");
                dispatch(clearFilteredDataTodayRedux());
            }
        };

        const timerId = setInterval(checkTimeAndClearState, 300000);
        
        return () => {
            clearInterval(timerId);
        };
    }, [dispatch]);

    useEffect(() => {
        if (workReportData.length) {
            const updatedFilteredData = workReportData
                .filter(item => item.WorkReportDate === formatToYYYYMMDD(selectedDate))
                .map(item => ({
                    HoursConsumed: item.HoursConsumed || 0,
                    ProjectName: item.ProjectName || '',
                    TaskID: item.TaskID || '',
                    TaskNumber: item.TaskNumber || '',
                    TaskProgressComment: item.TaskProgressComment || '',
                    TaskProgressPercentage: item.TaskProgressPercentage || '',
                    TaskTitle: item.TaskTitle || ''
                }));

            setInputTaskObstacles(workReportData[0].TaskObstacles);

            setFilteredDataArray(updatedFilteredData);
        } else {
            setFilteredDataArray([]);
            setInputTaskObstacles(null);
        }
    }, [workReportData, selectedDate]);

    useEffect(() => {
        if (selectedProject?.value) {
            dispatch(GetProjectInfoById(selectedProject.value));
        }
    }, [selectedProject, dispatch]);

    useEffect(() => {
        setAllTasks(tasksFromStore)
    }, [tasksFromStore])

    useEffect(() => {

        if (userDetail.Role === 'Admin') {
            setShowResourceSelection(true);
            setShowPRSelection(true);
        } else {

            if (userDetail.CurrentUserId) {
                const isProjectManager = projectManagerList.some(
                    (pm) => pm.StringMapId === userDetail.CurrentUserId
                );

                if (isProjectManager) {
                    setShowResourceSelection(true);
                } else {
                    return;
                }
            }
        }
    }, [userDetail.CurrentUserId, userDetail.Role]);

    useEffect(() => {
        let filteredTasks = tasksFromStore;

        if (selectedProject?.value && selectedProject.value !== "All") {
            filteredTasks = filteredTasks.filter(task => task.ProjectID === selectedProject.value);
        }

        if (selectedAssignedTo?.value && selectedAssignedTo.value !== "All") {
            filteredTasks = filteredTasks.filter(task => task.AssignedTo === selectedAssignedTo.value);
        }

        if (selectedProjectManager?.value && selectedProjectManager.value !== "All") {
            filteredTasks = filteredTasks.filter(task => task.ProjectManager === selectedProjectManager.value);
        }

        if (globalSearch.trim() !== "") {
            const search = globalSearch.toLowerCase();
            filteredTasks = filteredTasks.filter(task =>
                (task.TaskNumber?.toLowerCase().includes(search) || 
                 task.TaskTitle?.toLowerCase().includes(search))
            );
        }

        setAllTasks(filteredTasks);
    }, [tasksFromStore, selectedProject, selectedAssignedTo, selectedProjectManager, globalSearch]);

    useEffect(() => {
        setSelectedAssignedTo({ label: "All", value: "All" });
    }, [selectedProject]);

    const assignedToList = useMemo(() => {
        let list = [];
    
        if (selectedProject?.value === "All" || !selectedProject) {
            list = directoryList.map(item => ({
                label: item.FullName,
                value: item.PersonId,
            }));
        } else {
            list = projectInfoById
                .filter(item => item.AssociationType === "Access")
                .map(item => ({
                    label: item.AccessName,
                    value: item.AssociationValue,
                }));
        }
    
        return list.sort((a, b) => a.label.localeCompare(b.label));
    }, [selectedProject, directoryList, projectInfoById]);

    useEffect(() => {
        if (userDetail.Role === "Operator") {
            const defaultAssignedTo = assignedToList.find(item => item.value === userDetail.CurrentUserId);
            if (defaultAssignedTo) {
                setSelectedAssignedTo(defaultAssignedTo);
            }
        }
    }, [userDetail, assignedToList]);

    const actionBodyTemplate = (rowData) => {
        const isCurrentUserAssigned = rowData.AssignedTo === userDetail.CurrentUserId;

        if (rowData.PercentCompleted === 100) {
            return null;
        }

        return (
            <div className="actions-column">
                {isCurrentUserAssigned && (
                    <>
                        {workReportData.length > 0 ? (
                            <span>Already Submitted</span>
                        ) : (
                            <>
                                <a
                                    className="me-2"
                                    style={{ color: "inherit" }}
                                    onClick={() => {
                                        setSelectedTask(rowData);
                                        toggleModal();
                                    }}
                                >
                                    Mark In Progress
                                </a>
                                <a
                                    style={{ color: "#28A745" }}
                                    onClick={() => {
                                        setSelectedTask(rowData);
                                        setIs100Percent(true);
                                        toggleModal();
                                        validation.setFieldValue('TaskProgressPercentage', parseInt(100, 10));
                                    }}
                                >
                                    Mark Complete
                                </a>
                            </>
                        )}
                    </>
                )}
            </div>
        )
    };

    const toggleModal = () => {
        setModal(!modal);
        if (modal === true) {
            resetForm();
            setIs100Percent(false);
            setSelectedTask(null);
        }
    };

    const toggleMainModal = () => {
        setMainModal(!mainModal);
    };

    const taskColumns = [
        { name: "Project", field: "ProjectName", sortable: true, width: "200px" },
        { name: "Task", field: "Task", sortable: true, width: "250px" },
        { name: "Assignee", field: "Assignee", sortable: true, width: "175px" },
        { name: "Est", field: "Est", sortable: true, width: "300px" },
        { name: "Act", field: "Act", sortable: true, width: "300px" },
        { name: "Status", field: "TaskHealth", sortable: true, width: "325px" },
        { name: "Action", field: "", body: (rowData) => actionBodyTemplate(rowData), ignoreRowClick: true, allowOverflow: true, button: true, width: "250px" },
    ];

    const taskHealthIcons = {
        "Not Started": `${apiUrl}/images/Icons/notstarted.svg`,
        "In Progress": `${apiUrl}/images/Icons/inprogress.svg`,
        "Overdue In Progress": `${apiUrl}/images/Icons/o-inprogress.svg`,
        "Overdue Not Started": `${apiUrl}/images/Icons/o-notstarted.svg`,
        "Delayed Completion": `${apiUrl}/images/Icons/d-completed.svg`,
        "Completed within time": `${apiUrl}/images/Icons/completed.svg`,
        "Unknown Status": `${apiUrl}/images/Icons/unknown.svg`,
    };

    const processedData = allTasks.map((task) => ({
        ...task,
        TaskHealthRaw: task.TaskHealth,
        ProjectName: (
            <div style={{ display: "flex", alignItems: "center", width: "200px" }}>
                <img
                    src={task.ProjectIconURL}
                    alt="Project Icon"
                    style={{ width: "auto", height: "30px", maxWidth: "45px", marginRight: "10px" }}
                />
                {task.ProjectName}
            </div>
        ),
        Task: (
            <div style={{ display: "flex", alignItems: "start", width: "250px", cursor: "pointer" }}>
                <img
                    src={`${apiUrl}${task.TaskIcon}`}
                    alt="Task Icon"
                    style={{ width: "30px", height: "30px", marginRight: "10px" }}
                />
                <div>
                    <div>
                        {task.TaskTitle.length > 30
                            ? `${task.TaskTitle.substring(0, 30)}...`
                            : task.TaskTitle}
                    </div>

                    <div style={{ fontSize: "11px" }}>
                        {task.TaskNumber}
                    </div>
                </div>
            </div>
        ),
        Assignee: (
            <div style={{ display: "flex", alignItems: "center", width: "175px" }}>
                {task.AssigneePicturePath ? (
                    <img
                        src={`${apiUrl}${task.AssigneePicturePath}`}
                        alt="Assignee Image"
                        style={{
                            width: "35px",
                            height: "35px",
                            marginRight: "10px",
                            borderRadius: "50%",
                        }}
                    />
                ) : (
                    <div
                        style={{
                            width: "35px",
                            height: "35px",
                            marginRight: "10px",
                            borderRadius: "50%",
                            backgroundColor: "#7A6FBE",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#fff",
                            fontWeight: "bold",
                            fontSize: "14px",
                        }}
                    >
                        {`${task.AssigneeFirstName?.[0] || ""}${task.AssigneeLastName?.[0] || ""}`.toUpperCase()}
                    </div>
                )}
                {task.AssigneeFirstName} {task.AssigneeLastName}
            </div>
        ),
        TaskHealth: (
            <div style={{ display: "flex", alignItems: "center", width: "325px" }}>
                <img
                    src={taskHealthIcons[task.TaskHealth] || `${apiUrl}/images/Icon/default.svg`}
                    alt={`${task.TaskHealth} Icon`}
                    style={{ width: "30px", height: "30px", marginRight: "10px" }}
                />
                {task.TaskHealth} ({task.TaskHealthCondition})
            </div>
        ),
    }));

    const taskHealthCounts = useMemo(() => {
        return allTasks.reduce((acc, task) => {
            acc[task.TaskHealth] = (acc[task.TaskHealth] || 0) + 1;
            return acc;
        }, {});
    }, [allTasks]);

    const taskFilterGroups = {
        "Not Started All": ["Overdue Not Started", "Not Started"],
        "In Progress All": ["Overdue In Progress", "In Progress"],
        "Completed": ["Delayed Completion", "Completed within time"],

        "Overdue Not Started": ["Overdue Not Started"],
        "Not Started": ["Not Started"],
        "Overdue In Progress": ["Overdue In Progress"],
        "In Progress": ["In Progress"],
        "Delayed Completion": ["Delayed Completion"],
        "Completed within time": ["Completed within time"],
        All: [],
    };

    const handleFilterClick = (filter) => {
        if (filter !== selectedFilter) {
            setSelectedFilter(filter);
        }
    };

    const filteredData = useMemo(() => {
        if (!selectedFilter || selectedFilter === "All") return processedData;

        const selectedStatuses = taskFilterGroups[selectedFilter] || [];
        return processedData.filter((data) =>
            selectedStatuses.includes(data.TaskHealthRaw)
        );
    }, [processedData, selectedFilter]);

    const handleSelectChange = (selectedOption, field) => {
        switch (selectedOption) {
            case 'ProjectID':
                setSelectedProject(field);
                break;
            case 'AssignedTo':
                setSelectedAssignedTo(field);
                break;
            case 'ProjectManager':
                setSelectedProjectManager(field);
                break;
            default:
                break;
        }
    };

    const transformDataForCSV = (data) => {
        return data.map((task) => ({
            ProjectName: task.ProjectName?.props?.children[1] || task.ProjectName,
            Task: `${task.TaskNumber}_${task.TaskTitle}`,
            Assignee: `${task.AssigneeFirstName || ""} ${task.AssigneeLastName || ""}`.trim(),
            Est: task.Est,
            Act: task.Act,
            TaskHealth: task.TaskHealthRaw,
        }));
    };

    const memoizedCsvReport = useMemo(() => {
        const plainData = transformDataForCSV(filteredData);
        return {
            filename: "Task_Report.csv",
            headers: taskColumns.map((col) => ({ label: col.name, key: col.field })),
            data: plainData,
        };
    }, [filteredData]);

    const handleGlobalSearchChange = (e) => {
        setGlobalSearch(e.target.value);
    };

    const selectStyles = () => ({
        control: (provided) => ({
            ...provided,
            boxShadow: 'none',
        }),
        outline: "none",
    });

    const CustomOption = (props) => (
        <div style={{ zIndex: "2" }} {...props.innerProps} className="d-flex align-items-center p-2">
            {props.data.ProjectIconURL && (
                <img src={props.data.ProjectIconURL} alt="" style={{ width: 20, height: 20, marginRight: 8 }} />
            )}
            <span>{props.data.label}</span>
        </div>
    );

    const CustomSingleValue = (props) => (
        <div className="d-flex align-items-center" style={{ height: '100%', margin: 0, padding: 0, zIndex: "2" }}>
            {props.data.ProjectIconURL && (
                <img src={props.data.ProjectIconURL} alt="" style={{ width: 20, height: 20, marginRight: 8 }} />
            )}
            <span>{props.data.label}</span>
        </div>
    );

    const selectStylesWithImage = (hasError) => ({
        control: (provided, state) => ({
            ...provided,
            borderColor: hasError ? 'red' : provided.borderColor,
            boxShadow: 'none',
            minHeight: '38px',
            height: '38px',
            '&:hover': {
                borderColor: hasError ? 'red' : provided['&:hover'].borderColor,
            },
        }),
        // dropdownIndicator: () => ({
        //     display: 'none',
        // }),
        // indicatorSeparator: () => ({
        //     display: 'none',
        // }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0 8px',
            display: 'flex',
            alignItems: 'center',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            margin: 0,
        }),
        outline: "none",
    });

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            TaskProgressPercentage: '',
            Hour: '',
            Minute: '',
            TaskProgressComment: '',
        },
        validationSchema: Yup.object({
            TaskProgressPercentage: Yup.number()
                .required("Please Select Percentage Complete")
                .integer("Percentage should be an integer")
                .min(0, "Percentage cannot be less than 0")
                .max(100, "Percentage cannot be more than 100"),
            Hour: Yup.string().required("Please Select Hour"),
            Minute: Yup.string().required("Please Select Minute"),
            TaskProgressComment: Yup.string(),
        }),
        onSubmit: async (values) => {
            const projectNameElement = selectedTask.ProjectName;

            let projectName = "";
            if (projectNameElement && projectNameElement.props && projectNameElement.props.children) {
                const children = projectNameElement.props.children;
                projectName = Array.isArray(children)
                    ? children.find(child => typeof child === "string")
                    : typeof children === "string"
                        ? children
                        : "";
            }

            const hour = parseFloat(values.Hour || "0");
            const minute = parseFloat(values.Minute || "0");
            const hoursConsumed = hour + (minute / 60);

            values.HoursConsumed = parseFloat(hoursConsumed.toFixed(2));

            values.TaskID = selectedTask.TaskID
            values.TaskNumber = selectedTask.TaskNumber
            values.TaskTitle = selectedTask.TaskTitle
            values.ProjectName = projectName

            const { Hour, Minute, ...filteredData } = values;

            // setFilteredDataToday((prevData) => {
            //     const isTaskAlreadyAdded = prevData.some(
            //         (item) => item.TaskID === filteredData.TaskID
            //     );

            //     if (isTaskAlreadyAdded) {
            //         toast.error("Task already added!");
            //         return prevData;
            //     }

            //     return [...prevData, filteredData];
            // });

            const isTaskAlreadyAdded = filteredDataTodayRedux.some(
                (item) => item.TaskID === filteredData.TaskID
            );

            if (isTaskAlreadyAdded) {
                toast.error("Task already added!");
                return;
            }

            dispatch(setFilteredDataTodayRedux([...filteredDataTodayRedux, filteredData]));

            toggleModal();
        }
    });

    const handleFinalSubmit = () => {
        const payload = {
            CurrentUserId: userDetail.CurrentUserId,
            ResourceID: userDetail.CurrentUserId,
            WorkReportDate: formatToYYYYMMDD(new Date()),
            TaskObstacles: inputTaskObstacles || '',
            OrganizationId: userDetail.OrganizationId,
            // WorkReportDetails: filteredDataToday
            WorkReportDetails: filteredDataTodayRedux
        }

        try {
            dispatch(AddUpdateWorkReport(payload));
            toggleMainModal();
            setTimeout(() => {
                window.location.reload()
            }, 500);
        } catch (error) {
            console.error("Dispatch failed: ", error);
            console.log("Dispatch log fail", error);
        }
    };

    const handleWorkSelectChange = (selectedOption, field) => {
        switch (selectedOption) {
            case 'TaskProgressPercentage':
                validation.setFieldValue('TaskProgressPercentage', field ? parseInt(field.value, 10) : '');
                break;
            case 'Hour':
                validation.setFieldValue('Hour', field ? field.value : '');
                break;
            case 'Minute':
                validation.setFieldValue('Minute', field ? field.value : '');
                break;
            default:
                break;
        }
    };

    // const handleRemoveWorkReport = (taskID) => {
    //     const updatedData = filteredDataToday.filter((task) => task.TaskID !== taskID);
    //     setFilteredDataToday(updatedData);
    // };

    const handleRemoveWorkReport = (taskID) => {
        const updatedData = filteredDataTodayRedux.filter((task) => task.TaskID !== taskID);
        dispatch(setFilteredDataTodayRedux(updatedData));
    };

    const resetForm = () => {
        validation.resetForm();
        handleWorkSelectChange("TaskProgressPercentage", '');
        handleWorkSelectChange("Hour", '');
        handleWorkSelectChange("Minute", '');
        handleWorkSelectChange("TaskProgressComment", '');
    };

    const generateOptions = (start, end, step = 1) =>
        Array.from({ length: Math.floor((end - start) / step) + 1 }, (_, index) => {
            const value = start + index * step;
            return { label: value.toString(), value: value.toString() };
        });

    const percentageOptions = generateOptions(0, 100, 10);
    const hourOptions = generateOptions(0, 23, 1);
    const minuteOptions = generateOptions(0, 45, 15);

    useEffect(() => {
        if (mainModal) {
            setSelectedDate(new Date());
        }
    }, [mainModal]);

    const handleNextDay = () => {
        setSelectedDate((prevDate) => new Date(prevDate.getTime() + 24 * 60 * 60 * 1000));
    };

    const isFutureDate = selectedDate >= new Date(new Date().setHours(0, 0, 0, 0));

    const handlePrevDay = () => {
        setSelectedDate((prevDate) => new Date(prevDate.getTime() - 24 * 60 * 60 * 1000));
    };

    const formattedDate = format(selectedDate, "dd-MM-yyyy");
    const formattedDay = format(selectedDate, "EEEE");
    const formatToYYYYMMDD = (date) => format(date, "yyyy-MM-dd");
    const isToday = formatToYYYYMMDD(selectedDate) === formatToYYYYMMDD(new Date());

    return (
        <>
            <Row className="mb-2">
                <Col md={4} style={{ zIndex: "2" }}>
                    <Row>
                        <Label htmlFor="ProjectID" className="col-form-label">
                            Project
                        </Label>
                    </Row>
                    <Row>
                        <Select
                            id="ProjectID"
                            name="ProjectID"
                            options={[
                                { label: "All", value: "All" },
                                ...projectList.map((value) => ({
                                    label: value.StringMapName,
                                    value: value.StringMapId,
                                    ProjectIconURL: value.ProjectIconURL,
                                }))
                            ]}
                            value={selectedProject}
                            onChange={(selectedOption) => handleSelectChange('ProjectID', selectedOption)}
                            placeholder={inputProjectFocus ? "" : "Select Project..."}
                            isSearchable={true}
                            // inputValue={inputProjectValue}
                            // onInputChange={(value) => setInputProjectValue(value)}
                            // menuIsOpen={inputProjectValue.length > 0}
                            // onFocus={() => setInputProjectFocus(true)}
                            // onBlur={() => setInputProjectFocus(false)}
                            classNamePrefix="react-select"
                            styles={selectStylesWithImage()}
                            components={{
                                // Option: CustomOption, 
                                SingleValue: CustomSingleValue
                            }}
                        />
                    </Row>
                </Col>

                {/* {showResourceSelection && ( */}
                    <Col md={4} style={{ zIndex: "2" }}>
                        <Row>
                            <Label htmlFor="AssignedTo" className="col-form-label">
                                Resource
                            </Label>
                        </Row>
                        <Row>
                            <Select
                                id="AssignedTo"
                                name="AssignedTo"
                                options={[{ label: "All", value: "All" }, ...assignedToList]}
                                onChange={(selectedOption) => handleSelectChange('AssignedTo', selectedOption)}
                                value={selectedAssignedTo}
                                placeholder="Select Resource"
                                isSearchable={true}
                                // styles={selectStyles()}
                                className="mb-3"
                                classNamePrefix="react-select"
                            />
                        </Row>
                    </Col>
                {/* )} */}
                {showPRSelection && (
                    <Col md={4} style={{ zIndex: "2" }}>
                        <Row>
                            <Label htmlFor="ProjectManager" className="col-form-label">
                                Project Manager
                            </Label>
                        </Row>
                        <Row>
                            <Select
                                id="ProjectManager"
                                name="ProjectManager"
                                options={[
                                    { label: "All", value: "All" },
                                    ...projectManagerList.map((value) => ({
                                        label: value.StringMapName,
                                        value: value.StringMapId,
                                    }))
                                ]}
                                onChange={(selectedOption) => handleSelectChange('ProjectManager', selectedOption)}
                                value={selectedProjectManager}
                                placeholder="Select Project Manager..."
                                isSearchable={true}
                                // styles={selectStyles()}
                                className="mb-3"
                                classNamePrefix="react-select"
                            />
                        </Row>
                    </Col>
                )}
            </Row>

            <Row className="d-flex justify-content-end align-items-center mb-3">
                <Col md={4} className="d-flex justify-content-end align-items-center">
                    <div className="input-container me-2">
                        <i className="mdi mdi-magnify search-icon"></i>
                        <Input
                            type="text"
                            value={globalSearch}
                            onChange={handleGlobalSearchChange}
                            className="search-input"
                            placeholder="Title / Number"
                        />
                    </div>
                    <Button color="primary" id="filter" onClick={() => setIsOpenFilter(!isOpenFilter)} className="me-2">
                        <i style={{fontSize: "16px"}} className="mdi mdi-tune"></i>
                    </Button>
                    <UncontrolledTooltip target="filter" placement="bottom">
                        Filter
                    </UncontrolledTooltip>
                    <Button color="success" id="add-new" onClick={() => toggleMainModal()} className="me-2">
                        <i style={{fontSize: "16px"}} className="mdi mdi-chevron-left"></i>
                        <i style={{fontSize: "16px"}} className="mdi mdi-chevron-left"></i>
                        <i style={{fontSize: "16px"}} className="mdi mdi-chevron-left"></i>
                    </Button>
                    <UncontrolledTooltip target="add-new" placement="bottom">
                        Submit Work Report
                    </UncontrolledTooltip>
                </Col>
            </Row>

            <Collapse isOpen={isOpenFilter}>
                <div className="filter-buttons mb-3" style={{ border: "2px solid #bbb" }}>
                    <Row className="d-flex justify-content-around align-items-center mb-3 mt-3">
                        <Col md={3} className="d-flex flex-column justify-content-center align-items-start" style={{ border: "2px solid #dcdcdc" }}>
                            <div className="d-flex justify-content-center align-items-center p-2" style={{ backgroundColor: selectedFilter === "Not Started" ? "#ECEFF4" : "", borderRadius: "4px", cursor: "pointer" }} onClick={() => handleFilterClick("Not Started")}>
                                <img
                                    src={`${apiUrl}/images/Icons/notstarted.svg`}
                                    alt="Not Started Icon"
                                    style={{ width: "30px", height: "30px", marginRight: "10px" }}
                                />
                                <div>
                                    Not Started ({taskHealthCounts["Not Started"] || 0})
                                </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center p-2" style={{ backgroundColor: selectedFilter === "Overdue Not Started" ? "#ECEFF4" : "", borderRadius: "4px", cursor: "pointer" }} onClick={() => handleFilterClick("Overdue Not Started")}>
                                <img
                                    src={`${apiUrl}/images/Icons/o-notstarted.svg`}
                                    alt="Overdue Not Started Icon"
                                    style={{ width: "30px", height: "30px", marginRight: "10px" }}
                                />
                                <div>
                                    Overdue Not Started ({taskHealthCounts["Overdue Not Started"] || 0})
                                </div>
                            </div>
                        </Col>
                        <Col md={3} className="d-flex flex-column justify-content-center align-items-start" style={{ border: "2px solid #dcdcdc" }}>
                            <div className="d-flex justify-content-center align-items-center p-2" style={{ backgroundColor: selectedFilter === "In Progress" ? "#ECEFF4" : "", borderRadius: "4px", cursor: "pointer" }} onClick={() => handleFilterClick("In Progress")}>
                                <img
                                    src={`${apiUrl}/images/Icons/inprogress.svg`}
                                    alt="In Progress Icon"
                                    style={{ width: "30px", height: "30px", marginRight: "10px" }}
                                />
                                <div>
                                    In Progress ({taskHealthCounts["In Progress"] || 0})
                                </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center p-2" style={{ backgroundColor: selectedFilter === "Overdue In Progress" ? "#ECEFF4" : "", borderRadius: "4px", cursor: "pointer" }} onClick={() => handleFilterClick("Overdue In Progress")}>
                                <img
                                    src={`${apiUrl}/images/Icons/o-inprogress.svg`}
                                    alt="Overdue In Progress"
                                    style={{ width: "30px", height: "30px", marginRight: "10px" }}
                                />
                                <div>
                                    Overdue In Progress ({taskHealthCounts["Overdue In Progress"] || 0})
                                </div>
                            </div>
                        </Col>
                        <Col md={3} className="d-flex flex-column justify-content-center align-items-start" style={{ border: "2px solid #dcdcdc" }}>
                            <div className="d-flex justify-content-center align-items-center p-2" style={{ backgroundColor: selectedFilter === "Completed within time" ? "#ECEFF4" : "", borderRadius: "4px", cursor: "pointer" }} onClick={() => handleFilterClick("Completed within time")}>
                                <img
                                    src={`${apiUrl}/images/Icons/completed.svg`}
                                    alt="Completed within time Icon"
                                    style={{ width: "30px", height: "30px", marginRight: "10px" }}
                                />
                                <div>
                                    Completed within time ({taskHealthCounts["Completed within time"] || 0})
                                </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center p-2" style={{ backgroundColor: selectedFilter === "Delayed Completion" ? "#ECEFF4" : "", borderRadius: "4px", cursor: "pointer" }} onClick={() => handleFilterClick("Delayed Completion")}>
                                <img
                                    src={`${apiUrl}/images/Icons/d-completed.svg`}
                                    alt="Delayed Completion Icon"
                                    style={{ width: "30px", height: "30px", marginRight: "10px" }}
                                />
                                <div>
                                    Delayed Completion ({taskHealthCounts["Delayed Completion"] || 0})
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Collapse>

            <div className="d-flex align-items-center justify-content-between filter-buttons mb-3">
                <div className="d-flex align-items-center justify-content-start">
                    {filteredData.length} Tasks
                </div>
                <div className="d-flex align-items-center justify-content-end">
                    <Button
                        style={{ backgroundColor: selectedFilter === 'Not Started All' ? "#d8d8d8" : "#eceff4", border: "0", color: selectedFilter === 'Not Started All' ? "white" : "black" }}
                        onClick={() => handleFilterClick('Not Started All')}
                        className="me-2"
                    >
                        Not Started ({taskFilterGroups["Not Started All"]
                            .reduce((sum, status) => sum + (taskHealthCounts[status] || 0), 0)})
                    </Button>
                    <Button
                        style={{ backgroundColor: selectedFilter === 'In Progress All' ? "#f7cab3" : "#eceff4", border: "0", color: selectedFilter === 'In Progress All' ? "white" : "black" }}
                        onClick={() => handleFilterClick('In Progress All')}
                        className="me-2"
                    >
                        In Progress ({taskFilterGroups["In Progress All"]
                            .reduce((sum, status) => sum + (taskHealthCounts[status] || 0), 0)})
                    </Button>
                    <Button
                        style={{ backgroundColor: selectedFilter === 'Completed' ? "#c5e0b3" : "#eceff4", border: "0", color: selectedFilter === 'Completed' ? "white" : "black" }}
                        onClick={() => handleFilterClick('Completed')}
                        className="me-2"
                    >
                        Completed ({taskFilterGroups["Completed"]
                            .reduce((sum, status) => sum + (taskHealthCounts[status] || 0), 0)})
                    </Button>
                    <Button
                        style={{ backgroundColor: selectedFilter === 'All' ? "#bdd6ee" : "#eceff4", border: "0", color: selectedFilter === 'All' ? "white" : "black" }}
                        onClick={() => handleFilterClick('All')}
                        className="me-2"
                    >
                        All ({allTasks.length})
                    </Button>
                </div>
            </div>

            <DataTable
                filteredData={filteredData}
                Columns={taskColumns}
                csvReport={memoizedCsvReport}
                List={GetItems}
                globalSearch={""}
                fontSize={"13px"}
            />

            <Modal isOpen={modal} toggle={toggleModal} style={{ width: "50%", maxWidth: "50%" }}>

                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit()
                        return false;
                    }}
                >

                    <ModalHeader toggle={toggleModal}>Add Task Progress</ModalHeader>
                    <ModalBody>
                        {selectedTask ? (
                            <>
                                <Row>
                                    <Col lg={6}>
                                        <img
                                            src={`${apiUrl}${selectedTask.TaskIcon}`}
                                            alt="Task Type Icon"
                                            style={{ width: "30px", height: "30px", marginRight: "10px" }}
                                        />
                                        {selectedTask.TaskNumber}

                                        <p className="mt-3">{selectedTask.ProjectName}</p>
                                        <p className="mt-3">{selectedTask.TaskDescription ? selectedTask.TaskDescription : 'No Task Description'}</p>
                                        <p className="mt-3">{selectedTask.PercentCompleted ? `${selectedTask.PercentCompleted}% Completed` : 'No past progress'}</p>
                                    </Col>
                                    <Col lg={6}>
                                        <Row className="d-flex align-items-center">
                                            <Col lg={6}>
                                                <Label htmlFor="TaskProgressPercentage" className="col-form-label">
                                                    Percentage Complete
                                                </Label>
                                                <Select
                                                    id="TaskProgressPercentage"
                                                    name="TaskProgressPercentage"
                                                    options={percentageOptions}
                                                    value={
                                                        percentageOptions.find(
                                                            (option) => option.value == validation.values.TaskProgressPercentage
                                                        ) || null
                                                    }
                                                    onChange={(selectedOption) => handleWorkSelectChange('TaskProgressPercentage', selectedOption)}
                                                    placeholder="% Complete"
                                                    isSearchable={true}
                                                    classNamePrefix="react-select"
                                                    styles={selectStyles()}
                                                    isDisabled={Is100Percent}
                                                />
                                                {validation.touched.TaskProgressPercentage && validation.errors.TaskProgressPercentage ? (
                                                    <FormFeedback type="invalid" className="d-block">{validation.errors.TaskProgressPercentage}</FormFeedback>
                                                ) : null}
                                            </Col>
                                        </Row>

                                        <Row className="d-flex align-items-center">
                                            <Col lg={6}>
                                                <Label htmlFor="Hour" className="col-form-label">
                                                    Hours
                                                </Label>
                                                <Select
                                                    id="Hour"
                                                    name="Hour"
                                                    options={hourOptions}
                                                    onChange={(selectedOption) => handleWorkSelectChange('Hour', selectedOption)}
                                                    placeholder="Hour..."
                                                    isSearchable={true}
                                                    classNamePrefix="react-select"
                                                    styles={selectStyles()}
                                                />
                                                {validation.touched.Hour && validation.errors.Hour ? (
                                                    <FormFeedback type="invalid" className="d-block">{validation.errors.Hour}</FormFeedback>
                                                ) : null}
                                            </Col>
                                            <Col lg={6}>
                                                <Label htmlFor="Minute" className="col-form-label">
                                                    Minutes
                                                </Label>
                                                <Select
                                                    id="Minute"
                                                    name="Minute"
                                                    options={minuteOptions}
                                                    onChange={(selectedOption) => handleWorkSelectChange('Minute', selectedOption)}
                                                    placeholder="Minute..."
                                                    isSearchable={true}
                                                    classNamePrefix="react-select"
                                                    styles={selectStyles()}
                                                />
                                                {validation.touched.Minute && validation.errors.Minute ? (
                                                    <FormFeedback type="invalid" className="d-block">{validation.errors.Minute}</FormFeedback>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row className="d-flex align-items-start">

                                    <Col lg={3} className="mb-2">
                                        <Label htmlFor="TaskProgressComment" className="col-form-label">
                                            <strong>Comment (Optional):</strong>
                                        </Label>
                                    </Col>
                                    <Col lg={9} className="mb-2">
                                        <Input
                                            id="TaskProgressComment"
                                            name="TaskProgressComment"
                                            type="textarea"
                                            className="form-control validate"
                                            onChange={validation.handleChange}
                                            value={validation.values.TaskProgressComment}
                                        />
                                    </Col>
                                </Row>


                            </>
                        ) : (
                            <p>No details available</p>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">+Add</Button>
                        <Button color="secondary" onClick={toggleModal}>Close</Button>
                    </ModalFooter>

                </Form>
            </Modal>

            <Modal isOpen={mainModal} toggle={toggleMainModal} style={{ width: "50%", maxWidth: "50%" }}>
                <ModalBody>
                    <Row>
                        <div className="d-flex flex-row justify-content-between">
                            <div onClick={toggleMainModal} style={{ cursor: "pointer" }}>
                                <i className="mdi mdi-chevron-right"></i>
                                <i className="mdi mdi-chevron-right"></i>
                                <i className="mdi mdi-chevron-right"></i>
                            </div>
                            <div>
                                Status of EOD: {isToday ? (workReportData.length > 0 ? 'Submitted' : 'Pending') : (workReportData.length > 0 ? 'Submitted' : 'Not Sent')}
                            </div>
                        </div>
                    </Row>

                    <Row className="d-flex justify-content-between mt-3 mb-1">
                        <Col lg={6}>
                            <div>
                                Status report for {formattedDate} ({formattedDay})
                            </div>
                        </Col>
                        <Col lg={4} className="d-flex justify-content-between">
                            <div onClick={handlePrevDay} style={{ cursor: "pointer" }}>
                                <i className="mdi mdi-chevron-left"></i>Prev Day
                            </div>
                            <div 
                                onClick={!isFutureDate ? handleNextDay : undefined} 
                                style={{
                                    cursor: isFutureDate ? "not-allowed" : "pointer",
                                    color: isFutureDate ? "gray" : "inherit",
                                }}
                                >
                                Next Day<i className="mdi mdi-chevron-right"></i>
                            </div>
                        </Col>
                    </Row>

                    <Row className="d-flex justify-content-between mt-3 mb-1">
                        <div>Report of {userDetail.FirstName} {userDetail.LastName}</div>
                    </Row>

                    <Row className="d-flex justify-content-center align-items-center">
                        <Col lg={11}>
                            <div className="table-responsive mt-3">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Task</th>
                                            <th>Project</th>
                                            <th>% Complete</th>
                                            <th>Hours</th>
                                            {isToday && filteredDataArray?.length <= 0 && <th>Action</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isToday
                                            ? filteredDataArray?.length > 0
                                                ? filteredDataArray.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <div style={{ display: "flex", alignItems: "start" }}>
                                                                <div>
                                                                    <div>
                                                                        {item.TaskTitle.length > 20
                                                                            ? `${item.TaskTitle.substring(0, 20)}...`
                                                                            : item.TaskTitle}
                                                                    </div>
                                                                    <div style={{ fontSize: "11px" }}>
                                                                        {item.TaskNumber}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div style={{ display: "flex", alignItems: "start", justifyContent: "start" }}>
                                                                {item.ProjectName}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                                                {item.TaskProgressPercentage}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                                                {item.HoursConsumed}
                                                            </div>
                                                        </td>
                                                        {isToday && filteredDataArray?.length <= 0 && (
                                                            <td className="d-flex justify-content-center align-items-center">
                                                                <i
                                                                    className="fas fa-times"
                                                                    style={{
                                                                        border: '1px solid red',
                                                                        padding: '3px 5px',
                                                                        borderRadius: '50%',
                                                                        cursor: 'pointer',
                                                                        color: "red"
                                                                    }}
                                                                    onClick={() => handleRemoveWorkReport(item.TaskID)}
                                                                />
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))
                                                // : filteredDataToday?.length > 0
                                                //     ? filteredDataToday.map((item, index) => (
                                                : filteredDataTodayRedux?.length > 0
                                                    ? filteredDataTodayRedux.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <div style={{ display: "flex", alignItems: "start" }}>
                                                                    <div>
                                                                        <div>
                                                                            {item.TaskTitle.length > 20
                                                                                ? `${item.TaskTitle.substring(0, 20)}...`
                                                                                : item.TaskTitle}
                                                                        </div>
                                                                        <div style={{ fontSize: "11px" }}>
                                                                            {item.TaskNumber}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div style={{ display: "flex", alignItems: "start", justifyContent: "start" }}>
                                                                    {item.ProjectName}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                                                    {item.TaskProgressPercentage}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                                                    {item.HoursConsumed}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <i
                                                                        className="fas fa-times"
                                                                        style={{
                                                                            border: '1px solid red',
                                                                            padding: '3px 5px',
                                                                            borderRadius: '50%',
                                                                            cursor: 'pointer',
                                                                            color: "red"
                                                                        }}
                                                                        onClick={() => handleRemoveWorkReport(item.TaskID)}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    : (
                                                        <tr>
                                                            <td colSpan="5" className="text-center">
                                                                No Data Available for Today
                                                            </td>
                                                        </tr>
                                                    )
                                            : filteredDataArray?.length > 0
                                                ? filteredDataArray.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <div style={{ display: "flex", alignItems: "start" }}>
                                                                <div>
                                                                    <div>
                                                                        {item.TaskTitle.length > 20
                                                                            ? `${item.TaskTitle.substring(0, 20)}...`
                                                                            : item.TaskTitle}
                                                                    </div>
                                                                    <div style={{ fontSize: "11px" }}>
                                                                        {item.TaskNumber}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div style={{ display: "flex", alignItems: "start", justifyContent: "start" }}>
                                                                {item.ProjectName}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                                                {item.TaskProgressPercentage}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                                                {item.HoursConsumed}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                                : (
                                                    <tr>
                                                        <td colSpan="5" className="text-center">
                                                            No Data Available for Selected Date
                                                        </td>
                                                    </tr>
                                                )
                                        }
                                        {filteredDataArray?.length > 0 &&
                                            <tr>
                                                <td colSpan="3" className="text-right"><strong>Total Hours</strong></td>
                                                <td colSpan="1">
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <strong>
                                                            {filteredDataArray?.reduce((total, item) => total + item.HoursConsumed, 0)}
                                                        </strong>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                        {/* {filteredDataToday?.length > 0 && isToday && */}
                                        {filteredDataTodayRedux?.length > 0 && isToday &&
                                            <tr>
                                                <td colSpan="3" className="text-right"><strong>Total Hours</strong></td>
                                                <td colSpan="1">
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <strong>
                                                            {/* {filteredDataToday?.reduce((total, item) => total + item.HoursConsumed, 0)} */}
                                                            {filteredDataTodayRedux?.reduce((total, item) => total + item.HoursConsumed, 0)}
                                                        </strong>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>

                    <Row className="d-flex justify-content-center align-items-center">
                        <Col lg={11}>
                            <Row className="d-flex align-items-start">

                                <Col lg={4} className="mb-2">
                                    <Label htmlFor="Obstacles" className="col-form-label">
                                        <strong>Obstacles (if any):</strong>
                                    </Label>
                                </Col>
                                <Col lg={8} className="mb-2">
                                    <Input
                                        id="Obstacles"
                                        name="Obstacles"
                                        type="textarea"
                                        className="form-control validate"
                                        value={inputTaskObstacles || ""}
                                        onChange={(e) => setInputTaskObstacles(e.target.value)}
                                        disabled={
                                            !(isToday && workReportData.length === 0)
                                        }
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {isToday && workReportData.length === 0 && (
                        <Button color="primary"
                            onClick={handleFinalSubmit}
                            disabled={
                                // (filteredDataToday.length === 0)
                                (filteredDataTodayRedux.length === 0)
                            }
                            type="button"
                        >Submit</Button>
                    )}
                    <Button color="secondary" onClick={toggleMainModal}>Close</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => ({
    showTaskGetList: state.Task?.showTaskGetList,
});

export default connect(mapStateToProps, { setShowTaskGetList })(AllEOD);
