export const AddUpdateWorkReport = (workReportPayload) => {
    return {
        type: "ADD_UPDATE_WORK_REPORT",
        payload: { workReportPayload },
    };
};

export const AddUpdateWorkReportSuccess = (workReportPayload) => {
    return {
        type: "ADD_UPDATE_WORK_REPORT_SUCCESS",
        payload: workReportPayload,
    };
};

export const setShowWorkReportGetList = (showWorkReportGetList) => {
    return {
        type: "SET_SHOW_WORK_REPORT_LIST",
        payload: showWorkReportGetList,
    };
};

export const GetWorkReportByDate = (date, user) => {
    return {
        type: "GET_WORK_REPORT_BY_DATE",
        payload: { date, user },
    };
};

export const GetWorkReportByDateSuccess = (taskPayload) => {
    return {
        type: "GET_WORK_REPORT_BY_DATE_SUCCESS",
        payload: taskPayload,
    };
};

export const WorkReportAPIError = (error) => {
    return {
        type: "WORK_REPORT_API_ERROR",
        payload: error,
    };
};

export const setFilteredDataTodayRedux = (data) => {
    return {
        type: "SET_FILTERED_DATA_TODAY_REDUX",
        payload: data,
    };
};

export const clearFilteredDataTodayRedux = () => {
    return {
        type: "CLEAR_FILTERED_DATA_TODAY_REDUX",
    };
};
