const initialState = {
  error: "",
  loading: false,
  Response: [],
  OrderList: [],
  ShowOrderGetList: false,
}

const Order = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SHOW_ORDER_LIST":
      return {
        ...state,
        ShowOrderGetList: action.payload,
      }

    case "GET_ORDER_LIST":
      return {
        ...state,
        loading: false,
        error: "",
      }

    case "GET_ORDER_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        Response: action.payload, // Store the fetched
        error: "",
      }

    default:
      return state
  }
}

export default Order
