import React, { useState, useMemo, useEffect, useRef } from "react"
import {
  Row,
  Col,
  Label,
  FormFeedback,
  Form,
  Card,
  UncontrolledTooltip,
  Table,
  CardBody,
  FormGroup,
  CardTitle,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import Select from "react-select"
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { connect, useDispatch, useSelector } from "react-redux"
import { GetMultipleDropdown } from "../../store/actions"
import { setBreadcrumbItems, setShowItemGetList } from "../../store/actions"
import {
  AddUpdateQuotation,
  setShowQuotationGetList,
  GetSelectedItemDetails,
  GetQuotationNumber,
  GetQuotationItemList,
  ClearQuotation,
  ClearQuotationById,
  GetQuotationById,
  GetItemdropdownQuotation,
  DeleteProductQuotationById,
} from "../../store/Quotation/actions"
import { useNavigate, useParams } from "react-router-dom"
import { isUndefined } from "lodash"
import * as url from "../../helpers/url_helper"
import { select } from "redux-saga/effects"
import Spinners from "components/Common/Spinner"
import { error } from "toastr"
import Quotationprint from "./QuotationPrint"
import jsPDF from "jspdf"
import html2canvas from "html2canvas";
import { toast } from "react-toastify";

const apiAppUrl = url

const AddQuotaion = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { Id } = useParams()
  const { Type } = useParams()
  const [itemSubCategories, setitemSubCategories] = useState([])
  const [itemTypeList1, setitemTypeList1] = useState([])
  const [itemTypeList2, setitemTypeList2] = useState([])
  const [allItemsList, setAllItemsList] = useState([])
  const [filteredAllItemList, setFilteredAllItemList] = useState([])
  const [SelectedItemforSearch, setSelectedItemforSearch] = useState([])
  const [isDataReady, setIsDataReady] = useState(false)

  const itemCategories =
    useSelector(state => state.CommonAPI?.itemCategories?.data) || []
  const itemTypeList3 =
    useSelector(state => state.CommonAPI?.itemType3?.data) || []
  const Dimensionslist =
    useSelector(state => state.CommonAPI?.unitList?.data) || []

  const [Organizations, setOrganizations] = useState([])
  const [Parties, setParties] = useState([])
  const [Payment, setPayment] = useState([])
  const [AddressTypes, setAddressTypes] = useState([])
  const SelectedItem =
    useSelector(state => state.Quotation?.Response?.data) || []
  const QuotationNumber =
    useSelector(state => state.Quotation?.QuotationNumber?.data) || []
  const QuotationItemList =
    useSelector(state => state.Quotation?.QuotationItemList?.data) || []
  const QuotationById =
    useSelector(state => state.Quotation?.QuotationById?.data) || []
  const token = useSelector(state => state.Login?.token) || ""

  const multipleDropdownList =
    useSelector(state => state.CommonAPI?.multipleDropdownValues?.data) || []

  const [charges, setCharges] = useState([]) // To hold the list of charges
  const [chargeType, setChargeType] = useState("") // State for charge type
  const [chargeAmount, setChargeAmount] = useState("") // State for charge amount
  const [editIndex, setEditIndex1] = useState(null)
  const [errors, setErrors] = useState({})
  useEffect(() => {
    if (multipleDropdownList.length > 0) {
      const PartyList = multipleDropdownList.filter(
        item => item.StringMapType === "PartyList",
      )

      setParties(PartyList)

      const mappedData = {
        OrganizationList: setOrganizations,
        PAYMENT: setPayment,
        SHIPPINGADDRESSTYPE: setAddressTypes,
      }

      Object.keys(mappedData).forEach(key => {
        const filteredList = multipleDropdownList
          .filter(item => item.StringMapType === key)
          .map(data => ({
            StringMapName: data.StringMapName,
            StringMapId: data.StringMapId,
          }))

        mappedData[key](filteredList)
      })
      setIsDataReady(true)
    }
  }, [multipleDropdownList])

  const [showDefaultAddress, setShowDefaultAddress] = useState(false)
  const [modal, setModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState("")
  const [SelectedPaymentType, setSelectedPaymentType] = useState([])

  const [isLoading1, setIsLoading1] = useState(true)

  const toggle = () => setModal(!modal)
  var updatedRows = []
  const [phoneEntries, setPhoneEntries] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [ReadyOnly, setReadyOnly] = useState(false)
  const handleCancel = () => {
    document.getElementById("ItemError").textContent = ""
    validationItem.resetForm()
    setFormData({
      item: null,
      SubType: null,
      Type1: null,
      Type2: null,
      Type3: null,
      Dimensions: null,
    })
    toggle()
  }
  const [rows, setRows] = useState([
    {
      srno: 1,
      product: "",
      hsn: "",
      qty: 0,
      rate: 0,
      per: "",
      disc: 0,
      amount: 0,
    },
  ])
  const [formData, setFormData] = useState({
    item: null,
    SubType: null,
    Type1: null,
    Type2: null,
    Type3: null,
    Dimensions: null,
  })
  const [tableData, setTableData] = useState([])
  const [filteritemSubType, setfilteritemSubType] = useState([])
  const [filteritemTypeList1, setfilteritemTypeList1] = useState([])
  const [filteritemTypeList2, setfileritemTypeList2] = useState([])
  const [filteritemTypeList3, setfileritemTypeList3] = useState([])
  const [filteritemuom, setfilteritemuom] = useState([])
  const [isEditable, setIsEditable] = useState(false)

  const [sendToNumber, setSendToNumber] = useState("");
  const [whatsappMessage, setWhatsappMessage] = useState("")
  const [sendingLoading, setSendingLoading] = useState(false);
  const [partyPhone, setPartyPhone] = useState("")

  const [sendToEmail, setSendToEmail] = useState("");
  const [emailMessage, setEmailMessage] = useState("")
  const [emailSubject, setEmailSubject] = useState("Quotation from Comfort Zone")
  const [partyEmail, setPartyEmail] = useState("")

  const [downloadNm, setDownloadNm] = useState("DUPLICATE")

  const downloadNmOptions = [
    { label: "DUPLICATE"},
    { label: "ORIGINAL"},
    { label: "TRIPLICATE"},
  ]

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  }

  const breadcrumbItems = useMemo(
    () => [{ title: "Comfort Zone" }, { title: "Quotation" }, { title: Type }],
    [],
  )

  const printRef = useRef(null)

  const downloadPDF = async () => {
    const element = printRef.current;

    if (!element) {
      console.error("Unable to find Quotationprint content.");
      return;
    }

    try {
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("quotation.pdf");
    } catch (error) {
      toast.error("Error generating PDF");
      console.error("Error generating PDF:", error);
    }
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      if (!partyPhone) {
        toast.error("No number found. Please enter a number first.");
        return;
      }

      if (!/^\d{10}$/.test(partyPhone)) {
        toast.error("Invalid Party number found.");
        return;
      }

      setSendToNumber(partyPhone);
    } else {
      setSendToNumber("");
    }
  };

  const sendPDFViaWhatsApp = async () => {
    const element = printRef.current;

    if (!sendToNumber || !/^\d{10}$/.test(sendToNumber)) {
      toast.error("Please enter a valid 10-digit phone number.");
      return;
    }
  
    if (!element) {
      console.error("Unable to find Quotationprint content.");
      toast.error("Unable to find Quotation print content.");
      return;
    }
  
    setSendingLoading(true);

    try {
      const canvas = await html2canvas(element, {
        scale: 1,
        useCORS: true,
      });
  
      const imgData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  
      const pdfBlob = pdf.output("blob");
  
      const formData = new FormData();
      formData.append("MessageType", "WhatsApp");
      formData.append("Attachments", new File([pdfBlob], "quotation.pdf", { type: "application/pdf" }));
      formData.append("SenderProfileID", "123e4567-e89b-12d3-a456-426614174003");
      formData.append("WhatsAppTemplateName", "Quotation Whatsapp Send");
      formData.append("ToEmailOrMobileOrToken", sendToNumber);
      formData.append("Body", whatsappMessage);
  
      const response = await fetch("https://autotaskapi.cognisun.net/api/autotask/InsertUpdateMessageQueue", {
        method: "POST",
        headers: {
          Authorization: `Bearer XyZ1234AbC5678DeFgHi9012JkLmNoPq`,
        },
        body: formData,
      });
  
      if (response.ok) {
        toast.success("PDF sent via WhatsApp successfully!");
        setSendToNumber("");
        setWhatsappMessage("");
        setWhatsappModel(!whatsappModel);
      } else {
        const error = await response.json();
        console.error("Failed to send PDF via WhatsApp:", error);
        toast.error("Failed to send PDF via WhatsApp.");
      }
    } catch (error) {
      console.error("Error generating or sending PDF:", error);
      toast.error("Error generating or sending PDF");
    } finally {
      setSendingLoading(false);
    }
  };

  const sendPDFViaEmail = async () => {
    const element = printRef.current;

    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
  
    if (!sendToEmail || !validateEmail(sendToEmail)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    if (!emailSubject) {
      toast.error("Please enter a email subject");
      return;
    }

    if (!element) {
      console.error("Unable to find Quotation print content.");
      toast.error("Unable to find Quotation print content.");
      return;
    }
  
    setSendingLoading(true);

    try {
      const canvas = await html2canvas(element, {
        scale: 1,
        useCORS: true,
      });
  
      const imgData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  
      const pdfBlob = pdf.output("blob");
  
      const formData = new FormData();
      formData.append("MessageType", "Email");
      formData.append("Attachments", new File([pdfBlob], "quotation.pdf", { type: "application/pdf" }));
      formData.append("SenderProfileID", "123e4567-e89b-12d3-a456-426614174002");
      formData.append("WhatsAppTemplateName", "Quotation Whatsapp Send");
      formData.append("Subject", emailSubject)
      formData.append("ToEmailOrMobileOrToken", sendToEmail);
      formData.append("Body", emailMessage);
  
      const response = await fetch("https://autotaskapi.cognisun.net/api/autotask/InsertUpdateMessageQueue", {
        method: "POST",
        headers: {
          Authorization: `Bearer XyZ1234AbC5678DeFgHi9012JkLmNoPq`,
        },
        body: formData,
      });
  
      if (response.ok) {
        toast.success("PDF sent via Email successfully!");
        setSendToEmail("");
        setEmailMessage("");
        setSendEmailModel(!sendEmailModel);
        setEmailSubject("Quotation from Comfort Zone");
      } else {
        const error = await response.json();
        console.error("Failed to send PDF via Email:", error);
        toast.error("Failed to send PDF via Email.");
      }
    } catch (error) {
      console.error("Error generating or sending PDF:", error);
      toast.error("Error generating or sending PDF");
    } finally {
      setSendingLoading(false);
    }
  };

  useEffect(() => {
    dispatch(setBreadcrumbItems("Quotation", breadcrumbItems))
  }, [dispatch, breadcrumbItems])

  // const items = itemCategories.filter(category =>
  //   QuotationItemList.some(item => item.Category === category.StringMapId),
  // )
  const items = QuotationItemList?.map(item => ({
    StringMapName: item.ItemCategoryName,
    StringMapId: item.Category,
  }))
  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSelect = () => {
    setTableData([...tableData, formData])
    toggle()
  }

  // const [charges, setCharges] = useState([]);
  // const [chargeType, setChargeType] = useState('');
  // const [chargeAmount, setChargeAmount] = useState('');
  // const [editIndex, setEditIndex] = useState(null); // To track editing
  // const [errors, setErrors] = useState({});

  const handleEditCharge = index => {
    const charge = charges[index]
    setChargeType(charge.type)
    setChargeAmount(charge.amount)
    setEditIndex1(index)
  }
  const handleDeleteCharge = index => {
    const updatedCharges = charges.filter((_, i) => i !== index)
    setCharges(updatedCharges)
    const totalCharges = updatedCharges.reduce(
      (acc, charge) => acc + parseFloat(charge.amount || 0),
      0,
    )

    const updatedOptions = { ...FinalCalculate }
    updatedOptions.FinalAmount = (
      parseFloat(updatedOptions.RoundOff || 0) +
      parseFloat(updatedOptions.TransportationCharges || 0.0) +
      parseFloat(updatedOptions.AdditionalCharges || 0.0) +
      totalCharges
    ).toFixed(2)

    setFinalCalculate(updatedOptions)
  }

  const handleAddOrUpdateCharge = () => {
    if (!validateFields()) return

    const newCharge = { type: chargeType, amount: chargeAmount }
    debugger
    if (editIndex !== null) {
      // Update the existing charge
      const updatedCharges = [...charges]
      updatedCharges[editIndex] = newCharge
      setCharges(updatedCharges)
      setEditIndex1(null) // Reset edit mode
      updatefinalAmount(newCharge.type, newCharge.amount)
    } else {
      // Add a new charge
      setCharges([...charges, newCharge])
      updatefinalAmount(newCharge.type, newCharge.amount)
    }

    // Reset fields
    setChargeType("")
    setChargeAmount("")
    setErrors({})
  }

  const validateFields = () => {
    const newErrors = {}
    if (!chargeType.trim()) newErrors.chargeType = "Charge Type is required"
    if (!chargeAmount || chargeAmount < 0)
      newErrors.chargeAmount = "Charge Amount must be a positive value"
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }
  const [FinalCalculate, setFinalCalculate] = useState({
    RoundOff: "",
    SubTotal: 0,
    Discount: "",
    TransportationCharges: 0.0,
    AdditionalCharges: 0.0,
    FinalAmount: "",
    SpecialInstructions: "",
  })

  const [selectedOptions, setSelectedOption] = useState({
    QuotationId: "",
    QuotationNumber: QuotationNumber?.[0]?.QuotationNumber,
    QuotationFromCompany: null,
    Party: null,
    Payment: null,
    SpecialInstructions: "",
    RoundOff: "",
    SubTotal: 0,
    Discount: "",
    TransportationCharges: "",
    AdditionalCharges: "",
    FinalAmount: "",
    ShipToAddress: null,
    addressId: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    PostalCode: "",
  })

  const [printModel, setPrintModel] = useState(false)
  const [whatsappModel, setWhatsappModel] = useState(false)
  const [sendEmailModel, setSendEmailModel] = useState(false)

  const handlePrintPreview = () => {
    setPrintModel(true)
  }

  const handleEditRow = index => {
    setRows(
      rows.map((row, i) =>
        i === index ? { ...row, editable: !row.editable } : row,
      ),
    )
  }

  const handleSaveRow = (index, field, value) => {
    setRows(
      rows.map((row, i) =>
        i === index ? { ...row, [field]: value, editable: false } : row,
      ),
    )
  }

  const selectedPartyDetails = Parties.find(
    party => party.StringMapId === selectedOptions.Party?.value,
  )

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      QuotationId: selectedOptions?.QuotationId || "",
      QuotationFromCompany: selectedOptions.QuotationFromCompany?.value || null,
      Party: selectedOptions.Party?.value || null,
      QuotationNumber: selectedOptions.QuotationNumber, 
      ShipToAddress: "",
      addressType: selectedOptions.value || "",
      addressId: selectedOptions?.addressId || "",
      addressLine1: selectedOptions?.addressLine1 || "",
      addressLine2: selectedOptions?.addressLine2 || "",
      city: selectedOptions?.city || "",
      state: selectedOptions?.state || "",
      PostalCode: selectedOptions?.PostalCode || "",
    },
    validationSchema: Yup.object({
      QuotationFromCompany: Yup.string().required(
        "Please Select Quotation From Company",
      ),
      Party: Yup.string().required("Please Select Party"),
      // Payment:Yup.string().required("Please Select Payment Terms"),
      ShipToAddress: Yup.string().required(
        "Please Select Shipping Address Type",
      ),
      addressLine1: Yup.string().required("Please Enter Address Line 1"),
      addressLine2: Yup.string().required("Please Enter Address Line 2"),
      city: Yup.string().required("Please Enter City"),
      state: Yup.string().required("Please Enter State"),
      PostalCode: Yup.string()
        .required("Please Enter Postal Code")
        .matches(/^\d+$/, "Postal Code must contain only digits")
        .max(6, "Postal Code cannot exceed 6 digits")
        .min(6, "Postal Code must be exactly 6 digits"),
    }).test(
      "product-or-amount-validation",
      "Please select at least one product",
      function (values) {
        const { phoneEntries = [], FinalCalculate = {} } = values
        return phoneEntries.length > 0 || FinalCalculate.FinalAmount !== ""
      },
    ),

    onSubmit: async values => {
      if (
        phoneEntries.length > 0 &&
        FinalCalculate.FinalAmount !== "" &&
        phoneEntries[phoneEntries.length - 1].itemlabel !== undefined &&
        phoneEntries[phoneEntries.length - 1].amount !== undefined &&
        phoneEntries[phoneEntries.length - 1].amount !== "0.00" &&
        phoneEntries[phoneEntries.length - 1].amount !== 0
      ) {
        const filteredEntries = phoneEntries.filter(
          entry => entry.amount !== "0.00" || entry.amount !== 0,
        )

        const payload = {
          ...values,
          Payment: SelectedPaymentType?.value || null,
          PaymentTerms: SelectedPaymentType?.value || null,
          SpecialInstructions: FinalCalculate.SpecialInstructions || "",
          RoundOff: FinalCalculate.RoundOff || "",
          SubTotal: FinalCalculate.SubTotal || "",
          Discount: FinalCalculate.Discount || "",
          TransportationCharges: FinalCalculate.TransportationCharges || "",
          AdditionalCharges: FinalCalculate.AdditionalCharges || "",
          FinalAmount: FinalCalculate.FinalAmount || "",
          QuotationProductsDetail: filteredEntries,
          // Charges: charges,
        }

        dispatch(AddUpdateQuotation(payload))
        if (Id) {
          setTimeout(() => {
            navigate("/QuotationList")
          }, 1000)
        }
      } else if (
        phoneEntries.length === 0 &&
        FinalCalculate.FinalAmount === ""
      ) {
        document.getElementById("quantityError").textContent =
          "Please select at list one product"
      } else if (
        FinalCalculate.FinalAmount == "" &&
        FinalCalculate.SubTotal == 0
      ) {
        document.getElementById("quantityError").textContent =
          "Amount should not be zero"

        return
      } else if (
        phoneEntries[phoneEntries.length - 1].itemlabel === undefined
      ) {
        document.getElementById("quantityError").textContent =
          "Please select the product"
        return
      } else {
        document.getElementById("quantityError").textContent =
          "Please enter valid details"
        return
      }
    },
  })

  const validationItem = useFormik({
    enableReinitialize: true,

    initialValues: {
      item: SelectedItemforSearch?.value || null,
      Type1: null,
      Type2: null,
      Dimensions: null,
      SubType: null,
      Type3: null,
    },
    validationSchema: Yup.object({
      item: Yup.string().required("Please Select item"),
      // Type1: Yup.string().required("Please Select Type 1"),
      // Type2: Yup.string().required("Please Select Type2"),
      // Dimensions: Yup.string().required("Please Select Dimensions")
    }),
    onSubmit: async values => {
      document.getElementById("quantityError").textContent = ""

      axios
        .post(`${url.API_URL}/quotation/GetSelectedItemDetails`, values, config)
        .then(response => {
          if (response.status === 200) {
            const SelectedItem = response.data.data
            const itemExists = phoneEntries.some(
              entry => entry.ItemId === SelectedItem[0].ItemId,
            )

            if (itemExists) {
              document.getElementById("ItemError").textContent =
                "Please Select Another Product"
              // alert("this item is already selected")
              setFormData({
                item: null,
                SubType: null,
                Type1: null,
                Type2: null,
                Type3: null,
                Dimensions: null,
              })
              return
            }

            const Itemlabel =
              formData.item?.label +
              (formData.Type1?.label ? " - " + formData.Type1?.label : "") +
              (formData.Type2?.label ? " - " + formData.Type2?.label : "") +
              (formData.Dimensions?.label
                ? " - " + formData.Dimensions?.label
                : "")
            const updatedEntries = [
              ...phoneEntries.slice(0, phoneEntries.length - 1).map(entry => ({
                ...entry,
                isEditable: entry.isEditable || false,
                // Ensure existing entries also have the field
              })),

              { ...SelectedItem[0], isEditable: true, itemlabel: Itemlabel },
            ]

            setPhoneEntries(updatedEntries)
            setModal(false)
            setFormData({
              item: null,
              SubType: null,
              Type1: null,
              Type2: null,
              Type3: null,
              Dimensions: null,
            })
          }
        })
    },

    validateOnChange: true,
  })
  const handleDropdownChange = (selectedOption, name) => {
    setSelectedOption(prevState => {
      const newState = {
        ...prevState,
        [name]: selectedOption,
      }
      // Check if the selected option is "ShipToAddress" and has label "Same as Bill To"
      if (
        name === "ShipToAddress" &&
        selectedOption.label === "Same as Bill To"
      ) {
        setShowDefaultAddress(true)
        validation.setValues({
          ...validation.values,
          addressId: selectedPartyDetails?.AddressId || "",
          addressLine1: selectedPartyDetails?.AddrLine1 || "",
          addressLine2: selectedPartyDetails?.AddrLine2 || "",
          city: selectedPartyDetails?.City || "",
          state: selectedPartyDetails?.State || "",
          PostalCode: selectedPartyDetails?.PostalCode || "",
        })
      } else if (name === "ShipToAddress") {
        setShowDefaultAddress(false)
        validation.setValues({
          ...validation.values,
          addressId: "",
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          PostalCode: "",
        })
      }

      return newState
    })
  }
  const handlePaymentChange = (input, name) => {
    setSelectedPaymentType(input)

    if (name === "Payment") {
      setSelectedPaymentType(input)
    }
  }
  const handleOnChange = (type, value) => {
    if (type === "specialInstruction") {
      selectedOptions.SpecialInstructions = value
    }
  }
  // Save the new value and exit edit mode
  const saveEdit = index => {
    const updatedRows = phoneEntries.map((row, i) =>
      i === index
        ? { ...row, isEditable: !row.isEditable, originalRate: undefined }
        : row,
    )

    setPhoneEntries(updatedRows)
  }

  // Cancel edit and revert to the original value
  const cancelEdit = index => {
    const updatedRows = phoneEntries.map((row, i) =>
      i === index
        ? {
          ...row,
          isEditable: !row.isEditable,
          rate: row.originalRate,
          originalRate: undefined,
        }
        : row,
    )
    updateRow(index, "rate", Number(updatedRows[index].rate))
    setPhoneEntries(updatedRows)
    // updateRow(index, 'rate', Number(updatedRows[index].rate))
  }
  const setEditIndex = index => {
    const updatedRows = phoneEntries.map((row, i) =>
      i === index
        ? { ...row, isEditable: !row.isEditable, originalRate: row.rate }
        : { ...row, isEditable: row.isEditable },
    )
    setPhoneEntries(updatedRows)
  }

  const deleteModel = id => {
    setSelectedProduct(id)
    setShowDeleteModal(true)
  }
  const deleteProduct = () => {
    let id = selectedProduct
    // setShowDeleteModal(true)
    // setSelectedProduct(updatedRows.QuotationProductsDetailId)
    const updatedRows = phoneEntries.filter((_, index) => index === id)

    if (updatedRows[0].QuotationProductsDetailId) {
      let input = {
        QuotationProductsDetailId: updatedRows[0].QuotationProductsDetailId,
      }
      axios
        .post(
          `${url.API_URL}/quotation/DeleteQuotationProductById`,
          input,
          config,
        )
        .then(response => {
          if (response.status === 200) {
            handleRemove(id)
          }
        })
    } else {
      handleRemove(id)
    }
  }

  const handleRemove = id => {
    const updatedRows = phoneEntries.filter((_, index) => index !== id)
    // const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows)
    setPhoneEntries(updatedRows)

    const finalTotal = updatedRows.reduce(
      (total, row) => total + parseFloat(row.amount || 0),
      0,
    )
    FinalCalculate.SubTotal = finalTotal
    FinalCalculate.RoundOff = Math.round(finalTotal)
    // setSubTotal(finalTotal)
    // setRoundOff(Math.round(finalTotal))
    updatedRows.reduce((total, row) => total + parseFloat(row.amount || 0), 0)
    const AverageDiscount =
      calculateAverageDiscount(updatedRows).toFixed(2) || 0
    FinalCalculate.Discount = AverageDiscount || 0
    // setDiscount(AverageDiscount || 0)
    debugger
    const FinalAmount = (
      (FinalCalculate.RoundOff || 0) +
      (FinalCalculate.TransportationCharges || 0) +
      (FinalCalculate.AdditionalCharges || 0)
    ).toFixed(2)
    // const newFinalAmount = ((RoundOff || 0) + (TransportationCharges || 0)).toFixed(2)

    FinalCalculate.FinalAmount = FinalAmount
    // setFinalAmount(newFinalAmount)
    setShowDeleteModal(false)
  }

  const ProductOnchange = async Id => {
    setIsLoading(true)
    const input = { item: Id?.value ? Id?.value : SelectedItemforSearch?.value }

    axios
      .post(`${url.API_URL}/quotation/GetItemdropdownQuotation`, input, config)
      .then(response => {
        // const response = await dispatch(GetItemdropdownQuotation(input))

        if (response.status === 200) {
          const itemType1List = []
          const itemType2List = []
          const itemType3List = []
          const DimensionsList = []
          const subCategoryList = []
          let data = response.data.data
          data.forEach(item => {
            if (item.ItemType1Id && item.ItemType1Name) {
              // Check for duplicates in itemType1List
              if (
                !itemType1List.find(sub => sub.StringMapId === item.ItemType1Id)
              ) {
                itemType1List.push({
                  StringMapId: item.ItemType1Id,
                  CategoryId: item.Category,
                  StringMapName: item.ItemType1Name,
                  ItemType2Id: item.ItemType2Id,
                  ItemType3Id: item.ItemType3Id,
                  Dimensions: item.Dimentions,
                  SubCategoryId: item.SubCategoryId,
                })
              }
            }

            if (item.ItemType2Id && item.ItemType2Name) {
              // Check for duplicates in itemType2List
              if (
                !itemType2List.find(sub => sub.StringMapId === item.ItemType2Id)
              ) {
                itemType2List.push({
                  StringMapId: item.ItemType2Id,
                  CategoryId: item.Category,
                  StringMapName: item.ItemType2Name,
                  ItemType1Id: item.ItemType1Id,
                  ItemType3Id: item.ItemType3Id,
                  Dimensions: item.Dimentions,
                  SubCategoryId: item.SubCategoryId,
                })
              }
            }

            if (item.ItemType3Id && item.ItemType3Name) {
              // Check for duplicates in itemType3List
              if (
                !itemType3List.find(sub => sub.StringMapId === item.ItemType3Id)
              ) {
                itemType3List.push({
                  StringMapId: item.ItemType3Id,
                  CategoryId: item.Category,
                  StringMapName: item.ItemType3Name,
                  ItemType2Id: item.ItemType2Id,
                  ItemType1Id: item.ItemType1Id,
                  Dimensions: item.Dimentions,
                  SubCategoryId: item.SubCategoryId,
                })
              }
            }
            if (item.Dimentions) {
              // Check for duplicates in itemType3List
              if (
                !DimensionsList.find(sub => sub.StringMapId === item.Dimentions)
              ) {
                DimensionsList.push({
                  StringMapId: item.ItemId,
                  CategoryId: item.Category,
                  StringMapName: item.Dimentions,
                  ItemType1Id: item.ItemType1Id,
                  ItemType2Id: item.ItemType2Id,
                  ItemType3Id: item.ItemType3Id,
                  Dimensions: item.Dimentions,
                  SubCategoryId: item.SubCategoryId,
                })
              }
            }
            if (item.SubCategoryId && item.SubCategoryName) {
              // Check if the SubCategoryId already exists in subCategoryList
              if (
                !subCategoryList.find(
                  sub => sub.StringMapId === item.SubCategoryId,
                )
              ) {
                subCategoryList.push({
                  StringMapId: item.SubCategoryId,
                  CategoryId: item.Category,
                  StringMapName: item.SubCategoryName,
                  ItemType1Id: item.ItemType1Id,
                  ItemType2Id: item.ItemType2Id,
                  ItemType3Id: item.ItemType3Id,
                })
              }
            }
          })

          setAllItemsList(data)
          setFilteredAllItemList(data)
          setitemSubCategories(subCategoryList)
          setitemTypeList1(itemType1List)
          setitemTypeList2(itemType2List)

          setfilteritemSubType(subCategoryList)
          setfilteritemTypeList1(itemType1List)
          setfileritemTypeList2(itemType2List)
          setfileritemTypeList3(itemType3List)
          setfilteritemuom(DimensionsList)

          // itemSubCategories = subCategoryList
          setModal(true)
          setIsLoading(false)
        }
      })
  }

  const handleChangeItem = (selectedOption, name) => {
    if (name === "item") {
      setSelectedItemforSearch(selectedOption)
      const newitemSubCategories = itemSubCategories.filter(
        item => item.CategoryId === selectedOption.value,
      )
      const newitemTypeList1 = itemTypeList1.filter(
        item => item.CategoryId === selectedOption.value,
      )
      const newitemTypeList2 = itemTypeList2.filter(
        item => item.CategoryId === selectedOption.value,
      )
      const newitemTypeList3 = itemTypeList3.filter(
        item => item.CategoryId === selectedOption.value,
      )
      const newitemUOM = Dimensionslist.filter(
        item => item.CategoryId === selectedOption.value,
      )
      setfilteritemSubType(newitemSubCategories)
      setfilteritemTypeList1(newitemTypeList1)
      setfileritemTypeList2(newitemTypeList2)
      setfileritemTypeList3(newitemTypeList3)
      setfilteritemuom(newitemUOM)
      // document.getElementById("ItemError").textContent = ""
    } else if (name === "SubType") {
      const newitemTypeList1 = filteredAllItemList.filter(
        x => x?.SubCategoryId === selectedOption.value,
      )
      setfilteritemTypeList1(newitemTypeList1)
      setFilteredAllItemList(newitemTypeList1)
    } else if (name === "Type1") {
      const newitemTypeList2 = filteredAllItemList.filter(
        x => x?.ItemType1Id === selectedOption.value,
      )

      setfileritemTypeList2(newitemTypeList2)
      setFilteredAllItemList(newitemTypeList2)
    } else if (name === "Type2") {
      const newitemTypeList3 = filteredAllItemList.filter(
        x => x?.ItemType2Id === selectedOption.value,
      )
      setfileritemTypeList3(newitemTypeList3)
      setFilteredAllItemList(newitemTypeList3)
    } else if (name === "Dimensions") {
      const newDimentionList2 = filteredAllItemList.filter(
        x => x?.Dimentions === selectedOption.value,
      )
      setfileritemTypeList2(newDimentionList2)
      setFilteredAllItemList(newDimentionList2)
    }

    validationItem.setFieldValue(name, selectedOption?.value)

    setFormData(prevState => {
      const newState = {
        ...prevState,
        [name]: selectedOption,
      }

      return newState
    })
  }

  const calculateAmount = (qty, rate, disc) =>
    (qty * rate * (1 - disc / 100)).toFixed(2)

  // const calculateFinalAmount = (RoundOff, TransportationCharges) => (RoundOff+TransportationCharges);

  const [addrow, setaddrow] = useState([
    {
      srno: 1,
      product: "",
      hsn: "",
      qty: 0,
      rate: 0,
      per: "",
      disc: 0,
      amount: 0,
    },
  ])
  var newRow = {
    srno: rows.length + 1,
    product: "",
    hsn: "",
    qty: 0,
    rate: 0,
    per: "",
    disc: 0,
    amount: 0,
  }
  const addRowBelow = index => {
    if (
      phoneEntries.length > 0 &&
      phoneEntries[phoneEntries.length - 1].itemlabel === undefined &&
      (phoneEntries[phoneEntries.length - 1].amount === 0 ||
        !phoneEntries[phoneEntries.length - 1].amount ||
        phoneEntries[phoneEntries.length - 1].amount === "0.00")
    ) {
      document.getElementById("quantityError").textContent =
        "Please select above product details"

      return
    }
    document.getElementById("quantityError").textContent = ""
    // setModal(true)
    const newRow = {
      srno: index + 1,
      product: "",
      hsn: "",
      qty: 0,
      rate: 0,
      per: "",
      disc: 0,
      amount: 0,
      isEditable: true,
    }
    setPhoneEntries([...phoneEntries, { ...newRow, editable: true }])
  }

  const updatefinalAmount = (field, value) => {
    debugger
    const updatedOptions = { ...FinalCalculate }

    if (field === "TransportationCharges") {
      updatedOptions.TransportationCharges = value || 0.0
      // setTransportationCharges(value)
    } else if (field === "AdditionalCharges") {
      updatedOptions.AdditionalCharges = value || 0.0
    } else if (field === "RoundOff") {
      updatedOptions.RoundOff = value
      // setRoundOff(value)
    } else if (field === "Discount") {
      updatedOptions.Discount = value
      // setDiscount(value)
    }
    // else {
    //   updatedOptions[field] = value || 0.0
    // }
    const totalCharges = charges.reduce(
      (acc, charge) => acc + parseFloat(charge.amount || 0),
      0,
    )
    // if (
    //   [
    //     "RoundOff",
    //     "Discount",
    //     "TransportationCharges",
    //     "AdditionalCharges",
    //   ].includes(field)
    // ) {

    updatedOptions.FinalAmount = (
      parseFloat(updatedOptions.RoundOff || 0) +
      parseFloat(updatedOptions.TransportationCharges || 0.0) +
      parseFloat(updatedOptions.AdditionalCharges || 0.0) +
      parseFloat(totalCharges || 0.0) +
      parseFloat(value || 0.0)
    )
      // parseFloat(totalCharges || 0.0)+
      .toFixed(2)
    // }

    setFinalCalculate(updatedOptions)
  }
  function calculateAverageDiscount(products) {
    // Calculate total discount and total amount
    const totalDiscount = products.reduce((acc, product) => {
      const amount = product.rate * product.qty
      const discount = (amount * product.disc) / 100
      return acc + discount
    }, 0)

    const totalAmount = products.reduce((acc, product) => {
      return acc + product.rate * product.qty
    }, 0)

    // Calculate average discount
    return (totalDiscount / totalAmount) * 100 || 0
  }
  const updateRow = (index, field, value) => {
    debugger
    const newRows = phoneEntries.map((row, i) => {
      if (i === index) {
        const updatedRow = { ...row, [field]: value }

        if (
          updatedRow?.Length &&
          updatedRow?.Width &&
          updatedRow?.Thickness &&
          updatedRow?.Dimentions &&
          updatedRow?.UOM === "INCH" &&
          updatedRow?.UOMThickness === "MM"
        ) {
          const selected_foot = (updatedRow?.Length * updatedRow?.Width) / 144
          const price_with_mm = updatedRow.rate * updatedRow.Thickness
          const sheet_rate_per_mm = price_with_mm / 18
          const final_rate = sheet_rate_per_mm * selected_foot

          if (["qty", "rate", "disc"].includes(field)) {
            // updatedRow.amount = calculateAmount(updatedRow.qty, updatedRow.rate, updatedRow.disc);
            updatedRow.amount = calculateAmount(
              parseInt(updatedRow.qty || 0, 10),
              parseFloat(final_rate || 0),
              parseFloat(updatedRow.disc || 0),
            )
          }
        } else {
          if (["qty", "rate", "disc"].includes(field)) {
            // updatedRow.amount = calculateAmount(updatedRow.qty, updatedRow.rate, updatedRow.disc);
            updatedRow.amount = calculateAmount(
              parseInt(updatedRow.qty || 0, 10),
              parseFloat(updatedRow.rate || 0),
              parseFloat(updatedRow.disc || 0),
            )
          }
        }

        if (updatedRow.amount <= 0) {
          document.getElementById("quantityError").textContent =
            "Amount should not to be zero"
        } else {
          document.getElementById("quantityError").textContent = ""
          return updatedRow
        }
      }
      return row
    })
    setPhoneEntries(newRows)
    setRows(newRows)

    const finalTotal = newRows.reduce(
      (total, row) => total + parseFloat(row.amount || 0),
      0,
    )
    //   setSubTotal(finalTotal)
    //   setRoundOff(Math.round(finalTotal))
    FinalCalculate.SubTotal = finalTotal
    FinalCalculate.RoundOff = Math.round(finalTotal)

    const AverageDiscount = calculateAverageDiscount(newRows).toFixed(2) || 0
    //  setDiscount(AverageDiscount)
    FinalCalculate.Discount = AverageDiscount || 0
    const totalCharges = charges.reduce(
      (acc, charge) => acc + parseFloat(charge.amount || 0),
      0,
    )
    const FinalAmount = (
      parseFloat(FinalCalculate.RoundOff || 0) +
      parseFloat(FinalCalculate.TransportationCharges || 0) +
      parseFloat(FinalCalculate.AdditionalCharges || 0) +
      parseFloat(totalCharges || 0)
    ).toFixed(2)

    // setFinalAmount(newFinalAmount)
    FinalCalculate.FinalAmount = FinalAmount
  }
  useEffect(() => {
    dispatch(
      GetMultipleDropdown({
        Input: [
          {
            StringMapType: "OrganizationList",
            OrderBy: "StringMapName",
          },
          { StringMapType: "PartyList", OrderBy: "StringMapName" },
          { StringMapType: "PAYMENT", OrderBy: "StringMapName" },
          {
            StringMapType: "SHIPPINGADDRESSTYPE",
            OrderBy: "StringMapName",
          },
          { StringMapType: "ItemCategory", OrderBy: "StringMapName" },
          {
            StringMapType: "UnitofMesurement",
            OrderBy: "StringMapName",
          },
        ],
      }),
    )
    dispatch(GetQuotationNumber())
    dispatch(GetQuotationItemList())

    validationItem.setFieldValue("item", formData.item?.value || "")
    validationItem.setFieldValue("Type1", formData.Type1?.value || "")
    validationItem.setFieldValue("Type2", formData.Type2?.value || "")
    validationItem.setFieldValue("Dimensions", formData.Dimensions?.value || "")
    validationItem.setFieldValue("Type3", formData.Type3?.value || "")
    validationItem.setFieldValue("SubType", formData.SubType?.value || "")
    validation.setFieldValue(
      "QuotationFromCompany",
      selectedOptions.QuotationFromCompany?.value || "",
    )
    validation.setFieldValue("Party", selectedOptions.Party?.value || "")
    // validation.setFieldValue('Payment', selectedOptions.Payment?.value || "");
    validation.setFieldValue(
      "ShipToAddress",
      selectedOptions.ShipToAddress?.value || "",
    )
  }, [dispatch, selectedOptions])

  useEffect(() => {
    if (Type === "View") {
      setReadyOnly(true)
    } else {
      setReadyOnly(false)
    }
  }, [Type])

  useEffect(() => {
    if (Id && isDataReady) {
      const input = { QuotationId: Id }

      // const config = {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //     "Content-Type": "application/json",
      //   },
      // }
      axios
        .post(`${url.API_URL}/quotation/GetQuotationById`, input, config)

        // .post(`${apiAppUrl}/api/quotation/GetQuotationById`, input)
        .then(response => {
          if (response.status === 200) {
            const QuotationById = response?.data?.data
            debugger
            setPartyPhone(QuotationById?.[0]?.PartyPhone || "");
            const filtervalue = Organizations.find(
              org => org.StringMapId === QuotationById?.[0]?.SenderId,
            )
            const filterParty = Parties.find(
              org => org.StringMapId === QuotationById?.[0]?.RecepientId,
            )
            const filterAddress = AddressTypes.find(
              org => org.StringMapId === QuotationById?.[0]?.ShipToAddress,
            )
            const filterPayment = Payment.find(
              org => org.StringMapId === QuotationById?.[0]?.PaymentTerms,
            )
            const formatedpayment = filterPayment
              ? {
                value: filterPayment.StringMapId,
                label: filterPayment.StringMapName,
              }
              : null
            const formattedValue = filtervalue
              ? {
                value: filtervalue.StringMapId,
                label: filtervalue.StringMapName,
              }
              : null
            const selectedParty = filterParty
              ? {
                value: filterParty.StringMapId,
                label: filterParty.StringMapName,
              }
              : null

            var selectedAddress = filterAddress
              ? {
                value: filterAddress.StringMapId,
                label: filterAddress.StringMapName,
              }
              : null

            setSelectedOption(prevState => ({
              ...prevState,
              QuotationId: QuotationById?.[0]?.QuotationId,
              QuotationFromCompany: formattedValue,
              Party: selectedParty,
              AddressTypes: selectedAddress,
              QuotationNumber: QuotationById?.[0]?.QuotationNumber,
              ShipToAddress: selectedAddress,
              addressLine1: QuotationById?.[0]?.AddrLine1,
              addressLine2: QuotationById?.[0]?.AddrLine2,
              city: QuotationById?.[0]?.City,
              state: QuotationById?.[0]?.State,
              PostalCode: QuotationById?.[0]?.PostalCode,
            }))

            setSelectedPaymentType(formatedpayment)
            setFinalCalculate(prevState => ({
              ...prevState,
              // PaymentTerms : formatedpayment,
              SpecialInstructions: QuotationById?.[0]?.Notes,
              RoundOff: QuotationById?.[0]?.RoundOff,
              Discount: QuotationById?.[0]?.FinalDiscount,
              TransportationCharges: QuotationById?.[0]?.TransportationCharge,
              AdditionalCharges: QuotationById?.[0]?.AdditionalCharges,
            }))

            const selectedValues = QuotationById.map(
              ({
                Amount,
                Discount,
                Rate,
                HSNCode,
                ProductName,
                Quantity,
                QuotationProductsDetailId,
                ItemId,
              }) => ({
                itemlabel: ProductName,
                amount: Amount,
                disc: Discount,
                rate: Rate,
                hsn: HSNCode,
                qty: Quantity,
                isEditable: true,
                QuotationProductsDetailId: QuotationProductsDetailId,
                ItemId: ItemId,
              }),
            )

            setPhoneEntries(selectedValues)

            const SubTotal = selectedValues.reduce(
              (total, row) => total + parseFloat(row.amount || 0),
              0,
            )
            FinalCalculate.SubTotal = SubTotal.toFixed(2)
            const FinalAmount = (
              parseFloat(QuotationById?.[0]?.RoundOff || 0) +
              parseFloat(QuotationById?.[0]?.TransportationCharge || 0) +
              parseFloat(QuotationById?.[0]?.AdditionalCharges || 0)
            ).toFixed(2)
            FinalCalculate.FinalAmount = FinalAmount

            setIsLoading(false)
          }
        })
    }
  }, [Id, isDataReady])

  const selectStyles = hasError => ({
    control: (provided, state) => ({
      ...provided,
      borderColor: hasError ? "red" : provided.borderColor,
      boxShadow: "none",
      "&:hover": {
        borderColor: hasError ? "red" : provided["&:hover"].borderColor,
      },
    }),
    outline: "none",
  })

  return (
    <React.Fragment>
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <Row>
          <Col lg={6} className="mb-2">
            <div className="form-group">
              <Label htmlFor="Category" className="col-form-label">
                Quotation Number:{" "}
                <span className="text-danger">
                  {selectedOptions.QuotationNumber
                    ? selectedOptions.QuotationNumber
                    : QuotationNumber?.[0]?.QuotationNumber}
                </span>
              </Label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-2">
            <div className="form-group">
              <Label htmlFor="Category" className="col-form-label">
                Quotation From Company<span className="text-danger">*</span>
              </Label>
              <Select
                id="QuotationFromCompany"
                name="QuotationFromCompany"
                options={Organizations.map(value => ({
                  label: value.StringMapName,
                  value: value.StringMapId,
                }))}
                isDisabled={ReadyOnly}
                onChange={option =>
                  handleDropdownChange(option, "QuotationFromCompany")
                }
                onBlur={validation.handleBlur}
                value={selectedOptions.QuotationFromCompany}
                placeholder="Select Quotation From Company"
                isSearchable={true}
                styles={selectStyles(
                  validation.touched.QuotationFromCompany &&
                  validation.errors.QuotationFromCompany,
                )}
                classNamePrefix="react-select"
              />
              {validation.touched.QuotationFromCompany &&
                validation.errors.QuotationFromCompany ? (
                <FormFeedback className="d-block">
                  {validation.errors.QuotationFromCompany}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6} className="mb-2">
            <div className="form-group">
              <Label htmlFor="SubCategory" className="col-form-label">
                Party<span className="text-danger">*</span>
              </Label>
              <Select
                id="Party"
                name="Party"
                isDisabled={ReadyOnly}
                options={Parties.map(value => ({
                  label: value.StringMapName,
                  value: value.StringMapId,
                }))}
                onChange={option => handleDropdownChange(option, "Party")}
                onBlur={validation.handleBlur}
                value={selectedOptions.Party}
                placeholder="Select Party"
                isSearchable={true}
                styles={selectStyles(
                  validation.touched.Party && validation.errors.Party,
                )}
                classNamePrefix="react-select"
              />
              {validation.touched.Party && validation.errors.Party ? (
                <FormFeedback className="d-block">
                  {validation.errors.Party}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        {selectedPartyDetails ? (
          <Card className="mt-2">
            <CardBody>
              <CardTitle tag="h5">Bill To</CardTitle>
              <p>
                <strong>Party Name:</strong>{" "}
                {selectedPartyDetails?.OrganizationName}
              </p>
              <p>
                <strong>Address:</strong>{" "}
                {selectedPartyDetails?.FullAddress || "N/A"}
              </p>
              <p>
                <strong>GST No.:</strong> {selectedPartyDetails?.GST || "N/A"}
              </p>
            </CardBody>
          </Card>
        ) : null}
        <Row>
          <Col lg={6} className="mb-2">
            <Label htmlFor="ShipToAddress" className="col-form-label">
              Specify Ship To Address<span className="text-danger">*</span>
            </Label>
            <Select
              id="ShipToAddress"
              name="ShipToAddress"
              isDisabled={ReadyOnly}
              options={AddressTypes.map(value => ({
                label: value.StringMapName,
                value: value.StringMapId,
              }))}
              onChange={option => handleDropdownChange(option, "ShipToAddress")}
              value={selectedOptions.ShipToAddress}
              placeholder="Select Ship To Address"
              isSearchable={true}
              styles={selectStyles(
                validation.touched.ShipToAddress &&
                validation.errors.ShipToAddress,
              )}
              classNamePrefix="react-select"
            />
            {validation.touched.ShipToAddress &&
              validation.errors.ShipToAddress ? (
              <FormFeedback className="d-block">
                {validation.errors.ShipToAddress}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col lg={6} className="mb-2">
            <Label htmlFor="addressLine1" className="col-form-label">
              Address Line 1<span className="text-danger">*</span>
            </Label>
            <Input
              id="addressLine1"
              name="addressLine1"
              type="text"
              className="form-control validate"
              maxLength={50}
              placeholder="Enter Address Line 1..."
              validate={{ required: { value: true } }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.addressLine1}
              invalid={
                validation.touched.addressLine1 &&
                  validation.errors.addressLine1
                  ? true
                  : false
              }
              disabled={ReadyOnly ? ReadyOnly : showDefaultAddress}
            />
            {validation.touched.addressLine1 &&
              validation.errors.addressLine1 ? (
              <FormFeedback type="invalid">
                {validation.errors.addressLine1}
              </FormFeedback>
            ) : null}
          </Col>
          <Col lg={6} className="mb-2">
            <Label htmlFor="addressLine2" className="col-form-label">
              Address Line 2<span className="text-danger">*</span>
            </Label>
            <Input
              id="addressLine2"
              name="addressLine2"
              type="text"
              className="form-control validate"
              maxLength={50}
              placeholder="Enter Address Line 2..."
              validate={{ required: { value: true } }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.addressLine2}
              invalid={
                validation.touched.addressLine2 &&
                  validation.errors.addressLine2
                  ? true
                  : false
              }
              disabled={ReadyOnly ? ReadyOnly : showDefaultAddress}
            />
            {validation.touched.addressLine2 &&
              validation.errors.addressLine2 ? (
              <FormFeedback type="invalid">
                {validation.errors.addressLine2}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-2">
            <Label htmlFor="city" className="col-form-label">
              City<span className="text-danger">*</span>
            </Label>
            <Input
              id="city"
              name="city"
              type="text"
              className="form-control validate"
              maxLength={50}
              placeholder="Enter City Name..."
              validate={{ required: { value: true } }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.city}
              invalid={
                validation.touched.city && validation.errors.city ? true : false
              }
              disabled={ReadyOnly ? ReadyOnly : showDefaultAddress}
            />
            {validation.touched.city && validation.errors.city ? (
              <FormFeedback type="invalid">
                {validation.errors.city}
              </FormFeedback>
            ) : null}
          </Col>
          <Col lg={6} className="mb-2">
            <Label htmlFor="state" className="col-form-label">
              State<span className="text-danger">*</span>
            </Label>
            <Input
              id="state"
              name="state"
              type="text"
              className="form-control validate"
              maxLength={50}
              placeholder="Enter State Name..."
              validate={{ required: { value: true } }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.state}
              invalid={
                validation.touched.state && validation.errors.state
                  ? true
                  : false
              }
              disabled={ReadyOnly ? ReadyOnly : showDefaultAddress}
            />
            {validation.touched.state && validation.errors.state ? (
              <FormFeedback type="invalid">
                {validation.errors.state}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-2">
            <Label htmlFor="PostalCode" className="col-form-label">
              Postal Code<span className="text-danger">*</span>
            </Label>
            <Input
              id="PostalCode"
              name="PostalCode"
              type="text"
              className="form-control validate"
              maxLength={50}
              placeholder="Enter Postal Code..."
              validate={{ required: { value: true } }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.PostalCode}
              invalid={
                validation.touched.PostalCode && validation.errors.PostalCode
                  ? true
                  : false
              }
              disabled={ReadyOnly ? ReadyOnly : showDefaultAddress}
            />
            {validation.touched.PostalCode && validation.errors.PostalCode ? (
              <FormFeedback type="invalid">
                {validation.errors.PostalCode}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="product-table-card">
              <CardBody>
                <CardTitle tag="h5">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    Product Details :
                    {!ReadyOnly ? (
                      <Button
                        color="success"
                        disabled={ReadyOnly}
                        onClick={() => addRowBelow()}
                        className="me-2"
                      >
                        <i className="mdi mdi-plus-thick"></i> ADD
                      </Button>
                    ) : null}
                  </div>
                </CardTitle>
                <Table bordered className="product-table">
                  <thead>
                    <tr>
                      <th style={{ width: 100 }}>Sr No</th>
                      <th style={{ width: 210 }}>Product</th>
                      <th style={{ width: 100 }}>HSN Code</th>
                      <th style={{ width: 100 }}>Qty</th>
                      <th style={{ width: 150 }}>Rate</th>
                      <th style={{ width: 100 }}>Disc(%)</th>
                      <th style={{ width: 100 }}>Amount</th>
                      {!ReadyOnly ? (
                        <th style={{ width: 100 }}>Action</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {phoneEntries.map((entry, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {/* {entry.product} */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            {!entry.itemlabel ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                <Select
                                  id="item"
                                  name="item"
                                  options={items.map(value => ({
                                    label: value.StringMapName,
                                    value: value.StringMapId,
                                  }))}
                                  onChange={option =>
                                    handleChangeItem(option, "item")
                                  }
                                  value={validationItem.item}
                                  placeholder="Select Products"
                                  isSearchable={true}
                                  styles={{
                                    ...selectStyles(
                                      validation.touched.item &&
                                      validation.errors.item,
                                    ),
                                    container: base => ({
                                      ...base,
                                      width: "200px",
                                    }),
                                  }}
                                  classNamePrefix="react-select"
                                />
                                <i
                                  onClick={() =>
                                    ProductOnchange(validationItem.values.item)
                                  }
                                  className="mdi mdi-magnify"
                                ></i>
                              </div>
                            ) : (
                              entry.itemlabel
                            )}
                          </div>
                          {validation.errors.phoneEntries?.[index]?.item && (
                            <div className="text-danger">
                              {validation.errors.phoneEntries[index].item}
                            </div>
                          )}
                        </td>
                        <td>{entry?.hsn}</td>
                        <td>
                          <Input
                            type="number"
                            disabled={ReadyOnly}
                            value={entry.qty}
                            onChange={e => {
                              const value = Number(e.target.value)
                              if (value >= 0) {
                                if (value === "" || /^[0-9]+$/.test(value)) {
                                  updateRow(index, "qty", value)
                                }
                              } else {
                                const value = 0
                                updateRow(index, "qty", value)
                              }
                            }}
                          // disabled={!isEditable[index]}
                          />
                          {validation.errors.phoneEntries?.[index]?.qty &&
                            validation.errors.phoneEntries?.[index]?.qty && (
                              <div className="text-danger">
                                {validation.errors.phoneEntries[index].qty}
                              </div>
                            )}
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            {!entry.isEditable ? (
                              <Input
                                type="number"
                                value={entry.rate}
                                // onChange={(e) => updateRow(index, 'rate', Number(e.target.value))}
                                onChange={e => {
                                  const value = Number(e.target.value)
                                  if (value >= 0) {
                                    updateRow(
                                      index,
                                      "rate",
                                      Number(e.target.value),
                                    )
                                  } else {
                                    const value = 0
                                    updateRow(index, "rate", value)
                                  }
                                }}
                                disabled={
                                  ReadyOnly ? ReadyOnly : entry.isEditable
                                }
                              //    disabled={true}
                              />
                            ) : (
                              entry?.rate
                            )}

                            {!entry.isEditable ? (
                              <div>
                                <i
                                  className="mdi mdi-check"
                                  onClick={() => saveEdit(index)}
                                ></i>
                                <i
                                  className="mdi mdi-close"
                                  onClick={() => cancelEdit(index)}
                                ></i>
                              </div>
                            ) : !ReadyOnly ? (
                              <i
                                className="mdi mdi-pencil"
                                onClick={() => setEditIndex1(index)}
                              ></i>
                            ) : null}

                            {/* </Button> */}
                          </div>
                        </td>
                        <td>
                          <Input
                            type="number"
                            value={entry.disc}
                            disabled={ReadyOnly}
                            // onChange={(e) => updateRow(index, 'disc', Number(e.target.value))}
                            onChange={e => {
                              const value = Number(e.target.value)
                              if (value >= 0 && value <= 100) {
                                updateRow(index, "disc", Number(e.target.value))
                              } else {
                                const value = 0
                                updateRow(index, "disc", value)
                              }
                            }}
                          // disabled={!isEditable[index]}
                          />
                        </td>
                        <td>{entry.amount}</td>
                        {!ReadyOnly ? (
                          <td>
                            <Button
                              color="primary"
                              disabled={ReadyOnly}
                              // onClick={() => handleRemove(index)}
                              onClick={() => deleteModel(index)}
                            >
                              <i className="mdi mdi-delete"></i>
                              {/* <UncontrolledTooltip
                              target={`delete-${entry.ItemId}`}
                              placement="bottom"
                            >
                              Delete Product
                            </UncontrolledTooltip> */}
                            </Button>
                          </td>
                        ) : null}
                      </tr>
                    ))}
                  </tbody>

                  {/* <span className="text-danger">please fill up above the row</span> */}
                </Table>

                <span id="quantityError" class="error"></span>
                <Row>
                  <Col md={6}>
                    <Card className="left-card p-3">
                      <CardBody>
                        {/* <Row>
                          <Col lg={5} className="mb-2">
                            <FormGroup>
                              <Label htmlFor="chargeType">Charge Type *</Label>
                              <Input
                                id="chargeType"
                                name="chargeType"
                                type="text"
                                placeholder="Enter Charge Type"
                                value={chargeType}
                                onChange={e => setChargeType(e.target.value)}
                                invalid={!!errors.chargeType}
                              />
                              <FormFeedback>{errors.chargeType}</FormFeedback>
                            </FormGroup>
                          </Col>

                          <Col lg={4} className="mb-2">
                            <FormGroup>
                              <Label htmlFor="chargeAmount">
                                Charge Amount *
                              </Label>
                              <Input
                                id="chargeAmount"
                                name="chargeAmount"
                                type="number"
                                placeholder="Enter Charge Amount"
                                value={chargeAmount}
                                onChange={e => setChargeAmount(e.target.value)}
                                invalid={!!errors.chargeAmount}
                                min="0"
                              />
                              <FormFeedback>{errors.chargeAmount}</FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col
                            lg={3}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <Button
                              type="button"
                              color="primary"
                              onClick={handleAddOrUpdateCharge}
                              // onClick={addCharge}
                            >
                              {editIndex !== null ? "Update" : "Add"}
                            </Button>
                          </Col>
                        </Row> */}
                        <Row>
                          <Col lg={12} className="mb-2">
                            <div className="form-group">
                              <Label
                                htmlFor="Category"
                                className="col-form-label"
                              >
                                Payment Terms
                                {/* <span className="text-danger">*</span> */}
                              </Label>
                              {/* {validation.touched.PaymentTerms && validation.errors.PaymentTerms ? (
                                <FormFeedback className="d-block">{validation.errors.PaymentTerms}</FormFeedback>
                            ) : null} */}
                              <Select
                                id="Payment"
                                name="Payment"
                                isDisabled={ReadyOnly}
                                options={Payment.map(value => ({
                                  label: value.StringMapName,
                                  value: value.StringMapId,
                                }))}
                                // onChange={(option) => setSelectedPaymentType(option)}
                                onChange={option =>
                                  handlePaymentChange(option, "Payment")
                                }
                                // value={selectedOptions.Payment}
                                value={SelectedPaymentType}
                                placeholder="Select Payment Terms"
                                isSearchable={true}
                                // styles={selectStyles(validation.touched.PaymentTerms && validation.errors.PaymentTerms)}
                                classNamePrefix="react-select"
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={12} className="mb-2">
                            <div className="form-group">
                              <Label
                                htmlFor="Category"
                                className="col-form-label"
                              >
                                Special Instructions
                                <span className="text-danger"></span>
                              </Label>
                              <Input
                                disabled={ReadyOnly}
                                type="textarea"
                                value={FinalCalculate.SpecialInstructions}
                                onChange={e =>
                                  setFinalCalculate(prevState => ({
                                    ...prevState,
                                    SpecialInstructions: e.target.value, // Update the specific field
                                  }))
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <Card className="right-card p-3">
                      <CardBody>
                        <Table>
                          <thead>
                            <tr>
                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                }}
                              >
                                sub total
                              </td>
                              <td>{FinalCalculate?.SubTotal}</td>
                              {/* <td>{SubTotal}</td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                }}
                              >
                                Round Off
                              </td>
                              <td>{FinalCalculate?.RoundOff}</td>
                              {/* <td>{RoundOff}</td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                }}
                              >
                                Discount
                              </td>
                              <td>
                                {FinalCalculate?.Discount}{" "}
                                {FinalCalculate?.Discount > 0 ? "%" : ""}
                              </td>
                              {/* <td>{Discount}</td> */}
                            </tr>
                            {/* {charges.length > 0 ? (
                              charges.map((charge, index) => (
                                <tr key={index}>
                                  <td
                                    style={{
                                      fontWeight: "bold",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {charge.type}
                                  </td>
                                  <td>
                                    {charge.amount}{" "}
                                    {charge.type.toLowerCase() === "discount"
                                      ? "%"
                                      : ""}
                                  </td>
                                  <td>
                                    <i
                                      onClick={() => handleEditCharge(index)}
                                      className="mdi mdi-pencil"
                                    ></i>
                                  </td>
                                  <td>
                                    <i
                                      onClick={() => handleDeleteCharge(index)}
                                      className="mdi mdi-delete"
                                    ></i>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={2}>No charges added yet.</td>
                              </tr>
                            )} */}

                            <tr>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                }}
                              >
                                Transportation Charges
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  disabled={ReadyOnly}
                                  value={FinalCalculate.TransportationCharges}
                                  onChange={e => {
                                    // if (
                                    //   e.target.value === "" ||
                                    //   /^[0-9]*\.?[0-9]+$/.test(e.target.value)
                                    // ) {
                                    updatefinalAmount(
                                      "TransportationCharges",
                                      Number(e.target.value),
                                    )
                                    // }
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                }}
                              >
                                Additional Charges
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  disabled={ReadyOnly}
                                  value={FinalCalculate.AdditionalCharges}
                                  onChange={e =>
                                    updatefinalAmount(
                                      "AdditionalCharges",
                                      Number(e.target.value),
                                    )
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                }}
                              >
                                Amount
                              </td>
                              <td>{FinalCalculate?.FinalAmount}</td>
                              {/* <td>{FinalAmount}</td> */}
                            </tr>
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4 justify-content-end">
          <Col lg={6} className="d-flex justify-content-end mb-3">
            <button
              type="button"
              className="btn btn-info large-button me-2"
              onClick={() => handlePrintPreview()}
            >
              Generate Quote
            </button>
            <button
              type="button"
              className="btn btn-danger large-button me-2"
              onClick={async () => {
                try {
                  await dispatch(ClearQuotation())

                  props.setShowQuotationGetList(true)

                  if (Id) {
                    setIsLoading(true)
                    setTimeout(() => {
                      navigate("/QuotationList")
                      setIsLoading(false)
                    }, 1000)
                  }
                } catch (error) {
                  console.error(
                    "Error while clearing quotation or navigating:",
                    error,
                  )
                }
              }}
            >
              Cancel
            </button>
            {ReadyOnly ? null : (
              <button type="submit" className="btn btn-success large-button">
                Submit
              </button>
            )}
          </Col>
        </Row>
      </Form>

      {isLoading ? (
        <Spinners setLoading={setIsLoading} />
      ) : (
        <Modal isOpen={modal} toggle={toggle}>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validationItem.handleSubmit()
              return false
            }}
          >
            <ModalHeader toggle={toggle}>Specify Item</ModalHeader>
            <ModalBody>
              {/* Item Dropdown */}
              <Row>
                <Col lg={6} className="mb-2">
                  <div className="form-group">
                    <Label htmlFor="item" className="col-form-label">
                      Item Type<span className="text-danger">*</span>
                    </Label>
                    <Select
                      id="item"
                      name="item"
                      options={items.map(value => ({
                        label: value.StringMapName,
                        value: value.StringMapId,
                      }))}
                      onChange={option => handleChangeItem(option, "item")}
                      onBlur={validationItem.handleBlur}
                      // value={selectedOptions.items}
                      value={
                        formData.item ? formData.item : SelectedItemforSearch
                      }
                      placeholder="Item"
                      isSearchable={true}
                      styles={selectStyles(
                        validationItem.touched.item &&
                        validationItem.errors.item,
                      )}
                      classNamePrefix="react-select"
                    />

                    {validationItem.touched.item &&
                      validationItem.errors.item ? (
                      <FormFeedback className="d-block">
                        {validationItem.errors.item}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} className="mb-2">
                  <div className="form-group">
                    <Label htmlFor="SubCategory" className="col-form-label">
                      Sub Type
                    </Label>
                    <Select
                      id="SubType"
                      name="SubType"
                      options={Array.from(
                        new Map(
                          filteredAllItemList.map(value => [
                            value?.SubCategoryId,
                            value,
                          ]),
                        ).values(),
                      ).map(value => ({
                        label: value?.SubCategoryName,
                        value: value?.SubCategoryId,
                      }))}
                      onChange={option => handleChangeItem(option, "SubType")}
                      onBlur={validationItem.handleBlur}
                      value={formData.SubType}
                      placeholder="Select Sub Type"
                      isSearchable={true}
                      styles={selectStyles(
                        validationItem.touched.SubType &&
                        validationItem.errors.SubType,
                      )}
                      classNamePrefix="react-select"
                    />
                    {validationItem.touched.Party &&
                      validationItem.errors.SubType ? (
                      <FormFeedback className="d-block">
                        {validationItem.errors.SubType}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>

              {/* Type 2 Dropdown */}
              <Row>
                <Col lg={6} className="mb-2">
                  {filteredAllItemList[0]?.ItemType1Id && (
                    <div className="form-group">
                      <Label htmlFor="SubCategory" className="col-form-label">
                        Type 1<span className="text-danger">*</span>
                      </Label>
                      <Select
                        id="Type1"
                        name="Type1"
                        options={Array.from(
                          new Map(
                            filteredAllItemList.map(value => [
                              value?.ItemType1Id,
                              value,
                            ]),
                          ).values(),
                        ).map(value => ({
                          label: value?.ItemType1Name,
                          value: value?.ItemType1Id,
                        }))}
                        onChange={option => handleChangeItem(option, "Type1")}
                        onBlur={validationItem.handleBlur}
                        value={formData.Type1}
                        placeholder="Select Type1"
                        isSearchable={true}
                        styles={selectStyles(
                          validationItem.touched.Type1 &&
                          validationItem.errors.Type1,
                        )}
                        classNamePrefix="react-select"
                      />
                      {validationItem.touched.Type1 &&
                        validationItem.errors.Type1 ? (
                        <FormFeedback className="d-block">
                          {validationItem.errors.Type1}
                        </FormFeedback>
                      ) : null}
                    </div>
                  )}
                </Col>
                {filteredAllItemList[0]?.ItemType2Id && (
                  <Col lg={6} className="mb-2">
                    <div className="form-group">
                      <Label htmlFor="SubCategory" className="col-form-label">
                        Type 2
                      </Label>
                      <Select
                        id="Type2"
                        name="Type2"
                        options={Array.from(
                          new Map(
                            filteredAllItemList.map(value => [
                              value?.ItemType2Id,
                              value,
                            ]),
                          ).values(),
                        ).map(value => ({
                          label: value?.ItemType2Name,
                          value: value?.ItemType2Id,
                        }))}
                        onChange={option => handleChangeItem(option, "Type2")}
                        onBlur={validationItem.handleBlur}
                        value={formData.Type2}
                        placeholder="Select Type2"
                        isSearchable={true}
                        styles={selectStyles(
                          validationItem.touched.Type2 &&
                          validationItem.errors.Type2,
                        )}
                        classNamePrefix="react-select"
                      />
                      {validationItem.touched.Type2 &&
                        validationItem.errors.Type2 ? (
                        <FormFeedback className="d-block">
                          {validationItem.errors.Type2}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                )}
              </Row>
              <Row>
                {filteredAllItemList[0]?.ItemType3Id && (
                  <Col lg={6} className="mb-2">
                    <div className="form-group">
                      <Label htmlFor="SubCategory" className="col-form-label">
                        Type 3
                      </Label>
                      <Select
                        id="Type3"
                        name="Type3"
                        options={Array.from(
                          new Map(
                            filteredAllItemList.map(value => [
                              value?.ItemType3Id,
                              value,
                            ]),
                          ).values(),
                        ).map(value => ({
                          label: value?.ItemType3Name,
                          value: value?.ItemType3Id,
                        }))}
                        onChange={option => handleChangeItem(option, "Type3")}
                        onBlur={validationItem.handleBlur}
                        value={formData.Type3}
                        placeholder="Select Type3"
                        isSearchable={true}
                        styles={selectStyles(
                          validationItem.touched.Type3 &&
                          validationItem.errors.Type3,
                        )}
                        classNamePrefix="react-select"
                      />
                      {validationItem.touched.Type3 &&
                        validationItem.errors.Type3 ? (
                        <FormFeedback className="d-block">
                          {validationItem.errors.Type3}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                )}
                {filteredAllItemList[0]?.Dimentions && (
                  <Col lg={6} className="mb-2">
                    <div className="form-group">
                      <Label htmlFor="Category" className="col-form-label">
                        Dimensions
                      </Label>

                      <Select
                        id="Dimensions"
                        name="Dimensions"
                        options={Array.from(
                          new Map(
                            filteredAllItemList.map(value => [
                              value?.ItemId,
                              value,
                            ]),
                          ).values(),
                        ).map(value => ({
                          label: value?.Dimentions,
                          value: value?.Dimentions,
                        }))}
                        onChange={option =>
                          handleChangeItem(option, "Dimensions")
                        }
                        onBlur={validationItem.handleBlur}
                        value={formData.Dimensions}
                        placeholder="Dimensions"
                        isSearchable={true}
                        styles={selectStyles(
                          validationItem.touched.Dimensions &&
                          validationItem.errors.Dimensions,
                        )}
                        classNamePrefix="react-select"
                      />

                      {validationItem.touched.Dimensions &&
                        validationItem.errors.Dimensions ? (
                        <FormFeedback className="d-block">
                          {validationItem.errors.Dimensions}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                )}
              </Row>
              <FormGroup>
                <Label>Item Description</Label>
                <p>
                  {SelectedItemforSearch?.label || ""}
                  {formData.Type1?.label ? "-" + formData.Type1?.label : ""}
                  {formData.Type2?.label ? "-" + formData.Type2?.label : ""}
                  {formData.Dimensions?.label
                    ? "-" + formData.Dimensions?.label
                    : ""}
                </p>
              </FormGroup>
              <span id="ItemError" class="error"></span>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button color="primary" type="submit">
                Select
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}

      <Modal
        style={{ maxWidth: "350px" }}
        isOpen={showDeleteModal}
        toggle={() => setShowDeleteModal(!showDeleteModal)}
      >
        <ModalHeader toggle={() => setShowDeleteModal(!showDeleteModal)}>
          Alert
        </ModalHeader>
        <ModalBody>Are you sure you want to remove Product?</ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button color="secondary" onClick={() => setShowDeleteModal(false)}>
            No
          </Button>
          {/* <Button color="success" onClick={handleRemove}> */}
          <Button color="success" onClick={deleteProduct}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>

      {/* quotiaion print model */}
      <Modal
        style={{ maxWidth: "900px" }}
        isOpen={printModel}
        toggle={() => {
          setPrintModel(!printModel)
          setDownloadNm("Duplicate")
        }}
      >
        <ModalHeader style={{ width: "100%" }} toggle={() => {
          setPrintModel(!printModel)
          setDownloadNm("Duplicate")
        }}>
          

          <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <span>Quotation</span>
            <div style={{ width: "200px", marginLeft: "30px" }}>
              <Select
                options={downloadNmOptions}
                defaultValue={downloadNmOptions[0]}
                onChange={(selectedOption) => setDownloadNm(selectedOption.label)}
                isSearchable={false}
              />
            </div>
          </div>


        </ModalHeader>
        <ModalBody>
          <div ref={printRef}>
            <Quotationprint
              QuotationData={{
                phoneEntries: phoneEntries,
                selectedOptions: selectedOptions,
                FinalCalculate: FinalCalculate,
                downloadNm: downloadNm,
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <div>
            <Button color="success" onClick={downloadPDF}>
              Download Pdf
            </Button>
            <Button className="ms-3" color="info" onClick={() => setWhatsappModel(true)}>
              Send Via WhatsApp
            </Button>
            <Button className="ms-3" color="info" onClick={() => setSendEmailModel(true)}>
              Send Via Email
            </Button>
          </div>
          <div>
            <Button color="danger" onClick={() => {
              setPrintModel(!printModel)
              setDownloadNm("Duplicate")
            }}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      {/* whatsapp model */}
      <Modal
        isOpen={whatsappModel}
        toggle={() => {
          setWhatsappModel(!whatsappModel);
          setSendToNumber("");
          setWhatsappMessage("");
        }}
      >
        <ModalHeader toggle={() => {
          setWhatsappModel(!whatsappModel);
          setSendToNumber("");
          setWhatsappMessage("");
        }}>
          Send Quotation via WhatsApp
        </ModalHeader>
        <ModalBody>
          <div>
            <Row>
            <Col md={9}>
              <Label htmlFor="WhatsappSendTo" className="col-form-label">
                Send To<span className="text-danger">*</span>
              </Label>
              <Input
                id="WhatsappSendTo"
                name="WhatsappSendTo"
                type="text"
                className="form-control validate"
                maxLength={10}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  if (value.length <= 10) {
                    setSendToNumber(value);
                  }
                }}
                value={sendToNumber}
                placeholder="Enter 10-digit phone number"
                title="Please enter a 10-digit phone number"
              />
            </Col>
              <Col md={3}>
                <Label htmlFor="SendToParty" className="col-form-label">
                  Send To Party
                </Label>
                <div style={{marginTop: "5px"}}>
                  <Input id="SendToParty" style={{width: "15px", height: "15px"}} type="checkbox" onChange={handleCheckboxChange} />
                </div>
              </Col>
            </Row>
            <div>
              <Label htmlFor="WhatsappMessage" className="col-form-label">
                Message
              </Label>
              <Input
                id="WhatsappMessage"
                name="WhatsappMessage"
                type="text"
                className="form-control validate"
                onChange={(e) => { setWhatsappMessage(e.target.value) }}
                value={whatsappMessage}
                placeholder="Enter a Message"
                title="Please enter a message"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <div>
            <Button color="info" onClick={() => sendPDFViaWhatsApp()} disabled={sendingLoading}>
              {sendingLoading ? "Sending..." : "Send"}
            </Button>
          </div>
          <div>
            <Button color="danger" onClick={() => {
              setWhatsappModel(!whatsappModel);
              setSendToNumber("");
              setWhatsappMessage("");
              }} disabled={sendingLoading}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      {/* send email model */}
      <Modal
        isOpen={sendEmailModel}
        toggle={() => {
          setSendEmailModel(!sendEmailModel);
          setSendToEmail("");
          setEmailMessage("");
          setEmailSubject("Quotation from Comfort Zone");
        }}
      >
        <ModalHeader toggle={() => {
          setSendEmailModel(!sendEmailModel);
          setSendToEmail("");
          setEmailMessage("");
          setEmailSubject("Quotation from Comfort Zone");
        }}>
          Send Quotation via Email
        </ModalHeader>
        <ModalBody>
          <div>
            <Row>
              <Col md={9}>
                <Label htmlFor="EmailSendTo" className="col-form-label">
                  Send To<span className="text-danger">*</span>
                </Label>
                <Input
                  id="EmailSendTo"
                  name="EmailSendTo"
                  type="email"
                  className="form-control validate"
                  onChange={(e) => setSendToEmail(e.target.value)}
                  value={sendToEmail}
                  placeholder="Enter a valid email address"
                  title="Please enter a valid email address"
                />
              </Col>
              {/* <Col md={3}>
                <Label htmlFor="SendToParty" className="col-form-label">
                  Send To Party
                </Label>
                <div style={{ marginTop: "5px" }}>
                  <Input id="SendToParty" style={{ width: "15px", height: "15px" }} type="checkbox" onChange={handleEmailCheckboxChange} />
                </div>
              </Col> */}
            </Row>
            <div>
              <Label htmlFor="EmailSubject" className="col-form-label">
                Email Subject<span className="text-danger">*</span>
              </Label>
              <Input
                id="EmailSubject"
                name="EmailSubject"
                type="text"
                className="form-control validate"
                onChange={(e) => { setEmailSubject(e.target.value) }}
                value={emailSubject}
                placeholder="Enter a email subject"
                title="Please enter a email subject"
              />
            </div>
            <div>
              <Label htmlFor="EmailMessage" className="col-form-label">
                Message
              </Label>
              <Input
                id="EmailMessage"
                name="EmailMessage"
                type="text"
                className="form-control validate"
                onChange={(e) => { setEmailMessage(e.target.value) }}
                value={emailMessage}
                placeholder="Enter a Message"
                title="Please enter a message"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <div>
            <Button color="info" onClick={() => sendPDFViaEmail()} disabled={sendingLoading}>
              {sendingLoading ? "Sending..." : "Send"}
            </Button>
          </div>
          <div>
            <Button color="danger" onClick={() => {
              setSendEmailModel(!sendEmailModel);
              setSendToEmail("");
              setEmailMessage("");
              setEmailSubject("Quotation from Comfort Zone");
            }} disabled={sendingLoading}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Modal>

    </React.Fragment>
  )
}

const mapStateToProps = state => {
  console.log("Redux Stateqqq:", state)
  return {
    showQuotationGetList: state.Quotation?.showQuotationGetList,
  }
}

export default connect(mapStateToProps, { setShowQuotationGetList })(
  AddQuotaion,
)
