import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Label,
  Table,
  FormFeedback,
  Form,
  Input,
  FormGroup,
  Button,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { AddUpdateOrganization } from "store/actions"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import axios from "axios"
import * as url from "../../../helpers/url_helper"
const apiAppUrl = process.env.REACT_APP_API_URL

const TargetandPaymentTerms = ({
  previous,
  next,
  isEditMode,
  formData,
  activeTab,
}) => {
  const dispatch = useDispatch()
  const organization = useSelector(state => state.CompnayAPI?.data) || []
  const navigate = useNavigate()
  const [targets, setTargets] = useState([])
  const [editIndex, setEditIndex] = useState(null)
  const location = useLocation()
  const isViewMode = location.pathname.includes("/View")

  const handleFinalSubmit = () => {
    debugger
    const OrganizationId = Id ? Id : organization?.data[0]?.OrganizationId
    const payload = {
      Target_Terms: {
        target: targets.map(entry => ({ ...entry, OrganizationId })),
        terms: terms.map(entry => ({ ...entry, OrganizationId })),
      },
    }

    try {
      dispatch(AddUpdateOrganization(payload))
    } catch (error) {
      console.error("Dispatch failed: ", error)
    }
    next(formData)
  }
  const { Id } = useParams()
  var company = []
  useEffect(() => {
    if (activeTab == 6) {
      const payloadTab = {
        TabName: "Target_Terms",
        OrganizationId: Id,
      }
      axios
        .post(`${url.API_URL}/master/GetOrgnaizationById`, payloadTab)
        .then(response => {
          if (response.status === 200) {
            console.log("Data Retrieved Successfully:", response.data)
            company = []
            company = response.data.data
            if (company.length > 0) {
              console.log("ContactsData", company)
              const targetsData = []
              const termsData = []

              company.forEach(item => {
                if (item.TargetId) {
                  targetsData.push(item)
                } else if (item.PaymentTermsId) {
                  termsData.push(item)
                }
              })

              setTargets(targetsData)
              setTerms(termsData)
            }
          }
        })
        .catch(error => {
          console.error("Error Setting Up Request:", error)
        })
    }
  }, [Id, activeTab])

  const validationSchema = Yup.object().shape({
    basicTargetAmount: Yup.number()
      .min(1, "Basic Target Amount must be greater than zero")
      .required("Basic Target Amount is required"),
    slabPercentage: Yup.number()
      .min(0, "Slab Percentage must not be negative")
      .required("Slab Percentage is required"),
    startDate: Yup.date()
      .required("Start Date is required")
      .test(
        "within-2-years",
        "Start Date must be within the last 2 years",
        value => {
          const twoYearsAgo = new Date()
          twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2)
          return new Date(value) >= twoYearsAgo
        },
      ),
    endDate: Yup.date()
      .required("End Date is required")
      .test(
        "after-start-date",
        "End Date must be later than Start Date",
        function (value) {
          const { startDate } = this.parent
          return startDate && value > startDate
        },
      ),
  })

  const formik = useFormik({
    initialValues: {
      basicTargetAmount: "",
      slabPercentage: "",
      startDate: "",
      endDate: "",
    },
    validationSchema,
    onSubmit: values => {
      if (editIndex !== null) {
        const updatedTargets = [...targets]
        updatedTargets[editIndex] = values
        setTargets(updatedTargets)
        setEditIndex(null)
      } else {
        if (isDateRangeOverlapping(values.startDate, values.endDate)) {
          formik.setErrors({
            startDate: "Date range overlaps with an existing target",
            endDate: "Date range overlaps with an existing target",
          })
          return
        }
        setTargets([...targets, values])
      }
      formik.resetForm()
    },
  })

  const isDateRangeOverlapping = (startDate, endDate) => {
    return targets.some(
      target =>
        new Date(startDate) <= new Date(target.endDate) &&
        new Date(endDate) >= new Date(target.startDate),
    )
  }

  const handleEdit = index => {
    setEditIndex(index)
    formik.setValues(targets[index])
  }

  const handleDelete = index => {
    setTargets(entries =>
      entries.some((entry, i) => i === index && entry.Status)
        ? entries.map((entry, i) =>
            i === index ? { ...entry, Status: "Inactive" } : entry,
          )
        : entries.filter((_, i) => i !== index),
    )
    // setTargets(targets.filter((_, i) => i !== index))
  }

  const [terms, setTerms] = useState([])
  const [editIndex1, setEditIndex1] = useState(null)

  const validationSchema1 = Yup.object().shape({
    paymentReceivedOn: Yup.number()
      .min(0, "Days cannot be less than 0")
      .max(100, "Days cannot be more than 100")
      .required("Payment Received On is required"),
    paymentDiscount: Yup.number()
      .min(0, "Discount cannot be negative")
      .required("Payment Discount is required"),
  })

  //   const formik1 = useFormik({
  //     initialValues: {
  //       paymentReceivedOn: "",
  //       paymentDiscount: "",
  //     },
  //     validationSchema1,
  //     onSubmit: values => {
  //       if (editIndex1 !== null) {
  //         const updatedTerms = [...terms]
  //         updatedTerms[editIndex1] = values
  //         setTerms(updatedTerms)
  //         setEditIndex1(null)
  //       } else {
  //         if (isDuplicateDays(values.paymentReceivedOn)) {
  //           formik1.setErrors({
  //             paymentReceivedOn: "Days value already exists. Use a unique value.",
  //           })
  //           return
  //         }
  //         setTerms([...terms, values])
  //       }
  //       formik1.resetForm()
  //     },
  //   })
  const formik1 = useFormik({
    initialValues: {
      paymentReceivedOn: "",
      paymentDiscount: "",
    },
    validationSchema: validationSchema1, // Assuming you already have this
    onSubmit: values => {
      if (editIndex1 !== null) {
        const updatedTerms = [...terms]
        updatedTerms[editIndex1] = values
        setTerms(updatedTerms)
        setEditIndex1(null)
      } else {
        // Check for empty or duplicate values
        if (!values.paymentReceivedOn || !values.paymentDiscount) {
          formik1.setTouched({
            paymentReceivedOn: true,
            paymentDiscount: true,
          })
          return // Prevent submission if values are blank
        }

        if (isDuplicateDays(values.paymentReceivedOn)) {
          formik1.setErrors({
            paymentReceivedOn: "Days value already exists. Use a unique value.",
          })
          return // Prevent submission if Days are duplicate
        }

        setTerms([...terms, values])
      }
      formik1.resetForm()
    },
  })

  const isDuplicateDays = days => {
    return terms.some(term => term.paymentReceivedOn === days)
  }

  const handleEdit1 = index => {
    setEditIndex1(index)
    formik1.setValues(terms[index])
  }

  const handleDelete1 = index => {
    // setTerms(terms.filter((_, i) => i !== index))
    setTerms(entries =>
      entries.some((entry, i) => i === index && entry.Status)
        ? entries.map((entry, i) =>
            i === index ? { ...entry, Status: "Inactive" } : entry,
          )
        : entries.filter((_, i) => i !== index),
    )
  }

  return (
    <React.Fragment>
      <Card>
        <CardHeader>Target</CardHeader>
        <CardBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              formik.handleSubmit()
            }}
          >
            {!isViewMode && (
              <>
                <Row className="justify-content-center">
                  <Col lg={6} className="mb-3">
                    <FormGroup>
                      <Label htmlFor="basicTargetAmount">
                        Basic Target Amount{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="basicTargetAmount"
                        name="basicTargetAmount"
                        type="text"
                        placeholder="Enter Basic Target Amount"
                        value={formik.values.basicTargetAmount}
                        onChange={e => {
                          let value = e.target.value

                          // Allow only numbers with up to 2 decimal places
                          if (/^\d{0,10}(\.\d{0,2})?$/.test(value)) {
                            formik.setFieldValue("basicTargetAmount", value)
                          }
                        }}
                        onBlur={e => {
                          let value = parseFloat(e.target.value)
                          // Ensure value is formatted to 2 decimal places on blur
                          if (!isNaN(value)) {
                            formik.setFieldValue(
                              "basicTargetAmount",
                              value.toFixed(2),
                            )
                          }
                        }}
                        // onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.basicTargetAmount &&
                          !!formik.errors.basicTargetAmount
                        }
                      />
                      <FormFeedback>
                        {formik.touched.basicTargetAmount &&
                          formik.errors.basicTargetAmount}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col lg={6} className="mb-3">
                    <FormGroup>
                      <Label htmlFor="slabPercentage">
                        Slab Percentage <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="slabPercentage"
                        name="slabPercentage"
                        type="number"
                        placeholder="Enter Slab Percentage"
                        value={formik.values.slabPercentage}
                        onChange={e => {
                          let value = e.target.value

                          // Validate input: allow numbers with up to 2 decimal places
                          if (
                            /^(\d{1,3})(\.\d{0,2})?$/.test(value) ||
                            value === ""
                          ) {
                            // Ensure value is within the range 0 to 100
                            const numericValue = parseFloat(value)
                            if (
                              (numericValue >= 0 && numericValue <= 100) ||
                              value === ""
                            ) {
                              formik.setFieldValue("slabPercentage", value)
                            }
                          }
                        }}
                        onBlur={e => {
                          // Ensure value is formatted to 2 decimal places on blur
                          let value = parseFloat(e.target.value)
                          if (!isNaN(value)) {
                            if (value >= 0 && value <= 100) {
                              formik.setFieldValue(
                                "slabPercentage",
                                value.toFixed(2),
                              )
                            } else {
                              formik.setFieldValue("slabPercentage", "") // Reset invalid input
                            }
                          }
                        }}
                        // onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.slabPercentage &&
                          !!formik.errors.slabPercentage
                        }
                      />
                      <FormFeedback>
                        {formik.touched.slabPercentage &&
                          formik.errors.slabPercentage}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg={6} className="mb-3">
                    <FormGroup>
                      <Label htmlFor="startDate">
                        Start Date <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="startDate"
                        name="startDate"
                        type="date"
                        value={formik.values.startDate}
                        min={
                          new Date(
                            new Date().setFullYear(
                              new Date().getFullYear() - 2,
                            ),
                          )
                            .toISOString()
                            .split("T")[0]
                        } // Minimum date is 2 years ago
                        // max={new Date().toISOString().split("T")[0]} // Maximum date is today
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.startDate && !!formik.errors.startDate
                        }
                      />
                      <FormFeedback>
                        {formik.touched.startDate && formik.errors.startDate}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col lg={6} className="mb-3">
                    <FormGroup>
                      <Label htmlFor="endDate">
                        End Date <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="endDate"
                        name="endDate"
                        type="date"
                        min={formik.values.startDate}
                        //   max={endDateMax}
                        // min={
                        //   new Date(new Date().setFullYear(new Date().getFullYear() - 2))
                        //     .toISOString()
                        //     .split("T")[0]
                        // } // Minimum date is 2 years ago
                        // max={new Date().toISOString().split("T")[0]}
                        value={formik.values.endDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.endDate && !!formik.errors.endDate
                        }
                      />
                      <FormFeedback>
                        {formik.touched.endDate && formik.errors.endDate}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="text-end">
                    <Button type="submit" color="primary">
                      {editIndex !== null ? "Update Target" : "Add Target"}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form>

          {targets.length > 0 && (
            <Row className="justify-content-center mt-4">
              <Col>
                {/* <Label>Targets</Label> */}
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Basic Target Amount</th>
                      <th>Slab Percentage</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      {!isViewMode && <th>Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {targets
                      .filter(entry => entry.Status !== "Inactive")
                      .map((target, index) => (
                        <tr key={index}>
                          <td>{target.basicTargetAmount}</td>
                          <td>{target.slabPercentage}%</td>
                          <td>{target.startDate}</td>
                          <td>{target.endDate}</td>
                          {!isViewMode && (
                            <td>
                              <Button
                                size="sm"
                                onClick={() => handleEdit(index)}
                                // color="warning"
                              >
                                Edit
                              </Button>{" "}
                              <Button
                                size="sm"
                                onClick={() => handleDelete(index)}
                                color="danger"
                              >
                                Delete
                              </Button>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
      {/* payment terms */}
      <Card>
        <CardHeader>Payment Terms</CardHeader>
        <CardBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              formik1.handleSubmit()
            }}
          >
            {!isViewMode && (
              <Row className="justify-content-center align-items-center">
                <Col lg={4} md={6} sm={12} className="mb-3">
                  <FormGroup>
                    <Label htmlFor="paymentReceivedOn">
                      Payment Received On (Days){" "}
                      <span className="text-danger">*</span>
                    </Label>
                    <Input
                      id="paymentReceivedOn"
                      name="paymentReceivedOn"
                      type="text"
                      min={0} // Minimum value is 0
                      max={100} // Maximum value is 100
                      placeholder="Enter Days (0-100)"
                      value={formik1.values.paymentReceivedOn}
                      onChange={e => {
                        let value = e.target.value

                        // Validate input: allow only integers between 0 and 100
                        if (/^\d{0,3}$/.test(value)) {
                          const numericValue = parseInt(value, 10)
                          if (
                            (numericValue >= 0 && numericValue <= 100) ||
                            value === ""
                          ) {
                            formik1.setFieldValue("paymentReceivedOn", value)
                          }
                        }
                      }}
                      onBlur={e => {
                        // Ensure value is within range on blur
                        let value = parseInt(e.target.value, 10)
                        if (!isNaN(value)) {
                          if (value >= 0 && value <= 100) {
                            formik1.setFieldValue(
                              "paymentReceivedOn",
                              value.toString(),
                            )
                          } else {
                            formik1.setFieldValue("paymentReceivedOn", "") // Reset invalid input
                          }
                        }
                      }}
                      // onChange={formik1.handleChange}
                      // onBlur={formik1.handleBlur}
                      invalid={
                        formik1.touched.paymentReceivedOn &&
                        !!formik1.errors.paymentReceivedOn
                      }
                    />
                    <FormFeedback>
                      {formik1.touched.paymentReceivedOn &&
                        formik1.errors.paymentReceivedOn}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col lg={4} md={6} sm={12} className="mb-3">
                  <FormGroup>
                    <Label htmlFor="paymentDiscount">
                      Payment Discount (%)<span className="text-danger">*</span>
                    </Label>
                    <Input
                      id="paymentDiscount"
                      name="paymentDiscount"
                      type="text"
                      placeholder="Enter Discount (%)"
                      value={formik1.values.paymentDiscount}
                      onChange={e => {
                        let value = e.target.value

                        // Validate input: allow numbers with up to 2 decimal places
                        if (
                          /^(\d{1,3})(\.\d{0,2})?$/.test(value) ||
                          value === ""
                        ) {
                          // Ensure value is within the range 0 to 100
                          const numericValue = parseFloat(value)
                          if (
                            (numericValue >= 0 && numericValue <= 100) ||
                            value === ""
                          ) {
                            formik1.setFieldValue("paymentDiscount", value)
                          }
                        }
                      }}
                      onBlur={e => {
                        // Ensure value is formatted to 2 decimal places on blur
                        let value = parseFloat(e.target.value)
                        if (!isNaN(value)) {
                          if (value >= 0 && value <= 100) {
                            formik1.setFieldValue(
                              "paymentDiscount",
                              value.toFixed(2),
                            )
                          } else {
                            formik1.setFieldValue("paymentDiscount", "") // Reset invalid input
                          }
                        }
                      }}
                      // onChange={formik1.handleChange}
                      // onBlur={formik1.handleBlur}
                      invalid={
                        formik1.touched.paymentDiscount &&
                        !!formik1.errors.paymentDiscount
                      }
                    />
                    <FormFeedback>
                      {formik1.touched.paymentDiscount &&
                        formik1.errors.paymentDiscount}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col lg={4} md={12} className="mb-3 text-center">
                  <Button
                    type="submit"
                    color="primary"
                    onClick={e => {
                      e.preventDefault() // Prevent default form submission
                      formik1.handleSubmit() // Trigger form validation
                      formik1.setTouched({
                        paymentReceivedOn: true,
                        paymentDiscount: true,
                      }) // Mark fields as touched to show validation errors
                    }}
                  >
                    {editIndex1 !== null
                      ? "Update Payment Term"
                      : "Add Payment Term"}
                  </Button>
                </Col>
              </Row>
            )}
          </Form>

          {terms.length > 0 && (
            <Row className="justify-content-center mt-4">
              <Col>
                {/* <Label>Payment Terms</Label> */}
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Payment Received On (Days)</th>
                      <th>Payment Discount (%)</th>
                      {!isViewMode && <th>Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {terms.length > 0 &&
                      terms
                        .filter(entry => entry.Status !== "Inactive")
                        .map((term, index) => (
                          <tr key={index}>
                            <td>{term.paymentReceivedOn} Days</td>
                            <td>{term.paymentDiscount}%</td>
                            {!isViewMode && (
                              <td>
                                <Button
                                  size="sm"
                                  onClick={() => handleEdit1(index)}
                                  //   color="warning"
                                >
                                  Edit
                                </Button>{" "}
                                <Button
                                  size="sm"
                                  onClick={() => handleDelete1(index)}
                                  color="danger"
                                >
                                  Delete
                                </Button>
                              </td>
                            )}
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
      {!isViewMode && (
        <Row
          className="justify-content-between"
          style={{ paddingRight: "20px", paddingLeft: "20px" }}
        >
          <Col className="d-flex justify-content-start">
            <button
              type="button"
              onClick={previous}
              className="btn btn-secondary large-button"
            >
              Previous
            </button>
          </Col>
          <Col className="d-flex justify-content-end">
            <button
              type="button"
              onClick={handleFinalSubmit}
              className="btn btn-primary large-button"
            >
              Save & Next
            </button>
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

export default TargetandPaymentTerms
