const initialState = {
    error: "",
    loading: false,
    tasks: [],
    data: null,
    workReportData: null,
    projectInfoById: null,
    showTaskGetList: false,
    filteredDataTodayRedux: [],
};

const AddUpdateWorkReport = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_UPDATE_WORK_REPORT":
        case "GET_ALL_TASKS":
            return {
                ...state,
                loading: true,
            };

        case "ADD_UPDATE_WORK_REPORT_SUCCESS":
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
            };

        case "GET_WORK_REPORT_BY_DATE_SUCCESS":
            return {
                ...state,
                loading: false,
                workReportData: action.payload,
                error: "",
            };
    
        case "WORK_REPORT_API_ERROR":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "SET_SHOW_WORK_REPORT_LIST":
            return {
                ...state,
                showWorkReportGetList: action.payload,
            };

        case "SET_FILTERED_DATA_TODAY_REDUX":
            return {
                ...state,
                filteredDataTodayRedux: action.payload,
            };

        case "CLEAR_FILTERED_DATA_TODAY_REDUX":
            return {
                ...state,
                filteredDataTodayRedux: null,
            }; 

        default:
            return state;
    }
};

export default AddUpdateWorkReport;
