import { call, put, takeEvery, select, delay } from "redux-saga/effects"
import {
  setShowOrderGetList,
  GetInvoiceNumberSuccess,
  GetOrderListSuccess,
  OrderAPIError,
} from "./actions"
import axios from "axios"
import * as url from "../../../src/helpers/url_helper"
import { toast } from "react-toastify"

function* GetOrderList(input) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select(state => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(
      axios.post,
      url.GET_ORDER_LIST,
      input.payload,
      config,
    )
    if (response.status >= 200 && response.status < 300) {
      yield put(GetOrderListSuccess(response.data)) // Dispatch success action
    } else {
      throw new Error("Failed to fetch item details")
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.error ||
      err.response?.statusText ||
      "An error occurred"
    yield put(OrderAPIError(errorMessage))
    toast.error(errorMessage)
  }
}

// Watcher saga
function* OrderSaga() {
  yield takeEvery("GET_ORDER_LIST", GetOrderList)
}
export default OrderSaga
