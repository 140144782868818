export const setShowOrderGetList = ShowOrderGetList => ({
  type: "SET_SHOW_ORDER_LIST",
  payload: ShowOrderGetList,
})

// Get Invoice Number Action
export const GetOrderNumber = () => {
  return {
    type: "GET_ORDER_NUMBER",
  }
}

export const GetOrderList = inputdata => ({
  type: "GET_ORDER_LIST",
  payload: inputdata,
})

export const GetOrderListSuccess = OrderList => {
  return {
    type: "GET_ORDER_LIST_SUCCESS",
    payload: OrderList,
  }
}

export const OrderAPIError = error => {
  return {
    type: "ORDER_API_ERROR",
    payload: error,
  }
}
