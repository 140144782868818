import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Label, FormFeedback, Form, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { AddUpdateTask, ClearDefaultProject, ClearTaskDefaults, GetDropdown, GetMultipleDropdown, GetProjectInfoById, GetTasks, setShowTaskGetList, UploadAttachment, UploadAttachmentClear } from "../../store/actions";
import { toast } from "react-toastify";
import axios from "axios";
import * as url from "../../helpers/url_helper";
const apiUrl = process.env.REACT_APP_API_URL;

const formatDate = (date) => {
    if (!date) return "0000-00-00";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const AddTask = (props) => {
    const dispatch = useDispatch();
    const tasksFromStore = useSelector((state) => state.Task?.tasks?.data) || [];
    const projectInfoById = useSelector((state) => state.Task?.projectInfoById?.data) || [];
    const multipleDropdownList = useSelector((state) => state.CommonAPI?.multipleDropdownValues?.data) || [];
    const uploadedAttachment = useSelector((state) => state.CommonAPI?.attachmentData?.filePath) || [];
    const token = useSelector(state => state.Login?.token);
    const taskDefaults = useSelector((state) => state.Task?.taskDefaults) || [];
    const defaultProject = useSelector((state) => state.Task?.defaultProject) || [];
    const loading = useSelector((state) => state.Task?.loading);

    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedTaskStatus, setSelectedTaskStatus] = useState(null);
    const [selectedAssignedTo, setSelectedAssignedTo] = useState(null);
    const [selectedParentTask, setSelectedParentTask] = useState(null);
    const [startDate, setStartDate] = useState(new Date());

    const [dueDateOnly, setDueDateOnly] = useState();
    const [dueTimeOnly, setDueTimeOnly] = useState('12:00 AM');
    const [dueDateTime, setDueDateTime] = useState(null);

    const [selectedHour, setSelectedHour] = useState("12");
    const [selectedMinute, setSelectedMinute] = useState("00");
    const [amPm, setAmPm] = useState("AM");

    const [selectedFollower, setSelectedFollower] = useState(null);
    const [followersList, setFollowersList] = useState([]);
    const [selectedTag, setSelectedTag] = useState(null);
    const [tagsList, setTagsList] = useState([]);

    const [inputProjectValue, setInputProjectValue] = useState('');
    const [inputAssignedToValue, setInputAssignedToValue] = useState('');
    const [inputFollowerValue, setInputFollowerValue] = useState('');
    const [inputTagValue, setInputTagValue] = useState('');
    const [inputParentTaskIDValue, setInputParentTaskIDValue] = useState('');

    const [inputProjectFocus, setInputProjectFocus] = useState(false);
    const [inputAssignedToFocus, setInputAssignedToFocus] = useState(false);
    const [inputFollowerFocus, setInputFollowerFocus] = useState(false);
    const [inputTagFocus, setInputTagFocus] = useState(false);
    const [inputParentTaskIDFocus, setInputParentTaskIDFocus] = useState(false);

    const [specifyTimeToggle, setSpecifyTimeToggle] = useState(false);
    const [attachmentModal, setAttachmentModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [inputLink, setInputLink] = useState("");
    const [attachments, setAttachments] = useState([]);

    const [projectList, setProjectList] = useState([]);
    const [taskTypeList, setTaskTypeList] = useState([]);
    const [projectStatusList, setProjectStatusList] = useState([]);

    const toggleSpecifyTime = () => {
        setSpecifyTimeToggle(!specifyTimeToggle);
    };

    const today = new Date();
    const minDate = new Date();
    minDate.setDate(today.getDate() - 7);

    useEffect(() => {
        dispatch(GetMultipleDropdown({
            "Input": [{ "StringMapType": "PROJECTLIST", "OrderBy": "StringMapName" }, { "StringMapType": "TaskType", "OrderBy": "StringMapName" }, { "StringMapType": "ProjectStatusList", "OrderBy": "StringMapName" }]
        }));
    }, [dispatch])

    useEffect(() => {
        const projectListFiltered = multipleDropdownList.filter(item => item.StringMapType === "PROJECTLIST");
        const taskTypeListFiltered = multipleDropdownList.filter(item => item.StringMapType === "TaskType");
        const projectStatusListFiltered = multipleDropdownList.filter(item => item.StringMapType === "ProjectStatusList");

        setProjectList(projectListFiltered);
        setTaskTypeList(taskTypeListFiltered);
        setProjectStatusList(projectStatusListFiltered);
    }, [multipleDropdownList]);

    useEffect(() => {
        dispatch(GetTasks());
    }, [dispatch]);

    useEffect(() => {
        if (selectedProject?.value) {
            dispatch(GetProjectInfoById(selectedProject.value));
        }
    }, [selectedProject, dispatch]);

    useEffect(() => {
        if (defaultProject && defaultProject !== "All") {
            const project = projectList.find(
                (proj) => proj.StringMapId === defaultProject
            );
            if (project) {
                setSelectedProject({
                    label: project.StringMapName,
                    value: project.StringMapId,
                    ProjectIconURL: project.ProjectIconURL,
                });
                validation.setFieldValue("ProjectID", project.StringMapId);
                dispatch(ClearDefaultProject());
            }
        }
    }, [defaultProject, projectList, dispatch]);    

    const generatePlaceholderProfile = (firstName, lastName) => {
        const initials = `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
        const canvas = document.createElement('canvas');
        canvas.width = 100;
        canvas.height = 100;
        const context = canvas.getContext('2d');
    
        context.fillStyle = '#7A6FBE';
        context.fillRect(0, 0, canvas.width, canvas.height);
    
        context.font = '50px Arial';
        context.fillStyle = 'white';
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(initials, canvas.width / 2, canvas.height / 2);
    
        return canvas.toDataURL('image/png');
    };

    const createAssociationsList = (type) => {
        if (!selectedProject) return [];
        return projectInfoById
            .filter(item => item.AssociationType === type)
            .map(item => {
                if (type === 'Access') {
                    const hasProfilePhoto = item.PicturePath;
                    const profilePhoto = hasProfilePhoto
                        ? `${apiUrl}${item.PicturePath}`
                        : generatePlaceholderProfile(item.FirstName, item.LastName);

                    return {
                        label: item.AccessName,
                        value: item.AssociationValue,
                        profilePhoto,
                        firstName: item.FirstName,
                        lastName: item.LastName,
                    };
                }
                return {
                    label: item.AssociationValue,
                    value: item.ProjectAssociationID,
                };
            });
    };

    const taskAccessList = useMemo(() => {
        const list = createAssociationsList('Access');
        return list.sort((a, b) => a.label.localeCompare(b.label));
    }, [selectedProject, projectInfoById]);    

    const taskTagList = useMemo(() => {
        const list = createAssociationsList('Tag');
        return list.sort((a, b) => a.label.localeCompare(b.label));
    }, [selectedProject, projectInfoById]);

    const taskAccessListWithoutAssignee = useMemo(() => {
        if (!selectedAssignedTo) return taskAccessList;
        return taskAccessList
            .filter((assignee) => assignee.value !== selectedAssignedTo.value)
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [taskAccessList, selectedAssignedTo]);

    const taskStatusList = useMemo(() => {
        if (!selectedProject) return [];
        return projectStatusList
            .filter(item => item.ProjectID === selectedProject.value)
            .map(item => ({
                label: item.StringMapName,
                value: item.StringMapId,
                sortOrder: item.SortOrder,
            }));
    }, [selectedProject, projectStatusList]);

    useEffect(() => {
        if (!selectedProject) {
            setSelectedTaskStatus(null);
            setSelectedAssignedTo(null);
            setSelectedParentTask(null);
        }
    }, [selectedProject]);

    useEffect(() => {
        if(!taskDefaults.projectId || !taskDefaults.parentTaskId) return;

        const selectedProj = projectList.find(
            (project) => project.StringMapId === taskDefaults.projectId
        );

        const parentTask = tasksFromStore.find(
            task => task.TaskID === taskDefaults.parentTaskId
        );

        if (parentTask) {
            setSelectedParentTask({
                label: `${parentTask.TaskNumber} - ${parentTask.TaskTitle}`,
                value: parentTask.TaskID
            });
            validation.setFieldValue("ParentTaskID", parentTask.TaskID);
        }

        if (selectedProj) {
            setSelectedProject({
                label: selectedProj.StringMapName,
                value: selectedProj.StringMapId,
                ProjectIconURL: selectedProj.ProjectIconURL
            });
            validation.setFieldValue("ProjectID", selectedProj.StringMapId);
        }
        dispatch(ClearTaskDefaults());
    }, [taskDefaults]);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            ProjectID: '',
            TaskType: '',
            TaskStatus: '',
            TaskTitle: '',
            TaskDescription: '',
            EstimatedHours: '',
            AssignedTo: '',
            EstimatedStartDate: formatDate(new Date()),
            ParentTaskID: '',
        },
        validationSchema: Yup.object({
            ProjectID: Yup.string().required("Please Select a Project"),
            TaskType: Yup.string().required("Please Select a Task Type"),
            TaskStatus: Yup.string().required("Please Select Task Status"),
            TaskTitle: Yup.string().required("Please Enter Task Title"),
            TaskDescription: Yup.string(),
            EstimatedHours: Yup.number()
                .typeError("Estimated Hours must be a number")
                .test(
                    "is-valid-float",
                    "Estimated Hours must be a multiple of 0.25",
                    (value) => !value || (value % 0.25 === 0)
                )
                .min(0, "Estimated Hours must be greater than or equal to 0")
                .max(2000, "Estimated Hours must be less than or equal to 2000"),
            AssignedTo: Yup.string(),
            EstimatedStartDate: Yup.date(),
            ParentTaskID: Yup.string()
        }),
        onSubmit: async (values) => {
            const convertTo24HourTime = (time12h) => {
                const [time, modifier] = time12h.split(' ');
                let [hours, minutes] = time.split(':');
                if (modifier === 'PM' && hours !== '12') {
                    hours = parseInt(hours, 10) + 12;
                }
                if (modifier === 'AM' && hours === '12') {
                    hours = '00';
                }
                return `${hours}:${minutes}`;
            };

            const formattedDate = dueDateOnly
            ? `${dueDateOnly.getFullYear()}-${String(dueDateOnly.getMonth() + 1).padStart(2, '0')}-${String(dueDateOnly.getDate()).padStart(2, '0')}`
            : "0000-00-00";

            const formattedTime = specifyTimeToggle ? convertTo24HourTime(dueTimeOnly) : "00:00";
            const combinedDateTime = `${formattedDate} ${formattedTime}:00`;
        
            values.DueDateTime = combinedDateTime;

            const combinedAssociations = [
                ...followersList.map(follower => ({ ...follower, type: 'Follower' })),
                ...tagsList.map(tag => ({ ...tag, type: 'Tag' }))
            ];
        
            values.Associations = combinedAssociations;
            values.Attachments = attachments;

            if (values.EstimatedHours) {
                values.EstimatedHours = parseFloat(values.EstimatedHours);
            } else {
                values.EstimatedHours = 0;
            }

            const { DueDate, DueTime, ...filteredValues } = values;

            console.log("add task filteredValues", filteredValues);
            console.log("add task values", values);
            dispatch(AddUpdateTask(filteredValues));
        }
    });

    useEffect(() => {
        if (projectList.length === 1 && (!selectedProject || selectedProject.value !== projectList[0].StringMapId)) {
            const singleProject = projectList[0];
            setSelectedProject({
                label: singleProject.StringMapName,
                value: singleProject.StringMapId,
                ProjectIconURL: singleProject.ProjectIconURL,
            });
            validation.setFieldValue("ProjectID", singleProject.StringMapId);
        }
    }, [projectList]);    

    useEffect(() => {
        if (taskStatusList.length > 0) {
            const defaultStatus = taskStatusList.find(item => item.sortOrder === 1);
            setSelectedTaskStatus(defaultStatus || null);
            validation.setFieldValue('TaskStatus', defaultStatus?.value || '');
        }
    }, [taskStatusList]);

    const handleAddItem = (list, setList, selectedItem, type) => {
        if (selectedItem && !list.some(item => item.AssociationValue === selectedItem.value)) {
            const newItem = {
                Status: "Active",
                SortOrder: list.length + 1,
                AssociationType: type,
                AssociationValue: selectedItem.value,
                AssociationName: selectedItem.label,
                ProfilePhoto: selectedItem.profilePhoto
            };
            setList([...list, newItem]);
        }
        setSelectedFollower(null);
        setSelectedTag(null);
    };

    const handleRemoveItem = (list, setList, item) => {
        setList(prevList => prevList.filter(i => i.AssociationValue !== item.AssociationValue)
            .map((i, index) => ({ ...i, SortOrder: index + 1 })));
    };

    const handleAddFollower = () => handleAddItem(followersList, setFollowersList, selectedFollower, 'Follower');
    const handleRemoveFollower = (follower) => handleRemoveItem(followersList, setFollowersList, follower);
    const handleAddTag = () => handleAddItem(tagsList, setTagsList, selectedTag, 'Tag');
    const handleRemoveTag = (tag) => handleRemoveItem(tagsList, setTagsList, tag);
      
    const handleStartDateChange = (date) => {
        setStartDate(date);
        validation.setFieldValue('EstimatedStartDate', formatDate(date));
    };

    const handleDateChange = (date) => {
        setDueDateOnly(date);
    };

    const handleHourChange = (e) => {
        const newHour = e.target.value;
        setSelectedHour(newHour);
        updateDueTime(newHour, selectedMinute, amPm);
    };
    
    const handleMinuteChange = (e) => {
        const newMinute = e.target.value;
        setSelectedMinute(newMinute);
        updateDueTime(selectedHour, newMinute, amPm);
    };
    
    const handleAmPmChange = (e) => {
        const newAmPm = e.target.value;
        setAmPm(newAmPm);
        updateDueTime(selectedHour, selectedMinute, newAmPm);
    };

    const updateDueTime = (hour = selectedHour, minute = selectedMinute, period = amPm) => {
        const formattedTime = `${hour}:${minute} ${period}`;
        setDueTimeOnly(formattedTime);
    }; 

    const generateOptions = (start, end) => {
        return Array.from({ length: end - start + 1 }, (_, i) => {
            const value = String(i + start).padStart(2, "0");
            return <option key={value} value={value}>{value}</option>;
        });
    };

    const handleSelectChange = (selectedOption, field) => {
        switch (selectedOption) {
            case 'ProjectID':
                setSelectedProject(field);
                validation.setFieldValue('ProjectID', field ? field.value : '');
                if (!field) {
                    validation.setFieldValue('TaskStatus', '');
                    validation.setFieldValue('AssignedTo', '');
                    setSelectedTaskStatus(null);
                    setSelectedAssignedTo(null);
                } else {
                    setSelectedAssignedTo(null); 
                    validation.setFieldValue('AssignedTo', '');
                }
                break;
            case 'TaskStatus':
                setSelectedTaskStatus(field);
                validation.setFieldValue('TaskStatus', field ? field.value : '');
                break;
            case 'AssignedTo':
                setSelectedAssignedTo(field);
                validation.setFieldValue('AssignedTo', field ? field.value : '');
                setFollowersList([]);
                break;
            case 'ParentTaskID':
                setSelectedParentTask(field);
                validation.setFieldValue('ParentTaskID', field ? field.value : '');
                break;
            default:
                break;
        }
    };

    const handleRemoveParentTask = () => {
        setSelectedParentTask(null);
        validation.setFieldValue('ParentTaskID', '');
    };

    const toggleAttachmentModal = () => {
        dispatch(UploadAttachmentClear());
        setAttachmentModal(!attachmentModal);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && isValidFileType(file)) {
            setSelectedFile(file);
            if (isImageFile(file)) {
                setPreview(URL.createObjectURL(file));
            } else {
                setPreview(`${file.name}`);
            }
            setInputLink("");
        } else {
            toast.error("Please select a valid PNG, JPG, CSV, XLS, or XLSX file.");
        }
    };

    const handleLinkChange = (e) => {
        const link = e.target.value;
        setInputLink(link);
        if (link.trim() !== "") {
          setSelectedFile(null);
          setPreview(null);
        }
      };

    const getFormattedTimestamp = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        return `${year}${month}${day}_${hours}${minutes}${seconds}`;
    };

    const handleAttachmentSubmit = async (event) => {
        if (event) event.preventDefault();

        if (inputLink) {
            console.log("Submitted Link:", inputLink);

            const timestamp = new Date().getTime();

            const attachment = {
                DocumentID: "",
                FileName: `${timestamp}`,
                Directory: '',
                URL: inputLink,
                Status: "Active",
                Type: "link"
            };

            setAttachments(prev => [...prev, attachment]);
            setInputLink("");
            toggleAttachmentModal();

        } else if (selectedFile) {
            if (!selectedFile || !selectedProject?.label) {
                toast.error("Please select a project and a file to upload.");
                return;
            }

            const maxFileSize = 5 * 1024 * 1024;
            if (selectedFile.size > maxFileSize) {
                toast.error("File size exceeds the 5MB limit.");
                return;
            }

            const timestamp = getFormattedTimestamp();
            const newFileName = `${selectedProject.label}_${timestamp}${selectedFile.name.substring(selectedFile.name.lastIndexOf('.'))}`;

            const renamedFile = new File([selectedFile], newFileName, { type: selectedFile.type });

            const formData = new FormData();
            formData.append('file', renamedFile);
            formData.append('ProjectName', selectedProject.label);
    
            try {
                const response = await axios.post(
                    `${url.API_URL}/task/uploadAttachment`,
                    formData,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data',
                        }
                    }
                );
    
                if (response.status >= 200 && response.status < 300) {
                    const uploadedFile = response.data; 
    
                    if (uploadedFile?.filePath) {
                        const attachment = {
                            DocumentID: "",
                            FileName: renamedFile.name,
                            Directory: `documents/attachment/${selectedProject.label}`,
                            URL: uploadedFile.filePath,
                            Status: "Active",
                            Type: "file"
                        };
    
                        setAttachments(prev => [...prev, attachment]); 
                        setSelectedFile(null); 
                        toggleAttachmentModal();
                        setPreview(null);
    
                        toast.success("File uploaded successfully.");
                    } else {
                        toast.error("Failed to retrieve file path from upload response.");
                    }
                } else {
                    toast.error("Failed to upload file.");
                }
            } catch (error) {
                console.error("Error during file upload:", error);
                toast.error("An error occurred while uploading the file.");
            }

        } else {
            console.log("No input provided.");
        }
    };

    const handleRemoveAttachment = (attachment) => {
        if (attachment.DocumentID) {
            setAttachments((prevAttachments) =>
                prevAttachments.map((att) =>
                    att.DocumentID === attachment.DocumentID
                        ? { ...att, Status: 'Inactive' }
                        : att
                )
            );
        } else {
            setAttachments((prevAttachments) =>
                prevAttachments.filter((att) => att.FileName !== attachment.FileName)
            );
        }
    };
    
    const handleFileClick = (filePath, type) => {
        if (type === 'file') {
            const baseURL = url.API_URL.replace('/api', '');
            const fullURL = `${baseURL}/documents${filePath}`;
    
            window.open(fullURL, '_blank');
        } else if (type === 'link') {
            window.open(filePath, '_blank');
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file && isValidFileType(file)) {
            setSelectedFile(file);
            if (isImageFile(file)) {
                setPreview(URL.createObjectURL(file));
            } else {
                setPreview(`${file.name}`);
            }
        } else {
            toast.error("Please select a valid PNG, JPG, CSV, XLS, or XLSX file.");
        }
    };

    const isValidFileType = (file) => {
        const allowedTypes = [
            'image/png', 'image/jpeg', 'text/csv', 
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ];
        return allowedTypes.includes(file.type);
    };

    const isImageFile = (file) => {
        if (file && file.type) {
            return file.type.startsWith('image/');
        }
        return false;
    };

    const selectStyles = (hasError) => ({
        control: (provided, state) => ({
            ...provided,
            borderColor: hasError ? 'red' : provided.borderColor,
            boxShadow: 'none',
            '&:hover': {
                borderColor: hasError ? 'red' : provided['&:hover'].borderColor,
            },
        }),
        outline: "none",
    });

    const selectStyles2 = (hasError) => ({
        control: (provided, state) => ({
            ...provided,
            borderColor: hasError ? 'red' : provided.borderColor,
            boxShadow: 'none',
            '&:hover': {
                borderColor: hasError ? 'red' : provided['&:hover'].borderColor,
            },
        }),
        dropdownIndicator: () => ({
            display: 'none',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        outline: "none",
    });

    const selectStylesWithImage = (hasError) => ({
        control: (provided, state) => ({
            ...provided,
            borderColor: hasError ? 'red' : provided.borderColor,
            boxShadow: 'none',
            minHeight: '38px',
            height: '38px',
            '&:hover': {
                borderColor: hasError ? 'red' : provided['&:hover'].borderColor,
            },
        }),
        dropdownIndicator: () => ({
            display: 'none',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0 8px',
            display: 'flex',
            alignItems: 'center',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            margin: 0,
        }),
        outline: "none",
    });

    const selectStylesWithImage2 = (hasError) => ({
        control: (provided, state) => ({
            ...provided,
            borderColor: hasError ? 'red' : provided.borderColor,
            boxShadow: 'none',
            minHeight: '38px',
            height: '38px',
            '&:hover': {
                borderColor: hasError ? 'red' : provided['&:hover'].borderColor,
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0 8px',
            display: 'flex',
            alignItems: 'center',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            margin: 0,
        }),
        outline: "none",
    });

    const CustomOption = (props) => (
        <div {...props.innerProps} className="d-flex align-items-center p-2">
            <img src={props.data.ProjectIconURL} alt="" style={{ width: 20, height: 20, marginRight: 8 }} />
            <span>{props.data.label}</span>
        </div>
    );

    const CustomSingleValue = (props) => (
        <div className="d-flex align-items-center" style={{ height: '100%', margin: 0, padding: 0 }}>
            <img src={props.data.ProjectIconURL} alt="" style={{ width: 20, height: 20, marginRight: 8 }} />
            <span>{props.data.label}</span>
        </div>
    );  

    const CustomOption2 = (props) => (
        <div {...props.innerProps} className="d-flex align-items-center p-2">
            <img src={props.data.profilePhoto} alt="" style={{ width: 24, height: 24, marginRight: 8, borderRadius: '50%', objectFit: 'cover' }} />
            <span>{props.data.label}</span>
        </div>
    );

    const CustomSingleValue2 = (props) => (
        <div className="d-flex align-items-center" style={{ height: '100%', margin: 0, padding: 0 }}>
            <img 
                src={props.data.profilePhoto}
                alt={props.data.label || 'Profile Image'} 
                style={{ width: 24, height: 24, marginRight: 8, borderRadius: '50%', objectFit: 'cover' }} 
            />
            <span>{props.data.label}</span>
        </div>
    );    

    return (
        <React.Fragment>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit()
                    return false;
                }}
            >

                <Row className="mb-3">
                    <div className="col-form-label">
                        Fields marked with <span className="text-danger">*</span> are mandatory.
                    </div>
                </Row>

                {/* Project Code */}
                <Row>
                    <Col lg={3} className="mb-2">
                        <Label htmlFor="ProjectID" className="col-form-label">
                            Select Project<span className="text-danger">*</span>
                        </Label>
                    </Col>
                    <Col lg={4} className="mb-2">
                        <Select
                            id="ProjectID"
                            name="ProjectID"
                            options={projectList.map((value) => ({
                                label: value.StringMapName,
                                value: value.StringMapId,
                                ProjectIconURL: value.ProjectIconURL,
                            }))}
                            onChange={(selectedOption) => handleSelectChange('ProjectID', selectedOption)}
                            value={selectedProject}
                            // value={inputProjectFocus ? "" : selectedProject}
                            // placeholder={inputProjectFocus ? "" : "Select Project..."}
                            placeholder={"Select Project..."}
                            isSearchable={true}
                            // inputValue={inputProjectValue}
                            // onInputChange={(value) => setInputProjectValue(value)}
                            // menuIsOpen={inputProjectValue.length > 0}
                            // onFocus={() => setInputProjectFocus(true)}
                            // onBlur={() => setInputProjectFocus(false)}
                            styles={selectStylesWithImage2(validation.touched.ProjectID && validation.errors.ProjectID)}
                            className="mb-3"
                            classNamePrefix="react-select"
                            components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                        />

                        {validation.touched.ProjectID && validation.errors.ProjectID ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.ProjectID}</FormFeedback>
                        ) : null}
                    </Col>
                </Row>

                {/* TaskType Code */}
                <Row>
                    <Col lg={3} className="mb-2">
                        <Label htmlFor="TaskType" className="col-form-label">
                            Task Type<span className="text-danger">*</span>
                        </Label>
                    </Col>
                    <Col lg={6} className="mb-2">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            {taskTypeList.map((taskType) => {
                                const isSelected = validation.values.TaskType === taskType.StringMapId;

                                return (
                                    <div
                                        key={taskType.StringMapId}
                                        className={`task-tile ${isSelected ? "selected" : ""}`}
                                        onClick={() => validation.setFieldValue("TaskType", taskType.StringMapId)}
                                    >
                                        <div className="tile-image-container">
                                            <img src={`${apiUrl}${taskType.TaskTypeIcon}`} alt={taskType.StringMapName} className="tile-image" />
                                            {isSelected && <div className="tile-checkmark">✔</div>}
                                        </div>
                                        <div className="tile-label">{taskType.StringMapName}</div>
                                    </div>
                                );
                            })}
                        </div>
                        {validation.touched.TaskType && validation.errors.TaskType ? (
                            <FormFeedback type="invalid" className="d-block">
                                {validation.errors.TaskType}
                            </FormFeedback>
                        ) : null}
                    </Col>
                </Row>

                {/* TaskStatus Code */}
                <Row>
                    <Col lg={3} className="mb-2">
                        <Label htmlFor="TaskStatus" className="col-form-label">
                            Status<span className="text-danger">*</span>
                        </Label>
                    </Col>
                    <Col lg={3} className="mb-2">
                        <Select
                            id="TaskStatus"
                            name="TaskStatus"
                            options={taskStatusList}
                            onChange={(selectedOption) => handleSelectChange('TaskStatus', selectedOption)}
                            onBlur={validation.handleBlur}
                            value={selectedTaskStatus}
                            placeholder="Select Task Status"
                            isSearchable={true}
                            styles={selectStyles(validation.touched.TaskStatus && validation.errors.TaskStatus)}
                            className="mb-3"
                            classNamePrefix="react-select"
                        />

                        {validation.touched.TaskStatus && validation.errors.TaskStatus ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.TaskStatus}</FormFeedback>
                        ) : null}
                    </Col>
                </Row>

                {/* TaskTitle Code */}
                <Row>
                    <Col lg={3} className="mb-3">
                        <Label htmlFor="TaskTitle" className="col-form-label">
                            Task Title<span className="text-danger">*</span>
                        </Label>
                    </Col>
                    <Col lg={9} className="mb-3">
                    <Input
                                id="TaskTitle"
                                name="TaskTitle"
                                type="text"
                                className="form-control validate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.TaskTitle}
                                invalid={
                                    validation.touched.TaskTitle && validation.errors.TaskTitle
                                        ? true
                                        : false
                                } />

                        {validation.touched.TaskTitle && validation.errors.TaskTitle ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.TaskTitle}</FormFeedback>
                        ) : null}
                    </Col>
                </Row>

                {/* TaskDescription Code */}
                <Row>
                    <Col lg={3} className="mb-3">
                        <Label htmlFor="TaskDescription" className="col-form-label">
                            Description
                        </Label>
                    </Col>
                    <Col lg={9} className="mb-3">
                    <Input
                                id="TaskDescription"
                                name="TaskDescription"
                                type="textarea"
                                className="form-control validate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.TaskDescription}
                                invalid={
                                    validation.touched.TaskDescription && validation.errors.TaskDescription
                                        ? true
                                        : false
                                } />
                    </Col>
                </Row>

                {/* Attachment Code */}
                <Row>
                    <Col lg={3} className="mb-3">
                        <Label htmlFor="TaskDescription" className="col-form-label">
                            Attachment
                        </Label>
                    </Col>
                    <Col lg={9} className="mb-3">
                        <Row>
                            <div
                                onClick={selectedProject ? toggleAttachmentModal : null}
                                style={{
                                    display: 'inline-flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '32px',
                                    height: '32px',
                                    cursor: selectedProject ? 'pointer' : 'not-allowed',
                                    color: selectedProject ? 'inherit' : '#ccc',
                                }}
                            >
                                <i
                                    className="mdi mdi-paperclip"
                                    style={{
                                        fontSize: '24px',
                                        pointerEvents: selectedProject ? 'auto' : 'none',
                                    }}
                                ></i>
                            </div>
                        </Row>
                        <Row>
                            {attachments.length > 0 ? (
                                <div style={{ display: 'flex', flexWrap: 'wrap' }} className="d-flex">
                                    {attachments
                                        .filter((attachment) => {
                                            return attachment.Status === 'Active';
                                        })
                                        .map((attachment, index) => (
                                            <div
                                                key={index}
                                                className="d-flex flex-row align-items-center mt-2 me-3 mb-2 p-2 border rounded"
                                            >
                                                <span
                                                    style={{ cursor: "pointer" }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleFileClick(attachment.URL, attachment.Type);
                                                    }}
                                                >{attachment.FileName}</span>
                                                <Button
                                                    color="danger"
                                                    size="sm"
                                                    className="ms-2"
                                                    style={{ padding: '0px 5px', borderRadius: "50%" }}
                                                    onClick={() => handleRemoveAttachment(attachment)}
                                                >
                                                    &times;
                                                </Button>
                                            </div>
                                        ))}
                                </div>
                            ) : (
                                <div>No attachments available.</div>
                            )}
                        </Row>
                    </Col>
                </Row>

                {/* EstimatedHours Code */}
                <Row>
                    <Col lg={3} className="mb-3">
                        <Label htmlFor="EstimatedHours" className="col-form-label">
                            Estimated Hours
                        </Label>
                    </Col>
                    <Col lg={3} className="mb-3">
                    <Input
                                id="EstimatedHours"
                                name="EstimatedHours"
                                type="text"
                                className="form-control validate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.EstimatedHours}
                                invalid={
                                    validation.touched.EstimatedHours && validation.errors.EstimatedHours
                                        ? true
                                        : false
                                } />

                        {validation.touched.EstimatedHours && validation.errors.EstimatedHours ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.EstimatedHours}</FormFeedback>
                        ) : null}
                    </Col>
                </Row>

                {/* AssignedTo Code */}
                <Row>
                    <Col lg={3} className="mb-3">
                        <Label htmlFor="AssignedTo" className="col-form-label">
                            Assigned To
                        </Label>
                    </Col>
                    <Col lg={3} className="mb-3">
                    <Select
                            id="AssignedTo"
                            name="AssignedTo"
                            options={taskAccessList}
                            onChange={(selectedOption) => handleSelectChange('AssignedTo', selectedOption)}
                            value={selectedAssignedTo}
                            placeholder={inputAssignedToFocus ? "" : "Select Assignee..."}
                            isSearchable={true}
                            // onFocus={() => setInputAssignedToFocus(true)}
                            // onBlur={() => setInputAssignedToFocus(false)}
                            // inputValue={inputAssignedToValue}
                            // onInputChange={(value) => setInputAssignedToValue(value)}
                            // menuIsOpen={inputAssignedToValue.length > 0}
                            // styles={selectStyles(validation.touched.AssignedTo && validation.errors.AssignedTo)}
                            styles={selectStylesWithImage2(validation.touched.AssignedTo && validation.errors.AssignedTo)}
                            className="mb-3"
                            classNamePrefix="react-select"
                            components={{ Option: CustomOption2, SingleValue: CustomSingleValue2 }}
                        />
                        {validation.touched.AssignedTo && validation.errors.AssignedTo ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.AssignedTo}</FormFeedback>
                        ) : null}
                    </Col>
                </Row>

                {/* EstimatedStartDate Code */}
                <Row>
                    <Col lg={3} className="mb-3">
                        <Label htmlFor="EstimatedStartDate" className="col-form-label">
                            Start Date
                        </Label>
                    </Col>
                    <Col lg={3} className="mb-3">
                        <DatePicker
                            selected={startDate}
                            onChange={handleStartDateChange}
                            dateFormat="dd-MM-yyyy"
                            className="form-control"
                            id="EstimatedStartDate"
                            minDate={minDate}
                            placeholderText="Select Start Date"
                        />
                        {validation.touched.EstimatedStartDate && validation.errors.EstimatedStartDate ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.EstimatedStartDate}</FormFeedback>
                        ) : null}
                    </Col>
                </Row>

                {/* DueDateTime Code */}
                <Row>
                    <Col lg={3} className="mb-3">
                        <Label htmlFor="DueDate" className="col-form-label">
                            Due Date
                        </Label>
                    </Col>
                    <Col lg={3} className="mb-3">
                        <DatePicker
                            selected={dueDateOnly}
                            onChange={handleDateChange}
                            dateFormat="dd-MM-yyyy"
                            className="form-control"
                            id="DueDate"
                            placeholderText="Select Due Date"
                            minDate={startDate}
                        />
                    </Col>

                    {/* {!specifyTimeToggle && ( */}
                        <Col lg={1} className="mb-2" style={specifyTimeToggle ? { marginTop: "6px" } : {}}>
                            <div style={{ cursor: "pointer", textDecoration: "underline" }} onClick={toggleSpecifyTime}>
                                {specifyTimeToggle ? "Hide Time" : "Specify Time"}
                            </div>
                        </Col>
                    {/* )} */}

                    {specifyTimeToggle && (
                        <>
                            <Col lg={2} className="mb-2">
                                <Label htmlFor="DueTime" className="col-form-label">
                                    Due Time
                                </Label>
                            </Col>
                            <Col lg={3} className="mb-2 d-flex">
                                <Input
                                    type="select"
                                    id="Hour"
                                    name="Hour"
                                    value={selectedHour}
                                    onChange={handleHourChange}
                                    className="me-2"
                                >
                                    {generateOptions(1, 12)}
                                </Input>
                                <Input
                                    type="select"
                                    id="Minute"
                                    name="Minute"
                                    value={selectedMinute}
                                    onChange={handleMinuteChange}
                                    className="me-2"
                                >
                                    {generateOptions(0, 59)}
                                </Input>
                                <Input
                                    type="select"
                                    id="AmPm"
                                    name="AmPm"
                                    value={amPm}
                                    onChange={handleAmPmChange}
                                >
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                </Input>
                            </Col>
                        </>
                    )}
                </Row>

                {/* ParentTaskID Code */}
                <Row>
                    <Col lg={3} className="mb-3">
                        <Label htmlFor="ParentTaskID" className="col-form-label">
                        Parent Task
                        </Label>
                    </Col>
                    <Col lg={3} className="mb-3">
                    <Select
                            id="ParentTaskID"
                            name="ParentTaskID"
                            options={
                                selectedProject
                                    ? tasksFromStore
                                          .filter((task) => task.ProjectID === selectedProject.value)
                                          .map((task) => ({
                                              label: `${task.TaskNumber} - ${task.TaskTitle}`,
                                              value: task.TaskID,
                                          }))
                                    : []
                            }
                            onChange={(selectedOption) => handleSelectChange('ParentTaskID', selectedOption)}
                            value={selectedParentTask}
                            placeholder={inputParentTaskIDFocus ? "" : "Select Parent Task..."}
                            isSearchable={true}
                            onFocus={() => setInputParentTaskIDFocus(true)}
                            onBlur={() => setInputParentTaskIDFocus(false)}
                            inputValue={inputParentTaskIDValue}
                            onInputChange={(value) => setInputParentTaskIDValue(value)}
                            menuIsOpen={inputParentTaskIDValue.length > 0}
                            styles={selectStyles2(validation.touched.ParentTaskID && validation.errors.ParentTaskID)}
                            className="mb-3"
                            classNamePrefix="react-select"
                        />

                        {validation.touched.ParentTaskID && validation.errors.ParentTaskID ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.ParentTaskID}</FormFeedback>
                        ) : null}
                    </Col>
                    {selectedParentTask &&
                        <Col lg={3} className="mb-3">
                            <Button
                                color="danger"
                                className="ms-2"
                                onClick={handleRemoveParentTask}
                            >
                                x
                            </Button>
                        </Col>
                    }
                </Row>

                {/* Followers Code */}
                <Row>
                    <Col lg={3} className="mb-3">
                        <Label htmlFor="Followers" className="col-form-label">
                            Followers
                        </Label>
                    </Col>
                    <Col lg={3} className="mb-3">
                        <Select
                            id="Followers"
                            name="Followers"
                            // options={taskAccessList}
                            options={taskAccessListWithoutAssignee}
                            value={selectedFollower}
                            onChange={setSelectedFollower}
                            placeholder={inputFollowerFocus ? "" : "Select Follower..."}
                            isSearchable={true}
                            onFocus={() => setInputFollowerFocus(true)}
                            onBlur={() => setInputFollowerFocus(false)}
                            inputValue={inputFollowerValue}
                            onInputChange={(value) => setInputFollowerValue(value)}
                            menuIsOpen={inputFollowerValue.length > 0}
                            className="mb-3"
                            classNamePrefix="react-select"
                            styles={selectStylesWithImage()}
                            components={{ Option: CustomOption2, SingleValue: CustomSingleValue2 }}
                        />
                    </Col>
                    <Col lg={3} className="mb-3">
                        <Button
                            color="primary"
                            className="ms-2"
                            onClick={handleAddFollower}
                            disabled={!selectedFollower}
                        >
                            +
                        </Button>
                    </Col>
                </Row>

                {followersList.length > 0 && (
                    <Row>
                        <Col lg={3} className="mb-3"></Col>
                        <Col lg={9} className="mb-3">
                            <div className="d-flex flex-wrap">
                                {followersList.map((follower, index) => (
                                    <div
                                        key={index}
                                        className="d-flex align-items-center me-3 mb-2 p-2 border rounded"
                                    >
                                        <img
                                            src={follower.ProfilePhoto}
                                            alt={follower.AssociationName || 'Profile Image'}
                                            style={{ width: 24, height: 24, marginRight: 8, borderRadius: '50%', objectFit: 'cover' }}
                                        />
                                        <span>{follower.AssociationName}</span>
                                        <Button
                                            color="danger"
                                            size="sm"
                                            className="ms-2"
                                            style={{ padding: '0px 5px', borderRadius: "50%" }}
                                            onClick={() => handleRemoveFollower(follower)}
                                        >
                                            &times;
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                )}

                {/* Tags Code here */}
                <Row>
                    <Col lg={3} className="mb-3">
                        <Label htmlFor="Tags" className="col-form-label">
                            Tags
                        </Label>
                    </Col>
                    <Col lg={3} className="mb-3">
                        <Select
                            id="Tags"
                            name="Tags"
                            options={taskTagList}
                            value={selectedTag}
                            onChange={setSelectedTag}
                            placeholder={inputTagFocus ? "" : "Select a Tag..."}
                            isSearchable={true}
                            onFocus={() => setInputTagFocus(true)}
                            onBlur={() => setInputTagFocus(false)}
                            inputValue={inputTagValue}
                            onInputChange={(value) => setInputTagValue(value)}
                            menuIsOpen={inputTagValue.length > 0}
                            className="mb-3"
                            classNamePrefix="react-select"
                            styles={selectStylesWithImage()}
                        />
                    </Col>
                    <Col lg={3} className="mb-3">
                        <Button
                            color="primary"
                            className="ms-2"
                            onClick={handleAddTag}
                            disabled={!selectedTag}
                        >
                            +
                        </Button>
                    </Col>
                </Row>

                {tagsList.length > 0 && (
                    <Row>
                        <Col lg={3} className="mb-3"></Col>
                        <Col lg={9} className="mb-3">
                            <div className="d-flex flex-wrap">
                                {tagsList.map((tag, index) => (
                                    <div
                                        key={index}
                                        className="d-flex align-items-center me-3 mb-2 p-2 border rounded"
                                    >
                                        <span>{tag.AssociationName}</span>
                                        <Button
                                            color="danger"
                                            size="sm"
                                            className="ms-2"
                                            onClick={() => handleRemoveTag(tag)}
                                            style={{ padding: '0px 5px', borderRadius: "50%" }}
                                        >
                                            &times;
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                )}

















                <Row className="mt-4 justify-content-end">
                    <Col lg={6} className="d-flex justify-content-end mb-3">
                        <button
                            type="button"
                            className="btn btn-danger large-button me-2"
                            onClick={() => {
                                props.setShowTaskGetList(true);
                            }}
                        >
                            Cancel
                        </button>
                        <button 
                            type="submit" 
                            className="btn btn-success large-button"
                            disabled={loading}
                        >
                            {loading ? "Submitting..." : "Submit"}
                        </button>
                    </Col>
                </Row>
            </Form>

            <Modal isOpen={attachmentModal} toggle={toggleAttachmentModal}>
                <Form onSubmit={handleAttachmentSubmit}>
                    <ModalHeader toggle={toggleAttachmentModal}>Add Attachment</ModalHeader>
                    <ModalBody>
                        <div>
                            <div
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                                style={{
                                    border: '2px dashed #ccc',
                                    padding: '20px',
                                    textAlign: 'center',
                                    borderRadius: '10px',
                                    cursor: 'pointer',
                                    backgroundColor: '#f9f9f9',
                                }}
                            >
                                <p>
                                    Drag and drop a file here, or{' '}
                                    <label
                                        style={{
                                            color: '#007bff',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        click to upload
                                        <Input
                                            type="file"
                                            accept=".png, .jpg, .jpeg, .csv, .xls, .xlsx"
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                    </label>
                                </p>
                                {preview && (
                                    <div>
                                        <p>Preview:</p>
                                        {isImageFile(selectedFile) ? (
                                            <img
                                                src={preview}
                                                alt="Preview"
                                                style={{
                                                    maxWidth: '100%',
                                                    maxHeight: '200px',
                                                    marginTop: '10px',
                                                }}
                                            />
                                        ) : (
                                            <p>{preview}</p>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="mt-2">
                                <label>Or Enter a Link</label>
                                <Input
                                    type="url"
                                    placeholder="Enter a link"
                                    value={inputLink}
                                    onChange={handleLinkChange}
                                />
                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">Add</Button>
                        <Button color="secondary" onClick={toggleAttachmentModal}>Close</Button>
                    </ModalFooter>
                </Form>
            </Modal>

        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        showTaskGetList: state.Task?.showTaskGetList,
    };
};

export default connect(mapStateToProps, { setShowTaskGetList })(AddTask);

